import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, Grid, IconButton,  CircularProgress, Box, Button,  } from '@mui/material';
import TableCell, {  } from '@mui/material/TableCell';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, {  } from '@mui/material/Switch';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSnackbar } from 'notistack'
import { styled } from '@mui/material/styles';
import { DeleteOutline} from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';


// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: theme.palette.common.black,
//         color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,
//     },
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
// const IOSSwitch = styled((props) => (
//     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//     width: 40,
//     height: 20,
//     padding: 0,
//     '& .MuiSwitch-switchBase': {
//         padding: 0,
//         margin: 0,
//         transitionDuration: '300ms',
//         '&.Mui-checked': {
//             transform: 'translateX(20px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//                 opacity: 1,
//                 border: 0,
//             },
//             '&.Mui-disabled + .MuiSwitch-track': {
//                 opacity: 0.5,
//             },
//         },
//         '&.Mui-focusVisible .MuiSwitch-thumb': {
//             color: '#33cf4d',
//             border: '6px solid #fff',
//         },
//         '&.Mui-disabled .MuiSwitch-thumb': {
//             color:
//                 theme.palette.mode === 'light'
//                     ? theme.palette.grey[100]
//                     : theme.palette.grey[600],
//         },
//         '&.Mui-disabled + .MuiSwitch-track': {
//             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxSizing: 'border-box',
//         width: 18.5,
//         height: 18.5,
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 18.5 / 2,
//         backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
//         opacity: 1,
//         transition: theme.transitions.create(['background-color'], {
//             duration: 500,
//         }),
//     },
// }));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DataTable() {
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }

    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    // const [count, setCount] = React.useState(0)
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [subscriptionDetails, setSubscriptionDetails] = React.useState([])
    const [searchTerm] = React.useState('')
    // const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [truebutton, setTruebutton] = React.useState(false)
    // const [addOns,setAddons]=React.useState([])
    const role = JSON.parse(localStorage.getItem("roledata"))

    const[Tableloading,setTableloading]=React.useState(true)

    React.useEffect(() => {
        getSubscriptionDetails()
    }, [ searchTerm])


    // const handleChangePage = (event, newPage) => {
    //     console.log('New page:', newPage);
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };


    const getSubscriptionDetails = () => {
        setTableloading(true)
        axios.get(process.env.REACT_APP_BASE_URL + `subscription/GetSubscription`, tokendata).then((res) => {
          
            console.log(res.data);
            
            setSubscriptionDetails(res.data.subscriptions)
            // setCount(res.data.subscriptions.length);
        }).catch((err) => {
            console.log(err);
        })
        .finally(()=>{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        })
    }




    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    const handleView = (data) => {
     
      
        
        setAlldata(data); // Ensure 'data' is correctly passed or set here
        setOpenview(true);
    }

    const handleDelete = async () => {
        setTruebutton(true)
        await axios.put(process.env.REACT_APP_BASE_URL + `subscription/deleteStripePlan/${deleteid}`, {}, tokendata,).then((res) => {
            setTruebutton(false)
            handleClose()
            getSubscriptionDetails()
            enqueueSnackbar("Subscription deleted successfully", { variant: 'success' })
        }).catch((err) => {
            console.log(err);
        }).finally(()=>{
            setTruebutton(false)
        })
    }

    const editData = (row) => {
        let obj = {
            data: row
        }
        navigate("/editsubscription", { state: obj })
    }

    const handleOpen = (id) => {
        const subscription = subscriptionDetails.find(sub => sub._id === id);
        if (subscription) {
            const stripePlanId = subscription.stripePlanId;
            setDeleteId(stripePlanId);
            setOpen(true);
        }
    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }

    const handleChangeStatus = async (e, data) => {
        const body = {
        popular:e.target.checked,
        
        };
        
        try{
       await axios.put(
        process.env.REACT_APP_BASE_URL+`subscription/updatePopular/${data._id}`,
        body,
        {headers:tokendata.headers} // Assuming tokendata.headers has your authorization token set
        );
        getSubscriptionDetails(); // Refresh subscription details after updating popular status 
        } catch(err){
        console.error("Error while toggling popular:",err);
        enqueueSnackbar("Failed updating popular status",{variant:'error'}); // Assuming you're using snackbar for error display 
        }
        };

    return (
        <Box sx={{ width: '100%' }}>
            <Box className='whiteContainer '>
                
                <TableContainer id='table'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                        <TableHead className='DT-head'>
                            <TableRow className=''>
                                {/* <StyledTableCell style={{ minWidth: 150 }} align="left" className='TRow'></StyledTableCell> */}
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Subscription Name</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Usertype</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Amount</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Popular</strong> </TableCell>

                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'> <strong>Actions</strong>  </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { Tableloading?
                            (
                                <TableRow>
                                <TableCell colSpan={7} className='nodata' ><CircularProgress sx={{ color: '#F3714D',marginLeft:'580px' }} /> </TableCell>
                                    </TableRow>
                                      
                            )
                            :
                            (subscriptionDetails && subscriptionDetails.length > 0 ? subscriptionDetails.map((row, i) => {
                                return (
                                    <TableRow className='tbody-row' key={row._id}>
                                        <TableCell component="th" scope="row" padding="none" className='DT-row'>{row.name ? row.name : '-'}</TableCell>
                                        <TableCell component="th" scope="row" padding="none" className='DT-row'>{row.usertype==='teacher'?"Teacher":row.usertype}</TableCell>
                                        <TableCell align="left" className='DT-row'>
                                            {row.currency === 'INR' ? '₹ ' : '$ '}
                                            {row.amount ? row.amount.toLocaleString('en-US', { maximumFractionDigits: 2 }) : '-'}
                                        </TableCell>
                                        <TableCell align="center" className='DT-row'  ><FormControlLabel className='m-0 justify-content-center' // Disable switch if not subscribed
                                            control={<Switch size='small' onChange={(e) => handleChangeStatus(e, row)} checked={row.popular} sx={{ m: 1 }} />} /></TableCell>

                                        <TableCell align="center" className='DT-row'>
                                            <Box className='actionbtns'>
                          {     role.UserRoles.subscription.editsubscription &&<Tooltip title="Edit">
                                                    <IconButton onClick={() => editData(row)}>
                                                        <EditOutlinedIcon className='icons text-grey' />
                                                    </IconButton>
                                                </Tooltip>}
                          { role.UserRoles.subscription.deletesubscription &&<Tooltip title="Delete">
                                                    <IconButton onClick={() => handleOpen(row._id)}>
                                                        <DeleteOutline className='icons text-grey' />
                                                    </IconButton>
                                                </Tooltip>}
                                                <Tooltip title="View">
                                                    <Box >
                                                        <VisibilityIcon className='icons text-grey' onClick={() => handleView(row)} />
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableRow><TableCell colSpan={3} className='nodata'>NO DATA FOUND</TableCell></TableRow>
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete Subscription Type?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    {truebutton === false ? <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                        : <Button className='theme-btn'>Please wait...</Button>}               
                         </DialogActions>
            </Dialog>

            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Subscription Details</DialogTitle>
                <DialogContent dividers>
                    {alldata ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant='body1'><span className='fz-15 semibold'>Subscription Name:</span> {alldata.name}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>User Type:</span> {alldata.usertype==='teacher'?"Teacher":alldata.usertype}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Amount:</span> {alldata.currency === 'INR' ? '₹ ' : '$ '}{alldata.amount}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Interval:</span> {alldata.interval}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Description:</span> {alldata.description ? alldata.description : "No Description"}</Typography>

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='body1'><span className='fz-15 semibold'>Events:</span> {alldata.noOfEvent ? alldata.noOfEvent : "0"}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Blogs:</span> {alldata.noOfBlogs ? alldata.noOfBlogs : "0"}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Courses:</span> {alldata.noOfCourse ? alldata.noOfCourse : "0"}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Podcasts:</span> {alldata.noOfPodcast ? alldata.noOfPodcast : "0"}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Videos:</span> {alldata.noOfVideos ? alldata.noOfVideos : "0"}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Images:</span> {alldata.noOfImages ? alldata.noOfImages : "0"}</Typography>
                                <Typography variant='body1'><span className='fz-15 semibold'>Community:</span> {alldata.noOfCommunity ? alldata.noOfCommunity : "0"}</Typography>
                            </Grid>

                            {/* Add-Ons Section */}
                            {alldata && alldata.addOns?.length > 0 && (
                                <Grid item xs={12}>
                                    <Typography variant='body1' className='fz-15 semibold'>Add-Ons:</Typography>
                                    {alldata.addOns.map((addOn, index) => {
                                       
                                        
                                        
                                        return(
                                        <Grid container key={index}>
                                            <Grid item xs={6}>
                                                <Typography variant='body1'><span className='fz-15 semibold'>Section Name:</span> {String(addOn.sectionName)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant='body1'><span className='fz-15 semibold'>Amount:</span> {addOn.amount_type==='USD'?"$":''}{addOn.amount}</Typography>
                                                <Typography variant='body1'><span className='fz-15 semibold'>Count:</span> {addOn.count}</Typography>
                                            </Grid>
                                        </Grid>
                                    )})}
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <Typography variant='body1'>No data available</Typography>
                    )}
                </DialogContent>


                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}

