import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Button, TextField, CircularProgress, Avatar, InputAdornment, FormControl, FormControlLabel, Card, Menu } from '@mui/material';
import { SearchOutlined, Verified } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { CloseOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import Switch, { } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import IconButton, { } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Avatarimage from "../../../assets/images/Avatar.png"
import { Pagination, Navigation } from 'swiper/modules';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
// const IOSSwitch = styled((props) => (
//     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//     width: 40,
//     height: 20,
//     padding: 0,
//     '& .MuiSwitch-switchBase': {
//         padding: 0,
//         margin: 0,
//         transitionDuration: '300ms',
//         '&.Mui-checked': {
//             transform: 'translateX(20px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//                 opacity: 1,
//                 border: 0,
//             },
//             '&.Mui-disabled + .MuiSwitch-track': {
//                 opacity: 0.5,
//             },
//         },
//         '&.Mui-focusVisible .MuiSwitch-thumb': {
//             color: '#33cf4d',
//             border: '6px solid #fff',
//         },
//         '&.Mui-disabled .MuiSwitch-thumb': {
//             color:
//                 theme.palette.mode === 'light'
//                     ? theme.palette.grey[100]
//                     : theme.palette.grey[600],
//         },
//         '&.Mui-disabled + .MuiSwitch-track': {
//             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxSizing: 'border-box',
//         width: 18.5,
//         height: 18.5,
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 18.5 / 2,
//         backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
//         opacity: 1,
//         transition: theme.transitions.create(['background-color'], {
//             duration: 500,
//         }),
//     },
// }));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FormPropsTextFields() {
    //UseState's:
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const role = JSON.parse(localStorage.getItem("roledata"))
    const menuopen = Boolean(anchorEl);
    const [alldata, setAlldata] = React.useState('');
    const [deleteid, setDeleteId] = React.useState('');
    const [catid, setCatId] = React.useState('all');

    const [loading, setLoading] = React.useState(true);



    //Pagination:
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };



    React.useEffect(() => {
        getYogaquotesDetails()
    }, [rowsPerPage, page, search, catid])



    const getYogaquotesDetails = async () => {
        setLoading(true);
        try {
            // Replace with your data fetching logic
            const res = await axios.get(process.env.REACT_APP_BASE_URL + `YogaQuote/GetYogaQuote?size=${rowsPerPage}&page=${page}&search=${search}&category_id=${catid}`, { headers: headerlocal })
            setNewdata(res.data.quote)
            setTotal(res.data.totalcount)
        } catch (error) {
            console.error('Error fetching Yoga data:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }
    

    // To Delete Yoga Quote:
    const handleDeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `YogaQuote/deleteQuote/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Yogaquote deleted successfully", { variant: 'success' })
                getYogaquotesDetails()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };


    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/edityogaquote", { state: val })
    }

    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)
    }

    const handleRemove = () => {
        setCatId('all')
        setSearch('')
        setPage(0)
    }



 

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    const handleChangeStatus = async (e, data, type) => {

        if (type === 'Public') {
            const body = {
                public: e.target.checked,
            };

            try {

                await axios.put(process.env.REACT_APP_BASE_URL + `YogaQuote/updateYogaQuote/${data._id}`, body, { headers: headerlocal }).then((res) => {
                    getYogaquotesDetails();
                }).catch((error) => {
                    enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                    console.log(error, 'errrrr');
                })

            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error", error);
            }
        }
        if (type === 'primary') {
            const body = {
                primary_quote: e.target.checked,
            };
            try {
                await axios.put(process.env.REACT_APP_BASE_URL + `YogaQuote/updateYogaQuote/${data._id}`, body, { headers: headerlocal }).then((res) => {
                    getYogaquotesDetails();
                }).catch((error) => {
                    enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                    console.log(error, 'errrrr');
                })

            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating primary ad", error);
            }
        }
    };

    return (
        <>
            <Box className="p-20">
                <Box className='flex-center flex-wrap mb-20 '>


                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={search}
                                className='textfield search'
                                onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>


                    {/* {search != '' ?
                        <Button variant="contained" className='fz-18' onClick={handleRemove} ><Close /></Button>
                        : ""} */}
                </Box>
                <Box className=''>
                    <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                        {loading ? (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                style={{ height: '100%' }}
                            >
                                <Grid item>
                                    <CircularProgress sx={{ color: '#F3714D' }} />
                                </Grid>
                            </Grid>
                        ) : newdata && newdata.length > 0 ? (
                            newdata.map((data, index) => (
                                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginTop: 2 }}>
                                    <Card className="cardGrid">
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                            aria-expanded={menuopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, data)}
                                            className='vertical_menu'
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={handleClose_menu}
                                            PaperProps={{
                                                style: {
                                                    width: '15ch',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem className='menuitems' onClick={() => handleView()}>
                                                <VisibilityIcon className='fz-16 mr-5' />View
                                            </MenuItem>
                                            {
                                                role.UserRoles?.quotes.editequotes ?

                                                    <MenuItem className='menuitems' onClick={() => handleEdit()}>
                                                        <EditIcon className='fz-16 mr-5' />Edit
                                                    </MenuItem>
                                                    : ''

                                            }

                                            {
                                                role.UserRoles.quotes.deletequotes ?
                                                    <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}>
                                                        <DeleteIcon className='fz-14 mr-5' />Delete
                                                    </MenuItem>
                                                    : ''
                                            }

                                        </Menu>
                                        <Grid container justifyContent="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <div className="carosuel" style={{ position: 'relative', width: '100%', height: '250px' }}>
                                                {data.image ? (
                                                    <img
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        src={process.env.REACT_APP_BASE_URL + `${data.image}`}
                                                        alt="Carousel Img"
                                                    />
                                                ) : (
                                                    <Avatar sx={{ width: '100%', height: '100%', objectFit: 'cover' }} src={Avatarimage} alt="Default Avatar" />
                                                )}
                                            </div>
                                        </Grid>
                                        <CardContent>
                                            <Box className='mb-10 d-flex align-items-center justify-content-between' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography noWrap className='semibold title fz-18' variant="body1" component="div">{data.QuoteName}</Typography>
                                                {data.primary_quote && (
                                                    <Typography component='div' variant='body1' className='fz-14' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Verified className='fz-18' style={{ color: '#1492fd' }} />
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box className='d-flex align-items-start justify-content-between flex-wrap'>
                                                <Box className=''>
                                                    <Typography component='div' variant='body1' className='fz-14 semibold'>Primary:</Typography>
                                                    <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<Switch size='small' onChange={(e) => handleChangeStatus(e, data, 'primary')} checked={data.primary_quote} />} />
                                                </Box>
                                                <Box className=''>
                                                    <Typography variant='body1' className='fz-14 semibold'>Quoted By:</Typography>
                                                    <Typography variant='body1' className='fz-14'>{data.Quote_by}</Typography>
                                                </Box>
                                            </Box>
                                            <Box mt={2} className='d-flex'>
                                                <Typography variant='body1' className='fz-15 semibold mr-5'>Reference:</Typography>
                                                <Typography variant='body1' className='fz-15'>{data.role}</Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p className='text-center semibold'>No data found</p>
                            </Grid>
                        )}
                    </Grid>
                    <Dialog open={open} keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        className='custom_modal'>
                        <DialogTitle>Would you like to delete this Yoga Quote?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className='cancel_btn'>No</Button>
                            <Button onClick={handleDeleteService} className='theme-btn'>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <TablePagination
                    rowsPerPageOptions={[8, 12, 16, 20, 24]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Box>

            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Yoga Quotes Details
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container columnSpacing={2} className=" mb-10 align-items-center">
                        <Grid item xs={12} md={8} lg={9}>
                            <div className="carousel">
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper"
                                    slidesPerView={1}
                                    pagination={{ clickable: true }}
                                >
                                    {alldata && alldata.allimages && alldata.allimages.length > 0 && alldata.allimages.map((tdata, i) => (
                                        <SwiperSlide key={i}>
                                            <img width={'100%'} height={250} src={`${process.env.REACT_APP_BASE_URL}${tdata}`} alt={`Slide ${i}`} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>

                            <Box mt={2}>
                                <Typography variant='body1' className='fz-15 semibold'>Quote :</Typography>
                                <Typography variant='body1' className='fz-15' >{alldata.QuoteName}</Typography>
                            </Box>

                            <Box mt={2}>
                                <Typography variant='body1' className='fz-15 semibold'>Quoted By: </Typography>
                                <Typography variant='body1' className='fz-15'>{alldata.Quote_by}</Typography>
                            </Box>

                            <Box mt={2}>
                                <Typography variant='body1' className='fz-15 semibold'>Reference: </Typography>
                                <Typography variant='body1' className='fz-15'>{alldata.role}</Typography>
                            </Box>
                        </Grid>

                        <Grid style={{ marginTop: '-200px' }} item xs={12} sm={12} md={6} lg={6}>

                        </Grid>
                    </Grid>

                    {/* <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography>
                    {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                        : <Typography className='p18_tag' >There is no description</Typography>} */}

                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}
