import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import axios from 'axios';

function FanCard() {

    const [fansdata, setfansdata] = useState({})

    useEffect(() => {



        axios.get(`${process.env.REACT_APP_BASE_URL}Admin/getAllCards`)
            .then((response) => {



                setfansdata(response.data.allCards.Fanz)

            })
            .catch(() => {
                console.log("erroe");
            })
    }, {})






    return (
        <Box sx={{
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
        }}>

            <Typography variant="h5" sx={{ marginTop: '5px', textAlign: 'left' }}>
                Fan Page
            </Typography>

            <Box
                sx={{
                    position: 'relative',
                    background: 'radial-gradient(#97426b, #b14c63, #cb5c78,#d25f77)',
                    borderRadius: '8px',
                    height: '100%',
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'space-between', // Make sure elements are spaced out
                    alignItems: 'center',
                    flexDirection: 'row',
                    overflow: 'hidden',
                    padding: '60px 20px'
                }}
            >
                <Box>


                    <Box sx={{ flex: 1, textAlign: 'left', paddingLeft: 2, marginRight: '10px' }}>
                        <Typography variant="body1" sx={{ color: 'white', marginTop: 1, fontSize: 15, }}>
                            Duis aute irure dolor
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'white', marginTop: 1, textAlign: 'left', fontSize: 25, marginLeft: 1 }}>
                            Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do.
                        </Typography>

                    </Box>
                    <Box
                        sx={{

                            border: '1px solid rgba(255, 255, 255, 0.5)',
                            borderRadius: '80%',
                            width: '250px',
                            height: '250px',
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: "column",
                            marginRight: "40px"

                        }}
                    >
                        <Box
                            sx={{

                                border: '1px solid rgba(255, 255, 255, 0.5)',
                                borderRadius: '80%',
                                width: '70%',
                                height: '70%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: "column"

                            }}
                        >

                        </Box>


                    </Box>


                </Box>




                <Box
                    sx={{
                        background: 'radial-gradient(#e06576, #e77495, #e06576)',
                        border: '1px solid white',
                        borderRadius: '50%',
                        width: 180,
                        height: 180,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginLeft: 1,
                        marginRight: '60px'
                    }}
                >
                    <Box
                        sx={{
                            background: 'radial-gradient(#f491b8, #ed7985, #f2858f)',
                            border: '1px solid white',
                            borderRadius: '50%',
                            width: '80%',
                            height: '80%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: "column"

                        }}
                    >
                        <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize: '50PX', marginTop: "50px" }}>
                            {fansdata.total}
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize: '40PX', marginLeft: '100px' }}>
                            FANZ!!
                        </Typography>

                    </Box>

                </Box>
            </Box>

        </Box>
    );
}

export default FanCard;