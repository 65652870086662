import React, { useState } from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Checkbox, Grid, Tooltip, InputAdornment, TextField, FormControlLabel, Typography, IconButton,} from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DeleteOutline } from '@mui/icons-material'

function AddSubscription() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [error, setError] = React.useState({ type: "" ,amount:''})
    const [statesingle, ] = React.useState([])
	const [statesingle2,] = React.useState([])
    // const [imageError, setImageError] = React.useState('')
    const [isToggled, setToggled] = React.useState(false)
    const [subscriptiontype, setSubscriptionType] = React.useState('')
    const [currency] = React.useState('USD')
    const [modifierArrayClicked, setModifierArrayClicked] = useState(false);
    const [interval, setInterval] = React.useState('month')
    const [subscription, setSubscription] = React.useState({ plan: "", time: "", amount: "", currency: "USD", description: "" })
    const onlyDecimalNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9,.]/g, '') };
    const restrictZero = (e) => {
        if (e.target.value.length === 0 && e.key === '0') {
            e.preventDefault();
            enqueueSnackbar("First digit cannot be zero", { variant: "error" })
        }
       
        
        if(e.target.value.length>2 && e.key!=="Backspace")
        {
            e.preventDefault();
            enqueueSnackbar("Count cannot exceed three digits", { variant: "error" })
        }
    }



    const handleToggle = () => {
		setToggled(!isToggled)
	}


    const handleSubscription = (e) => {
        let { name, value } = e.target;
        
        
        setSubscription({ ...subscription, [name]: value });
    };

    const [modifierArray, setModifierArray] = React.useState([])
 
    const [modifierOption, setModifierOption] = React.useState({ sectionName: '', amount_type: 'USD', amount: '', count: '' })
    const [checkEvent, setCheckEvent] = React.useState([
      { name: "Event", check: false, noOfValue: "" },
      { name: "Course", check: false, noOfValue: "" },
      { name: "Blogs", check: false, noOfValue: "" },
      { name: "Podcast", check: false, noOfValue: "" },
      { name: "Videos", check: false, noOfValue: "" },
      { name: "Images", check: false, noOfValue: "" },
      { name: "Community", check: false, noOfValue: "" },
      { name: "Jobs", check: false, noOfValue: "" },
      { name: "News", check: false, noOfValue: "" },
      { name: "Book Review", check: false, noOfValue: "" },
      { name: "Categories", check: false, noOfValue: "" },
      { name: "FanPages", check: false, noOfValue: "" },
    ]);
    const[lastfeild,setLastfeild]=React.useState("")

    const handleCheckEvent = (e) => {
        const { name, checked } = e.target;

        checkEvent.forEach(item => {
            if(item.name === lastfeild && item.noOfValue==="") {
              item.check = false;
              
            }
          });
        
        setCheckEvent(prevState => prevState.map(item => {
            if (item.name === name) {
                setLastfeild(name)
                return { ...item, check: checked };
            }
            return item;
        }));
    };

    // const [totalEvents, setTotalEvents] = useState(0);
    const handleChangeValue = (e, index,name) => {
        const { value } = e.target;
        const numberValue = Number(value);
       
        
        if(numberValue===0)
        {
            const event = {
                target: {
                    name: name,
                    checked: false,
                },
            };
            handleCheckEvent(event);
            setCheckEvent(prevState => {
                const updatedCheckEvent = [...prevState];
                updatedCheckEvent[index] = { ...updatedCheckEvent[index], noOfValue: "" };
                return updatedCheckEvent;
            });
        }
        else{
            setCheckEvent(prevState => {
                const updatedCheckEvent = [...prevState];
                updatedCheckEvent[index] = { ...updatedCheckEvent[index], noOfValue: numberValue };
                return updatedCheckEvent;
            });
        }
        
    };

    const handleTotal = (e, sectionName, index) => {
        // const { value } = e.target;
        // const numberValue = Number(value);
        if (sectionName === 'Event') {
            // setTotalEvents(prev => prev + numberValue);
        }
    };
    const [View,setView]=useState(true)

    const handleChangeSection = (e) => {
        const { name, value } = e.target;
        if(value)
        {
            setView(false)
        }
        setModifierOption(prev => ({ ...prev, [name]: value }));
       
        setModError(prev => ({ ...prev, [name]: "" }));
    };

    const validateForm = () => {
        let valid = true;
        const newError = { ...error };
        if (!subscription.plan.trim()) {
            newError.plan = "Subscription Name is required";
            valid = false;
        } else {
            newError.plan = "";
        }

        if (!currency || currency === "Select Currency") {
            newError.currency = "Please select Currency";
            valid = false;
        } else {
            newError.currency = "";
        }

        if (!subscriptiontype){
            newError.subscriptiontype = "User Type is required";
            valid = false;
        } else {
            newError.subscriptiontype = "";
        }
        if (!subscription.amount) {
            newError.amount = "Amount is required";
            valid = false;
        } else {
            newError.amount = "";
        }

        if (!interval || interval === "sel") {
            newError.interval = "Please select Interval";
            valid = false;
        } else {
            newError.interval = "";
        }
        if(isToggled === true){
            if(statesingle.length === 0){
                // setImageError('This field is required');
                return false
            }
        }
        if (modifierArray.length > 0 && !modifierArrayClicked) {
            valid = false;
            newError.modifierArray = "Please click '+ Add Additional' for modifiers";
        } else {
            newError.modifierArray = "";
        }    

        setError(newError);
        return valid;
    };

    const [modError, setModError] = useState({ sectionName: '', count: '', amount: '' })

    const validateMod = (data) => {
   
   
    if(data==="addmore")
    {
        if (!modifierOption.sectionName && (modifierOption.count === 0 || modifierOption.count === '') && (modifierOption.amount === 0 || modifierOption.amount === '')) {
     
            setModError({ ...modError, sectionName: 'please select Section', count: 'please enter count', amount: 'please enter amount' })
            return false
        }
       
        
    }

   
        if (modifierOption.sectionName) 
            {
           
        if ((modifierOption.sectionName &&modifierOption.count === 0 )|| (modifierOption.sectionName && modifierOption.count === '')) {
            setModError({ ...modError, count: 'please enter count' })
            return false
        }
        if ((modifierOption.sectionName&&modifierOption.amount === 0 )|| (modifierOption.sectionName && modifierOption.amount === '')) {
            setModError({ ...modError, amount: 'please enter amount' })
            return false
        }   
    }
        
        // setModifierArray(modifierOption);

        return true
    }






    //     var Valid = validateMod()
    //     if (Valid) {
    //         const updatedModifierArray = [...modifierArray, modifierOption];
    //         // const updatedCheckEvent = [...checkEvent];
    //         // const index = updatedCheckEvent.findIndex(item => item.name === modifierOption.sectionName);
    //         // if (index !== -1) {
    //         //     if (updatedCheckEvent[index].check) {
    //         //         updatedCheckEvent[index].noOfValue = Number(updatedCheckEvent[index].noOfValue) + Number(modifierOption.count);
    //         //     }
    //         // }
    //         const newSubscriptionAmount = Number(subscription.amount) + Number(modifierOption.amount);
    //         setSubscription(prev => ({ ...prev, amount: newSubscriptionAmount }));
    //         setModifierArray(updatedModifierArray);
    //         // setCheckEvent(updatedCheckEvent);
    //         setModifierOption({ sectionName: '', amount_type: 'INR', amount: 0, count: 0 });
    //     }
    // };


    const handleAddClick = () => {


       if( validateMod("addmore"))
       {
        const updatedModifierArray = [...modifierArray, modifierOption];
       
        

        const index = checkEvent.findIndex(item => item.name === modifierOption.sectionName);
        if (index !== -1 && checkEvent[index].check && modifierOption.sectionName === 'Event') {
          ;
        }
      
        

      
        setModifierArrayClicked(true);
   
     
        
        
        
        setModifierArray(updatedModifierArray);

        setModifierOption({ sectionName: '', amount_type: 'USD', amount: "", count: ""});
       }

       
    };
  
    
    

    const handleRemove = (e, index) => {
        let list = [...modifierArray];
        let removedItem = list[index];
        let data = list.filter((item, i) => i !== index);
        const newSubscriptionAmount = Number(subscription.amount) - Number(removedItem.amount);
        setSubscription(prev => ({ ...prev, amount: newSubscriptionAmount }));
        // setCheckEvent(prevCheckEvent => prevCheckEvent.map(item => {
        //     if (item.name === removedItem.sectionName && item.check) {
        //         item.noOfValue = Number(item.noOfValue) - Number(removedItem.count);
        //     }
        //     return item;
        // }));
        setModifierArray(data);
    };

    const[truebutton,settruebutton]=React.useState(false)

    const handleSubmit = async () => {

      
        
        const updatedModifierArray =modifierOption.sectionName ? [...modifierArray, modifierOption] :  [...modifierArray];
       
   
    
        
        var Valid = validateForm()
        let validnew=validateMod("submit")
        if (Valid) {
            if(validnew)
            { 
               settruebutton(true)
            const formData = new FormData();
			formData.append("name",subscription.plan)
			formData.append("usertype", subscriptiontype)
			formData.append("currency", currency)
            formData.append("amount", subscription.amount)
            formData.append("interval",interval )
            formData.append("description",subscription.description)   
            formData.append("Event", checkEvent[0].check)   
            formData.append("noOfEvent",checkEvent[0].noOfValue )  
            formData.append("Course",checkEvent[1].check )   
            formData.append("noOfCourse",checkEvent[1].noOfValue )
            formData.append("Blogs", checkEvent[2].check)
            formData.append("noOfBlogs", checkEvent[2].noOfValue )
            formData.append("Podcast", checkEvent[3].check, )
            formData.append("noOfPodcast", checkEvent[3].noOfValue )
            formData.append("Videos", checkEvent[4].check)
            formData.append("noOfVideos", checkEvent[4].noOfValue )
            formData.append("Images", checkEvent[5].check)
            formData.append("noOfImages", checkEvent[5].noOfValue)
            formData.append("Community", checkEvent[6].check)
            formData.append("noOfCommunity", checkEvent[6].noOfValue)

            formData.append("Jobs", checkEvent[7].check)
            formData.append("JobsCount", checkEvent[7].noOfValue)
            formData.append("News", checkEvent[8].check)
            formData.append("NewsCount", checkEvent[8].noOfValue)
            formData.append("BookReview", checkEvent[9].check)
            formData.append("noOfBookReview", checkEvent[9].noOfValue)
            formData.append("Category", checkEvent[10].check)
            formData.append("noOfCategories", checkEvent[10].noOfValue)
            formData.append("Fanpages", checkEvent[11].check)
            formData.append("noOfFanpages", checkEvent[11].noOfValue)


         
           
    
         
            
            formData.append( "addOns",JSON.stringify(updatedModifierArray.length>0?updatedModifierArray:[]))
            formData.append("popular",isToggled)

                     
                        
      
            if (statesingle2) 
                {
                    statesingle2.forEach((item) => { formData.append("popularlogo", item) }) 
                 }
                 else{ formData.append("popularlogo", '') }

          

            
                

            axios.post(process.env.REACT_APP_BASE_URL + 'subscription/addStripePlan', formData, headerlocal).then((res) => {

                enqueueSnackbar("Subscription added successfully", { variant: 'success' })
                navigate("/subscription")
              
            }).catch((err) => {
                enqueueSnackbar(err && err.response && err.response.data.message ? err.response.data.message : "Something went wrong", { variant: 'error' })
                console.log(err);
            }).finally(()=>{
                settruebutton(false)
            })
        }
        }
    }

    


   

    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={10} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={10} >
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Subscription Type</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>

                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Subscription Name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                name="plan"
                                                value={subscription.plan}
                                                // onChange={handleSubscription}
                                                onChange={(e) => {
                                                    handleSubscription(e); setError(prevError => ({ ...prevError, plan: '' }))
                                                }}
                                            />
                                            {error.plan && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.plan}</p>}                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth >
                                            <Typography component='div' className='label'>Select User Type <Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={subscriptiontype ? subscriptiontype : "sel"}
                                                placeholder=""
                                                className="select"
                                                // onChange={(e) => { setSubscriptionType(e.target.value) }}
                                                onChange={(e) => {
                                                    setSubscriptionType(e.target.value); setError(prevError => ({ ...prevError, subscriptiontype: '' }))
                                                }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select</MenuItem>
                                              
                                                <MenuItem value={"teacher"}>Teacher</MenuItem>
                                                <MenuItem value={"yoga school"}>Yoga School</MenuItem>
                                                <MenuItem value={"yoga studio"}>Yoga Studio</MenuItem>
                                            </Select>
                                            {error.subscriptiontype && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.subscriptiontype}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} lg={3}>

                                       
                                        <FormControl fullWidth >
                                            <Typography component="div" className="label">
                                                                        Currency
                                                                     </Typography>
                                                                     <TextField
                                                                 id="filled-size-small"
                                                                 variant="outlined"
                                                                 size="small"
                                                                 name="name"
                                                                 className="textfield"
                                                                 disabled={true}
                                                                 style={{ width: "100%" }}
                                                                 value={"USD"}
                                                                
                                                                 
                                                               />
                                            {error.currency && <p style={{ color: 'red' }} className="fz-13 m-0">{error.currency}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Amount<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Amount'
                                                onKeyDown={restrictZero}
                                                name="amount"
                                                value={subscription.amount}
                                              
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    if (!isNaN(value) && value.trim() !== '') {
                                                        handleSubscription(e)
                                                        
                                                        setError(prevError => ({ ...prevError, amount: '' }))
                                                      } else {
                                                        setSubscription({ ...subscription, name: "" });
                                                        setError(prevError => ({
                                                          ...prevError,
                                                          amount: 'Please enter a valid number'
                                                        }));
                                                        return
                                                      }
                                                }}

                                            />
                                            {error.amount && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.amount}</p>}                                        </FormControl>
                                    </Grid>



                                    <Grid item xs={12} sm={6} md={4} lg={3}>

                                        <Typography component='div' className='label'>Select Interval<Typography component='span' className='star'></Typography></Typography>
                                        <FormControl fullWidth >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={interval ? interval : "sel"}
                                                placeholder="Select Interval"
                                                className="select"
                                                onChange={(e) => { setInterval(e.target.value); setError('') }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select Interval</MenuItem>
                                                <MenuItem value={"week"}>Weekly</MenuItem>
                                                <MenuItem value={"month"}>Monthly</MenuItem>
                                                <MenuItem value={"year"}>Yearly</MenuItem>
                                            </Select>
                                            {error.interval && <p style={{ color: 'red' }} className="fz-13 m-0">{error.interval}</p>}
                                        </FormControl>
                                    </Grid>

                                    <FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isToggled}
												onChange={handleToggle}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to add this as popular? </Typography>}
									/>
{/* Add Popular Logo
                               {isToggled && <Grid item xs={12} sm={12} md={12}  >
									<Box className='flex-center flex-between'>
										<Typography component='div' className='label'>Add Logo <Typography component='span' className='star'> * </Typography></Typography>
										<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
											{statesingle.length < 1 &&
												<Button className="upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
													<input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
												</Button>
											}
										</Box>
									</Box>
									{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
								</Grid>}
								{isToggled && <Grid item xs={12} sm={12} md={12} className="mt-10" >
									{statesingle.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
											{statesingle.length > 0 ? statesingle.map((x, i) => (
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
														<img src={x} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}

										</Grid>
									}
								</Grid> } */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description<Typography component='span' className='star'></Typography></Typography>
                                            <textarea
                                                className='textarea'
                                                placeholder='Description'
                                                name="description"
                                                value={subscription.description}
                                                onChange={handleSubscription}
                                                rows={4}
                                                cols={40} />
                                            {error.description && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.description}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>
                                        <Box >
                                            <Typography className=' fz-18 semibold' component='h4' variant='h4'>Permissions</Typography>
                                            <Box className='mt-30'>
                                                <Grid container columnSpacing={2} rowSpacing={2} className='mt-10 modifier_container' >
                                                    {checkEvent && checkEvent.map((item, index) => 
                                                    {
                                                      
                                                        
                                                        
                                                        return(

                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                                            <Box className="flex-center ">
                                                                <FormControlLabel control={<Checkbox onChange={handleCheckEvent} name={item.name} checked={item.check} size='small' />} />
                                                                <Typography className=' fz-16 semibold mr-10 minw80' component='h5' variant='h5'>{item.name}</Typography>
                                                                {item.check && (
                                                                    <TextField
                                                                        id={`filled-size-small-${item.name}`}
                                                                        type='tel'
                                                                        variant="outlined"
                                                                        size="small"
                                                                        className='textfield'
                                                                        placeholder={`Number of ${item.name}`}
                                                                        name={`noOf${item.name}`}
                                                                        value={item.noOfValue}

                                                                          
                                                                        onChange={(e) => handleChangeValue(e, index,item.name)}
                                                                        onBlur={(e) => handleTotal(e, item.name, index)}
                                                                        

                                                                    />
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    )})}
                                                </Grid>

                                            </Box>
                                        </Box>
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>

                                        <Typography component='div' variant='h6' className='fz-16 mt-20 semibold'>Add ons</Typography>
                                        {modifierArray ? modifierArray.length > 0 && modifierArray.map((item, i) => (
                                            <>
                                                <Grid container columnSpacing={2} rowSpacing={2} className='mt-10 modifier_container' >
                                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                className='textfield'
                                                                placeholder='Modifier name'
                                                                name="sectionName"
                                                                value={item.sectionName}
                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                                                        <FormControl fullWidth>
                                
                                                                     <TextField
                                                                 id="filled-size-small"
                                                                 variant="outlined"
                                                                 size="small"
                                                                 name="name"
                                                                 className="textfield"
                                                                 disabled={true}
                                                                 style={{ width: "100%" }}
                                                                 value={"USD"}
                                                                
                                                                 
                                                               />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={5} md={2} lg={2} xl={2}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                // onInput={(e) => onlyDecimalNumbers(e)}
                                                                // onKeyDown={restrictZero}
                                                                name='count'
                                                                className='textfield'
                                                                placeholder="Number of value"
                                                                value={item.count}

                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={10} sm={5} md={2} lg={2} xl={2}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                startAdornment={<InputAdornment position="start" >{modifierOption.amount_type==='USD' ? '$' : modifierOption.amount_type==='INR' ? '₹' : ''}</InputAdornment>}
                                                                onInput={(e) => onlyDecimalNumbers(e)}
                                                                onKeyDown={restrictZero}
                                                                name='amount'
                                                                className='textfield'
                                                                placeholder="amount"
                                                                value={item.amount}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start" >{modifierOption.amount_type==='USD' ? '$' : modifierOption.amount_type==='EUR' ? '₹' : ''}</InputAdornment>,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                        <Tooltip title="Remove"><IconButton className='trash_icon'
                                                            onClick={(e) => handleRemove(e, i)}
                                                        ><DeleteOutline /></IconButton></Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </>)) : ""}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>

                                        <Grid container columnSpacing={2} rowSpacing={2} className='mt-10 modifier_group'>
                                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                                <label className='form-labels fz-13 semibold' for="my-input">Select <Typography component='span' className='star'></Typography></label>
                                                <Box className="form-box ">
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            className='select'
                                                            name='sectionName'
                                                            onChange={(e) => handleChangeSection(e)}
                                                            value={modifierOption.sectionName}
                                                        >
                                                            {checkEvent.map((row, i) => 
                                                            
                                                            { 
                                                            
                                                                return(
                                                                row.check && (
                                                                    <MenuItem value={row.name} key={row.name}>
                                                                        {row.name}
                                                                    </MenuItem>
                                                                )
                                                            )})}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <span style={{ color: "red", fontSize: 12 }}>{modError.sectionName}</span>
                                            </Grid>


                                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>

                                                
                                                <Box className="form-box ">
                                                <FormControl fullWidth >
                                            <Typography component="div" className="label">
                                                                        Currency
                                                                     </Typography>
                                                                     <TextField
                                                                 id="filled-size-small"
                                                                 variant="outlined"
                                                                 size="small"
                                                                 name="name"
                                                                 className="textfield"
                                                                 disabled={true}
                                                                 style={{ width: "100%" }}
                                                                 value={"USD"}
                                                                
                                                                 
                                                               />
                                            {error.currency && <p style={{ color: 'red' }} className="fz-13 m-0">{error.currency}</p>}
                                        </FormControl>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                <label className='form-labels fz-13 semibold' for="my-input">Count</label>
                                                <Box className="form-box" >
                                                    <FormControl fullWidth>
                                                        <TextField

                                                            onInput={(e) => onlyDecimalNumbers(e)}
                                                            onKeyDown={restrictZero}
                                                            name='count'
                                                            disabled={View}
                                                            onChange={e => handleChangeSection(e)}
                                                            className='textfield'
                                                            placeholder="Number of value"
                                                            value={modifierOption.count}
                                                            

                                                        />
                                                    </FormControl>
                                                    <span style={{ color: "red", fontSize: 12 }}>{modError.count}</span>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                <label className='form-labels fz-13 semibold' for="my-input">Amount</label>
                                                <Box className="form-box" >
                                                    <FormControl fullWidth>
                                                        <TextField

                                                            startAdornment={<InputAdornment position="start" >{modifierOption.amount_type==='USD' ? '$' : modifierOption.amount_type==='INR' ? '₹' : ''}</InputAdornment>}
                                                            onInput={(e) => onlyDecimalNumbers(e)}
                                                            onKeyDown={restrictZero}
                                                            name='amount'
                                                            disabled={View}
                                                            onChange={e => handleChangeSection(e)}
                                                            className='textfield'
                                                            placeholder="Amount"
                                                            value={modifierOption.amount}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start" >{modifierOption.amount_type==='USD' ? '$' : modifierOption.amount_type==='INR' ? '₹' : ''}</InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <span style={{ color: "red", fontSize: 12 }}>{modError.amount}</span>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={3} lg={3} xl={3} >
                                                <Box className="benefit-btn "><Button className='theme-btn'
                                                    onClick={handleAddClick}
                                                >+ Add more</Button></Box>
                                            </Grid>
                                        </Grid>
                                        {error.modifierArray && <span style={{ color: "red", fontSize: 12 }}>{error.modifierArray}</span>}

                                    </Grid>
                                
                                </Grid>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    {truebutton?
                                     <Button
                                  
                                     variant="contained" className='theme-btn mr-10'>Please wait...</Button>
                                     :
                                     <Button
                                     onClick={handleSubmit}
                                     variant="contained" className='theme-btn mr-10'>Submit</Button>
                                
                                
                                }
                                   
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}

export default AddSubscription

