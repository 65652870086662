import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom';

export default function AddSub() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [category, setCategory] = React.useState({ subcategoryname: "", description: "" })
    const [error, setError] = React.useState({ categoryname: "", subcategoryname: "" })
    const [recordings, setRecordings] = React.useState([])
    const [categoryId, setCategoryId] = React.useState('')
    const [catError, setCatError] = React.useState('')
    const [truebutton, setTruebutton] = React.useState(false)


    let name, value;
    const handleCategory = (e) => {
        name = e.target.name;
        value = e.target.value
        setCategory({ ...category, [name]: value })
        setError('')
        setCatError('')
    }
    const Validator = () => {
        if (!categoryId  && !category.subcategoryname) {
            setCatError("This field is required.")        
            setError({ categoryname: "This field is required.",subcategoryname: "This field is required." })
            return false
        }
        if (!categoryId) {
            setCatError("This field is required.")
            return false
        }
        // if (!category.categoryname) {
        //     setError({ categoryname: "This field is required." })
        //     return false
        // }
        if (!category.subcategoryname) {
            setError({ subcategoryname: "This field is required." })
            return false
        }
        return true
    }
    React.useEffect(() => {
        getCategory()
    }, [])
    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal })
            .then((res) => {
                setRecordings(res.data.category)
                console.log(res.data.category,"category");
            }).catch((error) => {
                console.log(error, "Error ");
            })
    }

    const handleSubmit = async () => {
       	
        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)
            var body = {
                category_id: categoryId,
                subcategory_title: category.subcategoryname,
                description: category.description,
                createdby: auth && auth._id,
            }
            await axios.post(process.env.REACT_APP_BASE_URL + `SubCategory/addSubcategory`, body, { headers: headerlocal }).then((res) => {
                setTruebutton(false)
                navigate('/subcategory')
                enqueueSnackbar("Sub Category added successfully", { variant: 'success' })
                setCategory({subcategoryname: "", description: "" })
                setCategoryId('')
                console.log(category,"categorycategorycategory");
            }).catch((error) => {
                setTruebutton(false)
                enqueueSnackbar("Something went wrong.", { variant: 'error' })
                console.log(error.response, "error");
            })
        }
    }

    return (
        <Box className=''>
            <MiniDrawer  menuOrder={8} submenuOrder={17} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle mb-20'>Add Sub Category</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth >
                                            <Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={categoryId ? categoryId :'sel'}
                                                className="select"
                                                onChange={(e) => { setCategoryId(e.target.value); setCatError(''); setError('') }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select category</MenuItem>
                                                {recordings && recordings?.length > 0 ? recordings.map((data) => (
                                                    <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                                )):
                                                <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                            </Select>
                                            {catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
                                        </FormControl>
                                    </Grid>
                                   
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Sub Category Name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Sub Category Name'
                                                name="subcategoryname"
                                                onChange={(e) => { handleCategory(e); setError({ subcategoryname: "" }) }}
                                                // InputProps={{
                                                //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                // }}
                                            />
                                            {error.subcategoryname && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.subcategoryname}</p>}

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description</Typography>
                                            <textarea
                                                className='textarea'
                                                placeholder='Description'
                                                name="description"
                                                value={category.description}
                                                onChange={(e) => handleCategory(e)}
                                                rows={4}
                                                cols={40}
                                                style={{ resize: 'none' }} />
                                           
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {truebutton === false ?
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='addbtn' onClick={handleSubmit}>Save</Button>
                                </Box>
                                : truebutton === true &&
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='addbtn mr-10'>Please Wait...</Button>
                                </Box>
                            }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
