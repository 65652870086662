import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import Timeline from './timeline';

import { useCommunity } from "../Context/CommunityContext";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



export default function CommunityTabs({ community_id }) {
  
    const { communitydetails, fetchCommunity } = useCommunity();

  

    useEffect(() => {
        fetchCommunity(community_id);
        // eslint-disable-next-line 
    }, [community_id]);


    return (
        <Box className='community_tabs'>
           
        
                <Timeline communitydetails={communitydetails} />
           
        </Box>

    )
}
