
import axios from 'axios';
import * as React from 'react';
import { useSnackbar } from 'notistack'
import {  Delete as DeleteIcon } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button,  CircularProgress,Typography, Grid, FormControl,  } from '@mui/material';


export default function DataTable({ params }) {
    // const { communityAttachments, fetchCommunity } = useCommunity();
    const [previousFiles, setPreviousFiles] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const[truebutton,setTruebutton]=React.useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    };

    const supportedFileTypes = ['pdf', 'doc', 'docx', 'txt'];

    const [fileerror,setfileerror]=React.useState('')
    const[Tableloading,setTableloading]=React.useState(true)

    const deleteFile = (filepath) => {

        if(files.length>0)
        {


            let updatedFiles = files.filter((file,index) => index !== filepath);
            console.log(updatedFiles);
            
            setFiles(updatedFiles);
            return;
        }
        if (!filepath) {
         
            console.error("Filepath is undefined or empty.");
            return;
        }

        const deleteData = {
            filepath: filepath
        };

        axios.put(
            `${process.env.REACT_APP_BASE_URL}community/deleteattachments/${params.id}`,
            deleteData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('token')
                }
            }
        )
            .then((res) => {
                enqueueSnackbar("File deleted successfully", { variant: 'success' });
                // fetchCommunity(params.id); // Refresh community data after deletion
                getfile()
            })
            .catch((err) => {
                enqueueSnackbar(
                    err.response && err.response.data.error ? err.response.data.error : "Failed to delete file.",
                    { variant: 'error' }
                );
                console.error(err);
            });
    };


    React.useEffect(() => {

     
        getfile()
        // eslint-disable-next-line 
    }, []);



    const getfile=async()=>
    {
        setTableloading(true)
        try{
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Community/getCommunityBasedOnId/${params.id}`);
            
            setPreviousFiles(response.data.community.files);
        }
        catch{
            console.log("error");
            
        }
        finally{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        }


    }



   

    const handleFileChange = (e) => {
        setfileerror("")
        console.log("comming");
        
        const selectedFiles = Array.from(e.target.files);
        const filesWithNames = selectedFiles.map((file) => ({
            name: file.name,
            size: file.size,
            file: file,
        }));

        const validFiles = filesWithNames.filter(file => {
            const extension = file.name.split('.').pop().toLowerCase();
            return supportedFileTypes.includes(extension);
        });
        console.log(validFiles,"filesssssss");
        

        const unsupportedFiles = filesWithNames.filter(file => !validFiles.includes(file));
        if (unsupportedFiles.length > 0) {
            enqueueSnackbar(`Invalid file ${unsupportedFiles.map(file => file.name).join(', ')}`, { variant: 'error' });
        }

        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    };

 


    
const validate =()=>{

    if(files.length===0&& !files[0])
    {
        
        
        setfileerror("This field is required.")
        return false
    }
   
    return true
}
    
    const saveFilesToServer = () => {
     
const val=validate()
    if(val)
    {
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`files`, file.file);
            formData.append(`name_${index}`, file.name);
            formData.append(`size_${index}`, (file.size) / 1024);
        });
       // eslint-disable-next-line 
        { previousFiles ? previousFiles.forEach((item) => { formData.append("ExistingFiles", JSON.stringify(item)) }) : formData.append("ExistingFiles", '') }
            setTruebutton(true)
        axios.post(process.env.REACT_APP_BASE_URL + `community/updatecommunityFile/${params.id}`, formData, { headers: headerformdata })
            .then((res) => {
                enqueueSnackbar("Community updated successfully", { variant: 'success' });
                setFiles([]);
                getfile()
                // fetchCommunity(params.id);

            })
            .catch((err) => {
                enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                console.log(err, "error");
            }).finally(()=>{
               setTruebutton(false)
            })
    }
    };

    return (
        <div className="contentWraper">
            {/* <FileUploadOutlinedIcon className="fz-18 mr-5" />
            <input type="file" multiple onChange={handleFileChange} /> */}
            <Box className="mb-20 whiteContainer p-20">
                <Box>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading mb-20' sx={{color:"#f3714d",}}>Add Files <Typography component='span' className='star'>*</Typography></Typography>
                </Box>
          
                <FormControl variant="standard" fullWidth>
                    <Box className='flex-center flex-between flex-wrap w100'>
                        <Button className="upload_btn" variant="contained" component="label">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <FileUploadOutlinedIcon className="fz-18 mr-5" />
                                Add Files  
                              
                            </Box>
                           
                            <input onChange={handleFileChange} multiple hidden type="file" />
                        </Button>
                        
                        
                    </Box>

                    {files.length > 0 && files.map((data, index) => {

                           
                            
                        return (<ul className='p-0'>


                            <li key={index} className='flex-center fz-16 text_black'>
                                {data.name ? data.name : data.name}
                                <DeleteIcon className='ml-10 fz-20 text-grey' onClick={() => deleteFile(index)} />
                            </li>
                        </ul>

                        )
                    })
                    }
	             {fileerror&&(
                        <p style={{ color: "red" }} className="fz-13 m-0">
                          {fileerror}
                        </p>
                      )}
                   
                    <Typography
                      className=" fz-12 mt-10 mb-20"
                      component="p"
                         variant="p"
                    >
                        <strong>Note: </strong>Allowed *
                        'pdf', 'doc', 'docx', 'txt'.
                    </Typography>
                </FormControl>


          
            <ul className='p-0'>
                {Tableloading?
                            (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item>
                                            <CircularProgress sx={{ color: '#F3714D' }} />
                                        </Grid>
                                    </Grid>
                            )
                            :
                
                
                
                (previousFiles?.map((file, index) => (
                    <li key={index} className='flex-center fz-16 text_black'>

                        
                        {file.fileName ? file.fileName : file.name}
                        {/* <button onClick={() => removeFile(index)}>Remove</button> */}
                        {/* <button onClick={() => deleteFile(file.file)}>Delete</button> */}
                        <DeleteIcon className='ml-10 fz-20 text-grey' onClick={() => deleteFile(file.file)} />
                    </li>
                )))}
            </ul>
            {/* <button onClick={() => saveFilesToServer()}>Submit</button> */}
            <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
            {truebutton?
            
            <Button variant="contained" className='addbtn mr-10'>Please wait...</Button>
            :
            <Button variant="contained" className='addbtn mr-10' onClick={() => saveFilesToServer()}>Submit</Button>
        }
              
            </Box>
            </Box>
        </div>
    );
}

