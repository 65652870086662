import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, IconButton, TextField, InputAdornment, Box, Button, FormControl, CircularProgress } from '@mui/material';
import TableCell, {  } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack'
import {  SearchOutlined, DeleteOutline, CloseOutlined } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const UserTable = () => {

    const headerformdata = {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("token")
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    // const [deletearry, setDeleteArray] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [teacherDetails, setTeacherDetails] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    // const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    // const [subscription, setSubscription] = React.useState()
    const[Tableloading,setTableloading]=React.useState(true)

    React.useEffect(() => {
        getTeacherDetails()
    }, [page, rowsPerPage, searchTerm])


    const getTeacherDetails =  () => {
        setTableloading(true)
         axios.get(process.env.REACT_APP_BASE_URL + `MyUserIds/getMyUserIds?search=${searchTerm}&page=${page}&size=${rowsPerPage}`, { headers: headerformdata }).then((res) => {
            setTeacherDetails(res.data.Myuserids)
            setCount(res.data.Myuserids.length)
          
        }).catch((err) => {
            console.log(err);
        })
        .finally(()=>{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        })
    }


    const handleRemove = (e) => {
        setSearchTerm('');
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }

    const EditData = (row) => {
    
        let obj = {
            data: row
        }
        navigate("/editusername", { state: obj })
    }

    const handleDelete = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `MyUserIds/deleteMyuserid/${deleteid}`, {}, { headers: headerformdata },).then((res) => {
            handleClose()
            getTeacherDetails()
            enqueueSnackbar("User deleted successfully", { variant: 'success' })

        }).catch((err) => {
            console.log(err);
        })
    }

  return (
    <>
    <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>

                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>


                <TableContainer id='table'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className='tble'>
                        <TableHead className='DT-head'>
                            <TableRow className=''>
                                {/* <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Profile Image</strong></TableCell> */}
                                {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Name</strong> </TableCell> */}
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Username</strong> </TableCell>
                                {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Email</strong> </TableCell> */}
                                {/* <TableCell style={{ minWidth: 180 }} align="left" className='tbl_head'> <strong>Verified</strong>  </TableCell> */}
                                {/* <TableCell style={{ minWidth: 180 }} align="left" className='tbl_head'> <strong>Active</strong>  </TableCell> */}
                                {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Phone</strong>  </TableCell> */}
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'> <strong>Action</strong>  </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                    Tableloading ?
                                    (
                                        <TableRow>
                                    <TableCell colSpan={7} className='nodata' ><CircularProgress sx={{ color: '#F3714D',marginLeft:'580px' }} /> </TableCell>
                                        </TableRow>
                                       
                                                
                                        
                                    )
                                    :
                            (teacherDetails && teacherDetails.length > 0 ? teacherDetails.map((row, i) => {
                            
                                return (
                                    <TableRow className='' key={row._id}>
                                        {/* <TableCell align="center" className='DT-row'>
                                            {row.image
                                                ? <img className="cat_image" src={process.env.REACT_APP_BASE_URL + row.image} alt='' height={50} width={50} />
                                                : <PersonIcon style={{ height: 50, width: 50 }} /> // Replace PersonIcon with your actual icon component or element
                                            }
                                        </TableCell> */}
                                        {/* <TableCell className='DT-row'  >{row.name ? row.name : '-'}</TableCell> */}
                                        <TableCell className='DT-row'  >{row.username ? row.username : '-'}</TableCell>
                                        {/* <TableCell align="left" className='DT-row'  >{row.email ? row.email : '-'}</TableCell> */}
                                        {/* <TableCell align="center" className='DT-row'  ><FormControlLabel className='m-0 justify-content-center'  */}
                                            {/* control={<Switch size='small'  onChange={(e) => handleChangeStatus(e, row)} checked={row.verified == true ? true : false} sx={{ m: 1 }} />} /></TableCell> */}
                                        {/* <TableCell align="center" className='DT-row'  ><FormControlLabel className='m-0 justify-content-center'  */}
                                            {/* control={<Switch size='small' onChange={(e) => handleChangeStatusActive(e, row)} checked={row.active == true ? true : false} sx={{ m: 1 }} />} /></TableCell>                                    <TableCell align="left" className='DT-row' >{row.contact_number ? row.contact_number : '-'}</TableCell> */}
                                        <TableCell align="center" className='DT-row' style={{ cursor: 'pointer' }} >
                                            <Box className="flex-center justify-content-center actionbtns">
                                                <Tooltip title="Edit"><Box className='a_tag' onClick={() => EditData(row)}><EditOutlinedIcon className='icons text-grey' /></Box></Tooltip>
                                                <Tooltip title="Delete"><Box ><DeleteOutline className='icons text-grey' onClick={() => handleOpen(row._id)} /></Box></Tooltip>

                                                {/* <Tooltip title="View"> <Box  ><VisibilityIcon className='icons text-grey' onClick={(e) => handleNavigate(e, row._id)} /></Box></Tooltip> */}
                                                {/* <Tooltip title="Copy URL"> <Box  ><CiLink className='icons text-grey' onClick={(e) => handleCopy(e, row.unique_link)} /></Box></Tooltip> */}

                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableCell colSpan={7} className='nodata' > No data found </TableCell>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete User?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>
  )
}

export default UserTable