import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

const DownloadButton = ({ downloadUrl, fileName }) => {
  const [hasDownloaded, setHasDownloaded] = useState(false);


  useEffect(() => {
    // Check if the file has already been downloaded
    const downloadedFiles =
      JSON.parse(localStorage.getItem('downloadedFiles')) || [];
    if (downloadedFiles.includes(fileName)) {
      setHasDownloaded(true); // Update the state if file is already downloaded
    }
  }, [fileName]); // Re-run when fileName changes

  const handleDownload = async () => {
    if (hasDownloaded) {
      // Ask the user if they want to download again
      const userResponse = window.confirm(
        'You have already downloaded this file. Do you want to download it again?'
      );
      if (!userResponse) return;
    }

    try {


      // Send a GET request to the backend API with the file name
      const response = await axios({
        url: process.env.REACT_APP_BASE_URL + `Questions/download/${fileName}`, // Change to your actual API endpoint
        method: 'GET',
        params: { fileName }, // Pass the file name as a query parameter
        responseType: 'blob', // Set response type as blob to handle binary data
      });

      ;

      // Create a Blob object from the response data
      const blob = response.data;
      // Modify the file name by removing numbers and dashes from the start
      const modifiedFileName = fileName.replace(/^\d+-/, ''); // Remove the leading digits and hyphen

      // Create an object URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = modifiedFileName; // Use the correct fileName for the downloaded file

      // Append the link to the body and simulate a click to trigger the download
      document.body.appendChild(link);
      link.click();

      // Remove the link after download
      document.body.removeChild(link);

      // Revoke the blob URL after download to free memory
      URL.revokeObjectURL(blobUrl);

      // Mark the file as downloaded in localStorage
      const downloadedFiles =
        JSON.parse(localStorage.getItem('downloadedFiles')) || [];
      if (!downloadedFiles.includes(fileName)) {
        downloadedFiles.push(fileName);
        localStorage.setItem('downloadedFiles', JSON.stringify(downloadedFiles));
        setHasDownloaded(true); // Update state after successful download
      }
    } catch (error) {
      console.error('Download failed', error);
      enqueueSnackbar('Failed to download the file. Please try again.', { variant: 'error' });
    }
  };


  return (
    <Box className="text-right">
      <Button
        onClick={handleDownload}
        className="btn text_black border borderR0"
      >
        <i className="fa-solid fa-paperclip mr-10"></i> {fileName.replace(/^\d+-/, '')}
      </Button>
    </Box>
  );
};

export default DownloadButton;
