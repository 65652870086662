import React, { useState } from 'react'
import { Box,  IconButton, styled } from '@mui/material'

import 'swiper/css';
import 'swiper/css/pagination';

import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

// import Menu from '../../../Menu';
import Footer from '../../../Home/Footer';
import Spacing from '../../Spacing';
import CommunityTabs from './tabs';
import {  useLocation } from 'react-router-dom';


export default function CommunityDetails() {
    const location = useLocation()
    const [Open, setOpen] = useState(false);
   
    const community_id = location && location.state
    console.log(location.state)


    const handleCloseModal = () => {
        setOpen(false);
    };
  

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
   
    return (
        <Box className='community_details'>
            {/* <Header /> */}
            {/* <Menu /> */}

            <Box className="container ">
               
                <CommunityTabs community_id={community_id} />
            </Box>
            <Spacing lg="70" md="40" />

            <BootstrapDialog
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={Open}
                fullWidth
                maxWidth="xl"
                className='fullscreen_modal'
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box>
                    <iframe width="100%" height="600px" src="https://www.youtube.com/embed/v7AYKMP6rOE" frameborder="0" allowfullscreen title='link'></iframe>

                </Box>
            </BootstrapDialog>
            <Footer />
        </Box>
    )
}