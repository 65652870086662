import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Card, Typography,CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import axios from 'axios';



import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

const FormPropsTextFields = () => {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    };

    const { enqueueSnackbar } = useSnackbar();
    const [videos, setVideos] = React.useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectedVideo, setSelectedVideo] = React.useState(null);
    const [search] = React.useState("");
    const [catid] = React.useState('all');
    const [deleteIndex, setDeleteIndex] = React.useState(null);
    const[Tableloading,setTableloading]=React.useState(true)
    const params = useParams()

    React.useEffect(() => {
        getVideos();
        // eslint-disable-next-line 
    }, [search, catid]);

    const getVideos = async () => {

        setTableloading(true)
        try {
            const res = await axios.get(process.env.REACT_APP_BASE_URL + `video/GetVideobycommunityid/${params.id}`, {
                params: {
                    search: search,
                    Category_id: catid
                },
                headers: headerlocal
            });
         
            setVideos(res.data.getvideo);
        } catch (error) {
            console.log(error, "error in fetching videos");
        }
        finally{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        }
    };

    const handleDelete = async () => {
        if (selectedVideo === null || deleteIndex === null) {
           
            return;
        }

        const { _id, thumbnail, Video } = selectedVideo;
        const thumbnailToDelete = thumbnail[deleteIndex];
        const videoToDelete = Video[deleteIndex];

        const body = {
            thumbnailpath: process.env.REACT_APP_BASE_URL + thumbnailToDelete,
            videopath: process.env.REACT_APP_BASE_URL + videoToDelete
        };
        

        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}video/deleteVideo/${_id}`, body);
        
            if (response.status === 200) {
                enqueueSnackbar("Video and Thumbnail deleted successfully", { variant: 'success' });
                // Update state to reflect deletion in frontend
                const updatedVideos = [...videos];
                updatedVideos.forEach(video => {
                    if (video._id === _id) {
                        video.thumbnail.splice(deleteIndex, 1);
                        video.Video.splice(deleteIndex, 1);
                    }
                });
                setVideos(updatedVideos);
                handleCloseDeleteDialog();
            } else {
                enqueueSnackbar("Failed to delete Video and Thumbnail", { variant: 'error' });
            }
        } catch (error) {
            console.log(error, "error deleting video and thumbnail");
            enqueueSnackbar("Error deleting Video and Thumbnail", { variant: 'error' });
        }
    };

    const handleClickOpenDeleteDialog = (video, index) => {
        setSelectedVideo(video);
        setDeleteIndex(index); // Set the index of item to delete
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setSelectedVideo(null);
        setDeleteIndex(null); // Reset delete index
        setOpenDeleteDialog(false);
    };

 

    return (
        <>
            <Box>
                <Grid container rowSpacing={2} columnSpacing={2} style={{ marginBottom: 10 }} className='align-items-end'>
                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            value={search}
                            className='textfield search'
                            onChange={handleSearchChange}
                            placeholder='Search Here...'
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                            }}
                        />
                    </Grid> */}
                    {/* {search !== '' &&
                        <Grid item xs={12} sm={4} md={2} lg={2} className='d-flex align-items-end mb-5'>
                            <Button variant="contained" className='addbtn theme-btn' onClick={handleResetSearch}>Reset</Button>
                        </Grid>} */}
                </Grid>
                <Box className='whiteContainer p-20'>
                    <Grid container columnSpacing={1} rowSpacing={1} className="servicesList otherList">
                        
                        
                        {Tableloading?
                            (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item>
                                            <CircularProgress sx={{ color: '#F3714D' }} />
                                        </Grid>
                                    </Grid>
                            )
                            :
                        
                        
                        (videos.length>0 ? (
                            videos.map((video, index) => (
                                <Grid key={video._id} item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: 2 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Card className="cardGrid ">
                                            <div className="thumbnails-videos-horizontal-container position_rel">
                                                {video.thumbnail.map((thumbnail, idx) => (
                                                    <div key={idx} className="thumbnail-video-horizontal-item d-flex align-items-end flex-wrap mb-20">
                                                        <Box className="mb-10 d-flex flex-wrap">
                                                            <img
                                                                src={process.env.REACT_APP_BASE_URL + thumbnail}
                                                                alt={`Thumbnail ${idx}`}
                                                                className="thumbnail-horizontal"
                                                                style={{ width: 200, height: 150 }} // Example: Set width to 200px
                                                            />
                                                            <Box className="videoContainer ">
                                                                <video controls className="video-horizontal" style={{ width: 200 }}>
                                                                    <source src={process.env.REACT_APP_BASE_URL + video.Video[idx]} type="video/mp4" />
                                                                </video>
                                                            </Box>
                                                        </Box>
                                                        <Button
                                                            className="theme-btn mb-10"
                                                            onClick={() => handleClickOpenDeleteDialog(video, idx)}
                                                            size="small"
                                                        >
                                                            Delete Video
                                                        </Button>

                                                    </div>
                                                ))}
                                            </div>
                                        </Card>
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="body1" className='text-center semibold'>No videos found</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>

            {/* Delete Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title">Delete Video and Thumbnail?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FormPropsTextFields;
