import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Box,

} from '@mui/material';
import { BsPersonHearts } from "react-icons/bs";
import { FaRegNewspaper } from "react-icons/fa6";

import { MdEmojiEvents } from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import { FaBlog } from "react-icons/fa";
import { PiUsersThreeBold } from "react-icons/pi";

import { SlSocialYoutube } from "react-icons/sl";
import { FaChalkboardTeacher } from "react-icons/fa";

import { makeStyles } from '@mui/styles';
import axios from 'axios';

// Create a hook for styles
// Create a hook for styles
const useStyles = makeStyles((theme) => ({

    card: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '25px',
        alignItems: 'center',
        borderRadius: '30px',  // Add this line to give the card a rounded border
        overflow: 'hidden',




    },
    cardContent: {

        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1px',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',

        marginTop: '0.1px',
        color: 'white',


    },
    cardBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',


        height: '120px',
        borderRadius: "10px",
        width: '150px',
        paddingBottom: '8px',
    },
    typographyBold: {
        paddingBottom: '15px',
        fontSize: 14,  // Reduced font size
        fontWeight: 'bold',
    },
    typographyLarge: {
        fontSize: 400, // Adjust font size as needed
        fontWeight: 'bold', // This will make it bold
        marginBottom: '8px',
        textAlign: 'center', // If needed for centering the text
        '&.MuiTypography-root': {
            fontSize: '28px !important', // Force font size with !important
            fontWeight: 'bold !important', // Ensure font-weight is applied
        },
    },
    typographySmall: {
        fontSize: '10px', // Adjust font size as needed
        marginBottom: '10px',
        fontWeight: 'bold', // This will make it bold
        '&.MuiTypography-root': {
            fontSize: '13px !important', // Force font size with !important
            fontWeight: 'bold !important', // Ensure font-weight is applied
        },
        textAlign: 'center', // You can also center the text if needed
    },
    button: {
        fontSize: 17,  // Reduced font size
        padding: '8px',
        marginTop: '8px',
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        color: "white",
    },
    flexRow: {
        display: 'flex',
        color: 'white',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: '0.1px solid rgba(255, 255, 255, 0.3) ',
        marginTop: "2px"

    },
    gridItem: {
        padding: '16px',
        display: 'flex',

    },
    cardGreen: {
        backgroundColor: '#4CAF50',
    },
    cardBlue: {
        backgroundColor: '#2196F3',
    },
    cardYellow: {
        backgroundColor: '#FFC107',
    },
    cardRed: {
        backgroundColor: '#FF5722',
    },
    cardPurple: {
        backgroundColor: '#9C27B0',
    },
    cardPink: {
        backgroundColor: '#E91E63',
    },
    cardGrey: {
        backgroundColor: '#607D8B',
    },
    cardIndigo: {
        backgroundColor: '#3F51B5',
    },
    cardLightGreen: {
        backgroundColor: '#8BC34A',
    },
    iconStyle: {
        fontSize: '90px',
        fontWeight: 'bold', // Adjust the icon size as needed (larger icon)
        color: 'white',    // Keep the icon color white
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'end',     // Center vertically
        height: 60,           // Ensure icon fills the height of the Box
        width: 60,            // Ensure icon fills the width of the Box
        paddingTop: '10px',


    },
}));






const cards = [
    {
        title: "Fanz",
        icon: <BsPersonHearts size={40} color='white' />,
        color: "rgb(225, 132, 141)",
        value: 1083,
        label: "Total Fanz",
        cardcolour: "rgb(237, 180, 185)",
        valuecolor: "rgb(199, 66, 80)",
        value1: "Celebrities",
        value2: 'Popularpage',
        label1: "Celebrities",
        label2: "Popular Page",
    },

    {
        title: "Community",
        icon: <PiUsersThreeBold size={50} />,
        color: "rgb(243, 165, 160)",
        value: 56,
        label: "Total Communities",
        cardcolour: "rgb(248, 200, 197)",
        valuecolor: "rgb(219, 101, 93)",
        value1: "Pendingcommunityapproval",
        value2: 'Latestcommunity',
        label1: "Pending Approvals",
        label2: "Recently added",
    },
    {
        title: "E_learning",
        icon: <SlSocialYoutube size={50} />,
        color: "rgb(228, 181, 194)",
        value: 600,
        label: "Total Courses",
        cardcolour: "rgb(240, 212, 220)",
        valuecolor: "rgb(217, 78, 118)",
        value1: "Online_Prerecord",
        value2: 'Digitalcourses',
        label1: "Online | Prerecord",
        label2: "Digital Courses",
    },
    {
        title: "Blog",
        icon: <FaBlog size={40} />,
        color: "rgb(243, 185, 149)",
        value: 54,
        label: "Topics",
        cardcolour: "rgb(248, 213, 191)",
        valuecolor: "rgb(220, 104, 31)",
        value1: "Primaryblog",
        value2: 'Latestblog',
        label1: "Primary Blog",
        label2: "Recently added",
    },
    {
        title: "News",
        icon: <FaRegNewspaper size={40} />,
        color: 'rgb(199, 184, 169)',
        value: 54,
        label: "Topics",
        cardcolour: "rgb(221, 212, 202)",
        valuecolor: "rgb(126, 96, 66)",
        value1: "Primarynews",
        value2: 'Latestnews',
        label1: "Primary News",
        label2: "Recently added",

    },
    {
        title: "Jobs",
        icon: <GrUserManager size={40} />,
        color: "rgb(175, 167, 226)",
        value: 8,
        label: "Open Jobs",
        cardcolour: "rgb(207, 202, 238)",
        valuecolor: "rgb(119, 105, 209)",
        value1: "Latestjobs",
        value2: 'Pendingjobsapprovals',
        label1: "Recently added",
        label2: "Pending Approvals",
    },
    {
        title: "Teachers",
        icon: <FaChalkboardTeacher size={40} />,
        color: "rgb(169, 198, 204)",
        value: 119,
        label: "Teachers",
        cardcolour: "rgb(202, 220, 224)",
        valuecolor: "rgb(55, 138, 158)",
        value1: "Totalyogaschools",
        value2: 'Totalyogastudio',
        label1: "Yoga School",
        label2: "Yoga Studio",
    },
    {
        title: "Events",
        icon: <MdEmojiEvents size={40} />,
        color: "rgb(173, 192, 209)",
        value: 12,
        label: "Total Events",
        cardcolour: "rgb(205, 216, 227)",
        valuecolor: "rgb(75, 144, 205)",
        value1: "Latestevents",
        value2: 'Pendingeventsapprovals',
        label1: "Recent Events",
        label2: "Pending Approvals",
    },
    // {
    //   title: "Members",
    //   icon: <PiUsersThreeDuotone  size={40} />,
    //   color: "rgb(221, 196, 187)",
    //   value: 1254,
    //   label: "Total Members",
    //    cardcolour:"rgb(234, 220, 214)",
    //    valuecolor:"rgb(185, 124, 101)",
    //     value1:"",
    //    value2:'',
    //    label1: "New Members",
    // label2: "Pending Approvals",
    // },
    // {
    //   title: "Advertisements",
    //   icon: <IoMegaphone size={40}  /> ,
    //   color: "rgb(186, 196, 160)",
    //   value: 54,
    //   label: "Topics",
    //    cardcolour:"rgb(214, 220, 199)",
    //    valuecolor:"rgb(135, 170, 44)",
    //     value1:"",
    //    value2:'',
    //      label1: "Recent Ads",
    // label2: "Pending Approvals"
    // }
];

const Report = () => {
    const classes = useStyles();
    const [allCards, setallcards] = useState({});

    useEffect(() => {



        axios.get(`${process.env.REACT_APP_BASE_URL}Admin/getAllCards`)
            .then((response) => {


                setallcards(response.data.allCards)

            })
            .catch(() => {
                console.log("erroe");
            })
    }, {})


    return (
        <Box className="mt-40" >
            <div className={classes.container}>
                <Grid container spacing={2}>

                    {cards.map((data, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridItem}>
                            <Card className={`${classes.card} `} sx={{ backgroundColor: data.color, borderRadius: '10px', }} >
                                <CardContent className={classes.cardContent}>

                                    {/* label-Cards */}

                                    <Typography className={classes.typographyBold}>
                                        {data.title}
                                    </Typography>

                                    {/* Cards-total value and icon */}

                                    <Box className={classes.cardBox} sx={{ backgroundColor: data.cardcolour, color: data.valuecolor, boxShadow: `2px 1px 25px 1px rgba(0, 0, 0, 0.2)` }}>
                                        <Typography className={classes.iconStyle}>
                                            {data.icon}
                                        </Typography>
                                        <Typography className={classes.typographyLarge}>  {allCards?.[data.title]?.total ? allCards?.[data.title]?.total : 0}</Typography>
                                        <Typography className={classes.typographySmall}>{data.label}</Typography>
                                    </Box>

                                </CardContent>

                                {/* Cards -Label-1 and values */}

                                <Box className={classes.flexRow}>

                                    <Typography sx={{ fontSize: '14px', }}>
                                        {data.label1}
                                    </Typography>
                                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                                        {allCards?.[data.title]?.[data.value1] ? allCards?.[data.title]?.[data.value1] : 0}
                                    </Typography>
                                </Box>


                                {/* Cards -Label-2 and values */}

                                <Box className={classes.flexRow}>

                                    <Typography sx={{ fontSize: '14px', }}>{data.label2} </Typography>
                                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                                        {allCards?.[data.title]?.[data.value2] ? allCards?.[data.title]?.[data.value2] : 0}
                                    </Typography>
                                </Box>

                                <Typography variant="contained" className={classes.button} style={{ textAlign: 'end' }}>
                                    more →
                                </Typography>
                            </Card>
                        </Grid>))}



                </Grid>
            </div>
        </Box>

    );
};

export default Report;
