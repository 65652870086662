import React, { useEffect, useState } from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Tooltip, Grid, TextField, Typography, IconButton,  } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'


import { AiOutlineInfoCircle } from 'react-icons/ai';

const EditUserName = () => {
    // const headerformdata = {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: localStorage.getItem("token")
    // }

    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
   
    const { enqueueSnackbar } = useSnackbar()
    let location = useLocation()
    const [user, setUser] = React.useState({ firstname: '', username: '', email: '', gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: [''], description: "", plan: '', userid: '' });
    const [error, setError] = React.useState({ firstname: "", username: "", email: "", subscription: "" })
    // const [isToggled, setToggled] = React.useState(false)
    // const [isActive, setActive] = React.useState(false)
    // const [plans, setPlans] = React.useState([])
    // const [selectedPlan, setSelectedPlan] = useState('')
    // const [isChecked, setChecked] = React.useState(false)
    // const [isPrimary, setPrimary] = React.useState(false)
    const [truebutton, setTruebutton] = React.useState(false)
    const [users, setUsers] = useState('')



    useEffect(()=>{
        setUser({...users,username:location.state.data.username})
    },[])

  


    const handleChange = (e, index) => {
        const { name, value } = e.target;
        // let isValid = true;
        let errorMessage = '';
       

        // Validate Username
        if (name === 'username') {
            const trimmedValue = value.trim(); // Trim leading and trailing spaces
           
            if (trimmedValue === '' || !/^[a-zA-Z0-9]+$/.test(trimmedValue)) {
                // isValid = false;
                errorMessage = "Username should only contain alphanumeric characters (no spaces or special characters)";
            }
            setUser({ ...user, [name]: value });

        }

      
        setError({ ...error, [name]: errorMessage });
    };
   
    React.useEffect(() => {
		getUser()
	}, [])

    const getUser = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Userrole/getUserroles`, { headers: headerlocal }).then((res) => {
			
            setUsers(res.data.userroles)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}


    
   

 


    const validation = () => {
        let isValid = true;
        const errors = {};

        const trimmedValue = user.username.trim();
     
        if(!user.username)
        {
            errors.username = "This feild is required";
            isValid = false
            setError(errors);
            return isValid;

        }
        else if ( !/^[A-Za-z0-9]+$/.test(trimmedValue)||/^[0-9]+$/.test(trimmedValue) ) {
            errors.username = "Username should only contain alphanumeric characters (no spaces or special characters)";
            isValid = false;
        }

        

        setError(errors);
        return isValid;
    };


   


    

    const addUserDetails = () => {
        const validator = validation();
        if (validator === true) {
            setTruebutton(true)
           
            const body = {
                username: user.username
            }

            
            axios.put(process.env.REACT_APP_BASE_URL + `MyUserIds/UpdateMyUserid/${location.state.data?._id}`,body )
                .then((res) => {
                    setTruebutton(false)
                   
                    setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
                   
                    navigate(`/username`);
                    enqueueSnackbar("User updated successfully", { variant: 'success' });
                })
                .catch((err) => {
                    setTruebutton(false)
                    console.log(err.response, "------");
                    enqueueSnackbar(err.response.data.error ? err.response.data.error : err.response.data ? err.response.data : "Error occurred", { variant: 'error' });
                });
        }
    };


    // React.useEffect(() => {
    //     getPlans()
    // }, [])

    // const getPlans = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `Subscription/getSubscriptionPlansYogaTeacher`, { headers: headerlocal }).then((res) => {
    //         console.log(res.data, "getplanssssssssssssssssssss");
    //         setPlans(res.data.getSubscriptiondata)
    //     }).catch((error) => {
    //         console.log(error, "Error ");
    //     })
    // }

  return (
    <>
     <Box className='Addcompany'>
            <MiniDrawer menuOrder={18} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Update User</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    
                                   

                              
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Username: <Typography component='span' className='star'>*</Typography>
                                                <Tooltip title="This name will be used to create a unique URL for User">
                                                    <IconButton aria-label="information">
                                                        <AiOutlineInfoCircle size={20} />
                                                    </IconButton>
                                                </Tooltip></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='Username'
                                                value={user.username}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </FormControl>
                                        {error.username && <div className="error-message" style={{ color: "red", fontSize: 12 }}>{error.username}</div>}
                                    </Grid>

                                  
                                </Grid>

                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => addUserDetails()}>Update</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    </>
  )
}

export default EditUserName