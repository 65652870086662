  import React, { useState } from "react";
  import {
    Box,
    Button,
    Typography,
  
    ListItemText,
    // styled,
    Checkbox,
    OutlinedInput,
    FormControl,
 
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
  } from "@mui/material";
  import MenuItem from '@mui/material/MenuItem';
  import Select from '@mui/material/Select';
  import dayjs from "dayjs";
  import axios from "axios";
  import { useNavigate, useParams } from "react-router-dom";
  import { enqueueSnackbar } from "notistack";
  import Confetti from 'react-confetti';


  // function BpRadio(props) {
  //   return (
  //     <Radio
  //       sx={{
  //         "&:hover": {
  //           bgcolor: "transparent",
  //         },
  //       }}
  //       disableRipple
  //       color="default"
  //       checkedIcon={<BpCheckedIcon />}
  //       icon={<BpIcon />}
  //       {...props}
  //     />
  //   );
  // }
  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

  // const BpIcon = styled("span")(({ theme }) => ({
  //   borderRadius: "50%",
  //   width: 16,
  //   height: 16,
  //   boxShadow:
  //     theme.palette.mode === "dark"
  //       ? "0 0 0 1px rgb(16 22 26 / 40%)"
  //       : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  //   backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  //   backgroundImage:
  //     theme.palette.mode === "dark"
  //       ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
  //       : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  //   ".Mui-focusVisible &": {
  //     outline: "2px auto rgba(19,124,189,.6)",
  //     outlineOffset: 2,
  //   },
  //   "input:hover ~ &": {
  //     backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  //   },
  //   "input:disabled ~ &": {
  //     boxShadow: "none",
  //     background:
  //       theme.palette.mode === "dark"
  //         ? "rgba(57,75,89,.5)"
  //         : "rgba(206,217,224,.5)",
  //   },
  // }));

  // const BpCheckedIcon = styled(BpIcon)({
  //   backgroundColor: "#f3714d",
  //   backgroundImage:
  //     "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  //   "&:before": {
  //     display: "block",
  //     width: 16,
  //     height: 16,
  //     backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
  //     content: '""',
  //   },
  //   "input:hover ~ &": {
  //     backgroundColor: "#f3714d",
  //   },
  // });

  // const StyledSwitch = styled(Switch)(({ theme }) => ({
  //     // Custom styles for the switch can be added here
  //   }));

  export default function Publish() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    const [chapters, setChapters] = React.useState([]);
    const [course, setCourse] = React.useState([]);
    const [publishname,setpublishername] = React.useState('')
    const [showConfetti, setShowConfetti] = React.useState(false);
    //teacher states 
    const[teachername,setteachername]=useState([])
    const [teacher, setTeacher] = React.useState([])
    const [error,setError]=useState({userselect:"",})

    // Initialize state for lessons' date and time

    const [isPublished, setIsPublished] = React.useState(false);

    const [openWarn, setOpenWarn] = React.useState(false);

    const [openModal, setOpenModal] = React.useState(false); 

    const { id } = useParams();


    React.useEffect(() => {
      getTeacher();
      // eslint-disable-next-line 
    }, []);  // This ensures that getTeacher is only called once when the component mounts
    
    const getTeacher = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + `Admin/getTeachersListForCourse`, 
          { headers: headerlocal }
        );
        console.log(response.data.teachers,"datas,......");
        setTeacher(response.data.teachers);
     
      } catch (error) {
        console.log(error.response, "error");
      }
    };






    React.useEffect(() => {
      if (teacher.length > 0) {
        getChapters();
      }
      // eslint-disable-next-line 
    }, [id, teacher]);  // Add teacher to dependencies to ensure it triggers when teacher is available
    
    const getChapters = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`
        );
        setChapters(response.data.course.chapters);
    
        // Ensure teacher data is available
        if (teacher.length > 0) {
          const teacherId = response.data.course.teacher[0];
          const matchedTeacher = teacher.find((T) => T._id === teacherId);
          setteachername(matchedTeacher?._id);
        }
    
        setCourse(response.data.course);
        setIsPublished(response.data.course.publishCourse);
    
        const initialTimes = {};
        response.data.course.chapters.forEach((chapter) => {
          chapter.lessons.forEach((lesson, i) => {
            if (lesson.DripSchedule.length > 0) {
              const dateStr = lesson.DripSchedule[0]?.releaseDate; // Get the first release date
              if (dateStr) {
                initialTimes[lesson._id] = {
                  releaseDate: dayjs(dateStr),
                };
              }
            } else {
              initialTimes[lesson._id] = {
                releaseDate: dayjs(new Date()), // Set to current date if empty
              };
            }
          });
        });
    
       
        // setOpen(new Array(response.data.course.chapters.length).fill(false));
      } catch (error) {
        console.error(error);
      }
    };
  
    console.log(teachername)

  //Teacher details:
  React.useEffect(()=>{
    publisher(auth.username)
    // eslint-disable-next-line 
    },[])

    const publisher=async()=>{
      
              setpublishername(auth.username)
    }

 


   

    const handleChangeTeacher = (e) => {
      setteachername(e.target.value);
    };

    const headerlocal = {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("token")
    }

   

	

      
      
      
      
  
  
     
  


    const handleValidate = (status) => {
    
      
      
      if (chapters.length <= 0) {
          enqueueSnackbar('Please add chapters first', { variant: 'error' });
          return false;
      }
      if(teachername.length===0 && status)
        {
          setError({userselect:'This field is required'})
          return false;
        }
      for (const chapter of chapters) {
          if (chapter.lessons.length <= 0) {
              enqueueSnackbar('Please add lessons for chapters', { variant: 'error' });
              return false;
          }
          // for(const lesson of chapter.lessons) {
          //     if(lesson.DripSchedule <= 0){
          //         setOpenWarn(true)
          //         return false;
          //     }
          // }
      }
      if(course.course_paymenttype === undefined && (course.amountInMonth === undefined || course.amountInWeek === undefined) && course.amountOneTime === undefined ){
          enqueueSnackbar('Please Set Payment Option', { variant: 'error' });
          return false;
      }
      return true;
  };

    // handleUpdate

  // const [open, setOpen] = React.useState(chapters.map(() => true));






const debouncedHandleTogglePublish = async (status) => {



  
  
  if (handleValidate(status)) {  // If validation passes, proceed
    try {
      // Now debounce the actual API call to update the publish status
      const response = await axios.put(
        process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${id}`,
        {
          publishCourse: status,
          publishername: "Admin",
          teacher: teachername,
        }
      );
      
      console.log(response.data,"publish");
      
      setIsPublished(status);
      setOpenModal(true);

      if (status) {
        enqueueSnackbar('Course Published Successfully', { variant: 'success' });
        setShowConfetti(true);  // Trigger confetti if published
      } else {
        enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
        setShowConfetti(false);
      }
    } catch (error) {
      console.error("Error updating publish status:", error);
    }
  } else {
    // Optionally, handle the case when validation fails
    enqueueSnackbar('Validation failed. Please check the course details.', { variant: 'error' });
  }
}
// Wrap the function with debounce so only the actual publish status update is debounced


const handleCloseModal = () => {
  setOpenModal(false); // Close the modal
};


const handleClose =()=>{
    setOpenWarn(false)
}

const handleScheduleLessons =async ()=>{
   try {
    await axios.put(
        process.env.REACT_APP_BASE_URL + `Course1/updateReleasedate/${id}`,  
    )
    enqueueSnackbar('Lessons are  Successfully scheduled', { variant: 'success' })  
    setOpenWarn(false)
    getChapters()
    
   } catch (error) {
    console.log("failed to schedule the lessons",error)
   }
}

const nav = useNavigate('')


const handleDraftorBack=()=>{

  nav('/course')
}
 
  const trimmedDescription = course.shortDescription?.split(' ').slice(0, 20).join(' ') + (course.shortDescription?.split(' ').length > 20 ? '...' : '');

  return (
    <Box className="whiteContainer pricingPage p-20">
      <Box className="card p-20">
        <Box className="text-center ">
          <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
              Publisher Name:
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              className="fz-16 text_black "
            >
              {publishname}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
              Course Name:
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              className="fz-16 text_black "
            >
              {course.course_title}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
              Mode Of Course:
            </Typography>
            <Typography className="fz-16 text_black mb-10 ">
              {course.mode_of_course}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
              Description:
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              className="fz-16 text_black "
            >
              {(course.shortDescription && trimmedDescription) || `NA`}
            </Typography>
          </div>

          {course.course_paymenttype && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  Frequency:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  {course.course_paymenttype}
                </Typography>
              </div>
            </>
          )}

          {(course.amountInMonth ||
            course.amountInWeek ||
            course.amountInByWeekly) && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  Paymenttype:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  {course.amountInMonth
                    ? `Monthly`
                    : course.amountInWeek
                    ? "Weekly"
                    : "Biweekly"}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  Amount:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  ${" "}
                  {course.amountInMonth
                    ? course.amountInMonth
                    : course.amountInWeek
                    ? course.amountInWeek
                    : course.amountInByWeekly}
                </Typography>
              </div>



              

           
            </>
          )}

         

<Box className="mb-20" sx={{display:"flex",}}>
      <Typography component="div" className="label" sx={{textAlign:"left",width:'150px'}}>
        Select Teacher <Typography component='span' className='star'>*</Typography>
      </Typography>
      <FormControl fullWidth>
        <Select
          labelId="demo-single-checkbox-label"
          sx={{height:'40px',marginBottom:"20PX"}}
          id="demo-single-checkbox"
          value={teachername}
          onChange={handleChangeTeacher}
          input={<OutlinedInput />}
          MenuProps={MenuProps} // Define or import MenuProps if needed
          renderValue={(selected) => {
            const selectedTeacher = teacher.find(content => content._id === selected);
            return selectedTeacher ? selectedTeacher.username : 'Any Teacher';
          }}
        >
          {teacher && teacher.length > 0 ? (
            teacher.map((name) => (
              <MenuItem key={name._id} value={name._id} disabled={name.disabled}>
                <Checkbox checked={teachername === name._id} />
                <ListItemText primary={name.username} />
              </MenuItem>
            ))
          ) : (
            <MenuItem>No teacher found</MenuItem>
          )}
        </Select>
        {error.userselect && (
          <p style={{ color: 'red' }} className="error-field">{error.userselect}</p>
        )}
      </FormControl>
    </Box>

          {course.amountOneTime && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  Paymenttype:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  {`Onetime Payment`}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  OneTimeAmount:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  {"$ " + course.amountOneTime}
                </Typography>
              </div>
            </>
          )}

       

          <Button
            variant="contained"
            className="theme-btn"
            onClick={() => debouncedHandleTogglePublish(!isPublished)}
          >
            {isPublished ? "UnPublish Course" : "Publish Course"}
          </Button>
          <Button
            variant="contained"
            className="theme-btn"
            onClick={() => handleDraftorBack()}
            style={{marginLeft:'40px',}}
          >
            {isPublished ? "Back" : "Draft"}
          </Button>
          {showConfetti && (
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
              numberOfPieces={600}
            />
          )}
        </Box>
      </Box>

      <Dialog
        open={openWarn}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="custom_modal"
      >
        <DialogTitle>
          Your haven't Schedule a few lessons , Do you want lessons's being
          scheduled now.
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} className="cancel_btn">
            No
          </Button>
          <Button onClick={handleScheduleLessons} className="theme-btn">
            Yes
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Congratulations!</DialogTitle>
        <DialogContent>
          <p>Your course has been {isPublished ? 'published' : 'unpublished'} successfully!</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
