import React, { useEffect, useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,

} from 'recharts';
import { FormControl, Select, MenuItem, Box, Typography } from '@mui/material';
import axios from 'axios';

const JobChart = () => {
    const [LinechartData, setLineChartData] = useState([]);
    const [fullData, setFullData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(3); // Default to Last 3 months

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}Jobs/TotalJobsGraph?monthsBack=${selectedPeriod}`)
            .then((res) => {
                // Get the monthly jobs data from the response
                const data = res.data.monthlyJobs;

                // Reverse the array to get the most recent months first
                //  const reversedData = data.reverse();

                // Slice the data based on the selected period
                const slicedData = data.slice(0, selectedPeriod).map(item => ({
                    category: item.month, // The month will be used as the category for the x-axis
                    total: item.count,    // The count of jobs will be the value for the line chart
                }));
                // Update the state with the full data and the sliced data for the chart
                setFullData(data);
                setLineChartData(slicedData); // Set the formatted sliced data for the chart

                // Load data based on selected period
            })
            .catch((err) => {
                console.error('Error fetching data', err);
            });
    }, [selectedPeriod]); // Re-fetch data when selectedPeriod changes



    // Handle selection change for period
    const handleSelectChange = (event) => {
        setSelectedPeriod(event.target.value);
        setLineChartData(fullData.slice(0, event.target.value)); // Update chart data based on selected period
    };

    return (
        <Box>
            <Box className="flex-center flex-between flex-wrap mb-20">
                <Typography
                    variant="h6"
                    component="h6"
                    className="fz-18 fw-600 "
                >
                    Jobs
                </Typography>
                <FormControl sx={{ width: "200px" }}>
                    <Select
                        value={selectedPeriod}
                        onChange={handleSelectChange}
                        className="select"
                    >
                        <MenuItem value={3} sx={{ fontSize: "14px", color: "black" }}>
                            Last 3 Months
                        </MenuItem>
                        <MenuItem value={6} sx={{ fontSize: "14px", color: "black" }}>
                            Last 6 Months
                        </MenuItem>
                        <MenuItem value={9} sx={{ fontSize: "14px", color: "black" }}>
                            Last 9 Months
                        </MenuItem>
                        <MenuItem value={12} sx={{ fontSize: "14px", color: "black" }}>
                            Last 12 Months
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box className="position_rel flex-center justify-content-center mt-20 p-20 border borderR_4">



                <LineChart width={600} height={350} data={LinechartData}>
                    {/* XAxis with solid line */}
                    <XAxis
                        dataKey="category"
                        tickLine={false}
                        margin={{ left: 0, right: 0, top: 10, bottom: 20 }}
                        padding={{ left: 20, right: 20 }}
                    />

                    <YAxis
                        tickLine={false}
                        padding={{ top: 20, bottom: 20 }}
                        tickFormatter={(value) => `${value}`}
                    />

                    <CartesianGrid strokeDasharray="3" vertical={false} />

                    <Tooltip />

                    {/* Line chart with custom stroke */}
                    <Line
                        type="monotone"
                        dataKey="total"
                        stroke="#00C853"
                        strokeWidth={4}
                        dot={{ r: 6 }}
                    />
                </LineChart>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        position: "absolute",
                        left: "20px",
                        top: "20%",
                        transform: "translateY(100%) rotate(180deg)", // Added rotation here
                        writingMode: "vertical-rl",
                        color: "#000",
                        zIndex: 8,
                        letterSpacing: "0.5px",
                        fontWeight: "bold"
                    }}
                    className="fz-14 fw-600"
                >
                    Jobs Count
                </Typography>
                <Typography
                    className="fz-14 fw-600"
                    variant="h6"
                    component="h6"
                    sx={{
                        position: "absolute",
                        left: "50%",

                        color: "#000",
                        zIndex: 8,
                        letterSpacing: "0.5px",
                        bottom: "10px",

                    }}
                >
                    Month
                </Typography>
            </Box>

        </Box>
    );
};

export default JobChart;
