import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,

} from "@mui/material";
import {  Videocam } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {  useParams } from "react-router-dom";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import ContentUploader from "./ContentUploader";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';



export default function Content_uploader() {
  const { id } = useParams();
  const [chapters, setChapters] = React.useState([]);
  const [thumbnailToDelete] = React.useState("");
  const [videoToDelete, ] = React.useState("");

  const [chapterid, ] = React.useState("");
  const [openDel, setOpenDel] = React.useState(false);
  const [lessonToDelete, ] = React.useState(null);
  // State to manage additional inputs for thumbnails and videos
  const [fileToDelete] = React.useState("")
  const [audiothumbnailtoDelete] = React.useState(null)
  const [audioToDelete] = React.useState(null)

  


  



  React.useEffect(() => {
    getChapters();
    // eslint-disable-next-line 
  }, [id]);

  const getChapters = async () => {
    try {
      const response = await axios.get(
         process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`
      );
      setChapters(response.data.course.chapters);
      setOpen(new Array(chapters.length).fill(false));
    } catch (error) {
      console.error(error);
    }
  };


  
  





  const [open, setOpen] = React.useState(chapters.map(() => true));



  const handleChange = (index) => (event, isExpanded) => {
    const newOpen = [...open];
    newOpen[index] = isExpanded;
    setOpen(newOpen);
  };





  const handleClose = () => {
    setOpenDel(false);
  };

  const deleteContentFromBackend = async (
    courseId,
    chapterId,
    lessonId,
    body
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/deleteVideoFromLesson/${courseId}/${chapterId}/${lessonId}`;

    try {
     
      const response = await axios.put(
        url,
        body
      
      );
     
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      throw error;
    }
  };

  const handleConfirmDelete = async () => {
    const body = {
   
      videoPath: videoToDelete?.file[0],
      thumbnailPath: thumbnailToDelete.file[0],
    };
    if (lessonToDelete) {
      try {
      await deleteContentFromBackend(
          id,
          chapterid,
          lessonToDelete,
          body
        );
      
        enqueueSnackbar("Thumbnail and Video Deleted Successfully", {
          variant: "success",
        });

        getChapters();
      } catch (error) {
        console.error("Error during deletion:", error);
        enqueueSnackbar("Failed To Delete Thumbnail and Video", {
          variant: "error",
        });
      }
    }
    setOpenDel(false);
  };


  const handleDeleteAudioContent = async (courseId, chapterId, lessonToDelete, videoToDelete, thumbnailToDelete) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/deleteaudieo/${courseId}/${chapterId}/${lessonToDelete}`;
  
    const body = {

      audioPath: videoToDelete?.file[0],
      thumbnailPath: thumbnailToDelete.file[0],
    };
  
    try {
     
      const response = await axios.put(url, body);
    
      
      enqueueSnackbar("Thumbnail and Video Deleted Successfully", {
        variant: "success",
      });
  
      getChapters();
      
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      enqueueSnackbar("Failed To Delete Thumbnail and Video", {
        variant: "error",
      });
      throw error;
    } finally {
      setOpenDel(false);
    }
  };


  const handleDeleteFileContent = async (courseId, chapterId, lessonToDelete, fileTodelete) => {

    const url = `${process.env.REACT_APP_BASE_URL}Course1/deletepdf/${courseId}/${chapterId}/${lessonToDelete}`;
  
    const body = {
      pdfPath: fileTodelete?.file[0],
      
    };
  
    try {
     
      const response = await axios.put(url, body);
     
      
      enqueueSnackbar("Thumbnail and Video Deleted Successfully", {
        variant: "success",
      });
  
      getChapters();
      
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      enqueueSnackbar("Failed To Delete Thumbnail and Video", {
        variant: "error",
      });
      throw error;
    } finally {
      setOpenDel(false);
    }
  };
  
  // Example event handler
  const handleDelete= () => {
    if (audioToDelete) {
     
      handleDeleteAudioContent(id, chapterid, lessonToDelete, audioToDelete ,audiothumbnailtoDelete);
    }
    if (videoToDelete) {
     
      handleConfirmDelete();
    }
    if(fileToDelete){
      handleDeleteFileContent(id, chapterid , lessonToDelete, fileToDelete)
    }
  };



  const [idOfChapter,setIdOfChapter] = React.useState();
  const [titleOfChapter,setTitleOfChapter] = React.useState();
  const [idOfCourse,setIdOfCourse] = React.useState();
  const [indexOfChapter,setIndexOfChapter] = React.useState();
  const [indexOfLesson,setIndexOfLesson] = React.useState();
  // state for lesson component open.
  const [selectedLesson, setSelectedLesson] = React.useState(null);
  // const [selectedLessonOpen, setSelectedLessonOpen] = React.useState(null);

  
  const [selectedIndex, setSelectedIndex] = React.useState(null);


  const handleLessonClick = (chapterIndex, lessonIndex) => () => {
    setIdOfChapter(chapters[chapterIndex]._id)
    setTitleOfChapter(chapters[chapterIndex].title)
    setIdOfCourse(id)
    setIndexOfChapter(chapterIndex)
    setIndexOfLesson(lessonIndex)
    const lesson = chapters[chapterIndex].lessons[lessonIndex];
    setSelectedLesson({
      ...lesson,
      chapterTitle: chapters[chapterIndex].title, // Add chapter title for context
      chapterId:chapters[chapterIndex]._id,
      courseId:id
    });
    // setSelectedLessonOpen(true); 
    setSelectedIndex({ chapterIndex, lessonIndex });
  };







  
  


  return (
    <div className="curriculumPage">
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <Box className="chapterlist">
            {chapters.length > 0 &&
              chapters.map((item, chapterIndex) => (
                <Accordion
                  key={chapterIndex}
                  className="mb-20"
                  expanded={open[chapterIndex]}
                  onChange={handleChange(chapterIndex)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item._id}-content`}
                    id={`panel${item._id}-header`}
                  >
                    <Typography className="fz-16 text_black semibold">
                      {chapterIndex >= 0
                        ? `Chapter  + ${chapterIndex+1}  + : ` + item.title
                        : item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List className="mb-10">
                      {item.lessons.length ? item.lessons.map((cardItem,lessonIndex) => (
                        <ListItem
                          key={cardItem._id}
                          // className="eachcard mb-10 flex-col align-items-start"
                          // onClick={handleLessonClick(chapterIndex, lessonIndex)}
                          className={`eachcard mb-10 flex-col align-items-start ${selectedIndex?.lessonIndex === lessonIndex && selectedIndex.chapterIndex === chapterIndex ? 'selected' : ''}`}
                        onClick={handleLessonClick(chapterIndex, lessonIndex)}
                        style={{
                          backgroundColor: selectedIndex?.lessonIndex === lessonIndex && selectedIndex.chapterIndex === chapterIndex ? '#fdf5e6' : 'transparent', // Change this to your desired color
                        }}
                        >
                          <ListItemText primary={cardItem.title } />
                          <div style={{
                            display:"flex"
                          }}>
                          <PictureAsPdfIcon className="text-grey"/>
                          <AudiotrackIcon className="text-grey"/>
                          <Videocam className="text-grey" />
                          </div>
                          
                        </ListItem>
                      )):"Add lesson to upload content"}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={8}>
          {chapters.length <= 0 && (
            <Box className="h-100p whiteContainer flex-col flex-center justify-content-center">
              <Typography
                component="h5"
                variant="h5"
                className=" text_black semibold"
              >
                Upload Content to your Course
              </Typography>
            </Box>
          )}


         

            <Box>
            {selectedLesson && ( <ContentUploader  
            titleOfChapter={titleOfChapter}
            idOfCourse={idOfCourse}  
            idOfChapter={idOfChapter}
            indexOfChapter={indexOfChapter}
            indexOfLesson={indexOfLesson}
          
            /> )}
            </Box>
        </Grid>
      </Grid>

      <Dialog
        open={openDel}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="custom_modal"
      >
        <DialogTitle>
          {videoToDelete ? `Would you like to delete this Thumbnail and video?`: audioToDelete ? `Would you like to delete this Thumbnail and Audio?`:`coming`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} className="cancel_btn">
            No
          </Button>
          <Button onClick={handleDelete} className="theme-btn">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
