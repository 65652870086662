import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Button, FormControlLabel, CircularProgress, Card, Menu } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material'; // Import PhotoCamera icon
import DeleteIcon from '@mui/icons-material/Delete';

import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import Switch, { } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import IconButton, { } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));



export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const role = JSON.parse(localStorage.getItem("roledata"))
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const [openMenuIndex, setOpenMenuIndex] = React.useState(null);
    const [alldata, setAlldata] = React.useState('');
    const [deleteid, setDeleteId] = React.useState('');

    const [tableLoading, setTableLoading] = React.useState(false)
    const [publicbutton, setPublicbutton] = React.useState(null)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, data, index) => {
        setAnchorEl(event.currentTarget);
        setAlldata(data)
        setOpenMenuIndex(index);

    };

    const handleClose_menu = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null);
    };

    React.useEffect(() => {
        getHomeTab()
    }, [rowsPerPage, page])

    const getHomeTab = async () => {
        setTableLoading(true)
        await axios.get(process.env.REACT_APP_BASE_URL + `Home/GetHomePage?size=${rowsPerPage}&page=${page}`, { headers: headerlocal })
            .then((res) => {

                setNewdata(res.data.data)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error, "error in news get");
            })
            .finally(() => {
                setTimeout(() => {
                    setTableLoading(false)
                }, 1500);
            })
    }


    React.useEffect(() => {

        const publics = []
        const lastdata = []

        newdata.forEach((data, index) => {
            if (data.public === false) {

                publics.push(false);
            }
            if (index === newdata.length - 1 && data.public === false) {
                lastdata.push(false)
            }
        });

        if ((publics.length === newdata.length && newdata.length > 0)) {
            setPublicbutton(newdata.length - 1)
            let data = newdata[newdata.length - 1]._id

            let value = {
                public: true,
            }
            try {
                axios.put(process.env.REACT_APP_BASE_URL + `Home/updateHome/${data}`, value, { headers: headerlocal });
                setPublicbutton(newdata.length - 1)
                lastdata.pop()
                getHomeTab();
            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating public", error);
            }
        }


        if (publics.length > 0 && publics.length === newdata.length - 1 && lastdata.length !== 1) {
            setPublicbutton(newdata.length - 1)
        }


    }, [newdata])




    const handleDeleteHomeTab = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `Home/DeleteHome/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Home tab setting deleted successfully", { variant: 'success' })
                getHomeTab()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleClickOpen = (e, data, index) => {

        if (newdata.length - 1 === index) {
            enqueueSnackbar("Default slider image cannot be deleted", { variant: 'warning' })
            return;

        }

        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };

    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/edithometab", { state: val })
    }







    const handleChangeStatus = async (e, data, type, index) => {

        if (publicbutton === index || newdata.length === 1) {
            enqueueSnackbar("There should be atleast one public slider", { variant: 'warning' })
            return

        }
        if (type === 'Public') {
            const body = {
                public: e.target.checked,
            };
            try {
                const response = await axios.put(process.env.REACT_APP_BASE_URL + `Home/updateHome/${data._id}`, body, { headers: headerlocal });
                if (response.data.updateHome.public) {
                    setPublicbutton(null)
                }

                getHomeTab();




            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating public", error);
            }
        }
    };

    return (
        <>
            <Box className='whiteContainer  p-20'>
                <Box className=''>
                    <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >

                        {
                            tableLoading ? (

                                <Grid item xs={12} className="text-center">
                                    <CircularProgress sx={{ color: '#F3714D' }} />
                                </Grid>
                            ) :



                                (newdata && newdata.length > 0 ? newdata.map((data, index) => {


                                    return (
                                        <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginTop: 2 }}>
                                            <Card className="cardGrid" fullWidth>

                                                {
                                                    role.UserRoles.settings.editsettings || role.UserRoles.settings.deletesettings ?
                                                        <IconButton aria-label="more" id="long-button"
                                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                                            aria-expanded={menuopen ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={(e) => handleClick(e, data, index)}
                                                            className='vertical_menu'>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        : ''

                                                }

                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEl}

                                                    open={openMenuIndex === index}
                                                    onClose={handleClose_menu}
                                                    PaperProps={{
                                                        style: {
                                                            width: '15ch',
                                                        },
                                                    }}
                                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                >
                                                    {
                                                        role.UserRoles.settings.editsettings ?
                                                            <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
                                                            : ''
                                                    }

                                                    {
                                                        role.UserRoles.settings.deletesettings ?
                                                            <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id, index)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>
                                                            : ''
                                                    }




                                                </Menu>
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <div className="carosuel">
                                                        <Swiper
                                                            modules={[Navigation, Pagination]}
                                                            className="mySwiper "
                                                            slidesPerView={1}
                                                            pagination={{
                                                                clickable: true,
                                                            }}
                                                        >
                                                            {data.image ? (
                                                                <img style={{ width: '100%', height: '250px' }} src={process.env.REACT_APP_BASE_URL + data.image} alt='network error' />
                                                            ) : (
                                                                <PhotoCamera style={{ width: '100%', height: '250px' }} />
                                                            )}
                                                        </Swiper>
                                                    </div>
                                                </Grid>

                                                <CardContent>
                                                    <Box className='d-flex align-items-center justify-content-between' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography noWrap className='semibold title fz-18' variant="body1" component="div">{data.link}</Typography>
                                                    </Box>
                                                    <Box className='mt-10' >
                                                        <Typography component='div' variant='body1' className='fz-14 semibold'>Public:   </Typography>
                                                        <FormControlLabel className='m-0' control={<Switch size='small' onChange={(e) => handleChangeStatus(e, data, 'Public', index)} checked={data.public} />} />
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <p className='text-center semibold'>No data found</p></Grid>)}
                    </Grid>
                    <TablePagination
                        rowsPerPageOptions={[12, 24, 48]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>

            <BootstrapDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Would you like to delete this slider?"}</DialogTitle>
                {/* <DialogContent>
                    <Typography variant="body2" id="alert-dialog-description">
                        
                    </Typography>
                </DialogContent> */}
                <DialogActions>
                    <Button onClick={handleClose} color="primary" className='cancel_btn'>
                        No
                    </Button>
                    <Button onClick={handleDeleteHomeTab} color="primary" className='theme-btn' autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </BootstrapDialog>

        </>
    );
}
