import React, { useState } from "react";
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';
// import Sample from './sample.json'
// import DOMPurify from 'dompurify';
import { Button,  Grid,CircularProgress } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack'
// import dayjs from 'dayjs';
// import { usePlacesWidget } from "react-google-autocomplete";
import { useCommunity } from "../Context/CommunityContext";
import CompareIcon from '@mui/icons-material/Compare';

import {Dvr, ListAlt } from '@mui/icons-material';



export default function DataTable({ params }) {
	
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	// const headerformdata = {
	// 	'Content-Type': 'multipart/form-data',
	// 	Authorization: localStorage.getItem("token")
	// }
	// function BpRadio(props) {
	// 	return (
	// 		<Radio
	// 			sx={{
	// 				'&:hover': {
	// 					bgcolor: 'transparent',
	// 				},
	// 			}}
	// 			disableRipple
	// 			color="default"
	// 			checkedIcon={<BpCheckedIcon />}
	// 			icon={<BpIcon />}
	// 			{...props}
	// 		/>
	// 	);
	// }
	// const BpIcon = styled('span')(({ theme }) => ({
	// 	borderRadius: '50%',
	// 	width: 16,
	// 	height: 16,
	// 	boxShadow:
	// 		theme.palette.mode === 'dark'
	// 			? '0 0 0 1px rgb(16 22 26 / 40%)'
	// 			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	// 	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	// 	backgroundImage:
	// 		theme.palette.mode === 'dark'
	// 			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
	// 			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	// 	'.Mui-focusVisible &': {
	// 		outline: '2px auto rgba(19,124,189,.6)',
	// 		outlineOffset: 2,
	// 	},
	// 	'input:hover ~ &': {
	// 		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	// 	},
	// 	'input:disabled ~ &': {
	// 		boxShadow: 'none',
	// 		background:
	// 			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	// 	},
	// }));

	// const BpCheckedIcon = styled(BpIcon)({
	// 	backgroundColor: '#f3714d',
	// 	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	// 	'&:before': {
	// 		display: 'block',
	// 		width: 16,
	// 		height: 16,
	// 		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
	// 		content: '""',
	// 	},
	// 	'input:hover ~ &': {
	// 		backgroundColor: '#106ba3',
	// 	},
	// });

	// const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	// 	[`&.${stepConnectorClasses.alternativeLabel}`]: {
	// 		top: 22,
	// 	},
	// 	[`&.${stepConnectorClasses.active}`]: {
	// 		[`& .${stepConnectorClasses.line}`]: {
	// 			backgroundColor:
	// 				'#f3714d',
	// 		},
	// 	},
	// 	[`&.${stepConnectorClasses.completed}`]: {
	// 		[`& .${stepConnectorClasses.line}`]: {
	// 			backgroundColor:
	// 				'#f3714d',
	// 		},
	// 	},
	// 	[`& .${stepConnectorClasses.line}`]: {
	// 		height: 3,
	// 		border: 0,
	// 		backgroundColor:
	// 			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
	// 		borderRadius: 1,
	// 	},
	// }));

	const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		...(ownerState.active && {
			backgroundColor:
				'#f3714d',
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		}),
		...(ownerState.completed && {
			backgroundColor:
				'#f3714d',
		}),
	}));

	function ColorlibStepIcon(props) {
		const { active, completed, className } = props;

		const icons = {
			1: <ListAlt />,
			2: <CompareIcon />,
			3: <Dvr />,
		};
		return (
			<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
				{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	}

	ColorlibStepIcon.propTypes = {
		/**
		 * Whether this step is active.
		 * @default false
		 */
		active: PropTypes.bool,
		className: PropTypes.string,
		/**
		 * Mark the step as completed. Is passed to child components.
		 * @default false
		 */
		completed: PropTypes.bool,
		/**
		 * The label displayed in the step icon.
		 */
		icon: PropTypes.node,
	};
	const { communityCourse, fetchCommunity } = useCommunity();
	const [buttondisable, setButtondisable] = React.useState(false)
	// const [staffdata, setCoursedata] = React.useState([])
	const [selecttedCourse, setSelecttedCourse] = React.useState([])
	// const navigate = useNavigate();
	// const [activeStep, setActiveStep] = React.useState(0);
	const [course, setCourse] = React.useState([])
	// const [categoryId] = React.useState('')
	// const [subcategoryId, setSubCategoryId] = React.useState('')
	// const [statesingle, setStatesingle] = React.useState([])
	// const [statesingle2, setStatesingle2] = React.useState([])
	// const [selectedFromDateTime, setSelectedFromDateTime] = useState(dayjs(new Date()));
	// const [selectedToDateTime, setSelectedToDateTime] = useState(dayjs(new Date()));
	// const [category, setCategory] = React.useState([])
	// const [description, setDescription] = React.useState('')
	// const [subcat, setSubcat] = React.useState([])
	// const [state1, setState1] = React.useState([])
	// const [state2, setState2] = React.useState([])
	// const [imageAddError, setImageAddError] = React.useState('')
	// const [selectedVideo, setSelectedVideo] = useState([]);
	// const [videoPreviewUrl, setVideoPreviewUrl] = useState([]);
	// const [selectGallert, setSelectGallery] = useState('Image')
	// const [addDisable, setAddDisable] = React.useState(false)
	// const [cityName, setCityName] = useState('')
	// const [catError, setCatError] = React.useState('')
	// const [lat, setLat] = useState(0.0)
	// const [lng, setLng] = useState(0.0)
	// const [imageError, setImageError] = React.useState('')
	// const [addCourseFrom] = useState('Community')addCommunityCourse
	const { enqueueSnackbar } = useSnackbar()
	// const [newcourse, setNewCourse] = React.useState({ courseName: '', description: "", fees: "", owner: "", specialization: "", address: "", video: "" })
	// const [error, setError] = React.useState({ eventName: "", status: "", link: '', organizer: "", fromTime: "", toTime: "", video: "", location: "" })
	const[Tableloading,setTableloading]=React.useState(true)
	// const [status, setStatus] = React.useState('')
	const [rowsPerPage,setrowsPerPage]=useState(21)
	const [page,setPage]=useState(0)
	 const [count, setCount] = React.useState(0)

	// React.useEffect(() => {
	// 	getCategory()
	// }, [])

	// React.useEffect(() => {
	// 	getSubCategory()
	// }, [categoryId])

	// const getCategory = async () => {
	// 	await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
	// 		// setCategory(res.data.category)
	// 	}).catch((error) => {
	// 		console.log(error.response, "error");
	// 	})
	// }
	// const getSubCategory = async () => {
	// 	await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
	// 		.then((res) => { setSubcat(res.data.subcategory) }).catch((error) => {
	// 			console.log(error.response, "error");
	// 		})
	// }

	// const handleRemoveRestart = () => {
	// 	setSubCategoryId('')
	// }

	// const SingleImage = (e, index) => {
	// 	setImageError('');
	// 	setError('');

	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];

	// 	fileObj.push(e.target.files);

	// 	for (let i = 0; i < fileObj[0].length; i++) {
	// 		const img = new Image();
	// 		img.src = URL.createObjectURL(fileObj[0][i]);

	// 		img.onload = function () {
	// 			if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
	// 				setImageError('Image size should be less than 1 MB.');
	// 			} else {
	// 				fileArray.push(URL.createObjectURL(fileObj[0][i]));
	// 				filepath.push(fileObj[0][i]);

	// 				var data = statesingle.filter(x => x != null);
	// 				var data1 = statesingle2.filter(x => x != null);
	// 				setStatesingle(data);
	// 				setStatesingle2(data1);
	// 				setStatesingle(prevValue => prevValue.concat(fileArray));
	// 				setStatesingle2(prevValue => prevValue.concat(filepath));
	// 			}
	// 		};
	// 	}
	// };


	// const indexDelete1 = (e, index) => {
	// 	var filteredpreview = statesingle.filter((value, i) => {
	// 		return i !== index;
	// 	});
	// 	var filteredraw = statesingle2.filter((val, i) => {
	// 		return i !== index;
	// 	});
	// 	setStatesingle(filteredpreview);
	// 	setStatesingle2(filteredraw);
	// }

	// const { ref, autocompleteRef } = usePlacesWidget({
	// 	// need to change this API key 
	// 	apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
	// 	onPlaceSelected: (place) => {
	// 		setCityName(place.formatted_address)
	// 		setLat(place.geometry.location.lat());
	// 		setLng(place.geometry.location.lng());
	// 		// setError(prevError => ({ ...prevError, location: '' }))
	// 	},
	// 	options: {
	// 		types: "(regions)" | 'establishment'
	// 	},
	// });
	// const handleFromDateTimeChange = (newValue) => {
	// 	console.log(newValue);
	// 	setSelectedFromDateTime(newValue);
	// 	// setError(prevError => ({ ...prevError, start_date: '' }))
	// };
	// const handleToDateTimeChange = (newValue) => {
	// 	setSelectedToDateTime(newValue);
	// 	// setError(prevError => ({ ...prevError, end_date: '' }))
	// };

	// const handleEditGallery = (e) => {
	// 	setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')

	// }

	// const emailEditorRef = useRef({ editor: {} });
	// const unlayerSampleJSON = {
	// 	counters: {
	// 		u_column: 1,
	// 		u_row: 1,
	// 		u_content_text: 1,
	// 	},
	// 	body: {
	// 		id: "MJ_d5bY5sW",
	// 		rows: [
	// 			{
	// 				id: "-qw54ukLG6",
	// 				cells: [1],
	// 				columns: [
	// 					{
	// 						id: "JmI5YdJgB-",
	// 						contents: [
	// 							{
	// 								id: "ltAvFqp4nr",
	// 								type: "text",
	// 								values: {
	// 									containerPadding: "10px",
	// 									anchor: "",
	// 									textAlign: "left",
	// 									lineHeight: "140%",
	// 									linkStyle: {
	// 										inherit: true,
	// 										linkColor: "#0000ee",
	// 										linkHoverColor: "#0000ee",
	// 										linkUnderline: true,
	// 										linkHoverUnderline: true,
	// 									},
	// 									hideDesktop: false,
	// 									displayCondition: null,
	// 									_meta: {
	// 										htmlID: "u_content_text_1",
	// 										htmlClassNames: "u_content_text",
	// 									},
	// 									selectable: true,
	// 									draggable: true,
	// 									duplicatable: true,
	// 									deletable: true,
	// 									hideable: true,
	// 									text: " ",
	// 									// ------------>
	// 								},
	// 							},
	// 						],
	// 						values: {
	// 							border: {},
	// 							padding: "0px",
	// 							backgroundColor: "",
	// 							_meta: {
	// 								htmlID: "u_column_1",
	// 								htmlClassNames: "u_column",
	// 							},
	// 						},
	// 					},
	// 				],
	// 				values: {
	// 					displayCondition: null,
	// 					columns: false,
	// 					backgroundColor: "",
	// 					columnsBackgroundColor: "",
	// 					backgroundImage: {
	// 						url: "",
	// 						fullWidth: true,
	// 						repeat: "no-repeat",
	// 						size: "custom",
	// 						position: "center",
	// 					},
	// 					padding: "0px",
	// 					anchor: "",
	// 					hideDesktop: false,
	// 					_meta: {
	// 						htmlID: "u_row_1",
	// 						htmlClassNames: "u_row",
	// 					},
	// 					selectable: true,
	// 					draggable: true,
	// 					duplicatable: true,
	// 					deletable: true,
	// 					hideable: true,
	// 				},
	// 			},
	// 		],
	// 		values: {
	// 			popupPosition: "center",
	// 			popupWidth: "600px",
	// 			popupHeight: "auto",
	// 			borderRadius: "10px",
	// 			contentAlign: "center",
	// 			contentVerticalAlign: "center",
	// 			contentWidth: "950px",
	// 			fontFamily: {
	// 				label: "Arial",
	// 				value: "arial,helvetica,sans-serif",
	// 			},
	// 			textColor: "#000000",
	// 			popupBackgroundColor: "#FFFFFF",
	// 			popupBackgroundImage: {
	// 				url: "",
	// 				fullWidth: true,
	// 				repeat: "no-repeat",
	// 				size: "cover",
	// 				position: "center",
	// 			},
	// 			popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
	// 			popupCloseButton_position: "top-right",
	// 			popupCloseButton_backgroundColor: "#DDDDDD",
	// 			popupCloseButton_iconColor: "#000000",
	// 			popupCloseButton_borderRadius: "0px",
	// 			popupCloseButton_margin: "0px",
	// 			popupCloseButton_action: {
	// 				name: "close_popup",
	// 				attrs: {
	// 					onClick:
	// 						"document.querySelector('.u-popup-container').style.display = 'none';",
	// 				},
	// 			},
	// 			backgroundColor: "#e7e7e7",
	// 			backgroundImage: {
	// 				url: "",
	// 				fullWidth: true,
	// 				repeat: "no-repeat",
	// 				size: "custom",
	// 				position: "center",
	// 			},
	// 			preheaderText: "",
	// 			linkStyle: {
	// 				body: true,
	// 				linkColor: "#0000ee",
	// 				linkHoverColor: "#0000ee",
	// 				linkUnderline: true,
	// 				linkHoverUnderline: true,
	// 			},
	// 			_meta: {
	// 				htmlID: "u_body",
	// 				htmlClassNames: "u_body",
	// 			},
	// 		},
	// 	},
	// 	schemaVersion: 12,
	// };
	// const [designEditor, setDesignEditor] = useState(Sample)

	// const onLoad = () => {
	// 	emailEditorRef.current.editor.loadDesign(designEditor);
	// };

	// const handleEditor = (e) => {
	// 	const unlayer = emailEditorRef.current?.editor;
	// 	unlayer?.exportHtml((data) => {
	// 		const { design, html } = data;
	// 		const sanitizedHtml = DOMPurify.sanitize(html);
	// 		const editor = { "body": design.body }
	// 		setDesignEditor(editor)
	// 		enqueueSnackbar('Saved changes', { variant: 'info' });
	// 	})
	// }



	// const validate = () => {
	// 	// console.log(currentDate, 'currentDate');
	// 	if (!newcourse.courseName && statesingle2?.length == 0 && !newcourse.fees) {
	// 		setError({ courseName: "This field is required.", fees: "This field is required" })
	// 		setCatError("This field is required.")
	// 		setImageError("This field is required.")
	// 		return false
	// 	}
	// 	if (!newcourse.courseName) {
	// 		setError({ courseName: "This field is required." })
	// 		return false
	// 	}
	// 	// if (!status) {
	// 	// 	setError({ status: "This field is required." })
	// 	// 	return false
	// 	// }
	// 	if (!categoryId) {
	// 		setCatError("This field is required.")
	// 	}
	// 	if (!newcourse.fees) {
	// 		setError({ fees: "This field is required." })
	// 		return false
	// 	}
	// 	// if (!course.owner) {
	// 	// 	setError({ owner: "This field is required." })
	// 	// 	return false
	// 	// }
	// 	// if (!course.specialization) {
	// 	// 	setError({ specialization: "This field is required." })
	// 	// 	return false
	// 	// }
	// 	// if (!course.address) {
	// 	// 	setError({ address: "This field is required." })
	// 	// 	return false
	// 	// }
	// 	// if (!cityName) {
	// 	// 	setError({ location: "This field is required." })
	// 	// 	return false
	// 	// }
	// 	// if (currentDate <= selectedFromDateTime ) {
	// 	// 	setError({ fromTime: "Please select a date and time greater than the present date and time." })
	// 	// 	return false
	// 	// }
	// 	// if (selectedFromDateTime < selectedToDateTime ) {
	// 	// 	setError({ toTime: "Please select a date and time greater than the from date and time." })
	// 	// 	return false
	// 	// }
	// 	if (statesingle2?.length == 0) {
	// 		setImageError("This field is required.")
	// 		return false
	// 	}

	// 	return true
	// }
	// const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

	// const handleVideoChange = (e, index) => {
	// 	setError(prevError => ({ ...prevError, video: '' }))
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];
	// 	fileObj.push(e.target.files)
	// 	for (let i = 0; i <= fileObj[0].length; i++) {
	// 		if (i < fileObj[0].length) {
	// 			fileArray.push(URL.createObjectURL(fileObj[0][i]))
	// 			filepath.push(fileObj[0][i])
	// 		}
	// 	}
	// 	setVideoPreviewUrl(fileArray)
	// 	setSelectedVideo(filepath)
	// }

	// const handleVideoChange = (e, index) => {
	// 	setError({ video: '' });
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];
	// 	fileObj.push(e.target.files);
	// 	for (let i = 0; i < fileObj[0].length; i++) {
	// 		if (fileObj[0][i].size > 25 * 1024 * 1024) { // Corrected comparison
	// 			setError({ video: 'Video should be less than 25 MB' });
	// 			return;
	// 		}
	// 		fileArray.push(URL.createObjectURL(fileObj[0][i]));
	// 		filepath.push(fileObj[0][i]);
	// 	}

	// 	setVideoPreviewUrl(fileArray);
	// 	setSelectedVideo(filepath);
	// }

	// const clearVideo = () => {
	// 	setSelectedVideo([]);
	// 	setVideoPreviewUrl([]);
	// };

	// const multipleImgChange = (e, index) => {
	// 	setImageAddError('');

	// 	// Validate file size
	// 	const maxSize = 1024 * 1024; // 1MB in bytes
	// 	const files = e.target.files;
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];

	// 	for (let i = 0; i < files.length; i++) {
	// 		if (files[i].size > maxSize) {
	// 			// Handle file size error (for example, set an error state)
	// 			setImageAddError('Image size should be less than 1 MB.');
	// 			continue; // Skip this file
	// 		}

	// 		fileObj.push(files[i]);
	// 		fileArray.push(URL.createObjectURL(files[i]));
	// 		filepath.push(files[i]);
	// 	}

	// 	var data = state1.filter(x => x != null);
	// 	var data1 = state2.filter(x => x != null);
	// 	setState1(data);
	// 	setState2(data1);
	// 	setState1(prevValue => prevValue.concat(fileArray));
	// 	setState2(prevValue => prevValue.concat(filepath));
	// }

	// const indexDelete = (e, index) => {
	// 	var filteredpreview = state1.filter((value, i) => {
	// 		return i !== index;
	// 	});
	// 	var filteredraw = state2.filter((val, i) => {
	// 		return i !== index;
	// 	});
	// 	setState1(filteredpreview);
	// 	setState2(filteredraw);
	// }


	React.useEffect(() => {
		getCourse()
		// eslint-disable-next-line 
	}, [communityCourse,rowsPerPage,page])

	React.useEffect(() => {
		fetchCommunity(params.id);
		// eslint-disable-next-line 
	}, [params.id]);


	// let name, value;
	// const handlechange = (e) => {
	// 	name = e.target.name;
	// 	value = e.target.value;
	// 	setNewCourse({ ...newcourse, [name]: value })
	// 	// console.log("Name:", name);
	// 	// console.log("Value:", value);
	// }

	// const handleNext = () => {
	// 	var valid = validate()
	// 	if (valid === true) {
	// 		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	// 	}
	// 	if (valid === true && validatenew === true) {
	// 		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	// 	}
	// };

	// const validatenew = () => {
		// if (state2?.length == 0 && selectedVideo?.length == 0) {
		// 	setError({ video: "This field is required." })
		// 	setImageAddError("This field is required.")
		// 	return false
		// }
		// if (state2?.length == 0) {
		// 	setImageAddError("This field is required.")
		// 	return false
		// }
		// if (selectedVideo?.length == 0) {
		// 	setError({ video: "This field is required." })
		// 	return false
		// }
		// return true
	// }

	// const handleBack = () => {
	// 	setActiveStep((prevActiveStep) => prevActiveStep - 1);
	// };

	// const handleEditOwner = (e) => {
	// 	setSelectOwner(e.target.value);
	// 	setTeacherName([]);
	// 	setCommunityName([]);
	// }


	const getCourse = async () => {
		try {
			setTableloading(true)
			await axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourseallpublic?size=${rowsPerPage}&Page=${page}`, { headers: headerlocal }).then((res) => {
				res.data.course && res.data.course.forEach((item, i) => {
					const isSelected = communityCourse ? communityCourse.some(course => course.course_id === item._id) : false;
				
					setCount(res.data.totalcount);
					if (isSelected) {
						setSelecttedCourse(prevState => [...prevState, {
							specialization: item.specialization, 
							course_id: item._id, 
							course_name: item.course_name, 
							fees: item.fees, 
							owner: item.owner, 
							start_date: item.start_date, 
							end_date: item.end_date, 
							allimages: item.allimages, 
							Category_id: item.Category_id, 
							Subcategory_id: item.Subcategory_id, 
							formatted_address: item.formatted_address, 
							shortDescription: item.shortDescription
						}]);
					}
				
					var value = { selectCourse: isSelected }
					Object.assign(item, value)
				})
			
				setCourse(res.data.course)
			}).catch((err) => console.log(err, "error"))
		} catch (error) {
			console.log(error.response, "error");
		}
		finally{
			setTimeout(() => {
                setTableloading(false);
            }, 1000);
		}
	}


	const habdleSelectCourse = async (e, item, i) => {
		var courses = [...course]
		var value = item.selectCourse
	


		if (value === false) {
			courses[i].selectCourse = true;
			setCourse(courses)
			setSelecttedCourse(prev => prev.concat({
				specialization: item.specialization, 
				course_id: item._id, 
				course_name: item.course_name, 
				fees: item.fees, 
				owner: item.owner, 
				start_date: item.start_date, 
				end_date: item.end_date, 
				allimages: item.allimages, 
				Category_id: item.Category_id, 
				Subcategory_id: item.Subcategory_id, 
				formatted_address: item.formatted_address, 
				shortDescription: item.shortDescription
			}))
		}
		if (value === true) {
			var data1 = selecttedCourse.filter((val) => val.course_id !== item._id)
			setSelecttedCourse(data1)
			courses[i].selectCourse = false;
			// setCoursedata(courses)
		}
	}
	const handleUpdateCommunity = async () => {

		const filteredData = [];
		const seenIds = {};

		selecttedCourse.forEach(item => {
			if (!seenIds[item.course_id]) {
				filteredData.push(item);
				seenIds[item.course_id] = true;
			}
		});

		let body = {
			course_id: JSON.stringify(filteredData.length > 0 ? filteredData : []),
		}
		
		
		setButtondisable(true)
		axios.post(process.env.REACT_APP_BASE_URL + `community/UpdateCourseCommunity/${params.id}`, body, { headers: headerlocal }).then((res) => {
			setSelecttedCourse([]);
			fetchCommunity(params.id)
			enqueueSnackbar("Community updated successfully", { variant: 'success' });

		}).catch((err) => {
			enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
			console.log(err, "error");
		})
		.finally(()=>{
			setButtondisable(false)
		})
		// }
	}

	// const addCourseDetails = () => {
	// 	var multipleImage = state2.filter(x => x != null);

	// 	// const validator = validateDate()
	// 	// if (validator == true) {
	// 	const formData = new FormData();
	// 	formData.append('addCourseFrom', addCourseFrom)
	// 	formData.append('createdby[]', params && params.id);
	// 	formData.append("course_name", newcourse.courseName)
	// 	formData.append("status", status)
	// 	formData.append("start_date", selectedFromDateTime)
	// 	formData.append("end_date", selectedToDateTime)
	// 	formData.append("fees", newcourse.fees)
	// 	formData.append("owner", newcourse.owner)
	// 	formData.append("specialization", newcourse.specialization)
	// 	formData.append("address", newcourse.address)
	// 	formData.append("location_coord", cityName)
	// 	formData.append("latitude", lat)
	// 	formData.append("longitude", lng)
	// 	formData.append("formatted_address", cityName)
	// 	formData.append("shortDescription", description)
	// 	formData.append("Category_id", categoryId)
	// 	formData.append("Subcategory_id", subcategoryId)
	// 	formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
	// 	 statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') 
		
	// 		selectGallert === 'Image' ? <>
	// 			{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
	// 			{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
	// 		</> : <>
	// 			{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
	// 			{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}</>
		

	// 	function convertLatLongToNumbers(formData) {
	// 		const tempData = {};
	// 		for (let pair of formData.entries()) {
	// 			const key = pair[0].trim();
	// 			let value = pair[1];
	// 			if (key === 'latitude' || key === 'longitude') {
	// 				value = parseFloat(value);
	// 			}
	// 			tempData[key] = value;
	// 		}
	// 		formData = new FormData();
	// 		for (const [key, value] of Object.entries(tempData)) {
	// 			formData.append(key, value);
	// 		}
	// 		return formData;
	// 	}

	// 	const convertedFormData = convertLatLongToNumbers(formData);
	// 	console.log([...formData], 'formDatacourse');
	// 	axios.post(process.env.REACT_APP_BASE_URL + `course/addCourse`, convertedFormData, { headers: headerformdata }).then((res) => {
	// 		const resp = res.data.addCourse;
	// 		if (resp) {
	// 			const obj = {
	// 				"course_name": resp.courseName || "",
	// 				"course_id": resp._id || "",
	// 				"shortDescription": resp.description || "",
					
	// 			};
	// 			console.log(resp)
	// 			addCommunityCourse(obj);
	// 			fetchCommunity();
	// 			setCategoryId('');
	// 			setSubCategoryId('');
	// 			setStatesingle('')
	// 			setState1([])
	// 			setState2([])
	// 			setSelectedVideo('')
	// 			setNewCourse({ courseName: '', description: "", fees: "", owner: "", specialization: "", address: "" });
	// 			enqueueSnackbar("Course added successfully", { variant: 'success' });
	// 		} else {
	// 			enqueueSnackbar("Something went wrong", { variant: 'error' });
	// 		}
	// 	}).catch((err) => {
	// 		console.log(err)
	// 		enqueueSnackbar(err && err.response && err.response.message ? err.response.message : err.response.data.error ? err.response.data.error : "Somthing went wrong", { variant: 'error' })
	// 	})
	// 	// }
	// }

	const handleChangeRowsPerPage = (event) => {
        setrowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

	const handleChangePage = (event, newPage) => {
	
        setPage(newPage);
		
		
    };
	return (
		<Box sx={{ width: '100%' }} className="">
			<Box className="whiteContainer mb-20">
				<Grid container className='newmerchant '>
					<Typography component='h6' className='formTitle '>Select Course</Typography>
					<Grid item sm={12} md={12} className="w-100">
						<Box className="form-box staff_list" >
							<Grid container columnSpacing={2} rowSpacing={2}>
								{Tableloading?
                            (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item>
                                            <CircularProgress sx={{ color: '#F3714D' }} />
                                        </Grid>
                                    </Grid>
                            )
                            :



								(course && course.length > 0 ? course.map((item, index) => 
									{
									
										return(

									<Grid item sm={12} md={12} lg={4} className="w-100 d-flex">
										<Box onClick={(e) => habdleSelectCourse(e, item, index)} className={item.selectCourse === true ? "selected_staff staff_card" : "staff_card"} >
											<Check className="checksvg" />
											<Avatar className='mr-10' src={process.env.REACT_APP_BASE_URL + item.image} >{item && item.course_name && item.course_name.split("", 1)}</Avatar>
											<Box>
												<Box className='fz-16 semibold'> {item.course_title && item.course_title}</Box>
												{/* <Box className='fz-14'>{item.specialization}</Box> */}
											</Box>
							
										</Box>
									</Grid>
								)}) : "")}
							</Grid>
						</Box>
					</Grid>
				</Grid>

				
				<Box className="text-right mb-20 mr-10" sx={{marginTop:'15px'}}>


				



					{buttondisable === false ? <Button variant="contained" onClick={handleUpdateCommunity} sx={{ mt: 1, mr: 1, width: 100, marginLeft: 'auto' }}
						className='theme-btn'>Submit</Button> : <Button variant="contained" sx={{ mt: 1, mr: 1 ,marginLeft:"40px",}} className='theme-btn'>Please wait</Button>}

				</Box>
				<Box className="text-right mb-20 mr-10" sx={{marginTop:'15px'}}>
				<TablePagination
                    rowsPerPageOptions={[21, 42, 63]}
					sx={{display:'flex',justifyContent:'end',}}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
				</Box>
			</Box>
			{/* <Grid container className='newmerchant'> */}
			{/* <Box className="whiteContainer">
				<Box className=' p-20'>
					<Typography component='h6' className='formTitle mb-20'>Add Course</Typography>

					<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					{activeStep === 0 &&
						<Box className='steppy_content'>
							<Box className="maxw_700">
								<Box className="mb-20">
									<Typography component='div' className='label'>Course Name <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											size="small"
											name='courseName'
											placeholder='Name'
											className='textfield'
											style={{ width: '100%' }}
											value={course.courseName}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, courseName: '' }))
											}}
										/>
										{error.courseName && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.courseName}</p>}
									</FormControl>
								</Box>



								<Box className='mb-20'>
									<Typography component='div' className='label'>Select Mode<Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={status ? status : "sel"}
											placeholder="Select mode of course"
											className="select"
											onChange={(e) => { setStatus(e.target.value) }}
										>
											<MenuItem disabled value={'sel'}>Select mode of course</MenuItem>
											<MenuItem value={"online"}>Online</MenuItem>
											<MenuItem value={"offline"}>Offline</MenuItem>
										</Select>
										{error.status && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.status}</p>}
									</FormControl>
								</Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={categoryId ? categoryId : 'sel'}
											onChange={(e) => { setCategoryId(e.target.value); setCatError("") }}>
											<MenuItem disabled value={'sel'} >Select category</MenuItem>
											{category && category?.length > 0 ? category.map((data) => (
												<MenuItem value={data._id}>{data.category_title}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
										</Select>
										{catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component='div' className='label' >
										Select Sub Category
									</Typography>
									<FormControl fullWidth style={{ flex: 1 }}>
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											disabled={categoryId !== '' ? false : true}
											value={subcategoryId ? subcategoryId : 'sel18'}
											onChange={(e) => {
												setSubCategoryId(e.target.value);
												setSubCatError('');
												setCatError('');
												setSubCatError('');
											}}
										>
											<MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
											{subcat && subcat?.length > 0 ? (
												subcat.map((data5) => (
													<MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
												))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
										</Select>
										{subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
									</FormControl>
									{subcategoryId !== '' && (
										<RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
									)}
								</Box>


								<Box className="mb-20">
									<Typography component='div' className='label'>Start Date <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth className="datepicker">
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
												<DateTimePicker
													onChange={handleFromDateTimeChange}
													value={selectedFromDateTime && selectedFromDateTime}
													format="DD-MM-YYYY hh:mm A"
													minDate={dayjs()}
													ampm={true}
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock,
														seconds: renderTimeViewClock,
													}}
													className="textfield"
												/>
											</DemoContainer>
										</LocalizationProvider>
										{error.start_date && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.start_date}</p>}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component='div' className='label'>End Date<Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth className="datepicker">
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
												<DateTimePicker
													size="small"
													onChange={handleToDateTimeChange}
													value={selectedToDateTime && selectedToDateTime}
													format="DD-MM-YYYY hh:mm A"
													minDate={dayjs(selectedFromDateTime)}
													ampm={true}
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock,
														seconds: renderTimeViewClock,
													}}
													className="textfield"
												/>
											</DemoContainer>
										</LocalizationProvider>
										{error.end_date && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.end_date}</p>}
									</FormControl>
								</Box>



								<Box className="mb-20">
									<Typography component='div' className='label'>Fees <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Fees'
											size="small"
											name='fees'
											onInput={(e) => onlyNumbers(e)}
											className='textfield'
											style={{ width: '100%' }}
											value={course.fees}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, fees: '' }))
											}}
										/>
										{error.fees && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.fees}</p>}
									</FormControl>
								</Box>


								{status === 'offline' &&
									<Box className="mb-20">
										<Typography component='div' className='label'>Location</Typography>
										<AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />
									</Box>}

								<Box className="mb-20">

									<Typography component='div' className='label'>Cover Image <Typography component='span' className='star'>*</Typography></Typography>
									<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
										{statesingle.length < 1 &&
											<Button className="multiple_upload_btn" variant="contained" component="label">
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												<input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
											</Button>
										}
									</Box>
								</Box>
								{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
								<Box className="mb-20">
									{statesingle.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
											{statesingle.length > 0 ? statesingle.map((x, i) => (
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
														<img src={x} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}
										</Grid>
									}
								</Box>
								<Box className="mb-20">
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
								</Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Short Description </Typography>
									<FormControl variant="standard" fullWidth>
										<textarea
											placeholder='Short Description '
											className='textarea'
											defaultValue={description}
											onChange={(e) => setDescription(e.target.value)}
											rows={4}
											cols={40}
											style={{ resize: 'none' }} />
									</FormControl>
								</Box>
								{
									activeStep === 0 &&
									<>
										<Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
											<Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
										</Box>
									</>
								}
							</Box>
						</Box >
					}
					{activeStep === 1 &&
						<Box className="steppy_content mb-10">
							<Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">

								<Grid item xs={12} sm={4} md={4} lg={4}>
									<Typography component='div' className='label'>Images/Video </Typography>

								</Grid>
								<Grid item xs={12} sm={8} md={8} lg={8}>
									<Box className=" ">
										<RadioGroup
											row
											aria-labelledby="demo-customized-radios"
											name="payment_type"
											onChange={handleEditGallery}
											value={selectGallert}
										>
											<FormControlLabel value="Image" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Additional images</Typography>} />
											<FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
										</RadioGroup>
									</Box>
								</Grid>
								{selectGallert === 'Image' ? <>
									<Grid item xs={12} sm={12} md={12}  >
										<Typography component='div' className='label'>Additional Images</Typography>
										<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
											{state1.length < 1 &&
												<Button className="multiple_upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
													<input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
												</Button>
											}
										</Box>
									</Grid>
									<Grid item xs={12} sm={12} md={12}  >
										{state1.length > 0 &&
											<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
												{state1.length > 0 ? state1.map((x, i) => (
													<Grid item xs={6} sm={4} md={3} lg={3} >
														<Box className='uploaded_img' >
															<Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
															<img src={x} alt='' height={155} width={195} />
														</Box>
													</Grid>
												)) : ""}
												<Grid item xs={6} sm={4} md={3} lg={3} >
													<label htmlFor="contained-button-file1" className="w-full">
														<Box className='multiple_upload_btn' >
															<Input
																inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
																onChange={multipleImgChange}
																style={{ display: "none", }}
																id="contained-button-file1"
																type="file"
															/>
															<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
														</Box>
													</label>
												</Grid>
											</Grid>}
										{imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}

									</Grid>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
								</> : <>
									<Grid item xs={12} sm={12} md={12}>
										<Typography component='div' className='label'>Video</Typography>

										<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
											{selectedVideo && selectedVideo?.length === 0 &&
												<Button className="multiple_upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
													</Box>
													<input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
												</Button>
											}
											<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
												{selectedVideo && selectedVideo?.length > 0 &&
													<Grid item xs={6} sm={4} md={4} lg={4} >
														<Box className="uploaded_vdo" >
															<Button className='close_icon' onClick={clearVideo}><Close /></Button>
															<video className='uploaded_vdo' controls>
																<source src={videoPreviewUrl} type={selectedVideo.type} />
															</video>
														</Box></Grid>}
											</Grid>
										</Box>
										{error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
									</Grid>


								</>} */}

								{/* <Grid item xs={12} sm={12} md={6} lg={6} className='mb-10'>
                                    <Typography component='div' className='label'>Description</Typography>
                                    <div className="editorclass">
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={handleEditorChange}
                                            toolbarClassName="toolbar"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorcontent"

                                            toolbar={{
                                                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline'],
                                                },
                                                blockType: {
                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                },
                                                list: {
                                                    options: ['unordered', 'ordered'],
                                                },
                                                textAlign: {
                                                    options: ['left', 'center', 'right', 'justify'],
                                                },
                                                link: {
                                                    options: ['link'],
                                                },
                                                emoji: {
                                                    options: [
                                                        '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                        '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                    ],
                                                },
                                                image: {
                                                    uploadEnabled: true,
                                                    previewImage: true,
                                                    inputAccept: 'image/*',
                                                    alt: { present: false, mandatory: false },
                                                },
                                            }}
                                        />
                                    </div>
                                </Grid> */}
								{/* {
									activeStep === 1 &&
									<Grid item xs={12} sm={12} md={12}>

										<Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
											<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>

											<Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
										</Box>
									</Grid>
								}


							</Grid>
						</Box>
					} */}
					{/* {activeStep === 2 &&
						<Box className="steppy_content mb-10">
							<Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
								<EmailEditor ref={emailEditorRef}
									onLoad={onLoad}
									projectId={144031}
									tools={{
										"custom#product_tool": {
											// data: { products },
											// properties: {
											// 	productLibrary: {
											// 		editor: {
											// 			data: {
											// 				products,
											// 			},
											// 		},
											// 	},
											// },
										},
									}}
									options={{
										designTags: {
											business_name: "My business",
											current_user_name: "Ron",
										},
										customCSS: [
											"https://examples.unlayer.com/examples/product-library-tool/productTool.css",
										],
										customJS: [
											window.location.protocol + "//" +
											window.location.host + "/custom.js",
										],
									}}
								/>
							</Grid>
							<Box className='heading-btns mt-20' style={{ textalign: 'end' }}>
								<Button onClick={handleEditor} variant="contained" className='theme-btn  mr-10'>SaveDesign</Button>
							</Box>
						</Box>
					}

					<>
						{activeStep === 2 && truebutton === false ?
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button onClick={addCourseDetails} variant="contained" className='theme-btn'>Submit</Button>
								</Box>
							</>
							:
							activeStep === 2 && truebutton === true &&
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button variant="contained" className='theme-btn  mr-10'>Please wait...</Button>
								</Box>
							</>
						}
					</> */}


				</Box >
			// </Box>
			// {/* </Grid> */}
		// </Box>
	);
}