import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Avatar, Grid, Select, MenuItem,  TextField, RadioGroup,FormControlLabel, Typography, IconButton, Input, Radio,  } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import {  AddAPhoto} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Avatarimage from "../../../assets/images/Avatar.png"
import Spinner from '../../../components/spinner'
// import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';



export default function EditTeacher() {
    let location = useLocation()
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    // let tokendata = {
    //     'headers': {
    //         'Content-Type': 'application/json',
    //         Authorization: localStorage.getItem("token")
    //     }
    // }

   

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 15,
                            color: (theme) => theme.palette.grey[500],
                            border: '1px solid #E5EBF0',
                            borderRadius: '8px',
                            height: 38,
                            width: 38
                        }}
                    >
                        <CloseIcon className='fz-18' />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };


    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f3714d',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    function BpRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }

    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    var editId = location.state.data._id
    const [user, setUser] = React.useState({ firstname: location.state.data.name ? location.state.data.name : "", uniquename: location.state.data.username, image: location.state.data.image, Imageprew: "", Imageraw: "", email: location.state.data.email, specialization: location.state.data.specialization, gender: location.state.data.gender, phone: location.state.data.contact_number, description: location.state.data.description });
    const [error, setError] = React.useState({ firstname: "", email: "", image: "" })
    const [statesingle, setStatesingle] = React.useState(location.state.data.image)
    const [statesingle2, setStatesingle2] = React.useState(location.state.data.image)
    const [isToggled] = React.useState(location.state.data.SetVerifyTeacher)
    // const [isPrimary, setPrimary] = React.useState(location.state.data.senior_teacher)
    // const [plans, setPlans] = React.useState([])
    // const [lat, setLat] = React.useState('')
    // const [lng, setLng] = React.useState('')
    const [users, setUsers] = React.useState('')
    const [userId, setUserId] = React.useState(location.state.data.userrole)
    // const [selectedPlan, setSelectedPlan] = React.useState(location.state.data.planId ? location.state.data.planId.name : "")
    // const [isChecked, setChecked] = React.useState(location.state.data.merit)
    // const [cityName, setCityName] = React.useState(location.state.data.address)
    const [truebutton, setTruebutton] = React.useState(false)
    // const [subscribed, setSubscribed] = React.useState(location.state.data.isSubscribed)

  
    const handleChange = (e, index) => {
        const { name, value } = e.target;
        let isValid = true;
        let errorMessage = '';

        // Validate Username
        if (name === 'username') {
            const trimmedValue = value.trim(); // Trim leading and trailing spaces
            if (trimmedValue === '' || !/^[a-zA-Z0-9]+$/.test(trimmedValue)) {
                isValid = false;
                errorMessage = "Username should only contain alphanumeric characters (no spaces or special characters)";
            }
        }

        // Validate Name
        if (name === 'firstname') {
            if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Name should not contain only numbers or special characters";
            }
        }

        // Validate Email
        if (name === 'email') {
            if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Please enter a valid Email";
            } else {
                const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
                if (!emailPattern.test(value)) {
                    isValid = false;
                    errorMessage = "Please enter a valid Email";
                }
            }
        }

        // Validate Specialization
        if (name === 'specialization') {
            if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Specialization should not contain only numbers or special characters";
            }
        }

        // Update state based on validation result
        if (name === 'specialization') {
            const updatedSpecializations = [...user.specialization];
            updatedSpecializations[index] = value;
            setUser({ ...user, specialization: updatedSpecializations });
        } else {
            setUser({ ...user, [name]: value });
        }

        // Update error state
        setError({ ...error, [name]: errorMessage });
        return isValid
    };



    

  

    const handleprofilephoto = (e) => {
        const file = e.target.files[0];
        if (file.size > 1024 * 1024) {
            setError({ image: 'Image should be less than 1 MB' });
            return;
        } else {
            setError({ image: '' });
        }
        setStatesingle(URL.createObjectURL(file));
        setStatesingle2(file);
    }

    const Validator = () => {
        let isValid = true;
        const errors = {};

        if(!user.firstname)
            {
                errors.firstname = "This feild is required";
                isValid = false;
            }
    
       
    
            if (!user.email) {
                errors.email = "This feild is required";
                isValid = false;
              
            }
    
            if(!userId)
            {
                errors.Role = "This feild is required";
                isValid = false;
            }
    
            setError(errors);
            return isValid;
        
    };





    React.useEffect(() => {
		getUser()
	}, [])

    const getUser = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Userrole/getUserroles`).then((res) => {
		
            setUsers(res.data.userroles)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}



    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const handleUpdate = async () => {
        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("usertype", 'User')
            formData.append("image", statesingle2 ? statesingle2 : "");
            formData.append("name", user.firstname);
            formData.append("email", user.email);
            formData.append("SetVerifyTeacher", isToggled);
            formData.append("IsAdminUsers", true);
         
            formData.append("contact_number", user.phone);
            formData.append("gender", user.gender ? user.gender : '');
           
           
           
            await axios.put(process.env.REACT_APP_BASE_URL + `Admin/updateUser/${editId}`, formData, { headers: headerformdata })
                .then((res) => {
                   
                
                    enqueueSnackbar("Portal User Updated successfully", { variant: 'success' });
                    navigate('/portalusers')

                }).catch((error) => {
                    setTruebutton(false)
                    console.log(error.response, "error");
                    // enqueueSnackbar(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Something went wrong.", { variant: 'error' })
                })
        }
    }

  

    return (
        <Box className=''>
            <MiniDrawer menuOrder={20} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit Portal User</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className='personal-info-tab'>
                                            <Typography component='div' className='label' >
                                                <center>Profile Photo<Typography component='span' className='star'></Typography></center>
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleprofilephoto} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        {statesingle && statesingle.slice(0, 4) === "blob" ? <Avatar src={statesingle}>
                                                        </Avatar> : !statesingle?<Avatar src={Avatarimage}>
                                                        </Avatar>:<React.Suspense fallback={<Spinner />}>
                                                            <Avatar src={process.env.REACT_APP_BASE_URL + statesingle}> </Avatar>
                                                        </React.Suspense>
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed .jpeg, .jpg, *.png<br />max size of 1 MB
                                            </Typography>
                                            {/* {error.image && <p style={{ color: 'red' }} className="fz-13 ml-20 text-center" >{error.image}</p>} */}
                                        </Box>

                                    </Grid>
                                   
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Username: <Typography component='span' className='star'>*</Typography>
                                                <Tooltip title="This name will be used to create a unique URL for teacher">
                                                    <IconButton aria-label="information">
                                                        <AiOutlineInfoCircle size={20} />
                                                    </IconButton>
                                                </Tooltip></Typography>                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='User Name'
                                                value={user.uniquename}
                                                disabled
                                            // onChange={handleChange}

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography component='div' className='label'>Select Role<Typography component='span' className='star'>*</Typography></Typography>
                                        <FormControl fullWidth >
                                            <Select
                                                className="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={userId ? userId : 'sel'}
                                                onChange={(e) => { setUserId(e.target.value); }}>
                                                <MenuItem disabled value={'sel'} >Select Role</MenuItem>
                                                {users && users?.length > 0 ? users.map((data) => (
                                                    <MenuItem value={data._id}>{data.UserRole}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                            </Select>
                                            {/* {catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>} */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={handleChange}
                                                disabled

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>

                                    <Grid item lg={12} className='radiobtnGrid'>
                                        <Typography component='div' className='label '>Gender:</Typography>
                                    </Grid>
                                    <Grid item lg={12} className='pt-0'>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 14 }}

                                            />
                                        </FormControl>
                                    </Grid>

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Address</Typography>
                                            <AutoComplete_Edit cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />
                                            {error.location && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.location}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Details"
                                                className='textfield textarea'
                                                name='description'
                                                value={user.description}
                                                onChange={handleChange}
                                                minRows={3}
                                                style={{ resize: 'none' }} />
                                        </FormControl>
                                    </Grid> */}
                                </Grid>
                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => handleUpdate()}>Update</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
