import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Button, CircularProgress, Card} from '@mui/material';
import {  Delete as DeleteIcon } from '@mui/icons-material';

// import { styled } from '@mui/material/styles';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import {  useParams } from 'react-router-dom';

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//     '& .MuiDialogContent-root': {
//         padding: theme.spacing(2),
//     },
//     '& .MuiDialogActions-root': {
//         padding: '15px',
//     },
// }));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
  
    const params = useParams()
    const { enqueueSnackbar } = useSnackbar();
    const [newdata, setNewdata] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null); // State to store the ID of image to delete
    const [rowsPerPage] = React.useState(12);
    // const [total, setTotal] = React.useState(0);
    const [page] = React.useState(0);
    const [search] = React.useState("");
    // const [anchorEl, setAnchorEl] = React.useState({});
    // const [alldata, setAlldata] = React.useState('');
    const [catid] = React.useState('all');
    const [deleteImagePath, setDeleteImagePath] = React.useState(null);
    const[Tableloading,setTableloading]=React.useState(true)


    React.useEffect(() => {
        getNews();
        // eslint-disable-next-line 
    }, [rowsPerPage, page, search, catid]);

    const getNews = async () => {
        setTableloading(true)
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `image/Getimagebycommunityid/${params.id}`, { headers: headerlocal });
          
            setNewdata(response.data.getCommunityimage);
        } catch (error) {
            console.error("Error fetching news:", error);
            enqueueSnackbar("Error fetching news", { variant: 'error' });
        }
        finally{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        }
    }

    const handleDeleteImage = async (imagePath) => {
        const body = {
            imgpath: imagePath
        };

        try {
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}image/deleteImage/${deleteId}`,
                body,
                { headers: headerlocal }
            );
            enqueueSnackbar("Image deleted successfully", { variant: 'success' });
            getNews();
            handleClose();
        } catch (error) {
            console.error("Error deleting image:", error);
            enqueueSnackbar("Error deleting image", { variant: 'error' });
        }
    };





   



    const handleClose = () => {
        setOpen(false);
    };





    const handleDeleteDialogOpen = (id, imagePath) => {
        setDeleteId(id); // Set the _id of the image to delete
        setDeleteImagePath(imagePath); // Set the image path
        setOpen(true); // Open the delete dialog
    };



    const handleDeleteDialogClose = () => {
        setDeleteId(null);
        setOpen(false);
    };

    return (
        <>
            <Box className='whiteContainer p-20 communityDet_Images'>
                <Box className='flex-center flex-wrap mb-20 '>
                  
                </Box>
                <Box>
                    {
                        Tableloading?
                        (
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Grid item>
                                        <CircularProgress sx={{ color: '#F3714D' }} />
                                    </Grid>
                                </Grid>
                        )
                        :
                    
                    
                   ( newdata && newdata.length > 0 ? newdata.map((data, index) => (
                        <Grid container columnSpacing={2} rowSpacing={2} className="">
                            {/* Render images side by side here */}
                            {data && data.additionalimages && data.additionalimages?.length > 0 && data.additionalimages.map((tdata, i) => (
                                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3}>
                                    <Card key={i} className="imageContainer position_rel">
                                        <img
                                            width={'100%'}
                                            height={250}
                                            src={process.env.REACT_APP_BASE_URL + `${tdata}`}
                                            style={{ marginRight: '5px' }}
                                            alt={` ${i}`}
                                        />
                                        <IconButton onClick={() => handleDeleteDialogOpen(data._id, tdata)} className="vertical_menu">
                                            <DeleteIcon className='fz-18'/>
                                        </IconButton>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    )) : (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body1" className='text-center semibold'>No data found</Typography>
                        </Grid>
                    ))}

                    <Dialog
                        open={open}
                        keepMounted
                        onClose={handleDeleteDialogClose}
                        aria-describedby="alert-dialog-slide-description"
                        className='custom_modal'
                    >
                        <DialogTitle>Would you like to delete this image?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogClose} className='cancel_btn'>No</Button>
                            <Button onClick={() => handleDeleteImage(deleteImagePath)} className='theme-btn'>Yes</Button>

                        </DialogActions>
                    </Dialog>

                </Box>
                {/* <TablePagination
                    rowsPerPageOptions={[8, 12, 16, 20, 24]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                /> */}
            </Box>
        </>
    );
}
