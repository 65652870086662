
import { Box} from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../../components/drawer'
import Description from './Desc'
import Sidebar from '../Sidebar'
import { useParams } from 'react-router-dom'

export default function CommunityDesc() {
    const params = useParams()
    return (
        <Box className='settings'>
            <MiniDrawer menuOrder={13} submenuOrder={23} />
            <Sidebar subtype={5} />
            <Box component="section" className="contentWraper">               
                <Description params={params} />
            </Box>
        </Box>
    )
}
