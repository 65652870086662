import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, IconButton,CircularProgress, TextField, InputAdornment,  Box, Button, FormControl } from '@mui/material';
import TableCell, {  } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import PropTypes from 'prop-types';
import { CiLink } from "react-icons/ci";
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Paper from '@mui/material/Paper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack'
import Switch, {  } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {SearchOutlined, CloseOutlined, DeleteOutline } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};




BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



const loaderContainerStyle = {
    // height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    
};

const loaderTextStyle = {
    fontSize: '24px',
    color: '#555'
};

export default function DataTable() {
    const headerformdata = {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("token")
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [yogalegend, setYogalegend] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const role = JSON.parse(localStorage.getItem("roledata"))
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [loading,setLoading] = React.useState(false)
    

    const getYogaLegend = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `YogaLegend/GetYogaLegend?size=${rowsPerPage}&page=${page}&search=${searchTerm}`, { headers: headerformdata }).then((res) => {
         
            setYogalegend(res.data.yogaLegend)
            setCount(res.data.totalcount)
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }


    React.useEffect(() => {
        getYogaLegend()
    }, [page, rowsPerPage, searchTerm])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

 
  

    const handleDelete = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `YogaLegend/DeleteYogaLegend/${deleteid}`, {}, { headers: headerformdata },).then((res) => {
            handleClose()
            getYogaLegend()
            enqueueSnackbar("Yoga Legend deleted successfully", { variant: 'success' })
        }).catch((err) => {
            console.log(err);
        })
    }
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            enqueueSnackbar('Link copied to clipboard.', { variant: 'success' })
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const generateUrl = (id) => {
        return `${id}`;
    };

    const handleCopy = (e, id) => {
        e.preventDefault();
        const url = generateUrl(id);
        copyToClipboard(url);
    };

    const handleChangeStatus = async (e, data) => {
        
        const formData = new FormData();
        formData.append("SetVerifyTeacher",e.target.checked)


        

        try {
          
            await axios.put(process.env.REACT_APP_BASE_URL + `YogaLegend/UpdateYogaLegend/${data._id}`, formData, { headers: headerformdata },)
            .then((res)=>{
                    
                    
            })
        
            getYogaLegend()

            if (!e.target.checked) {
                enqueueSnackbar('Legend approved successfully.', { variant: 'success' });
            }
            else if (e.target.checked) {
                enqueueSnackbar('Legend approve removed successfully.', { variant: 'success' });
            }
        }
         catch (err) 
         {
            console.error("Error while toggling teacher:", err);
        }
    };



           
        
    


    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
   

        const items = Array.from(yogalegend);

        const [reorderedItem] = items.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        
        
        // var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;

        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
            
        } else if (after === undefined || after === "undefined") {

            
            currElIndexNumber = before.index_position - 512;
          
        } else if (before.setService === true && after.setService === false) {
     

            currElIndexNumber = before.index_position - 512;
        } else if (before.setService === false && after.setService === true) {
          

            currElIndexNumber = after.index_position + 512;

        } else if (before.setService === false && after.setService === true) {
          

            currElIndexNumber = before.index_position - 512;
        } else if (before.setService === true && after.setService === false) {
          

            currElIndexNumber = after.index_position + 512;
        }
        else {
            

            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }

        setYogalegend(fromvar);

        var body = {
            index_position: currElIndexNumber
        }
       

       

        axios.put(process.env.REACT_APP_BASE_URL + `Admin/updateUsers/${final._id}`, body, { headers: headerformdata })
            .then((res) => {
                getYogaLegend();
            
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }

    const EditData = (row) => {
     
        let obj = {
            data: row
        }
        navigate("/edityogalegend", { state: obj })
    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }

    const handleRemove = (e) => {
        setSearchTerm('');
        setPage(0)
    }

    return (
        <Box sx={{ width: '100%' }}>

<Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>

                    {/* {deletearry && deletearry.length > 0 && <Button className='del-btn' onClick={handleDeleteData}>Delete</Button>} */}
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <TableContainer id='table'>
                {loading ? (
                <Box className="loader-container" sx={loaderContainerStyle}>
                    <div className="loader" style={loaderTextStyle}><CircularProgress sx={{color:"#ed6d44"}}/></div>
                </Box>
            ) : (
                <Table sx={{ minWidth: 700 }} aria-label="customized table" className='tble'>
                    <TableHead className='DT-head'>
                        <TableRow>
                            <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Drag raw</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Profile Image</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Name</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Username</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Email</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Public</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Specialization</strong></TableCell>
                            <TableCell style={{ minWidth: 180 }} align="left" className='tbl_head'><strong>Address</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Phone</strong></TableCell>
                            <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Action</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="characters">
                        {(provided) => (

<TableBody className="characters " {...provided.droppableProps} ref={provided.innerRef}>
    {yogalegend && yogalegend?.length > 0 ? yogalegend.map((row, i) => (

        <Draggable key={row.data._id} draggableId={row.data._id} index={i}>
            {(provided) => (
                <>
                    <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className=''>
                        <TableCell className='DT-row' style={{ width: 200 }} align="center"><DragHandleIcon /></TableCell>

                        <TableCell align="center" className='DT-row'>
                                {row.data.image
                                ? <img className="cat_image" src={process.env.REACT_APP_BASE_URL + row.data.image} alt='' height={50} width={50} />
                                : <PersonIcon style={{ height: 50, width: 50 }} />}
                        </TableCell>
                        <TableCell className='DT-row'>{row.data.name ? row.data.name : '-'}</TableCell>
                        <TableCell className='DT-row'>{row.data.username ? row.data.username : '-'}</TableCell>
                        <TableCell align="left" className='DT-row'>{row.data.email ? row.data.email : '-'}</TableCell>
                        <TableCell align="center" className='DT-row'>
                                    <FormControlLabel
                                        className='m-0 justify-content-center'
                                        control={<Switch size='small' onChange={(e) => handleChangeStatus(e, row.data)} checked={row.data.SetVerifyTeacher} sx={{ m: 1 }} />}
                                    />
                        </TableCell>
                        
                                <TableCell align="left" className='DT-row'>
                                    {Array.isArray(row.data.specialization) && row.data.specialization.length > 0
                                        ? row.data.specialization.join(', ')
                                        : '-'}
                                </TableCell>
                                <TableCell align="left" className='DT-row'>{row.data.address ? row.data.address : '-'}</TableCell>
                                <TableCell align="left" className='DT-row'>{row.data.contact_number ? row.data.contact_number : '-'}</TableCell>
                                <TableCell align="center" className='DT-row' style={{ cursor: 'pointer' }}>
                                    <Box className="flex-center justify-content-center actionbtns">
                                        <Tooltip title="Edit">
                                    {
                                        role.UserRoles.legends.editelegends ?
                                            <Box className='a_tag' onClick={() => EditData(row.data)}>
                                                <EditOutlinedIcon className='icons text-grey' />
                                            </Box>
                                            :
                                            ""
                                    }
                                </Tooltip>
                                <Tooltip title="Delete">
                                    {
                                        role.UserRoles.legends.deletelegends ?
                                            <Box>
                                                <DeleteOutline className='icons text-grey' onClick={() => handleOpen(row.data._id)} />
                                            </Box>
                                            : ""

                                        }
                                           
                                        </Tooltip>
                                        <Tooltip title="Copy URL">
                                            
                                                <Box>
                                                <CiLink className='icons text-grey' onClick={(e) => handleCopy(e, row.data.unique_link)} />
                                            </Box>
                                          
                                           
                                        </Tooltip>
                                    </Box>
                                </TableCell>                        
                    </TableRow>
                </>
            )}
        </Draggable>
    )) : <TableRow className=''>
        <TableCell className='DT-row' align="center" colSpan={10}>
            No Data Found
        </TableCell>
    </TableRow>}
</TableBody>
)}
                        </Droppable>
                    </DragDropContext>
                </Table>
            )}
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>

            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete Yoga Legend?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
