import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, IconButton, TextField, InputAdornment, Box, Button, FormControl, CircularProgress } from '@mui/material';
import TableCell, { } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSnackbar } from 'notistack'
import { SearchOutlined, DeleteOutline, CloseOutlined } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';





const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >

                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DataTable() {
    //UseState's:
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [deletearry] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tagDetails, setTagDetails] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const [setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [tableLoading, setTableLoading] = React.useState(false)
    const role = JSON.parse(localStorage.getItem("roledata"))

    //Pagination:
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Hook's State:
    React.useEffect(() => {
        getTagDetails()
    }, [page, rowsPerPage, searchTerm])

    const getTagDetails = () => {
        setTableLoading(true)
        axios.get(process.env.REACT_APP_BASE_URL + `tags/GetTags?size=${rowsPerPage}&page=${page}&search=${searchTerm}`, tokendata).then((res) => {

            setTagDetails(res.data.Tag)
            setCount(res.data.totalcount || res.data.category.length || 0);
        }).catch((err) => {
            console.log(err);
        })
            .finally(() => {
                setTimeout(() => {
                    setTableLoading(false)
                }, 1500);
            })
    }

    //To Delete Tag:
    const handleDeleteData = () => {
        setDeletemodel(true)
    }

    const handleDelete = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `tags/deletetags/${deleteid}`, {}, tokendata,).then((res) => {

            handleClose()
            getTagDetails()
            enqueueSnackbar("Tag deleted successfully", { variant: 'success' })

        }).catch((err) => {
            console.log(err);
        })
    }


    //To Navigate to edit tag:
    const EditData = (row) => {
        let obj = {
            data: row
        }
        navigate("/edittag", { state: obj })
    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }


    const handleRemove = (e) => {
        setSearchTerm('');
        setPage(0)
    }



    return (
        <Box sx={{ width: '100%' }}>

            <Toolbar className='toolbar comptoolbar'>

                {deletearry && deletearry.length > 0 && <Button className='del-btn' onClick={handleDeleteData}>Delete</Button>}
                <Box component='div' className='DTsearch mr-10'>
                    <FormControl variant="standard">
                        <Typography component='div' className='label mr-10'>Search :</Typography>
                        <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            className='textfield search'
                            onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                            placeholder='Search Here...'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchTerm !== '' && (
                                            <IconButton onClick={handleRemove} size="small">
                                                <CloseOutlined className='fz-18' />
                                            </IconButton>
                                        )}
                                        <SearchOutlined className='fz-18' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Box>
            </Toolbar>
            <TableContainer id='table'>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                    <TableHead className='DT-head'>
                        <TableRow className=''>
                            {/* <StyledTableCell style={{ minWidth: 150 }} align="left" className='TRow'></StyledTableCell> */}
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Tag Name</strong> </TableCell>
                            <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Description</strong> </TableCell>

                            <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'> <strong>Action</strong>  </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableLoading ? (

                            <TableRow><TableCell colSpan={3} className='text-center semibold ' align='center'>
                                <CircularProgress sx={{ color: '#F3714D' }} />
                            </TableCell></TableRow>) :

                            (tagDetails && tagDetails.length > 0 ? tagDetails.map((row, i) => {
                                return (
                                    <TableRow className='tbody-row' key={row._id}>
                                        <TableCell component="th" scope="row" padding="none" className='DT-row'>{row.tagname ? row.tagname : '-'}</TableCell>
                                        <TableCell align="left" className='DT-row'>{row.description ? row.description : '-'}</TableCell>
                                        <TableCell align="center" className='DT-row'>
                                            <Box className='actionbtns'>

                                                {
                                                    role.UserRoles.tags.edittags ?
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => EditData(row)}>
                                                                <EditOutlinedIcon className='icons text-grey' />
                                                            </IconButton>
                                                        </Tooltip>
                                                        : ''
                                                }
                                                {
                                                    role.UserRoles.tags.deletetags ?
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => handleOpen(row._id)}>
                                                                <DeleteOutline className='icons text-grey' />
                                                            </IconButton>
                                                        </Tooltip>
                                                        : ''
                                                }


                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableRow><TableCell colSpan={3} className='text-center semibold ' align='center'>No data found</TableCell></TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='DT-pagination'
            />


            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete this tag?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

