
import { Box} from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../../components/drawer'
import Description from './Desc'
import Sidebar from '../Sidebar'
import { useParams } from 'react-router-dom'

export default function CommunityDesc() {
    const params = useParams()   
    return (
        <Box className='settings withSubmenu'>
            <MiniDrawer menuOrder={13}/>
            <Sidebar subtype={3}/>
            <Box component="section" className="contentWraper">
                <Description params={params}/>
            </Box>
        </Box>
    )
}
