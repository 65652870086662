import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid,  TextField, Typography } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'

export default function AddLinks() {
//UseState's:
    let action = useLocation() 
    const state = action.state || {};
    const item = state.item;
    const from = state.from;

  

    React.useEffect(()=>{
        if(item){
            setFacebook(item.facebook)
            setInstagram(item.instagram)
            setTiktok(item.tiktok)
            setTwitter(item.twitter)
            setPinterest(item.pinterest)
            setWhatsapp(item.whatsapp)
        }
        else{
            setFacebook('')
            setInstagram('')
            setTiktok('')
            setTwitter('')
            setPinterest('')
            setWhatsapp('')
        }
    },[])

    const regexPatterns = {
        facebook: /^https?:\/\/(www\.)?facebook\.com$/,
  twitter: /^https?:\/\/(www\.)?twitter\.com$/,
  instagram: /^https?:\/\/(www\.)?instagram\.com$/,
  tiktok: /^https?:\/\/(www\.)?tiktok\.com$/,
  pinterest: /^https?:\/\/(www\.)?pinterest\.com$/,
  whatsapp: /^https?:\/\/(www\.)?wa\.me$/
      };


    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [facebook,setFacebook] = React.useState('')
    const [instagram,setInstagram] = React.useState('')
    const [twitter,setTwitter] = React.useState('')
    const [pinterest,setPinterest] = React.useState('')
    const [whatsapp,setWhatsapp] = React.useState('')
    const [tiktok,setTiktok] = React.useState('')
    const [error, setError] = React.useState({ facebook: "", instagram: "",twitter:'',pinterest:"",tiktok:'',whatsapp:'', })
const[truebutton,setTruebutton]=React.useState(false)



    const valid =()=>{

        let validation=true;
        if (facebook) {
            if (!regexPatterns.facebook.test(facebook)) {
                setError((prevError) => ({ ...prevError, facebook: 'Please enter a valid  link' }));
                validation = false;
            }
        }
        if (instagram) {
            if (!regexPatterns.facebook.test(instagram)) {
                setError((prevError) => ({ ...prevError, instagram: 'Please enter a valid  link' }));
                validation = false;
            }
        }
        if (twitter) {
            if (!regexPatterns.facebook.test(twitter)) {
                setError((prevError) => ({ ...prevError, twitter: 'Please enter a valid  link' }));
                validation = false;
            }
        }
        if (pinterest) {
            if (!regexPatterns.facebook.test(pinterest)) {
                setError((prevError) => ({ ...prevError, pinterest: 'Please enter a valid  link' }));
                validation = false;
            }
        }
        if (whatsapp) {
            if (!regexPatterns.facebook.test(whatsapp)) {
                setError((prevError) => ({ ...prevError, whatsapp: 'Please enter a valid  link' }));
                validation = false;
            }
        }
        if (tiktok) {
            if (!regexPatterns.facebook.test(tiktok)) {
                setError((prevError) => ({ ...prevError, tiktok: 'Please enter a valid  link' }));
                validation = false;
            }
        }
        return validation;
    }


//add links functionality:
const handleLink = () => {




    if(valid())
    {
        setTruebutton(true)
    let body = {
        "instagram":instagram,
        "facebook": facebook,
        "twitter": twitter,
        "tiktok": tiktok,
        "pinterest":pinterest,
        "whatsapp":whatsapp
    }
    axios.post(process.env.REACT_APP_BASE_URL + 'SocailMediaLinks/SocialMediaLinks',body)
    .then((res)=>{
        enqueueSnackbar(from ? 'Social Media Links Updated Successfully' : 'Social Media Links Added Successfully',{variant:'success'})
        setFacebook('')
        setInstagram('')
        setTiktok('')
        setTwitter('')
        setPinterest('')
        setWhatsapp('')
        navigate('/viewlinks')
    })
    .catch((error)=>{
        enqueueSnackbar(error.response.data.message,{variant:'error'})
        console.log(error,"error in handleLink");
    })
    .finally(()=>{
        setTruebutton(false)
    })
}
}

return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={21} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>{from=== 'edit' ? "Edit Social Media Links" : "Add Social Media Links"}</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Facebook Link </Typography>
                                            {/* <Typography component='div' className='label'>Facebook Link <Typography component='span' className='star'>*</Typography></Typography> */}
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Facebook link'
                                                name="name"
                                                value={facebook}
                                                onChange={(e)=>
                                                   {

                                                    const value = e.target.value;

                                                    if (regexPatterns.facebook.test(value)) {
                                                      setFacebook(value);
                                                      setError((prevError) => ({
                                                        ...prevError, 
                                                        facebook: '' 
                                                      }));
                                                    } else {
                                                      setFacebook(value);
                                                      setError((prevError) => ({
                                                        ...prevError, 
                                                        facebook: 'Please enter a valid  link' 
                                                      }));
                                                    }
                                                    

                                                   } }
                                            />
                                        </FormControl> 
                                        {error.facebook && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.facebook}</p>}   
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Instagram Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Instagram link'
                                                name="name"
                                                value={instagram}
                                                onChange={(e)=>

                                                    {

                                                        const value = e.target.value;
    
                                                        if (regexPatterns.instagram.test(value)) {
                                                            setInstagram(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            instagram: '' 
                                                          }));
                                                        } else {
                                                            setInstagram(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            instagram: 'Please enter a valid  link' 
                                                          }));
                                                        }
                                                        
    
                                                       }
                                                  
                                                }
                        
                                            />
                                        </FormControl>    
                                        {error.instagram && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.instagram}</p>}   
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Twitter Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Twitter link'
                                                name="name"
                                                value={twitter}
                                                onChange={(e)=>
                                                    
                                                    {

                                                        const value = e.target.value;
    
                                                        if (regexPatterns.twitter.test(value)) {
                                                            setTwitter(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            twitter: '' 
                                                          }));
                                                        } else {
                                                            setTwitter(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            twitter: 'Please enter a valid  link' 
                                                          }));
                                                        }
                                                        
    
                                                       }
                                                    
                                                    }
                                            />
                                        </FormControl>    
                                        {error.twitter && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.twitter}</p>}   
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Pinterest Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Pinterest link'
                                                name="name"
                                                value={pinterest}
                                                onChange={(e)=>
                                                    
                                                    {

                                                        const value = e.target.value;
    
                                                        if (regexPatterns.pinterest.test(value)) {
                                                            setPinterest(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            pinterest: '' 
                                                          }));
                                                        } else {
                                                            setPinterest(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            pinterest: 'Please enter a valid  link' 
                                                          }));
                                                        }
                                                        
    
                                                       }
                                                    
                                                    
                                                    
                                                   }
                                            />
                                        </FormControl>    
                                        {error.pinterest && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.pinterest}</p>}   
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Whatsapp Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Whatsapp link'
                                                name="name"
                                                value={whatsapp}
                                                onChange={(e)=>
                                                    {

                                                        const value = e.target.value;
    
                                                        if (regexPatterns.whatsapp.test(value)) {
                                                            setWhatsapp(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            whatsapp: '' 
                                                          }));
                                                        } else {
                                                            setWhatsapp(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            whatsapp: 'Please enter a valid  link' 
                                                          }));
                                                        }
                                                        
    
                                                       }
                                                    
                                                  
                                                
                                                
                                                
                                                }
                                            />
                                        </FormControl>    
                                        {error.whatsapp && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.whatsapp}</p>}   
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>TikTok Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Tiktok link'
                                                name="name"
                                                value={tiktok}
                                                onChange={(e)=>
                                                    
                                                    {

                                                        const value = e.target.value;
    
                                                        if (regexPatterns.tiktok.test(value)) {
                                                            setTiktok(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            tiktok: '' 
                                                          }));
                                                        } else {
                                                            setTiktok(e.target.value)
                                                          setError((prevError) => ({
                                                            ...prevError, 
                                                            tiktok: 'Please enter a valid  link' 
                                                          }));
                                                        }
                                                        
    
                                                       }
                                                   
                                                
                                                
                                                }
                        
                                            />
                                        </FormControl>   
                                        {error.tiktok && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.tiktok}</p>}    
                                    </Grid>
                                </Grid>

                                
                                
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        {
                                            truebutton?
                                            <Button variant="contained" className='theme-btn'>Please wait...</Button>
                                            :
                                            <Button variant="contained" className='theme-btn' onClick={()=>handleLink()}>{from === 'edit' ? "Update" : "Submit"}</Button>
                                        }
                                        
                                    </Box> 
                                    
                                
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}