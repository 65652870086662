import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Avatar, Grid,  Checkbox, TextField, TextareaAutosize, FormControlLabel, Typography, IconButton,  Input } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import {  AddAPhoto } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatarimage from "../../../assets/images/Avatar.png"
import Spinner from '../../../components/spinner'
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoComplete_Edit from "./Autocomplete_edit";

export default function EditTeacher() {
    let location = useLocation()
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }


    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 15,
                            color: (theme) => theme.palette.grey[500],
                            border: '1px solid #E5EBF0',
                            borderRadius: '8px',
                            height: 38,
                            width: 38
                        }}
                    >
                        <CloseIcon className='fz-18' />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };


    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };







    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    var editId = location.state.data._id
    const [truebutton, setTruebutton] = React.useState(false)
    const [user, setUser] = React.useState({ firstname: location.state.data.name, uniquename: location.state.data.username, image: location.state.data.image, Imageprew: "", Imageraw: "", email: location.state.data.email, specialization: location.state.data.specialization, gender: location.state.data.gender, phone: location.state.data.contact_number, description: location.state.data.description, instagram: location.state.data.instagram, facebook: location.state.data.facebook, twitter: location.state.data.twitter,
    youtube: location.state.data.youtube,pinterest: location.state.data.pinterest,tiktok: location.state.data.pinterest });
    const [error, setError] = React.useState({ firstname: "", email: "", image: "" })
    const [statesingle, setStatesingle] = React.useState(location.state.data.image)
    const [statesingle2, setStatesingle2] = React.useState(location.state.data.image)
    const [isToggled, setToggled] = React.useState(location.state.data.SetVerifyTeacher)
    const [isPrimary, setPrimary] = React.useState(location.state.data.premium_school)
    const [ setPlans] = React.useState([])
    const [lat, setLat] = React.useState(location.state.data?.location_coord?.coordinates[0])
    const [lng, setLng] = React.useState(location.state.data.location_coord?.coordinates[1])
    const [selectedPlan, setSelectedPlan] = React.useState(location.state.data.planId ? location.state.data.planId.name : "")
    const [isChecked] = React.useState(location.state.data.merit)
    const [cityName, setCityName] = React.useState(location.state.data.address)
    const [subscribed] = React.useState(location.state.data.isSubscribed)

    

    let username, uservalue;
    const handleChange = (e, index) => {
        // const { value } = e.target;
        username = e.target.name;
        uservalue = e.target.value;
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations[index] = uservalue;
        if (username === 'username') {
            uservalue = uservalue.trim();
        }
        setUser({ ...user, [username]: uservalue, specialization: updatedSpecializations });
    };

    const handleAddSpecialization = () => {
        setUser({ ...user, specialization: [...user.specialization, ''] });
    };

    const handleRemoveSpecialization = (index) => {
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations.splice(index, 1);
        setUser({ ...user, specialization: updatedSpecializations });
    };




    const handleprofilephoto = (e) => {
        const file = e.target.files[0];
        if (file.size > 1024 * 1024) {
            setError({ image: 'Image should be less than 1 MB' });
            return;
        } else {
            setError({ image: '' });
        }
        setStatesingle(URL.createObjectURL(file));
        setStatesingle2(file);
    }

    const handleSetCityName = (newCityName) => {
        setCityName(newCityName);

        // Clear location error when cityName is set
        if (newCityName.trim() !== '') {
            setError(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors.location;
                return newErrors;
            });
        }
    };

    const validation = () => {
        let isValid = true;
        const errors = {};

        if (!user.firstname) {
            errors.firstname = "First name cannot be empty";
            isValid = false;
        }
       
        if (!cityName) {
            errors.location = "Location cannot be empty";
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }

 
    

    const handleToggle = async (e) => {

        if (!subscribed) {
            enqueueSnackbar("Please complete the payment process for approving", { variant: 'error' });
            };
        

        const body = {
            SetVerifyTeacher: e.target.checked,
        };
       
        await axios.put(process.env.REACT_APP_BASE_URL + `Admin/UpdateSetVerifyTeacher/${editId}`, body, tokendata).then((res) => {
            setToggled(!isToggled)
          
        }).catch((err) => {
            console.error("Error while toggling teacher:", err);
        })
    }

    const handleDisabledToggleClick = async (e) => {
        enqueueSnackbar("Please complete the payment process for approving", { variant: 'error' });
    };

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const handleUpdate = async (details) => {
        var Valid = validation()
        if (Valid) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("image", statesingle2 ? statesingle2 : "");
            formData.append("usertype", 'yoga school')
            formData.append("name", user.firstname);
            formData.append("email", user.email);
            formData.append("premium_school", isPrimary)
            formData.append("SetVerifyTeacher", isToggled);
            formData.append("username", user.uniquename);
            // formData.append("specialization", user.specialization);
            const transformedSpecializations = user.specialization.flatMap(spec => spec.split(',').map(s => s.trim())).filter(Boolean);
            if (transformedSpecializations.length === 0) {
                formData.append(`specialization[]`, "");
            }
            else {
                transformedSpecializations.forEach((spec, index) => {
                    formData.append(`specialization[${index}]`, spec);
                });
            }
            formData.append("description", user.description ? user.description : '');
            formData.append("address", cityName ? cityName : "");
            formData.append("latitude", lat);
            formData.append("longitude", lng);
            formData.append("contact_number", user.phone);
            formData.append("gender", user.gender ? user.gender : '');
            formData.append("merit", isChecked)
            formData.append("senior_teacher", isPrimary)
            formData.append("instagram", user.instagram ? user.instagram : '')
            formData.append("facebook", user.facebook ? user.facebook : '' )
            formData.append("twitter", user.twitter ? user.twitter : '')
            formData.append("youtube", user.youtube ? user.youtube : '')
            formData.append("pinterest", user.pinterest ? user.pinterest : '')
            formData.append("tiktok", user.tiktok ? user.tiktok : '')
            // formData.append("planId", selectedPlan)
            await axios.put(process.env.REACT_APP_BASE_URL + `Admin/UpdateYogaSchool/${editId}`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                   
                    if (details === 'addWithNavigate') {
                        navigate(`/yogaschool/details/events/${res.data.updateYogaSchool._id}`);
                    } else if (details === 'add') {
                        enqueueSnackbar("Yoga School Updated successfully", { variant: 'success' });
                        navigate('/yogaschool')
                    }
                }).catch((error) => {
                    setTruebutton(false)
                    console.log(error, "error");
                    
                })
        }
    }

    React.useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `subscription/GetSubscription`, tokendata).then((res) => {
            setPlans(res.data.getSubscriptiondata)
        }).catch((error) => {
            console.log(error, "Error ");
        })
    }

    return (
        <Box className=''>
            <MiniDrawer menuOrder={2} submenuOrder={4} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit Yoga School</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className='personal-info-tab'>
                                            <Typography component='div' className='label' >
                                                <center>Profile Photo<Typography component='span' className='star'></Typography></center>
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleprofilephoto} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        {statesingle && statesingle.slice(0, 4) === "blob" ? <Avatar src={statesingle}>
                                                        </Avatar> : <React.Suspense fallback={<Spinner />}>
                                                            <Avatar src={statesingle ? process.env.REACT_APP_BASE_URL + statesingle : Avatarimage}> </Avatar>
                                                        </React.Suspense>
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed .jpeg, .jpg, *.png<br />max size of 1 MB
                                            </Typography>
                                            {error.image && <p style={{ color: 'red' }} className="fz-13 ml-20 text-center" >{error.image}</p>}
                                        </Box>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth>
                                            <Typography component='div' className='label'>
                                                Subscription <Typography component='span' className='star'>*</Typography>
                                            </Typography>
                                            <TextField
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                placeholder={selectedPlan}
                                                value={selectedPlan ? selectedPlan : "Not Subscribed"}
                                                className="select"
                                                onChange={(e) => setSelectedPlan(e.target.value)}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Yoga School Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='Yoga School Name'
                                                value={user.uniquename}
                                                disabled
                                            // onChange={handleChange}

                                            />
                                        </FormControl>
                                        {/* <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span> */}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={handleChange}
                                                disabled

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>

                                    {subscribed === true ? <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className=''>
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 mr-10'>Yoga School Approved? </Typography>
                                            <FormControlLabel className='m-0' control={<Switch size='small' onClick={(e) => handleToggle(e)} checked={isToggled} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Grid> : subscribed === false &&
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className=''>
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 mr-10'>Yoga School Approved? </Typography>
                                            <FormControlLabel className='m-0' control={<Switch size='small' onClick={(e) => handleDisabledToggleClick(e)} checked={isToggled} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Grid>}

                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isPrimary}
                                                onChange={handleTogglePrimary}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to make this yoga school as Premium? </Typography>}
                                    />

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>
                                                Specialization:
                                            </Typography>
                                            {user.specialization.map((value, index) => (
                                                <Grid container spacing={1} alignItems="center" key={index}>
                                                    <Grid item xs={index > 0 ? 10 : 12}> {/* Adjust width for cancel icon */}
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            className='textfield'
                                                            placeholder={`Specialization`}
                                                            value={value}
                                                            onChange={(e) => handleChange(e, index)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {index > 0 && (
                                                        <Grid item xs={2}> {/* Adjust width for cancel icon */}
                                                            <IconButton onClick={() => handleRemoveSpecialization(index)}>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            <IconButton onClick={handleAddSpecialization}>
                                                <AddIcon />
                                            </IconButton>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 14 }}

                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
                                            <AutoComplete_Edit cityName={cityName} setCityName={handleSetCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />
                                            {error.location && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.location}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Details"
                                                className='textfield textarea'
                                                name='description'
                                                value={user.description}
                                                onChange={handleChange}
                                                minRows={3}
                                            />
                                        </FormControl>
                                    </Grid>

                                    {/* Edit Social Media Link */}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Instagram Link:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='instagram'
                                                className='textfield'
                                                value={user.instagram}
                                                placeholder='Instagram Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Facebook Link:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='facebook'
                                                className='textfield'
                                                value={user.facebook}
                                                placeholder='Facebook Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Twitter Link:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='twitter'
                                                className='textfield'
                                                value={user.twitter}
                                                placeholder='Twitter Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Youtube Link:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='youtube'
                                                className='textfield'
                                                value={user.youtube}
                                                placeholder='Youtube Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Pinterest Link:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='pinterest'
                                                className='textfield'
                                                value={user.pinterest}
                                                placeholder='Pinterest Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tiktok Link:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='tiktok'
                                                className='textfield'
                                                value={user.tiktok}
                                                placeholder='Tiktok Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => handleUpdate('add')}>Update</Button>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => handleUpdate('addWithNavigate')}>Save + Continue</Button>
                                    </Box> :
                                    truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
