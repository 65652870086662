import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Avatar, Grid, TextField, FormControlLabel, Typography, IconButton } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { AddAPhoto } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';


import Avatarimage from "../../../assets/images/Avatar.png"
import Spinner from '../../../components/spinner'
import Switch from '@mui/material/Switch';

export default function EditTeacher() {
    let location = useLocation()
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    // function BpRadio(props) {
    //     return (
    //         <Radio
    //             sx={{
    //                 '&:hover': {
    //                     bgcolor: 'transparent',
    //                 },
    //             }}
    //             disableRipple
    //             color="default"
    //             checkedIcon={<BpCheckedIcon />}
    //             icon={<BpIcon />}
    //             {...props}
    //         />
    //     );
    // }
    // const IOSSwitch = styled((props) => (
    //     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    // ))(({ theme }) => ({
    //     width: 40,
    //     height: 20,
    //     padding: 0,
    //     '& .MuiSwitch-switchBase': {
    //         padding: 0,
    //         margin: 0,
    //         transitionDuration: '300ms',
    //         '&.Mui-checked': {
    //             transform: 'translateX(20px)',
    //             color: '#fff',
    //             '& + .MuiSwitch-track': {
    //                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
    //                 opacity: 1,
    //                 border: 0,
    //             },
    //             '&.Mui-disabled + .MuiSwitch-track': {
    //                 opacity: 0.5,
    //             },
    //         },
    //         '&.Mui-focusVisible .MuiSwitch-thumb': {
    //             color: '#33cf4d',
    //             border: '6px solid #fff',
    //         },
    //         '&.Mui-disabled .MuiSwitch-thumb': {
    //             color:
    //                 theme.palette.mode === 'light'
    //                     ? theme.palette.grey[100]
    //                     : theme.palette.grey[600],
    //         },
    //         '&.Mui-disabled + .MuiSwitch-track': {
    //             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    //         },
    //     },
    //     '& .MuiSwitch-thumb': {
    //         boxSizing: 'border-box',
    //         width: 18.5,
    //         height: 18.5,
    //     },
    //     '& .MuiSwitch-track': {
    //         borderRadius: 18.5 / 2,
    //         backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
    //         opacity: 1,
    //         transition: theme.transitions.create(['background-color'], {
    //             duration: 500,
    //         }),
    //     },
    // }));

    // const BpIcon = styled('span')(({ theme }) => ({
    //     borderRadius: '50%',
    //     width: 16,
    //     height: 16,
    //     boxShadow:
    //         theme.palette.mode === 'dark'
    //             ? '0 0 0 1px rgb(16 22 26 / 40%)'
    //             : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    //     backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    //     backgroundImage:
    //         theme.palette.mode === 'dark'
    //             ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
    //             : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    //     '.Mui-focusVisible &': {
    //         outline: '2px auto rgba(19,124,189,.6)',
    //         outlineOffset: 2,
    //     },
    //     'input:hover ~ &': {
    //         backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    //     },
    //     'input:disabled ~ &': {
    //         boxShadow: 'none',
    //         background:
    //             theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    //     },
    // }));

    // const BpCheckedIcon = styled(BpIcon)({
    //     backgroundColor: '#f3714d',
    //     backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    //     '&:before': {
    //         display: 'block',
    //         width: 16,
    //         height: 16,
    //         backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    //         content: '""',
    //     },
    //     'input:hover ~ &': {
    //         backgroundColor: '#106ba3',
    //     },
    // });


    //UseState's:
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()



    const [yogaquote, setYogaquote] = React.useState({ quote: location.state.data.QuoteName, writtenby: location.state.data.Quote_by, image: location.state.data.image, Imageprew: "", Imageraw: "", role: location.state.data.role });
    const [error, setError] = React.useState({ quote: "", writtenby: "", role: "", image: "" })
    const [quote, setQuote] = React.useState(location.state.data.QuoteName)
    const [isPrimary, setPrimary] = React.useState(location.state.data.primary_quote)
    const [isPublic] = React.useState(location.state.data.public)
    const [truebutton, setTruebutton] = React.useState(false)

    //OnChange:
    let name, value;
    const handleYogaquote = (e) => {
        name = e.target.name;
        value = e.target.value
        setYogaquote({ ...yogaquote, [name]: value })
    }

    // const handleprofilephoto = (e) => {
    //     setYogaquote({ ...yogaquote, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] });
    // }


    //Upload Single Image
    const handleaddphoto = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const fileSize = file.size / 1024 / 1024; // in MB
        if (fileSize > 1) {
            setError({ ...error, image: 'Image should be less than 1 MB' });
            setYogaquote({
                ...yogaquote,
                Imageprew: '',
                Imageraw: null,
            });
        } else {
            setYogaquote({
                ...yogaquote,
                Imageprew: URL.createObjectURL(file),
                Imageraw: file,
            });
            setError({ ...error, image: '' }); // Clear image error message
        }
    };



    // Validation:
    const Validator = () => {
        if (!quote && !yogaquote.writtenby && !yogaquote.role) {
            setError({ quote: "This field is required.", writtenby: "This field is required.", role: "This field is required." })
            return false
        }
        if (!yogaquote.role) {
            setError({ role: "This field is required." })
            return false
        }
        if (!quote) {
            setError({ quote: "This field is required." })
            return false
        }
        if (!yogaquote.writtenby) {
            setError({ writtenby: "This field is required." })
            return false
        }
        return true
    }

    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }




    // Update Yoga Quote:
    const handleUpdate = async () => {
        var Valid = Validator()
        if (Valid) {
            const formData = new FormData();
            formData.append("image", yogaquote.Imageraw ? yogaquote.Imageraw : "");
            formData.append("role", yogaquote.role);
            formData.append("QuoteName", quote);
            formData.append("Quote_by", yogaquote.writtenby);
            formData.append("primary_quote", isPrimary);
            formData.append("public", isPublic)
            setTruebutton(true)
            axios.put(process.env.REACT_APP_BASE_URL + `YogaQuote/updateYogaQuote/${location.state.data._id}`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    navigate('/yogaquote')
                    enqueueSnackbar("Yogaquote updated successfully", { variant: 'success' })
                    console.log(res, "edit yoga");
                })
                .catch((error) => {
                    setTruebutton(false)
                    enqueueSnackbar(error && error.response && error.response.data.error ? error.response.data.error : "Somthing went wrong", { variant: 'error' })
                    console.log(error.response, "error");
                })
        }
    }

    return (
        <Box className=''>
            <MiniDrawer menuOrder={1} submenuOrder={2} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit Yoga Quotes</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className='personal-info-tab'>
                                            <Typography component='div' className='label' >
                                                <center>Author Profile<Typography component='span' className='star'>*</Typography></center>
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    {/* <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} /> */}
                                                    <input
                                                        accept={"image/png, image/jpg,image/jpeg"}
                                                        id="icon-button-file"
                                                        className="profUploadbtn"
                                                        type="file"
                                                        onChange={handleaddphoto}
                                                    />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        {yogaquote.Imageprew ? <Avatar src={yogaquote.Imageprew}>
                                                        </Avatar> : yogaquote.image ? <React.Suspense fallback={<Spinner />}>
                                                            <Avatar src={process.env.REACT_APP_BASE_URL + yogaquote.image}> </Avatar>
                                                        </React.Suspense> : <Avatar src={Avatarimage} />
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 1 MB
                                            </Typography>
                                            {error.image && <p style={{ color: 'red' }} className="fz-13 ml-20 text-center" >{error.image}</p>}

                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box className='d-flex align-items-start justify-content-between' >
                                            <Box className=''>
                                                <Typography component='div' variant='body1' className='fz-14 '>Primary </Typography>
                                                <FormControlLabel
                                                    className='m-0'
                                                    control={<Switch size='small' checked={isPrimary} onClick={handleTogglePrimary} />}

                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Box className='d-flex align-items-start justify-content-between' >
                                            <Box className='flex-center'>
                                                <Typography component='div' variant='body1' className='fz-14 '>Public</Typography>
                                                <FormControlLabel
                                                    className='m-0'
                                                    control={<Switch size='small' checked={isPublic} onClick={handleTogglePublic}  />}
                                                />									</Box>
                                        </Box>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>
                                                Yoga Quotes<Typography component='span' className='star'>*</Typography>
                                            </Typography>
                                            <textarea
                                                placeholder='Quote'
                                                className='textarea maxw100'
                                                value={quote}
                                                // onPaste={handlePaste}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;

                                                    // Regex to allow only alphabetic characters (a-z, A-Z) and no spaces or special characters
                                                    const validPattern = /^[\d\s]*$/;

                                                    if (inputValue.trim() === "") {
                                                        setQuote(""); // Clear quote if input is empty
                                                        setError((prevError) => ({
                                                            ...prevError,
                                                            quote: "", // Clear the error message for empty input
                                                        }));
                                                    } else if (!validPattern.test(inputValue)) {
                                                        setQuote(inputValue); // Set the value if it's valid (only letters)
                                                        setError((prevError) => ({
                                                            ...prevError,
                                                            quote: "", // Clear any previous error
                                                        }));
                                                    } else {
                                                        setError((prevError) => ({
                                                            ...prevError,
                                                            quote: "Only alphabetic characters are allowed (no spaces, numbers, or special characters)",
                                                        }));
                                                    }
                                                }}

                                                rows={4}
                                            />
                                            {error.quote && <p style={{ color: 'red' }} className="fz-13 m-0">{error.quote}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Quoted By<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Quoted by'
                                                name="writtenby"
                                                value={yogaquote.writtenby}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    // Regex to match only alphabetic characters
                                                    const validPattern = /^[a-zA-Z\s]*$/;

                                                    if (validPattern.test(inputValue)) {
                                                        handleYogaquote(e); setError(prevError => ({ ...prevError, quote: '' }));
                                                    } else {
                                                        setError(prevError => ({ ...prevError, writtenby: 'Only letters are allowed' }));
                                                    }
                                                }}
                                            />
                                            {error.writtenby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.writtenby}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Reference<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Anonymous'
                                                name="role"
                                                value={yogaquote.role}
                                                onChange={(e) => {
                                                    handleYogaquote(e); setError(prevError => ({ ...prevError, role: '' }))
                                                }}
                                            />
                                            {error.role && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.role}</p>}
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button onClick={handleUpdate} variant="contained" className='theme-btn '>Update</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait...</Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
