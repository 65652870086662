

import React, { useState, useEffect } from 'react';
import MultiSelectWithCheckboxes from './MultiSelectWithCheckboxes';
import { Autocomplete, TextField, FormControl, Chip, Checkbox, Typography } from '@mui/material';

const EditMultipleBundle = (
  {
    selectedCommunity, selectedYogaFans, selectedCourses, selectedTeacher,
    onUpdateTeacher,onUpdateCommunity, onUpdateYogaFans, onUpdateCourses,
    communities, yogaFans, courses, teacher,errors,errorFanpageindex,errorCommunityindex
  }
) => {



  const [localSelectedCommunity, setLocalSelectedCommunity] = useState([]);
  const [localSelectedYogaFans, setLocalSelectedYogaFans] = useState([]);
  const [localSelectedCourses, setLocalSelectedCourses] = useState([]);
  const [localSelectedTeacher, setLocalSelectedTeacher] = useState([]);


  const [filterComm,setFilterComm] = useState([])
  const [filterCourse,setFilterCourse] = useState([])
  const [filterFans,setFilterFans] = useState([])


  // // // Filter the data when selectedTeacher changes
  // // useEffect(() => {
  // //   if (selectedTeacher.length >=  10) {
  // //       // Filter Communities based on selected teachers' IDs
  // //       setLocalSelectedCommunity(communities.filter(community => 
  // //         selectedTeacher?.some(teacherId => 
  // //           selectedCommunity?.createdby?.some(teacher => teacher._id === teacherId._id) // Check if teacher._id is in the createdBy array
  // //         )
  // //       ));
  // // / Filter Communities based on selected teachers' IDs
  //   setLocalSelectedCommunity(communities.filter(community => 
  //     selectedTeacher.some(teacher => 
  //       community.createdby.some(creator => creator._id === teacher._id)  // Check if teacher._id is in the createdBy array of community
  //     )
  //   ));
    
  // //       setLocalSelectedYogaFans(yogaFans.filter(yogaFan => 
  // //         selectedTeacher.some(teacher => 
  // //           yogaFan.createdby === teacher._id // Compare teacher._id with the string in createdBy
  // //         )
  // //       ));
    
  // //       // Filter Courses based on selected teachers' IDs (createdBy is a string here)
  // //       setLocalSelectedCourses(courses.filter(c => 
  // //       selectedTeacher.some(teacherId => 
  // //         c.createdby === teacherId._id // Compare the teacher's _id with the string in createdBy
  // //       )
  // //     ));
  // //     } else {
  // //       // If no teacher is selected, show all options
  // //       setLocalSelectedCommunity(communities);
  // //       setLocalSelectedYogaFans(yogaFans);
  // //       setLocalSelectedCourses(courses);
  // //     }
  // // }, [selectedTeacher, communities, yogaFans, courses]);

  useEffect(() => {
    if (selectedTeacher.length >= 0) {
      // Filter Communities based on selected teachers' IDs
      setFilterComm(communities?.filter(community => 
        selectedTeacher.some(teacher => 
          community.createdby.some(creator => creator._id === teacher._id)  // Check if teacher._id is in the createdBy array of community
        )
      ));
  
      // Filter Yoga Fans based on selected teachers' IDs
      setFilterFans(yogaFans?.filter(yogaFan => 
        selectedTeacher.some(teacher => 
          yogaFan.createdby === teacher._id  // Compare teacher._id with the string in createdBy for yogaFans
        )
      ));
  
      // Filter Courses based on selected teachers' IDs
      setFilterCourse(courses?.filter(course => 
        selectedTeacher.some(teacher => 
          course.createdby === teacher._id  // Compare teacher._id with the string in createdBy for courses
        )
      ));
  

      setLocalSelectedTeacher(selectedTeacher);
    } else {
      // If no teacher is selected, show all options
      setLocalSelectedCommunity(communities);
      setLocalSelectedYogaFans(yogaFans);
      setLocalSelectedCourses(courses);
    }
  }, [selectedTeacher, communities, yogaFans, courses]);



  useEffect(() => {
    setLocalSelectedCommunity(selectedCommunity);
    setLocalSelectedYogaFans(selectedYogaFans);
    setLocalSelectedCourses(selectedCourses);
    setLocalSelectedTeacher(selectedTeacher);
  }, [selectedCommunity, selectedYogaFans, selectedCourses, selectedTeacher]);

  // Handle the change for Communities (checkbox change)
  const handleChangeCommunity = (selectedCommunities) => {
    setLocalSelectedCommunity(selectedCommunities);
    onUpdateCommunity(selectedCommunities);
  };

  // Handle the change for Yoga-Fans
  const handleChangeYogaFans = (selectedFansPage) => {
    setLocalSelectedYogaFans(selectedFansPage);
    onUpdateYogaFans(selectedFansPage);
  };

  // Handle the change for Courses
  const handleChangeCourses = (selectedCourse) => {
    setLocalSelectedCourses(selectedCourse);
    onUpdateCourses(selectedCourse);
  };

  // Handle the change for Teachers (Autocomplete)
  // const handleTeacherChange = (selectedTeachers) => {
  //   setLocalSelectedTeacher(selectedTeachers);
  //   onUpdateTeacher(selectedTeachers);
  // };
  const handleTeacherChange = (newValue) => {
    // 'newValue' is an array of selected teacher objects
  
    onUpdateTeacher(newValue);  // Notify the parent with the selected teachers
  
    // Extract teacher IDs from selected teacher objects
    const selectedTeacherIds = newValue.map(item => item._id); 
  
  
  
    // Update local state with teacher IDs (if you need to track them locally)
    setLocalSelectedTeacher(newValue);  // Update the local selected teacher array (objects, not just IDs)
  
    // If you need to update other payment plans or send the IDs back to the parent
    // handlePaymentPlansChange({
    //   communities: selectedItemsPaymentComm,
    //   courses: selectedItemsPaymentForCourse,
    //   yogaFans: selectedItemsPaymentForFansPage,
    //   teachers: selectedTeacherIds,  // Send only the teacher IDs to the parent
    // });
  };
  
  return (
  
    <div>

<Typography component="div" className="label">
        Select Teachers <Typography component="span" className="star">*</Typography>
      </Typography>
      <FormControl variant="outlined" className="mb-20 mt-5">
        <Autocomplete
          multiple
          id="teacher-autocomplete"
          options={teacher} // Array of all teachers
          getOptionLabel={(option) => option.username || ""}
          value={localSelectedTeacher} // Array of selected teacher objects (not just IDs)
          onChange={(event, newValue) => handleTeacherChange(newValue)} // Handle change
          sx={{ minWidth: "500px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Type to search..."
            />
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip key={option._id} label={option.username} {...getTagProps({ index })} />
            ))}
          renderOption={(props, option, { selected }) => {
            // Check if the current option is in the selected value list
            const isSelected = localSelectedTeacher.some(teacher => teacher._id === option._id);

            return (
              <li {...props}>
                <Checkbox checked={isSelected} />
                {option.username}
              </li>
            );
          }}
          noOptionsText="No Records found"
        />
        {errors && errors.teacher && (
            <div style={{ color: 'red' }}>
              <p>{errors.teacher}</p>
            </div>
          )}
      </FormControl>

      
     
      {/* Communities */}
      <MultiSelectWithCheckboxes
        label="Assign Communities"
        options={filterComm}
        name="community"
        selectedValues={localSelectedCommunity}
        handleChange={handleChangeCommunity} 
        renderValue={(selectedValues) => selectedValues.map(id => communities.find(c => c._id === id)?.coummunity_name).join(' , ')}
        errors={errors}
        errorCommunityindex={errorCommunityindex}
      />
      
      {errors && errors.paymentPlan && (
            <div style={{ color: 'red' }}>
              <p>{errors.paymentPlan}</p>
            </div>
          )}

      {/* Yoga-Fans */}
      <MultiSelectWithCheckboxes
        label="Assign Yoga-Fans Pages"
        options={filterFans}
        name="yogaFans"
        selectedValues={localSelectedYogaFans}
        handleChange={handleChangeYogaFans}
        renderValue={(selectedValues) => selectedValues.map(id => yogaFans.find(c => c._id === id)?.name).join(' , ')}
        errors={errors}
        errorFanpageindex={errorFanpageindex}
      />

{errors && errors.paymentPlan && (
            <div style={{ color: 'red' }}>
              <p>{errors.paymentPlan}</p>
            </div>
          )}

      {/* Courses */}
      <MultiSelectWithCheckboxes
        label="Assign Courses"
        options={filterCourse}
        name="course"
        selectedValues={localSelectedCourses}
        handleChange={handleChangeCourses}
        renderValue={(selectedValues) => selectedValues.map(id => courses.find(c => c._id === id)?.course_title).join(' , ')}
        errors={errors}
      />

{errors && errors.paymentPlan && (
            <div style={{ color: 'red' }}>
              <p>{errors.paymentPlan}</p>
            </div>
          )}

      

    </div>
 
  )
}

export default EditMultipleBundle