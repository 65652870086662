import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Checkbox, Grid, InputAdornment, TextField, FormControlLabel, Typography } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom';

function Editrole() {
  let location = useLocation()
  let locationData = location.state.data
  const tokendata = {
    'headers': {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem("token")
    }
  }

  let navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  var editId = locationData._id
  const [role, setRole] = React.useState({ title: locationData.UserRole })
  const [error, setError] = React.useState({ title: "" })
const[truebutton,setTruebutton]=React.useState(false)
  let name, value;
  const handleRole = (e) => {
    name = e.target.name;
    value = e.target.value
    setRole({ ...role, [name]: value })
  }

  const Validator = () => {
    if (!role.title) {
      setError({ title: "This field is required." })
      return false
    }
    return true
  }

  const handleUpdate = async () => {
    var Valid = Validator()
    if (Valid) {
      setTruebutton(true)
  
      let body = {
        UserRole: role.title.toString(),
        Event: {
          addEvent: checkEvent.addEvent,
          viewEvent: checkEvent.listEvent,
          editEvent: checkEvent.editEvent,
          deleteEvent: checkEvent.deleteEvent,
        },
        Course: {
          addCourse: checkCourse.addCourse,
          viewCourse: checkCourse.listCourse,
          deleteCourse: checkCourse.deleteCourse,
          editCourse: checkCourse.editCourse,
        },
        Blogs: {
          addBlogs: checkBlog.addBlog,
          viewBlogs: checkBlog.listBlog,
          deleteBlogs: checkBlog.deleteBlog,
          editBlogs: checkBlog.editBlog,
        },
        Category: {
          AddCategory: checkCategory.addCategory,
          viewCategory: checkCategory.listCategory,
          editCategory: checkCategory.editCategory,
          deleteCategory: checkCategory.deleteCategory,
        },
        SubCategory: {
          AddSubCategory: checkSubcategory.addSubcategory,
           viewSubCategory: checkSubcategory.listSubcategory,
          editSubCategory: checkSubcategory.editSubcategory,
          deleteSubCategory: checkSubcategory.deleteSubcategory,
        },
        About: {
          AddAbout: checkAbout.addAbout,
          getAbout: checkAbout.listAbout,
          deleteAbout: checkAbout.deleteAbout,
          editAbout: checkAbout.editAbout,
        },
        Teachers: {
          addTeacher: checkTeacher.addTeacher,
          viewTeacher: checkTeacher.viewTeacher,
          editTeacher: checkTeacher.editTeacher,
          deleteTeacher: checkTeacher.deleteTeacher,
        },
        News: {
          addNews: checkNews.addNews,
          viewNews: checkNews.viewNews,
          editNews: checkNews.editNews,
          deleteNews: checkNews.deleteNews,
        },
        Jobs: {
          addjobs: checkJobs.addjobs,
          viewJobs: checkJobs.viewJobs,
          editjobs: checkJobs.editjobs,
          deletejobs: checkJobs.deletejobs,
        },
        Community: {
          AddCommunity: checkCommunity.addCommunity,
          getCommunity: checkCommunity.viewCommunity,
          deleteCommunity: checkCommunity.deleteCommunity,
          editCommunity: checkCommunity.editCommunity,
        },
        Podcast: {
          AddPodcast: checkPodcast.addPodcast,
          getPodcast: checkPodcast.viewPodcast,
          deletePodcast: checkPodcast.deletePodcast,
          editPodcast: checkPodcast.editPodcast,
        },
        Images: {
          AddImages: checkImage.addImage,
          getImages: checkImage.listImage,
          deleteImages: checkImage.deleteImage,
          editImages: checkImage.editImage,
        },

        Videos: {
          AddVideos: checkVideo.addVideo,
          getVideos: checkVideo.listVideo,
          deleteVideos: checkVideo.deleteVideo,
          editVideos: checkVideo.editVideo,
        },

        BookReview: {
          AddBookReview: checkReview.addReview,
          getBookReview: checkReview.viewReview,
          deleteBookReview: checkReview.deleteReview,
          editBookReview: checkReview.editReview,
        },
        AddManagements: {
          AddAddManagements: checkAd.addAd,
          getAddManagements: checkAd.viewAd,
          deleteAddManagements: checkAd.deleteAd,
          editAddManagements: checkAd.editAd,
        },
        quotes: {
          AddAquotes: checkQuote.addQuote,
          getquotes: checkQuote.listQuote,
          deletequotes: checkQuote.deleteQuote,
          editequotes: checkQuote.editQuote,
        },
        tags: {
          Addtags: checkTags.addTags,
          gettags: checkTags.listTags,
          deletetags: checkTags.deleteTags,
          edittags: checkTags.editTags,
        },

        settings: {
          Addsettings: checkSettings.addSettings,
          getsettings: checkSettings.listSettings,
          deletesettings: checkSettings.deleteSettings,
          editsettings: checkSettings.editSettings,
        },
        merit: {
          Addmerit: checkMerit.addMerit,
          getmerit: checkMerit.listMerit,
          deletemerit: checkMerit.deleteMerit,
          editmerit: checkMerit.editMerit,
        },
        school: {
          Addschool: checkSchool.addSchool,
          getschool: checkSchool.listSchool,
          deleteschool: checkSchool.deleteSchool,
          editschool: checkSchool.editSchool,
        },
        studio: {
          Addstudio: checkStudio.addStudio,
          getstudio: checkStudio.listStudio,
          deletestudio: checkStudio.deleteStudio,
          editstudio: checkStudio.editStudio,
        },
        // newsletter: {
        //   Addnewsletter: checkNewsletter.addNewsletter,
        //   getnewsletter: checkNewsletter.viewNewsletter,
        //   deletenewsletter: checkNewsletter.deleteNewsletter,
        //   editnewsletter: checkNewsletter.editNewsletter,
        // },
        users: {
          Addusers: checkUsers.addUsers,
          getusers: checkUsers.viewUsers,
          deleteusers: checkUsers.deleteUsers,
          editusers: checkUsers.editUsers,
        },
        legends: {
          Addlegends: checkLegends.addLegends,
          getlegends: checkLegends.listLegends,
          deletelegends: checkLegends.deleteLegends,
          editelegends: checkLegends.editLegends,
        },
        subscription: {
          Addsubscription: checkSubscription.addSubscription,
          getsubscription: checkSubscription.listSubscription,
          deletesubscription: checkSubscription.deleteSubscription,
          editsubscription: checkSubscription.editSubscription,
        },
      }
      await axios.put(process.env.REACT_APP_BASE_URL + `Userrole/UpdateUserroles/${editId}`, body, tokendata)
        .then((res) => {
          navigate('/rolemanagement')
          enqueueSnackbar("Role updated successfully", { variant: 'success' })
       
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong.", { variant: 'error' })
          console.log(error.response, "error");
        })
        .finally(()=>{
          setTruebutton(false)
        })
    }
  }

  // CHECKBOX
  const [checkQuote, setCheckQuote] = React.useState({ addQuote: locationData.quotes.AddAquotes, listQuote: locationData.quotes.getquotes, editQuote: locationData.quotes.editequotes, deleteQuote: locationData.quotes.deletequotes })
  const handleCheckQuote = (e) => {
 
    if (e.target.name === 'deleteQuote') {
      setCheckQuote({ addQuote: e.target.checked, listQuote: e.target.checked, editQuote: e.target.checked, deleteQuote: e.target.checked })
    }
    if (e.target.name === 'editQuote') {
      setCheckQuote({ addQuote: e.target.checked, listQuote: e.target.checked, editQuote: e.target.checked })
    }
    if (e.target.name === 'addQuote') {
      setCheckQuote({ addQuote: e.target.checked, listQuote: e.target.checked })
    }
    if (e.target.name === 'viewQuote') {
      setCheckQuote({ listQuote: e.target.checked })
    }
  }

  const [checkTags, setCheckTags] = React.useState({ addTags: locationData.tags.Addtags, listTags: locationData.tags.gettags, editTags: locationData.tags.edittags, deleteTags: locationData.tags.deletetags })
  const handleCheckTags = (e) => {
   
    if (e.target.name === 'deleteTags') {
      setCheckTags({ addTags: e.target.checked, listTags: e.target.checked, editTags: e.target.checked, deleteTags: e.target.checked })
    }
    if (e.target.name === 'editTags') {
      setCheckTags({ addTags: e.target.checked, listTags: e.target.checked, editTags: e.target.checked })
    }
    if (e.target.name === 'addTags') {
      setCheckTags({ addTags: e.target.checked, listTags: e.target.checked })
    }
    if (e.target.name === 'viewTags') {
      setCheckTags({ listTags: e.target.checked })
    }
  }

  const [checkSettings, setCheckSettings] = React.useState({ addSettings: locationData.settings.Addsettings, listSettings: locationData.settings.getsettings, editSettings: locationData.settings.editsettings, deleteSettings: locationData.settings.deletesettings })
  const handleCheckSettings = (e) => {
 
    if (e.target.name === 'deleteSettings') {
      setCheckSettings({ addSettings: e.target.checked, listSettings: e.target.checked, editSettings: e.target.checked, deleteSettings: e.target.checked })
    }
    if (e.target.name === 'editSettings') {
      setCheckSettings({ addSettings: e.target.checked, listSettings: e.target.checked, editSettings: e.target.checked })
    }
    if (e.target.name === 'addSettings') {
      setCheckSettings({ addSettings: e.target.checked, listSettings: e.target.checked })
    }
    if (e.target.name === 'viewSettings') {
      setCheckSettings({ listSettings: e.target.checked })
    }
  }

  const [checkLegends, setCheckLegends] = React.useState({ addLegends: locationData.legends.Addlegends, listLegends: locationData.legends.getlegends, editLegends: locationData.legends.editelegends, deleteLegends: locationData.legends.deletelegends })
  const handleCheckLegends = (e) => {
  
    if (e.target.name === 'deleteLegends') {
      setCheckLegends({ addLegends: e.target.checked, listLegends: e.target.checked, editLegends: e.target.checked, deleteLegends: e.target.checked })
    }
    if (e.target.name === 'editLegends') {
      setCheckLegends({ addLegends: e.target.checked, listLegends: e.target.checked, editLegends: e.target.checked })
    }
    if (e.target.name === 'addLegends') {
      setCheckLegends({ addLegends: e.target.checked, listLegends: e.target.checked })
    }
    if (e.target.name === 'viewLegends') {
      setCheckLegends({ listLegends: e.target.checked })
    }
  }

  const [checkMerit, setCheckMerit] = React.useState({ addMerit: locationData.merit.Addmerit, listMerit: locationData.merit.getmerit, editMerit: locationData.merit.editmerit, deleteMerit: locationData.merit.deletemerit })
  const handleCheckMerit = (e) => {
   
    if (e.target.name === 'deleteMerit') {
      setCheckMerit({ addMerit: e.target.checked, listMerit: e.target.checked, editMerit: e.target.checked, deleteMerit: e.target.checked })
    }
    if (e.target.name === 'editMerit') {
      setCheckMerit({ addMerit: e.target.checked, listMerit: e.target.checked, editMerit: e.target.checked })
    }
    if (e.target.name === 'addMerit') {
      setCheckMerit({ addMerit: e.target.checked, listMerit: e.target.checked })
    }
    if (e.target.name === 'viewMerit') {
      setCheckMerit({ listMerit: e.target.checked })
    }
  }

  const [checkSchool, setCheckSchool] = React.useState({ addSchool: locationData.school.Addschool, listSchool: locationData.school.getschool, editSchool: locationData.school.editschool, deleteSchool: locationData.school.deleteschool })
  const handleCheckSchool = (e) => {

    if (e.target.name === 'deleteSchool') {
      setCheckSchool({ addSchool: e.target.checked, listSchool: e.target.checked, editSchool: e.target.checked, deleteSchool: e.target.checked })
    }
    if (e.target.name === 'editSchool') {
      setCheckSchool({ addSchool: e.target.checked, listSchool: e.target.checked, editSchool: e.target.checked })
    }
    if (e.target.name === 'addSchool') {
      setCheckSchool({ addSchool: e.target.checked, listSchool: e.target.checked })
    }
    if (e.target.name === 'viewSchool') {
      setCheckSchool({ listSchool: e.target.checked })
    }
  }

  const [checkStudio, setCheckStudio] = React.useState({ addStudio: locationData.studio.Addstudio, listStudio: locationData.studio.getstudio, editStudio: locationData.studio.editstudio, deleteStudio: locationData.studio.deletestudio })
  const handleCheckStudio = (e) => {
    
    if (e.target.name === 'deleteStudio') {
      setCheckStudio({ addStudio: e.target.checked, listStudio: e.target.checked, editStudio: e.target.checked, deleteStudio: e.target.checked })
    }
    if (e.target.name === 'editStudio') {
      setCheckStudio({ addStudio: e.target.checked, listStudio: e.target.checked, editStudio: e.target.checked })
    }
    if (e.target.name === 'addStudio') {
      setCheckStudio({ addStudio: e.target.checked, listStudio: e.target.checked })
    }
    if (e.target.name === 'viewStudio') {
      setCheckStudio({ listStudio: e.target.checked })
    }
  }

  const [checkEvent, setCheckEvent] = React.useState({ addEvent: locationData.Event.addEvent, listEvent: locationData.Event.viewEvent, editEvent: locationData.Event.editEvent, deleteEvent: locationData.Event.deleteEvent })
  const handleCheckEvent = (e) => {
   
    if (e.target.name === 'deleteEvent') {
      setCheckEvent({ addEvent: e.target.checked, listEvent: e.target.checked, editEvent: e.target.checked, deleteEvent: e.target.checked })
    }
    if (e.target.name === 'editEvent') {
      setCheckEvent({ addEvent: e.target.checked, listEvent: e.target.checked, editEvent: e.target.checked })
    }
    if (e.target.name === 'addEvent') {
      setCheckEvent({ addEvent: e.target.checked, listEvent: e.target.checked })
    }
    if (e.target.name === 'listEvent') {
      setCheckEvent({ listEvent: e.target.checked })
    }
  }

  const [checkCourse, setCheckCourse] = React.useState({ addCourse: locationData.Course.addCourse, listCourse: locationData.Course.viewCourse, editCourse: locationData.Course.editCourse, deleteCourse: locationData.Course.deleteCourse })
  const handleCheckCourse = (e) => {
    if (e.target.name === 'deleteCourse') {
      setCheckCourse({ addCourse: e.target.checked, listCourse: e.target.checked, editCourse: e.target.checked, deleteCourse: e.target.checked })
    }
    if (e.target.name === 'editCourse') {
      setCheckCourse({ addCourse: e.target.checked, listCourse: e.target.checked, editCourse: e.target.checked })
    }
    if (e.target.name === 'addCourse') {
      setCheckCourse({ addCourse: e.target.checked, listCourse: e.target.checked })
    }
    if (e.target.name === 'listCourse') {
      setCheckCourse({ listCourse: e.target.checked })
    }
  }

  const [checkBlog, setCheckBlog] = React.useState({ addBlog: locationData.Blogs.addBlogs, listBlog: locationData.Blogs.viewBlogs, editBlog: locationData.Blogs.editBlogs, deleteBlog: locationData.Blogs.deleteBlogs })
  const handleCheckBlog = (e) => {
    if (e.target.name === 'deleteBlog') {
      setCheckBlog({ addBlog: e.target.checked, listBlog: e.target.checked, editBlog: e.target.checked, deleteBlog: e.target.checked })
    }
    if (e.target.name === 'editBlog') {
      setCheckBlog({ addBlog: e.target.checked, listBlog: e.target.checked, editBlog: e.target.checked })
    }
    if (e.target.name === 'addBlog') {
      setCheckBlog({ addBlog: e.target.checked, listBlog: e.target.checked })
    }
    if (e.target.name === 'listBlog') {
      setCheckBlog({ listBlog: e.target.checked })
    }
  }

  const [checkImage, setCheckImage] = React.useState({ addImage: locationData.Images.AddImages, listImage: locationData.Images.getImages, editImage: locationData.Images.editImages, deleteImage: locationData.Images.deleteImages })
  const handleCheckImage = (e) => {
    if (e.target.name === 'deleteImage') {
      setCheckImage({ addImage: e.target.checked, listImage: e.target.checked, editImage: e.target.checked, deleteImage: e.target.checked })
    }
    if (e.target.name === 'editImage') {
      setCheckImage({ addImage: e.target.checked, listImage: e.target.checked, editImage: e.target.checked })
    }
    if (e.target.name === 'addImage') {
      setCheckImage({ addImage: e.target.checked, listImage: e.target.checked })
    }
    if (e.target.name === 'viewImage') {
      setCheckImage({ listImage: e.target.checked })
    }
  }

  const [checkVideo, setCheckVideo] = React.useState({ addVideo: locationData.Videos.AddVideos, listVideo: locationData.Videos.getVideos, editVideo: locationData.Videos.editVideos, deleteVideo: locationData.Videos.deleteVideos })
  const handleCheckVideo = (e) => {
    if (e.target.name === 'deleteVideo') {
      setCheckVideo({ addVideo: e.target.checked, listVideo: e.target.checked, editVideo: e.target.checked, deleteVideo: e.target.checked })
    }
    if (e.target.name === 'editVideo') {
      setCheckVideo({ addVideo: e.target.checked, listVideo: e.target.checked, editVideo: e.target.checked })
    }
    if (e.target.name === 'addVideo') {
      setCheckVideo({ addVideo: e.target.checked, listVideo: e.target.checked })
    }
    if (e.target.name === 'viewVideo') {
      setCheckVideo({ listVideo: e.target.checked })
    }
  }

  const [checkCategory, setCheckCategory] = React.useState({ addCategory: locationData.Category.AddCategory, listCategory: locationData.Category.viewCategory, editCategory: locationData.Category.editCategory, deleteCategory: locationData.Category.deleteCategory })
  const handleCategory = (e) => {
    if (e.target.name === 'deleteCategory') {
      setCheckCategory({ addCategory: e.target.checked, listCategory: e.target.checked, editCategory: e.target.checked, deleteCategory: e.target.checked })
    }
    if (e.target.name === 'editCategory') {
      setCheckCategory({ addCategory: e.target.checked, listCategory: e.target.checked, editCategory: e.target.checked })
    }
    if (e.target.name === 'addCategory') {
      setCheckCategory({ addCategory: e.target.checked, listCategory: e.target.checked })
    }
    if (e.target.name === 'listCategory') {
      setCheckCategory({ listCategory: e.target.checked })
    }
  }

  const [checkSubcategory, setCheckSubcategory] = React.useState({ addSubcategory: locationData.SubCategory.AddSubCategory, listSubcategory: locationData.SubCategory.viewSubCategory, editSubcategory: locationData.SubCategory.editSubCategory, deleteSubcategory: locationData.SubCategory.deleteSubCategory })
  const handleCheckSubcategory = (e) => {
    if (e.target.name === 'deleteSubcategory') {
      setCheckSubcategory({ addSubcategory: e.target.checked, listSubcategory: e.target.checked, editSubcategory: e.target.checked, deleteSubcategory: e.target.checked })
    }
    if (e.target.name === 'editSubcategory') {
      setCheckSubcategory({ addSubcategory: e.target.checked, listSubcategory: e.target.checked, editSubcategory: e.target.checked })
    }
    if (e.target.name === 'addSubcategory') {
      setCheckSubcategory({ addSubcategory: e.target.checked, listSubcategory: e.target.checked })
    }
    if (e.target.name === 'listSubcategory') {
      setCheckSubcategory({ listSubcategory: e.target.checked })
    }
  }

  const [checkNews, setCheckNews] = React.useState({ addNews: locationData.News.addNews, viewNews: locationData.News.viewNews, editNews: locationData.News.editNews, deleteNews: locationData.News.deleteNews })
  const handleCheckNews = (e) => {
    if (e.target.name === 'deleteNews') {
      setCheckNews({ addNews: e.target.checked, viewNews: e.target.checked, editNews: e.target.checked, deleteNews: e.target.checked })
    }
    if (e.target.name === 'editNews') {
      setCheckNews({ addNews: e.target.checked, viewNews: e.target.checked, editNews: e.target.checked })
    }
    if (e.target.name === 'addNews') {
      setCheckNews({ addNews: e.target.checked, viewNews: e.target.checked })
    }
    if (e.target.name === 'viewNews') {
      setCheckNews({ viewNews: e.target.checked })
    }
  }

  const [checkAbout, setCheckAbout] = React.useState({ addAbout: locationData.About.AddAbout, listAbout: locationData.About.getAbout, editAbout: locationData.About.editAbout, deleteAbout: locationData.About.deleteAbout })
  const handleCheckAbout = (e) => {
    if (e.target.name === 'deleteAbout') {
      setCheckAbout({ addAbout: e.target.checked, listAbout: e.target.checked, editAbout: e.target.checked, deleteAbout: e.target.checked })
    }
    if (e.target.name === 'editAbout') {
      setCheckAbout({ addAbout: e.target.checked, listAbout: e.target.checked, editAbout: e.target.checked })
    }
    if (e.target.name === 'addAbout') {
      setCheckAbout({ addAbout: e.target.checked, listAbout: e.target.checked })
    }
    if (e.target.name === 'listAbout') {
      setCheckAbout({ listAbout: e.target.checked })
    }
  }

  const [checkSubscription, setCheckSubscription] = React.useState({ addSubscription: locationData.subscription.Addsubscription, listSubscription: locationData.subscription.getsubscription, editSubscription: locationData.subscription.editsubscription, deleteSubscription: locationData.subscription.deletesubscription })
  const handleCheckSubscription = (e) => {
    if (e.target.name === 'deleteSubscription') {
      setCheckSubscription({ addSubscription: e.target.checked, listSubscription: e.target.checked, editSubscription: e.target.checked, deleteSubscription: e.target.checked })
    }
    if (e.target.name === 'editSubscription') {
      setCheckSubscription({ addSubscription: e.target.checked, listSubscription: e.target.checked, editSubscription: e.target.checked })
    }
    if (e.target.name === 'addSubscription') {
      setCheckSubscription({ addSubscription: e.target.checked, listSubscription: e.target.checked })
    }
    if (e.target.name === 'listSubscription') {
      setCheckSubscription({ listSubscription: e.target.checked })
    }
  }

  const [checkTeacher, setCheckTeacher] = React.useState({ addTeacher: locationData.Teachers.addTeacher, viewTeacher: locationData.Teachers.viewTeacher, editTeacher: locationData.Teachers.editTeacher, deleteTeacher: locationData.Teachers.deleteTeacher })
  const handleCheckTeacher = (e) => {
    if (e.target.name === 'deleteTeacher') {
      setCheckTeacher({ addTeacher: e.target.checked, viewTeacher: e.target.checked, editTeacher: e.target.checked, deleteTeacher: e.target.checked })
    }
    if (e.target.name === 'editTeacher') {
      setCheckTeacher({ addTeacher: e.target.checked, viewTeacher: e.target.checked, editTeacher: e.target.checked })
    }
    if (e.target.name === 'addTeacher') {
      setCheckTeacher({ addTeacher: e.target.checked, viewTeacher: e.target.checked })
    }
    if (e.target.name === 'viewTeacher') {
      setCheckTeacher({ viewTeacher: e.target.checked })
    }
  }

  const [checkJobs, setCheckJobs] = React.useState({ addjobs: locationData.Jobs.addjobs, viewJobs: locationData.Jobs.viewJobs, editjobs: locationData.Jobs.editjobs, deletejobs: locationData.Jobs.deletejobs })
  const handleCheckJobs = (e) => {
    if (e.target.name === 'deletejobs') {
      setCheckJobs({ addjobs: e.target.checked, viewJobs: e.target.checked, editjobs: e.target.checked, deletejobs: e.target.checked })
    }
    if (e.target.name === 'editjobs') {
      setCheckJobs({ addjobs: e.target.checked, viewJobs: e.target.checked, editjobs: e.target.checked })
    }
    if (e.target.name === 'addjobs') {
      setCheckJobs({ addjobs: e.target.checked, viewJobs: e.target.checked })
    }
    if (e.target.name === 'viewJobs') {
      setCheckJobs({ viewJobs: e.target.checked })
    }
  }

  const [checkCommunity, setCheckCommunity] = React.useState({ addCommunity: locationData.Community.AddCommunity, viewCommunity: locationData.Community.getCommunity, editCommunity: locationData.Community.editCommunity, deleteCommunity: locationData.Community.deleteCommunity })
  const handleCheckCommunity = (e) => {
    if (e.target.name === 'deleteCommunity') {
      setCheckCommunity({ addCommunity: e.target.checked, viewCommunity: e.target.checked, editCommunity: e.target.checked, deleteCommunity: e.target.checked })
    }
    if (e.target.name === 'editCommunity') {
      setCheckCommunity({ addCommunity: e.target.checked, viewCommunity: e.target.checked, editCommunity: e.target.checked })
    }
    if (e.target.name === 'addCommunity') {
      setCheckCommunity({ addCommunity: e.target.checked, viewCommunity: e.target.checked })
    }
    if (e.target.name === 'viewCommunity') {
      setCheckCommunity({ viewCommunity: e.target.checked })
    }
  }

  const [checkReview, setCheckReview] = React.useState({ addReview: locationData.BookReview.AddBookReview, viewReview: locationData.BookReview.getBookReview, editReview: locationData.BookReview.editBookReview, deleteReview: locationData.BookReview.deleteBookReview })
  const handleCheckReview = (e) => {
    if (e.target.name === 'deleteReview') {
      setCheckReview({ addReview: e.target.checked, viewReview: e.target.checked, editReview: e.target.checked, deleteReview: e.target.checked })
    }
    if (e.target.name === 'editReview') {
      setCheckReview({ addReview: e.target.checked, viewReview: e.target.checked, editReview: e.target.checked })
    }
    if (e.target.name === 'addReview') {
      setCheckReview({ addReview: e.target.checked, viewReview: e.target.checked })
    }
    if (e.target.name === 'viewReview') {
      setCheckReview({ viewReview: e.target.checked })
    }
  }

  const [checkPodcast, setCheckPodcast] = React.useState({ addPodcast: locationData.Podcast.AddPodcast, viewPodcast: locationData.Podcast.getPodcast, editPodcast: locationData.Podcast.editPodcast, deletePodcast: locationData.Podcast.deletePodcast })
  const handleCheckPodcast = (e) => {
    if (e.target.name === 'deletePodcast') {
      setCheckPodcast({ addPodcast: e.target.checked, viewPodcast: e.target.checked, editPodcast: e.target.checked, deletePodcast: e.target.checked })
    }
    if (e.target.name === 'editPodcast') {
      setCheckPodcast({ addPodcast: e.target.checked, viewPodcast: e.target.checked, editPodcast: e.target.checked })
    }
    if (e.target.name === 'addPodcast') {
      setCheckPodcast({ addPodcast: e.target.checked, viewPodcast: e.target.checked })
    }
    if (e.target.name === 'viewPodcast') {
      setCheckPodcast({ viewPodcast: e.target.checked })
    }
  }

  const [checkAd, setCheckAd] = React.useState({ addAd: locationData.AddManagements.AddAddManagements, viewAd: locationData.AddManagements.getAddManagements, editAd: locationData.AddManagements.editAddManagements, deleteAd: locationData.AddManagements.deleteAddManagements })
  const handleCheckAd = (e) => {
    if (e.target.name === 'deleteAd') {
      setCheckAd({ addAd: e.target.checked, viewAd: e.target.checked, editAd: e.target.checked, deleteAd: e.target.checked })
    }
    if (e.target.name === 'editAd') {
      setCheckAd({ addAd: e.target.checked, viewAd: e.target.checked, editAd: e.target.checked })
    }
    if (e.target.name === 'addAd') {
      setCheckAd({ addAd: e.target.checked, viewAd: e.target.checked })
    }
    if (e.target.name === 'viewAd') {
      setCheckAd({ viewAd: e.target.checked })
    }
  }

  // const [checkNewsletter, setCheckNewsletter] = React.useState({ addNewsletter: locationData.newsletter.Addnewsletter, viewNewsletter: locationData.newsletter.getnewsletter, editNewsletter: locationData.newsletter.editnewsletter, deleteNewsletter: locationData.newsletter.deletenewsletter })
  // const handleCheckNewsletter = (e) => {
  //   if (e.target.name === 'deleteNewsletter') {
  //     setCheckNewsletter({ addNewsletter: e.target.checked, viewNewsletter: e.target.checked, editNewsletter: e.target.checked, deleteNewsletter: e.target.checked })
  //   }
  //   if (e.target.name === 'editNewsletter') {
  //     setCheckNewsletter({ addNewsletter: e.target.checked, viewNewsletter: e.target.checked, editNewsletter: e.target.checked })
  //   }
  //   if (e.target.name === 'addNewsletter') {
  //     setCheckNewsletter({ addNewsletter: e.target.checked, viewNewsletter: e.target.checked })
  //   }
  //   if (e.target.name === 'viewNewsletter') {
  //     setCheckNewsletter({ viewNewsletter: e.target.checked })
  //   }
  // }

  const [checkUsers, setCheckUsers] = React.useState({ addUsers: locationData.users.Addusers, viewUsers: locationData.users.getusers, editUsers: locationData.users.editusers, deleteUsers: locationData.users.deleteusers })
  const handleCheckUsers = (e) => {
    if (e.target.name === 'deleteUsers') {
      setCheckUsers({ addUsers: e.target.checked, viewUsers: e.target.checked, editUsers: e.target.checked, deleteUsers: e.target.checked })
    }
    if (e.target.name === 'editUsers') {
      setCheckUsers({ addUsers: e.target.checked, viewUsers: e.target.checked, editUsers: e.target.checked })
    }
    if (e.target.name === 'addUsers') {
      setCheckUsers({ addUsers: e.target.checked, viewUsers: e.target.checked })
    }
    if (e.target.name === 'viewUsers') {
      setCheckUsers({ viewUsers: e.target.checked })
    }
  }

  return (
    <Box className='Addcompany'>
      <MiniDrawer menuOrder={19} />
      <Box component="section" className="contentWraper">
        <Grid container rowSpacing={5} columnSpacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box className=' whiteContainer'>
              <Box className='content p-20'>
                <Typography component='h6' className='formTitle'>Edit Role</Typography>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl variant="standard" fullWidth>
                      <Typography component='div' className='label'>Role name <Typography component='span' className='star'>*</Typography></Typography>
                      <TextField
                        id="filled-size-small"
                        variant="outlined"
                        size="small"
                        className='textfield'
                        placeholder='Title'
                        name="title"
                        value={role.title}
                        onChange={(e) => { handleRole(e); setError({ title: "" }) }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                      />
                      {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>
                    <Box >
                      <Typography className=' fz-18 semibold' component='h4' variant='h4'>Permissions</Typography>
                      <Box className='mt-30'>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Yoga Quotes</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckQuote} name="addQuote" checked={checkQuote.addQuote} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckQuote} name="viewQuote" checked={checkQuote.listQuote} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckQuote} name="editQuote" checked={checkQuote.editQuote} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckQuote} name="deleteQuote" checked={checkQuote.deleteQuote} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Tags</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckTags} name="addTags" checked={checkTags.addTags} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckTags} name="viewTags" checked={checkTags.listTags} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckTags} name="editTags" checked={checkTags.editTags} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckTags} name="deleteTags" checked={checkTags.deleteTags} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Slider Settings</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckSettings} name="addSettings" checked={checkSettings.addSettings} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckSettings} name="viewSettings" checked={checkSettings.listSettings} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckSettings} name="editSettings" checked={checkSettings.editSettings} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckSettings} name="deleteSettings" checked={checkSettings.deleteSettings} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Teachers</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckTeacher} name="addTeacher" checked={checkTeacher.addTeacher} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckTeacher} name="viewTeacher" checked={checkTeacher.viewTeacher} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckTeacher} name="editTeacher" checked={checkTeacher.editTeacher} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckTeacher} name="deleteTeacher" checked={checkTeacher.deleteTeacher} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Yoga Legends</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckLegends} name="addLegends" checked={checkLegends.addLegends} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckLegends} name="viewLegends" checked={checkLegends.listLegends} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckLegends} name="editLegends" checked={checkLegends.editLegends} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckLegends} name="deleteLegends" checked={checkLegends.deleteLegends} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Teachers of great merit</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckMerit} name="addMerit" checked={checkMerit.addMerit} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckMerit} name="viewMerit" checked={checkMerit.listMerit} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckMerit} name="editMerit" checked={checkMerit.editMerit} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckMerit} name="deleteMerit" checked={checkMerit.deleteMerit} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Yoga School</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckSchool} name="addSchool" checked={checkSchool.addSchool} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckSchool} name="viewSchool" checked={checkSchool.listSchool} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckSchool} name="editSchool" checked={checkSchool.editSchool} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckSchool} name="deleteSchool" checked={checkSchool.deleteSchool} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Yoga Studio</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckStudio} name="addStudio" checked={checkStudio.addStudio} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckStudio} name="viewStudio" checked={checkStudio.listStudio} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckStudio} name="editStudio" checked={checkStudio.editStudio} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckStudio} name="deleteStudio" checked={checkStudio.deleteStudio} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Events</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckEvent} name="addEvent" checked={checkEvent.addEvent} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckEvent} name="listEvent" checked={checkEvent.listEvent} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckEvent} name="editEvent" checked={checkEvent.editEvent} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckEvent} name="deleteEvent" checked={checkEvent.deleteEvent} size='small' />} />
                          </Box>
                        </Box>

                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Course</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckCourse} name="addCourse" checked={checkCourse.addCourse} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckCourse} name="listCourse" checked={checkCourse.listCourse} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckCourse} name="editCourse" checked={checkCourse.editCourse} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckCourse} name="deleteCourse" checked={checkCourse.deleteCourse} size='small' />} />
                          </Box>
                        </Box>

                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Blogs</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckBlog} name="addBlog" checked={checkBlog.addBlog} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckBlog} name="listBlog" checked={checkBlog.listBlog} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckBlog} name="editBlog" checked={checkBlog.editBlog} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckBlog} name="deleteBlog" checked={checkBlog.deleteBlog} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Image</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckImage} name="addImage" checked={checkImage.addImage} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckImage} name="viewImage" checked={checkImage.listImage} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckImage} name="editImage" checked={checkImage.editImage} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckImage} name="deleteImage" checked={checkImage.deleteImage} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Video</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckVideo} name="addVideo" checked={checkVideo.addVideo} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckVideo} name="viewVideo" checked={checkVideo.listVideo} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckVideo} name="editVideo" checked={checkVideo.editVideo} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckVideo} name="deleteVideo" checked={checkVideo.deleteVideo} size='small' />} />
                          </Box>
                        </Box>

                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Category</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCategory} name="addCategory" checked={checkCategory.addCategory} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCategory} name="listCategory" checked={checkCategory.listCategory} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCategory} name="editCategory" checked={checkCategory.editCategory} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCategory} name="deleteCategory" checked={checkCategory.deleteCategory} size='small' />} />
                          </Box>
                        </Box>

                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>SubCategory</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckSubcategory} name="addSubcategory" checked={checkSubcategory.addSubcategory} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckSubcategory} name="listSubcategory" checked={checkSubcategory.listSubcategory} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckSubcategory} name="editSubcategory" checked={checkSubcategory.editSubcategory} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckSubcategory} name="deleteSubcategory" checked={checkSubcategory.deleteSubcategory} size='small' />} />
                          </Box>
                        </Box>

                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>About</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckAbout} name="addAbout" checked={checkAbout.addAbout} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckAbout} name="listAbout" checked={checkAbout.listAbout} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckAbout} name="editAbout" checked={checkAbout.editAbout} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckAbout} name="deleteAbout" checked={checkAbout.deleteAbout} size='small' />} />
                          </Box>
                        </Box>

                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Subscription</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckSubscription} name="addSubscription" checked={checkSubscription.addSubscription} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckSubscription} name="listSubscription" checked={checkSubscription.listSubscription} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckSubscription} name="editSubscription" checked={checkSubscription.editSubscription} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckSubscription} name="deleteSubscription" checked={checkSubscription.deleteSubscription} size='small' />} />
                          </Box>
                        </Box>

                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>News</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckNews} name="addNews" checked={checkNews.addNews} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckNews} name="viewNews" checked={checkNews.viewNews} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckNews} name="editNews" checked={checkNews.editNews} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckNews} name="deleteNews" checked={checkNews.deleteNews} size='small' />} />
                          </Box>
                        </Box>

                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Jobs</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckJobs} name="addjobs" checked={checkJobs.addjobs} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >List</Typography>} control={<Checkbox onChange={handleCheckJobs} name="viewJobs" checked={checkJobs.viewJobs} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckJobs} name="editjobs" checked={checkJobs.editjobs} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckJobs} name="deletejobs" checked={checkJobs.deletejobs} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Community</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckCommunity} name="addCommunity" checked={checkCommunity.addCommunity} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckCommunity} name="viewCommunity" checked={checkCommunity.viewCommunity} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckCommunity} name="editCommunity" checked={checkCommunity.editCommunity} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckCommunity} name="deleteCommunity" checked={checkCommunity.deleteCommunity} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Book Review</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckReview} name="addReview" checked={checkReview.addReview} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckReview} name="viewReview" checked={checkReview.viewReview} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckReview} name="editReview" checked={checkReview.editReview} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckReview} name="deleteReview" checked={checkReview.deleteReview} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Podcast</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckPodcast} name="addPodcast" checked={checkPodcast.addPodcast} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckPodcast} name="viewPodcast" checked={checkPodcast.viewPodcast} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckPodcast} name="editPodcast" checked={checkPodcast.editPodcast} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckPodcast} name="deletePodcast" checked={checkPodcast.deletePodcast} size='small' />} />
                          </Box>
                        </Box>
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Ad Management</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckAd} name="addAd" checked={checkAd.addAd} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckAd} name="viewAd" checked={checkAd.viewAd} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckAd} name="editAd" checked={checkAd.editAd} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckAd} name="deleteAd" checked={checkAd.deleteAd} size='small' />} />
                          </Box>
                        </Box>
                        {/* <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Newsletter Subscription</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckNewsletter} name="addNewsletter" checked={checkNewsletter.addNewsletter} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckNewsletter} name="viewNewsletter" checked={checkNewsletter.viewNewsletter} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckNewsletter} name="editNewsletter" checked={checkNewsletter.editNewsletter} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckNewsletter} name="deleteNewsletter" checked={checkNewsletter.deleteNewsletter} size='small' />} />
                          </Box>
                        </Box> */}
                        <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Users</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add</Typography>} control={<Checkbox onChange={handleCheckUsers} name="addUsers" checked={checkUsers.addUsers} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View</Typography>} control={<Checkbox onChange={handleCheckUsers} name="viewUsers" checked={checkUsers.viewUsers} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit</Typography>} control={<Checkbox onChange={handleCheckUsers} name="editUsers" checked={checkUsers.editUsers} size='small' />} />
                          </Box>
                          <Box sx={{ width: '250px' }}>
                            <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete</Typography>} control={<Checkbox onChange={handleCheckUsers} name="deleteUsers" checked={checkUsers.deleteUsers} size='small' />} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>

                   {
                                                          truebutton?
                                                          <Button  variant="contained" className='theme-btn mr-10'>Please wait...</Button>
                                                          :
                                                          <Button onClick={handleUpdate} variant="contained" className='theme-btn mr-10'>Update</Button>
                                                      }
                 
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box >
    </Box >
  )
}
export default Editrole

