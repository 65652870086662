import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Typography, Dialog, DialogTitle, DialogActions } from '@mui/material';
import axios from 'axios';
import { Add, Remove } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from 'moment';
import userlogo from './../../../../../assets/images/upcoming.png'
import { useSnackbar } from 'notistack';






export default function Comment_section(props) {

  
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem('userData'))
    const [content, setContent] = useState('');
    const [replydata, setReplydata] = React.useState('')
    const [replyChilddata, setReplyChilddata] = React.useState('')
    const [isVisible, setIsVisible] = useState(false);
    const [isReply, setReply] = useState(false);
    const [isChildReply, setChildReply] = useState(false);
    const [isVisible1, setIsVisible1] = useState(false);
    const [error, setError] = React.useState({ replymsg: '' })
    const [deleteopen, setDeleteOpen] = React.useState(false)
    
    const [deleteCommentId,setDelCommentId] =React.useState(null)
    const [deleteChildCommentId,setDelChildCommentId] =React.useState(null)



    // const [comments, setComments] = useState(props.lessonId.comments)
    const [comments, setComments] = useState([])

 

    React.useEffect(() => {
      getComments()
      // eslint-disable-next-line 
  }, [props.lessonId._id])

  const getComments = async () => {
      try {
          const response = await axios.get(process.env.REACT_APP_BASE_URL + `CourseComment/GetAdminReply/${props.lessonId.comments[0].courseId}/${props.lessonId.comments[0].chapterId}/${props.lessonId.comments[0].lessonId}`);
        
          setComments(response.data)
      } catch (error) {
          console.error(error);
      }
  }



    const showReplies = () => {
        setIsVisible(true);
    };

    const showAnotherReplies = () => {
        setIsVisible1(true)
    }

    const hideReplies = () => {
        setIsVisible(false);
    };


    const hideAnotherReplies = () => {
        setIsVisible1(false);
    };

    const handleReply = (data) => {
   
        setReplydata(data);
        setReply(!isReply);
    }

    const handleChildReply = (data) => {
   
      setReply(false);
      setReplyChilddata(data);
      setChildReply(!isChildReply);
  }

    const deleteComment = (data) => {
      setDelCommentId(data);
        setDeleteOpen(true);
    }

    const deleteChildComment = (data) => {
      setDelChildCommentId(data);
        setDeleteOpen(true);
    }

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDelCommentId(null);
    setDelChildCommentId(null)
}

      //Comment validation:
  const ReplyValidation = () => {
    if (!content) {
      // setError({ replycommentmsg: "Please enter the comment" });
      alert("please eneter the comment")
      return false;
    }
    return true;
  };


    const handleSend = (data) => {
    
    
      if (auth && auth._id) {
  
       
          let valid = ReplyValidation();
        
  
          
          if (valid) {
            let commentbody = {
              user_id: auth._id,
              content: content,
              parentId: data._id,
            };

  
            axios
              .post(
                process.env.REACT_APP_BASE_URL +
                  `CourseComment/replyToCommentsByAdmin/${data.courseId}/${data.chapterId}/${data.lessonId}`,
                commentbody
              )
              .then((res) => {
                
                getComments();
                setContent("");
                setReply(false)
                // setActiveReplyId(null);
              })
              .catch((error) => {
                console.log(error, "error in handleComments");
              });
          }
        
      } else {
        // setLoginAlert(true);
      }
    };

    const DeleteCommentInfo = (replyId) => {

      axios.put(process.env.REACT_APP_BASE_URL + 
        `CourseComment/DeleteCourseComments`,
        {
          commentId: replyId._id,
        }
      // `CourseComment/DeleteCommentsAndReplyByAdmin/${replyId.courseId}/${replyId.chapterId}/${replyId.lessonId}/${replyId._id}`
      )
          .then((res) => {
            getComments() // Replace with the actual function to refresh comments list if needed
            setDeleteOpen(false);
            setDelCommentId(null)
            enqueueSnackbar('Comment Deleted Successfully', { variant: 'success' });
              // Add any additional state updates or API calls you want to perform after deleting the comment
              // For example, you might want to refresh the list of comments or reset state
          })
          .catch((err) => {
              // Close the delete confirmation dialog if there was an error
              enqueueSnackbar('Error In deting Comment', { variant: 'error' });
              console.log(err, "error in DeleteCommentInfo");
          });
  }

  const DeleteSingleReply = (replyId) => {
  
    axios.put(process.env.REACT_APP_BASE_URL + 
      `CourseComment/DeleteCourseComments`,
        {
          commentId: replyId._id,
        }
      
      // `CourseComment/DeleteSingleReplyFromAdmin/${replyId._id}`
      )
        .then((res) => {
          getComments()
          setDelChildCommentId(null)
    setDeleteOpen(false);

            enqueueSnackbar('Reply Deleted Successfully', { variant: 'success' });
        })
        .catch((err) => {
            // Close the delete confirmation dialog if there was an error
            console.log(err, "error in DeleteSingleReply");
        });
}

  




    return (
      <Box className="comments_section my-30">
        <Grid container columnSpacing={2} rowSpacing={2}>
          {comments && comments.length > 0 ? (
            comments &&
            comments.map((data, i) => (
              <Grid item xs={12} sm={12} md={12} key={i}>
                <Box className="receiver ">
                  <Box className="comment_each ">
                    <Box className="flex-center ">
                      {data?.replies.length > 0 && (
                        // First Child first box ka count and show and hide
                        <Box className="collapseBtns">
                          <Button
                            onClick={showReplies}
                            className="text_primary"
                          >
                            <Add className="fz-16" />
                          </Button>
                          <Typography>{data?.replies.length}</Typography>
                          <Button
                            onClick={hideReplies}
                            className="text_primary"
                          >
                            <Remove className="fz-16" />
                          </Button>
                        </Box>
                      )}
                      {/* first box ka image */}
                      <Box className="">
                        {data.user?.image ? (
                          <img
                            src={
                              process.env.REACT_APP_BASE_URL +
                              `${data.user.image}`
                            }
                            height={50}
                            width={50}
                            style={{ borderRadius: "50%" }}
                            className="mr-10"
                            alt=''
                          />
                        ) : (
                          <AccountCircleIcon
                            className="mr-10"
                            fontSize="large"
                          />
                        )}
                      </Box>
                      {/* First child ka name time */}
                      <Box>
                        <Typography
                          component="span"
                          variant="span"
                          className="text_black fz-16 semibold mr-10"
                        >
                          {data.user && data.user?.username}
                        </Typography>
                        {auth._id === data.user._id && (
                          <Typography
                            component="span"
                            variant="span"
                            className="text_white fz-12 semibold mr-10 badge"
                          >
                            You
                          </Typography>
                        )}

                        <Typography
                          component="p"
                          variant="body1"
                          className="text_grey letter_sp fz-12 semibold "
                        >
                          {moment(data.createdAt).fromNow()}
                        </Typography>
                      </Box>
                    </Box>
                          {/* First child ka reply button */}
                          <Box className="flex-center ">
                          <Button className=" mr-10">
                                <Typography
                                  component="span"
                                  variant="span"
                                  className="text_secondary fz-12 semibold "
                                  onClick={()=>deleteComment(data)}
                                >
                                  <i class="fa-solid fa-trash"></i> Delete
                                </Typography>
                              </Button>
                    <Button className="" onClick={() => handleReply(data)}>
                      <Typography
                        component="span"
                        variant="span"
                        className="text_primary fz-12 semibold "
                      >
                        <i class="fa-solid fa-reply"></i> Reply
                      </Typography>
                    </Button>
                          </Box>
                          
                  </Box>



                  <Typography
                    component="span"
                    variant="body1"
                    className="text_grey letter_sp fz-14 fw-400 mt-20"
                  >
                    {data.content}
                  </Typography>

                  {/* First Reply Box to comment */}
                  {replydata._id === data._id && isReply && (
                    <Box className="comment_box">
                      {auth.image ? (
                        <img
                          src={process.env.REACT_APP_BASE_URL + `${auth.image}`}
                          height={50}
                          width={50}
                          style={{ borderRadius: "50%" }}
                          className="mr-10"
                          alt=''
                        />
                      ) : (
                        <AccountCircleIcon className="mr-10" fontSize="large" />
                      )}
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        placeholder="Add a comment"
                        multiline
                        rows={1}
                        variant="outlined"
                        className="textfield text_white"
                        value={content}
                        onChange={(e) => {
                          setContent(e.target.value);
                          setError({ replymsg: "" });
                        }}
                      />
                      <Button 
                      className=" theme-btn mr-10"
                      onClick={()=> handleSend(replydata)}
                      
                      >Reply</Button>
                      <Button
                        className=" cancel_btn"
                        onClick={() => setReply(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                  {error.replymsg ? (
                    <p style={{ color: "red", marginLeft: "90px" }}>
                      {error.replymsg}
                    </p>
                  ) : null}
                </Box>

                {isVisible===true &&
                  data.replies.map((reply) => {
                    return (
                      <>
                        <Box className="sender" key={reply._id}>
                          <Box className="comment_each ">
                            <Box className="flex-center ">
                              <Box className="collapseBtns">
                                <Button
                                  onClick={showAnotherReplies}
                                  className="text_primary"
                                >
                                  <Add className="fz-16" />
                                </Button>
                                <Typography>{reply.replies?.length}</Typography>
                                <Button
                                  onClick={hideAnotherReplies}
                                  className="text_primary"
                                >
                                  <Remove className="fz-16" />
                                </Button>
                              </Box>
                              <Box className="">
                                {reply.user?.image ? (
                                  <img
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      `${reply.user?.image}`
                                    }
                                    alt=''
                                    height={50}
                                    width={50}
                                    style={{ borderRadius: "50%" }}
                                    className="mr-10"
                                  />
                                ) : (
                                  <AccountCircleIcon
                                    className="mr-10"
                                    fontSize="large"
                                  />
                                )}
                              </Box>
                              <Box className="flex-center flex-wrap">
                             
                               <Typography
                                component="span"
                                variant="span"
                                className="text_black fz-16 semibold mr-10"
                              >
                               {reply.user.username}
                              </Typography>
                           

                                {auth._id === reply.user._id && <Typography
                                  component="span"
                                  variant="span"
                                  className="text_white fz-12 semibold mr-10 badge"
                                >
                                  You
                                </Typography>}
                                
                                
                                
                                <Typography
                                  component="span"
                                  variant="body1"
                                  className="text_grey letter_sp fz-12 semibold "
                                >
                                  {moment(reply.createdAt).fromNow()}
                                </Typography>
                              </Box>
                            </Box>


                            <Box className="flex-center ">
                              <Button className=" mr-10">
                                <Typography
                                  component="span"
                                  variant="span"
                                  className="text_secondary fz-12 semibold "
                                  onClick={()=>deleteChildComment(reply)}
                                >
                                  <i class="fa-solid fa-trash"></i> Delete
                                </Typography>
                              </Button>
                              <Button className="" onClick={() => handleChildReply(reply)}>
                                <Typography
                                  component="span"
                                  variant="span"
                                  className="text_primary fz-12 semibold "
                                >
                                  <i class="fa-solid fa-reply"></i> Reply
                                </Typography>
                              </Button>
                            </Box>

                            
                          </Box>
                          <Typography
                            component="span"
                            variant="body1"
                            className="text_grey letter_sp fz-14 fw-400 "
                          >
                            <Typography
                                        component="span"
                                        variant="body1"
                                        className="text_blue semibold fz-14"
                                      >
                                        @
                                        {data &&
                                          data.user?.username }
                                      </Typography>{" "}
                            {reply.content}
                          </Typography>

                                            {/* second Reply Box to comment */}
                  {replyChilddata._id === reply._id && isChildReply && (
                    <Box className="comment_box">
                      {auth.image ? (
                        <img
                          src={process.env.REACT_APP_BASE_URL + `${auth.image}`}
                          height={50}
                          width={50}
                          style={{ borderRadius: "50%" }}
                          className="mr-10"
                          alt=''
                        />
                      ) : (
                        <AccountCircleIcon className="mr-10" fontSize="large" />
                      )}
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        placeholder="Add a comment"
                        multiline
                        rows={1}
                        variant="outlined"
                        className="textfield text_white"
                        value={content}
                        onChange={(e) => {
                          setContent(e.target.value);
                          setError({ replymsg: "" });
                        }}
                      />
                      <Button 
                      className=" theme-btn mr-10"
                      onClick={()=> handleSend(replyChilddata)}
                      
                      >Reply</Button>
                      <Button
                        className=" cancel_btn"
                        onClick={() => setChildReply(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                  {error.replymsg ? (
                    <p style={{ color: "red", marginLeft: "90px" }}>
                      {error.replymsg}
                    </p>
                  ) : null}
                        </Box>

                        {isVisible1===true &&
                          reply &&
                          reply.replies.map((info, i) => (
                            <Box className="doubleReply_box">
                              <Box className="sender ">
                                <Box className="flex-center">
                                  <Box className="w100">
                                    <Box className="comment_each ">
                                      <Box className="flex-center ">
                                        <Box className="">
                                          <img src={userlogo} alt='' />
                                        </Box>
                                        <Box>
                                          <Typography
                                            component="span"
                                            variant="span"
                                            className="text_black fz-16 semibold mr-10"
                                          >
                                            {info.user?.username}
                                          </Typography>
                                          {auth._id === info.user._id && <Typography
                                  component="span"
                                  variant="span"
                                  className="text_white fz-12 semibold mr-10 badge"
                                >
                                  You
                                </Typography>}
                                          <Typography
                                            component="span"
                                            variant="body1"
                                            className="text_grey letter_sp fz-12 semibold "
                                          >
                                            {moment(info.createdAt).fromNow()}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      className="text_grey letter_sp fz-14 fw-400 "
                                    >
                                      <Typography
                                        component="span"
                                        variant="body1"
                                        className="text_blue semibold fz-14"
                                      >
                                        @
                                        {reply &&
                                          reply.user?.username }
                                      </Typography>{" "}
                                      {info.content}
                                    </Typography>
                                    
                                  </Box>
                                  <Box className="flex-center ">
                              <Button className=" mr-10">
                                <Typography
                                  component="span"
                                  variant="span"
                                  className="text_secondary fz-12 semibold "
                                  onClick={()=>deleteChildComment(reply)}
                                >
                                  <i class="fa-solid fa-trash"></i> Delete
                                </Typography>
                              </Button></Box>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                      </>
                    );
                  })}
              </Grid>
            ))
          ) : (
            <p className="w100 text-center semibold">No Comments found</p>
          )}
        </Grid>
        <Dialog
                open={deleteopen}
                keepMounted
                onClose={handleDeleteClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'
            >
                <DialogTitle>{"Would you like to delete this comment?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteClose} className='cancel_btn'>No</Button>
                    <Button onClick={() => DeleteCommentInfo(deleteCommentId)} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
            {deleteChildCommentId && <Dialog
                open={deleteopen}
                keepMounted
                onClose={handleDeleteClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'
            >
                <DialogTitle>{"Would you like to delete this reply?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteClose} className='cancel_btn'>No</Button>
                    <Button onClick={() => DeleteSingleReply(deleteChildCommentId)} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>}

      </Box>

      
    );
}
