
import {
  Box, Button, FormControl, FormControlLabel, Grid, Checkbox,
  OutlinedInput, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography
} from '@mui/material'
import React, { useState } from 'react'
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { styled } from '@mui/material/styles';
import { Editor } from "react-draft-wysiwyg";
import Card from '@mui/material/Card';
import InfoIcon from '@mui/icons-material/Info';
import MiniDrawer from '../../../components/drawer'
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import JSZip from 'jszip';

import Courseimage from '../../../assets/images/courseimage.png'

import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// import MiniDrawer from '../../../../../components/drawer';
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#f3714d',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#f3714d',
  },
});
const questions = [
  'Lorem Ipsum is simply dummy?',
  'Lorem Ipsum is simply dummy?',
  'Lorem Ipsum is simply dummy?'
]




const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    padding: 0;
    background: none;
  }
  & .MuiTooltip-arrow {
    color: #ffffff;
    &::before {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
  }
`;

// Custom styled info icon
const StyledInfoIcon = styled(InfoIcon)`
  transition: all 0.3s ease;
  color: #666;
  &:hover {
    color: #2196f3;
    transform: scale(1.1);
  }
`;
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}


export default function Service_Steppyform() {
  const [courseimageurl, setCourseImageUrl] = React.useState()
  const [courseimage, setCourseImage] = React.useState('')
  const [coursetitle, setCourseTitle] = React.useState('');
  const [shortdescription, setShortDescription] = React.useState('')
  const [modeofcourse, setModeofCourse] = React.useState('');
  const [isPublished, setIsPublished] = React.useState(false);



  const navigate = useNavigate('');



  // DigitalDownloadForm;
  const [openDigitalDownloadBox, setOpenDigitalDownloadBox] = React.useState(false);

  // Publish Box Open;
  const [openPublishBox, setOpenPublishCourse] = React.useState(false);
  const [publishDeatils, setPublishDetails] = React.useState(null);








  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  //Text Editor function

  const onEditorStateChange = (newEditorState) => {
    setInpError({ descriptionmsg: '' })
    setEditorState(newEditorState);
  };







  const auth = JSON.parse(localStorage.getItem("userData"));
  const [paymenttype, setPaymentType] = React.useState('free')
  const [payingAmount, setPayingAmount] = React.useState('');
  const [file, setFile] = React.useState([])
  const [digiDownLoadId, setDigiDownLoad] = React.useState("")

  const [input, setInput] = React.useState([{ type: '', name: '', size: 0 }]);
  const [fileDetails, setFileDetails] = React.useState([{ size: "", count: "" }])

  //teacher states 
  const [teachername, setteachername] = useState([])
  const [selecttedTeacher, setSelecttedTeacher] = React.useState("")
  const [teacher, setTeacher] = React.useState([])
  const headerlocal = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("token")
  }
  /// course  image handle 

  const handleImageChange = (e) => {

    const PreviewImage = URL.createObjectURL(e.target.files[0])
    const file = e.target.files[0];
    if (PreviewImage && file) {
      setCourseImageUrl(URL.createObjectURL(file));
      setCourseImage(file)
    }
  }
  // toogle open 
  const [isTextBoxOpen, setTextBoxOpen] = useState(true);


  const handleImageClick = () => {
    setTextBoxOpen(false);
  };

  const toggleTextBox = () => {

    setTextBoxOpen(!isTextBoxOpen)
  }


  //Validation:
  const [error, setError] = React.useState({ titlemsg: '', modemsg: '', onetimemsg: "" });
  const [inperror, setInpError] = React.useState({ descriptionmsg: '', filemsg: '', paymentmsg: "" });

  const validation = () => {
    if (!coursetitle) {
      setError({ titlemsg: 'This field is required' })
      return false
    }
    if (!modeofcourse) {
      setError({ modemsg: 'This field is required' })
      return false
    }

    return true

  }


  React.useEffect(() => {
    getTeacherval()
  }, [teachername])

  function getTeacherval() {
    var array = []
    const selectedTeacher = teacher.filter(content => teachername === content.username);

    selectedTeacher.forEach((item, i) => {
      var val = { user_id: item._id, username: item.username, email: item.email }

      Object.assign(item, val)
      array.push(val)
    })


    setSelecttedTeacher(selectedTeacher)



  }



  const handlechangeTeacher = (event, item) => {

    // setError({ ...error, ["userselect"]: '' })



    const {
      target: { value },
    } = event;

    if (value === teachername) {
      setteachername("")
        ;

    }
    else {

      setteachername(
        typeof value === 'string' ? value : value.split(','),

      );


    }
    getTeacherval()

  }


  React.useEffect(() => {
    getTeacher()
  }, [])

  const getTeacher = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersListForCourse`, { headers: headerlocal });
      setTeacher(response.data.teachers);


    } catch (error) {
      console.log(error.response, "error");
    }
  }




  const AddCreatecourse = () => {

    const validate = validation();

    if (validate) {



      let formdata = new FormData()
      formdata.append('image', courseimage ? courseimage : '')
      formdata.append("course_title", coursetitle)
      formdata.append("shortDescription", shortdescription)
      formdata.append("mode_of_course", modeofcourse)
      formdata.append("Createdby[]", auth._id)
      formdata.append("usertype", 'Admin')
      console.log(courseimage,"courseimage");
      

      axios.post(process.env.REACT_APP_BASE_URL + 'Course1/AddingCourse', formdata)
        .then((res) => {




          if (res.data.creatcourse.mode_of_course === "digitaldownload") {
            enqueueSnackbar("'Digital Download Created Successfully'", { variant: "success" })
            setDigiDownLoad(res.data.creatcourse._id);
            setOpenDigitalDownloadBox(true)

            return;
          }

          if (res.data.creatcourse) {



            navigate(`/addcourse/courses/details/${res.data.creatcourse._id}`)

          }

          enqueueSnackbar('Course Created Successfully', { variant: 'success' })
          setCourseImageUrl('');
          setCourseImage('');
          setCourseTitle('');

        })
        .catch((err) => {
          console.log("erro while add the data", err);
        })


    }

  }





  const handleFileChange = async (e) => {

    const selectedFiles = Array.from(e.target.files); // Convert FileList to an array



    if (selectedFiles.length > 0) {
      const zip = new JSZip();
      const fileDetails = []; // Array to hold file details for preview
      let totalSizeInBytes = 0; // Variable to hold total size in bytes

      for (const selectedFile of selectedFiles) {
        // Validate file type if needed
        const extension = selectedFile.name.split('.').pop().toLowerCase();
        const fullName = selectedFile.name;
        const sizeInMB = (selectedFile.size / (1024 * 1024)).toFixed(2); // Converts bytes to MB

        // Add the file to the zip
        zip.file(fullName, selectedFile);

        // Push the file details for preview
        fileDetails.push({ type: extension, name: fullName, size: sizeInMB });

        // Accumulate the total size
        totalSizeInBytes += selectedFile.size;
      }


      // Calculate total size in MB
      const totalSizeInMB = (totalSizeInBytes / (1024 * 1024)).toFixed(2);

      // Add total size to the fileDetails array (optional)
      setFileDetails([{ size: totalSizeInMB, count: fileDetails.length }])



      // Generate the zip file as a Blob
      const zipBlob = await zip.generateAsync({ type: 'blob' });

      // Create a new file from the Blob
      const zipFile = new File([zipBlob], 'files.zip', { type: 'application/zip' });

      // Update the input state with the file information
      setInput(fileDetails); // Update to show details of all files
      setFile(zipFile); // Set the zip file for uploading
    }
  };



  //payment validation checking only number
  const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };




  const validateEditorContent = () => {
    const content = editorState.getCurrentContent();
    return !content.hasText(); // Returns true if there's no text
  };

  const DigiValidation = () => {

    if (modeofcourse === "digitaldownload") {

      if (validateEditorContent()) {
        setInpError({ descriptionmsg: "This field is required" })
        return false;
      }

      if (!input[0].name) {

        setInpError({ filemsg: "This field is required" })
        return false;
      }
      if (paymenttype === "onetimepayment") {


        if (!payingAmount) {
          setInpError({ paymentmsg: "This field is required" })
          return false;
        }
      }
    }
    return true;
  }


  const getEditorContent = () => {
    const contentState = editorState?.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const plainText = contentState.getPlainText();
    return { rawContent, plainText };

  };



  //handle digitaldownlad 

  const DigiDownloadCreateCourse = async () => {

    if (DigiValidation()) {

      const { rawContent, plainText } = getEditorContent();




      const url = `${process.env.REACT_APP_BASE_URL}Course1/addPaymentPricingforDigital/${digiDownLoadId}`;
      let formdata = new FormData()


      formdata.append('rawdescription', JSON.stringify(rawContent))
      formdata.append("plaindescription", plainText)
      formdata.append("course_paymenttype", paymenttype)
      if (file) {
        formdata.append(`files`, file);
        formdata.append(`name`, input[0].name);
        formdata.append(`size`, input[0].size);
      }

      if (paymenttype !== "free") formdata.append("amountOneTime", payingAmount)
      formdata.append("currency", "USD")
      // if(file) formdata.append("files", file);




      try {
        const response = await axios.put(url, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          }
        })

        enqueueSnackbar('Data Added Successfully', { variant: 'success' })
        setOpenDigitalDownloadBox(false)
        setPublishDetails(response.data.course)
        setIsPublished(response.data.course.publishCourse)
        setOpenPublishCourse(true)




      }
      catch (error) {
        console.error("Error uploding course Digital Download Data", error);
        enqueueSnackbar('Error uploading Digital Download Data', { variant: 'error' })

      }


    }
  }

  const handlePublishToggle = async (event) => {
    const newPublishStatus = true;
  



    try {
     await axios.put(
        process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${digiDownLoadId}`,
        { publishCourse: true, publishername: "Admin", teacher: selecttedTeacher[0]._id }
      );
      setIsPublished(newPublishStatus);



      if (newPublishStatus) {
        enqueueSnackbar('Course Published Successfully', { variant: 'success' });




      } else {
        enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
      }

      // Optionally, you can refresh course details after updating
    } catch (error) {
      console.error("Error updating publish status:", error);
      enqueueSnackbar('Error while Publishing Digital content', { variant: 'error' });


    }
  };

  const handleDraftorBack = () => {

    navigate('/course')
  }

  const handleUnPublishToggle = async (event) => {
  };

  return (
    <Box className="">
      {/*  */}
      <MiniDrawer menuOrder={4} submenuOrder={13} />
      <Box component="section" className="contentWraper">
        <Box className="headingBox">
          <Typography
            component="h4"
            variant="h4"
            className="Submenu-page-heading formTitle"
          >
            Course
          </Typography>
        </Box>

        <Box className="course firstScreen whiteContainer">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="flex-center justify-content-center"
            >
              <Box className="h-100 p-20 leftContent">
                <Box>
                  <Typography component="h5" variant="h5" className="semibold">
                    Describe what you'd like to teach and we'll craft your
                    course
                  </Typography>
                  <Typography component="div" variant="body1" className="mt-30">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry
                  </Typography>
                </Box>

                <List className="w100 my-30">
                  {questions.map((question, index) => (
                    <ListItem className="p-0">
                      <ListItemText>
                        <Typography component="span" className="semibold">{`${index + 1
                          }. ${question}`}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
                <Typography component="div" variant="body1" className="fz-14">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </Typography>
              </Box>
            </Grid>

            {openDigitalDownloadBox && (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="flex-center justify-content-center rightContent_grid"
              >
                <Box className="d-flex justify-content-center p-20 flex-col">
                  <Card className="mt-20 p-20">
                    <Typography
                      component="div"
                      className="label"
                      style={{ marginRight: 430 }}
                    >
                      Description{" "}
                      <Typography component="span" className="star">
                        {" "}
                        *
                      </Typography>
                    </Typography>

                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                      editorStyle={{ minHeight: 300 }}
                    />
                    {inperror.descriptionmsg && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {inperror.descriptionmsg}
                      </p>
                    )}
                  </Card>

                  <Card
                    sx={{
                      display: "flex",

                      gap: 2,
                    }}
                    className="mt-20 p-20"
                  >
                    {/* Left section (1 part) */}
                    <Box sx={{ width: "25%" }}>
                      <Card sx={{ p: 2 }}>
                        <Box>
                          <Typography component="div" className="label">
                            Update File{" "}
                            <Typography
                              component="span"
                              sx={{ color: "error.main" }}
                            >
                              *
                            </Typography>
                          </Typography>

                          <Button
                            className="upload_btn"
                            variant="contained"
                            component="label"
                            fullWidth
                            sx={{ mt: 1 }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadOutlinedIcon
                                sx={{ fontSize: 18, mr: 0.5 }}
                              />
                              Update File
                            </Box>
                            <input
                              // accept={row.type === "Video" ? "video/*" :
                              // row.type === "Audio" ? "audio/*" : "file/*"}
                              type="file"
                              accept="*/*"
                              onChange={(e) => {
                                handleFileChange(e);
                                setInpError({ filemsg: "" });
                              }}
                              multiple
                              hidden

                            />
                          </Button>
                        </Box>
                        {inperror.filemsg && (
                          <Typography
                            sx={{ color: "error.main", fontSize: 13, mt: 1 }}
                          >
                            {inperror.filemsg}
                          </Typography>
                        )}
                      </Card>
                    </Box>

                    {/* Right section (3 parts) */}
                    <Box sx={{ width: "75%" }}>
                      <Card sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                          {!fileDetails[0].size &&
                            !file.length &&
                            !input[0].type && (
                              <Grid
                                item
                                xs={6}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  minHeight: "100px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minHeight: "100px",
                                  }}
                                >
                                  <Typography variant="body2">
                                    File Details
                                  </Typography>
                                </Box>
                              </Grid>
                            )}

                          {/* Left column */}
                          {fileDetails[0].size && (
                            <Grid item xs={6}>
                              <Box>
                                <Typography component="div" className="label">
                                  ZIP Name
                                </Typography>
                                <Typography
                                  component="div"
                                  sx={{ color: "error.main" }}
                                >
                                  {file.name}
                                </Typography>

                                <Typography
                                  component="div"
                                  className="label"
                                  sx={{ mt: 2 }}
                                >
                                  Total Size
                                </Typography>
                                <Typography
                                  component="div"
                                  sx={{ color: "error.main" }}
                                >
                                  {fileDetails[0]?.size &&
                                    `${fileDetails[0].size} MB`}
                                </Typography>
                              </Box>
                            </Grid>
                          )}

                          {/* Right column */}

                          <Grid item xs={6}>
                            {fileDetails[0].size && input[0].type && (
                              <Box>
                                <Typography component="div" className="label">
                                  Additional Details
                                </Typography>
                                <Typography variant="body2">
                                  List of Files:
                                </Typography>
                                <ul>
                                  {input.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                  ))}
                                </ul>
                                <Typography variant="body2">
                                  Count Of Files: {fileDetails[0].count}
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  </Card>

                  <Card className="mt-20 p-20">
                    <Box className="mb-20 ">
                      <Typography
                        component="div"
                        className="label"
                        style={{ marginRight: 430 }}
                      >
                        Choose Payment Mode{" "}
                        <Typography component="span" className="star">
                          {" "}
                          *
                        </Typography>
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="demo-customized-radios"
                        name="payment_type"
                        onChange={(e) => {
                          setPaymentType(e.target.value);
                          setInpError({ paymentmsg: "" });
                        }}
                        value={paymenttype}
                      >
                        <FormControlLabel
                          value="free"
                          className="radio-btns"
                          control={<BpRadio />}
                          label={
                            <Typography className="fz-14">Free</Typography>
                          }
                        />

                        <FormControlLabel
                          value="onetimepayment"
                          className="radio-btns"
                          control={<BpRadio />}
                          label={
                            <Typography className="fz-14">Paid</Typography>
                          }
                        />
                      </RadioGroup>
                    </Box>

                    {paymenttype === "onetimepayment" && (
                      <Grid
                        container
                        columnSpacing={2}
                        rowSpacing={2}
                        className="mb-20"
                      >
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <FormControl variant="standard" fullWidth>
                            <Typography component="div" className="label">
                              Amount
                              <Typography component="span" className="star">
                                {" "}
                                *
                              </Typography>
                            </Typography>
                            <TextField
                              id="filled-size-small"
                              variant="outlined"
                              size="small"
                              className="textfield"
                              placeholder="Amount"
                              name="amount"
                              onInput={(e) => onlyNumbers(e)}
                              value={"$" + payingAmount}
                              // disabled={OTPayment}
                              sx={{
                                marginBottom: "5px",
                              }}
                              onChange={(e) => {
                                setPayingAmount(e.target.value);
                                setInpError({ paymentmsg: "" });
                              }}
                            />
                            {inperror.paymentmsg && (
                              <p style={{ color: "red" }} className="fz-13 m-0">
                                {inperror.paymentmsg}
                              </p>
                            )}
                          </FormControl>
                        </Grid>

                        {/* {
						
						OTPayment ? <Button className='theme-btn' sx={{width:"95%",margin:"auto"}} onClick={() => handlePaidEditDetails(paymenttype)} 
						>Update</Button> :
						    <Button className='theme-btn' sx={{width:"95%",margin:"auto"}} onClick={() => handlePaidDetails(paymenttype)}>Submit</Button>
							} */}
                      </Grid>
                    )}
                  </Card>

                  <Box className="text-right mt-30">
                    {/* <Button className="theme-btn" onClick={() => navigate('/teachers/details/courses/details')}>Create</Button> */}

                    <Button
                      className="theme-btn"
                      onClick={DigiDownloadCreateCourse}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}

            {openPublishBox && (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="flex-center justify-content-center rightContent_grid"
              >
                <Box className="d-flex justify-content-center p-20 flex-col">
                  <Card style={{ width: "410px" }} className="mt-20 p-20">
                    <Box>
                      <Typography className="fz-16 text_black semibold">
                        Course Name
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {publishDeatils?.course_title}
                      </Typography>

                      <Typography className="fz-16 text_black semibold">
                        Course Description
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {/* {trimmedDescription} */}
                      </Typography>

                      <Typography className="fz-16 text_black semibold">
                        File Name
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {publishDeatils?.files[0]?.name
                          ? publishDeatils.files[0].name
                          : "NA"}
                      </Typography>

                      <Typography className="fz-16 text_black semibold">
                        Payment Type
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {publishDeatils
                          ? publishDeatils.course_paymenttype
                          : "NA"}
                      </Typography>

                      <Typography className="fz-16 text_black semibold">
                        Course Fees
                      </Typography>
                      <Typography
                        component="h6"
                        variant="h6"
                        className="fz-16 text_black mb-10"
                      >
                        {publishDeatils?.amountOneTime
                          ? `${publishDeatils.currency === "USD" ? `$` : ""} ${publishDeatils.amountOneTime
                          } `
                          : "free"}
                      </Typography>

                      <Box className="mb-20">
                        <Typography component="div" className="label">
                          Select Teacher
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-single-checkbox-label"
                            id="demo-single-checkbox"
                            value={teachername}
                            onChange={(e) => handlechangeTeacher(e)}
                            input={<OutlinedInput />}
                            // renderValue={(selected) => {
                            // const selectedTeacher = teacher.find(
                            // 	(content) => content.username === selected
                            // );
                            // return selectedTeacher ? selectedTeacher.username : 'Any Teacher';
                            // }}
                            MenuProps={MenuProps} // Define or import MenuProps if needed
                          >
                            {teacher && teacher.length > 0 ? (
                              teacher.map((name) => (
                                <MenuItem key={name._id} value={name.username}>
                                  <Checkbox
                                    checked={teachername === name.username}
                                  />
                                  <ListItemText primary={name.username} />
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>No teacher found</MenuItem>
                            )}
                          </Select>
                       
                        </FormControl>
                      </Box>

                      {!isPublished ? (
                        <Button
                          variant="contained"
                          className="theme-btn"
                          onClick={(e) => handlePublishToggle(e)}
                        >
                          Publish Course
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          className="theme-btn"
                          onClick={(e) => handleUnPublishToggle(e)}
                        >
                          UnPublish Course
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        className="theme-btn"
                        onClick={() => handleDraftorBack()}
                        style={{ marginLeft: "40px" }}
                      >
                        {isPublished ? "Back" : "Draft"}
                      </Button>

                    </Box>
                  </Card>
                </Box>
              </Grid>
            )}

            {!openDigitalDownloadBox && !openPublishBox && (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="flex-center justify-content-center rightContent_grid"
              >
                <Box className="d-flex justify-content-center p-20 flex-col">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="fw-bold mb-30"
                  >
                    Create Course
                  </Typography>

                  <Box>
                    <Typography component="body2">Course Image</Typography>

                    <StyledTooltip
                      open={isTextBoxOpen}
                      onClose={toggleTextBox}
                      title={
                        <Box
                          sx={{
                            bgcolor: "#ffffff",
                            p: 2,
                            borderRadius: "8px",
                            fontSize: "0.875rem",
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                            border: "1px solid rgba(0, 0, 0, 0.05)",
                            maxWidth: "220px",
                            animation: "fadeIn 0.3s ease-in-out",
                            position: "relative",
                            "&:hover": {
                              boxShadow: "0 6px 24px rgba(0, 0, 0, 0.15)",
                            },
                            "@keyframes fadeIn": {
                              from: {
                                opacity: 0,
                                transform: "translateY(-10px)",
                              },
                              to: {
                                opacity: 1,
                                transform: "translateY(0)",
                              },
                            },
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              color: "#333",
                              fontWeight: 500,
                              lineHeight: 1.5,
                              "&::before": {
                                content: '""',
                                position: "absolute",
                                left: -2,
                                top: 0,
                                width: "3px",
                                height: "100%",
                                background:
                                  "linear-gradient(45deg, #2196f3, #64b5f6)",
                                borderRadius: "4px",
                              },
                            }}
                          >
                            <Box sx={{ pl: 2 }}>
                              Click on the image to add your course image
                            </Box>
                          </Box>
                        </Box>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton
                        onClick={toggleTextBox}
                        sx={{
                          padding: "8px",
                          marginBottom: "5px",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            background: "rgba(33, 150, 243, 0.08)",
                          },
                        }}
                      >
                        <StyledInfoIcon fontSize="small" />
                      </IconButton>
                    </StyledTooltip>
                  </Box>

                  <Box sx={{ marginLeft: "150px" }}>
                    <label>
                      <Box
                        sx={{
                          width: 150,
                          height: 150,
                          borderRadius: "50%",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          border: "2px solid #ccc",
                          position: "relative",
                          "&:hover .overlay": {
                            opacity: 0.7,
                          },
                        }}
                        onClick={handleImageClick}
                      >
                        <img
                          src={courseimageurl || courseimage || Courseimage}
                          width={150}
                          height={150}
                          className="borderR50"
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                            position: "relative",
                            zIndex: 1,
                          }}
                          alt="Course"
                        />
                        <Box
                          className="overlay"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            transition: "opacity 0.3s ease",
                            opacity: 0.5,
                            zIndex: 2,
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: "white",
                              fontSize: "40px",
                              filter:
                                "drop-shadow(0px 2px 4px rgba(0,0,0,0.3))",
                            }}
                          />
                        </Box>
                        <input
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={handleImageChange}
                          hidden
                          type="file"
                        />
                      </Box>
                    </label>
                  </Box>
                  {/* {isTextBoxOpen && (
          <Box style={{ marginTop: 10 }}>
             <Typography component="div" >click Here to Add Image</Typography>
          </Box>
        )} */}

                  <FormControl fullWidth variant="standard" className="mb-20">
                    <Typography component="div" className="label">
                      Course Title{" "}
                      <Typography component="span" className="star">
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      id="filled-size-small"
                      variant="outlined"
                      size="small"
                      className="textfield"
                      name="coursetitle"
                      value={coursetitle}
                      onChange={(e) => {
                        setCourseTitle(e.target.value);
                        setError({ titlemsg: "" });
                      }}
                    />
                    {error.titlemsg && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.titlemsg}
                      </p>
                    )}
                  </FormControl>

                  <FormControl variant="standard" fullWidth>
                    <Typography component="div" className="label">
                      Description
                    </Typography>
                    <textarea
                      // placeholder='Long Description '
                      className="textarea"
                      rows={4}
                      cols={40}
                      value={shortdescription}
                      onChange={(e) => setShortDescription(e.target.value)}
                    />
                  </FormControl>

                  <FormControl variant="standard" fullWidth className="mt-20">
                    <Typography component="div" className="label">
                      Course Mode{" "}
                      <Typography component="span" className="star">
                        *
                      </Typography>
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-customized-radios"
                      name="coursemode"
                      value={modeofcourse}
                      onChange={(e) => {
                        setModeofCourse(e.target.value);
                        setError({ modemsg: "" });
                      }}
                    >
                      <FormControlLabel
                        value="online"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14 semibold">
                            Online
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="prerecorded"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14 semibold">
                            Pre Recorded
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        value="digitaldownload"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14 semibold">
                            Digital Download
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        value="challenges"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14 semibold">
                            Challenges
                          </Typography>
                        }
                      />

                    </RadioGroup>
                    {error.modemsg && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.modemsg}
                      </p>
                    )}
                  </FormControl>

                  <Box className="text-right mt-30">
                    {/* <Button className="theme-btn" onClick={() => navigate('/teachers/details/courses/details')}>Create</Button> */}
                    <Button
                      className="theme-btn"
                      onClick={() => AddCreatecourse()}
                    >
                      Create
                    </Button>
                  </Box>

                  {/* -------------------------------***************------------------------ */}

                  {/* <Card sx={{ maxWidth: 345 }} className='p-20'>
						<Typography component='div' variant="body2" className='semibold fz-18 mb-10'>
							Course Title
						</Typography>
						<CardMedia
							component="img"
							height="194"
							image={courseImg}
							alt="Paella dish"
						/>
						<CardContent className='p-0 my-10'>
							<Typography component='div' variant="body2" className='semibold fz-18 mb-10'>
								Course Description
							</Typography>
							<Typography variant="body2" sx={{ color: 'text.secondary' }}>
								This impressive paella is a perfect party dish and a fun meal to cook
								together with your guests. Add 1 cup of frozen peas along with the mussels,
								if you like.
							</Typography>
						</CardContent>
						<Typography component='div' variant="body2" className='semibold fz-18 mb-20'>
							Introduction
						</Typography>
						<CardActions disableSpacing className='p-0 flex-col'>

							{categories.map((category, i) =>
								<Box className="w100 flex-between">
									<Typography variant="body2" className='text_black semibold' >
										{category}
									</Typography>
									<ExpandMore
										// expand={expandedStates[i]}
										// onClick={() => handleExpandClick(i)}
										aria-expanded={expandedStates[i]}
										aria-label="show more"
									>
										<KeyboardArrowRight className='text_black' />
									</ExpandMore>
								</Box>
							)}

						</CardActions> 

						
							{categories.map((category, i) => (
							<Collapse key={i} in={expandedStates[i]} timeout="auto" unmountOnExit>
								<CardContent>
									<Typography >Lorem Ipsum is simply dummy</Typography>
								</CardContent>
							</Collapse>
						))} 
							</Card> */}

                  {/* -------------------------------***************------------------------ */}
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );

}
