import * as React from 'react';
import Box from '@mui/material/Box';
import {  Typography, Grid, Button, TextField, InputAdornment, FormControl, Card, Menu } from '@mui/material';
import { CloseOutlined,  SearchOutlined} from '@mui/icons-material';

import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DOMPurify from 'dompurify';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import CardContent from '@mui/material/CardContent';
import IconButton, {  } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
// const IOSSwitch = styled((props) => (
//     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//     width: 40,
//     height: 20,
//     padding: 0,
//     '& .MuiSwitch-switchBase': {
//         padding: 0,
//         margin: 0,
//         transitionDuration: '300ms',
//         '&.Mui-checked': {
//             transform: 'translateX(20px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//                 opacity: 1,
//                 border: 0,
//             },
//             '&.Mui-disabled + .MuiSwitch-track': {
//                 opacity: 0.5,
//             },
//         },
//         '&.Mui-focusVisible .MuiSwitch-thumb': {
//             color: '#33cf4d',
//             border: '6px solid #fff',
//         },
//         '&.Mui-disabled .MuiSwitch-thumb': {
//             color:
//                 theme.palette.mode === 'light'
//                     ? theme.palette.grey[100]
//                     : theme.palette.grey[600],
//         },
//         '&.Mui-disabled + .MuiSwitch-track': {
//             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxSizing: 'border-box',
//         width: 18.5,
//         height: 18.5,
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 18.5 / 2,
//         backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
//         opacity: 1,
//         transition: theme.transitions.create(['background-color'], {
//             duration: 500,
//         }),
//     },
// }));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
	const [truebutton, setTruebutton] = React.useState(false)
    const menuopen = Boolean(anchorEl);
    const [alldata, setAlldata] = React.useState('');
    const [deleteid, setDeleteId] = React.useState('');
    const [catid, setCatId] = React.useState('all');
    const role = JSON.parse(localStorage.getItem("roledata"))
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // React.useEffect(() => {
    //     getCategory()
    // }, [])

    // const getCategory = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategory?size=100&page=0&order=asc`, { headers: headerlocal })
    //         .then((res) => {
    //             setCat(res.data.category)
    //         }).catch((error) => {
    //             console.log(error.response, "error");
    //         })
    // }

    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };



    React.useEffect(() => {
        getAboutDetails()
    }, [rowsPerPage, page, search, catid])

    const getAboutDetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `AboutUs/GetAboutUs?size=${rowsPerPage}&page=${page}&search=${search}&Category_id=${catid}`, { headers: headerlocal })
            .then((res) => {
                setNewdata(res.data.aboutUs)
                setTotal(res.data.totalcount)
            }).catch((error) => {
            })
    }


    const handleDeleteService = async () => {
        setTruebutton(true)
        await axios.put(process.env.REACT_APP_BASE_URL + `AboutUs/deleteAboutUs/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                setTruebutton(false)
                enqueueSnackbar("Yoga Retreat deleted successfully", { variant: 'success' })
                getAboutDetails()
                handleClose()
            })
            .catch((error) => {
                setTruebutton(false)
                console.log(error);
            })
    }


    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };


    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/editabout", { state: val })
    }

    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)

    }

    const handleRemove = () => {
        setCatId('all')
        setSearch('')
        setPage(0)
    }


    const sanitizedContent = DOMPurify.sanitize(alldata.description, { ALLOWED_TAGS: ['img'] });

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    return (
        <>
            <Box className=' p-20'>
                <Box className='flex-center flex-wrap mb-20 '>
                <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={search}
                                className='textfield search'
                                onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search!== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Box >
                    <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                        {newdata && newdata.length > 0 ? newdata.map((data, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginTop: 2 }}>
                                    <Card className="cardGrid" >
                             <IconButton aria-label="more" id="long-button"
                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                            aria-expanded={menuopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, data)}
                                            className='vertical_menu'>
                                            <MoreVertIcon />
                                        </IconButton>
                        
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={handleClose_menu}
                                            PaperProps={{
                                                style: {
                                                    width: '15ch',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>
                                            { role.UserRoles.About.editAbout &&      <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>}
                                            { role.UserRoles.About.deleteAbout &&     <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>}
                                        </Menu>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div className="">
                                                <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${data.image}`} alt=''/>
                                            </div>
                                        </Grid>

                                        <CardContent>
                                            <Box className='mb-10 d-flex align-items-center justify-content-between' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography noWrap className='semibold title' variant="h6" component="h6">{data.title}</Typography>
                                                {/* <Typography component='div' variant='body1' className='fz-14' style={{ display: 'flex', alignItems: 'center' }}><Verified className='fz-18' style={{ color: '#1492fd' }} /></Typography> */}
                                            </Box>


                                            {data && data.description ?
                                                <Typography component='div' variant='body1' className='fz-14 ' >{data && data.description.slice(0, 50) + "..."}</Typography> :
                                                <Typography component='div' variant='body1' className='fz-14 '>There is no description</Typography>}

                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p className='text-center semibold'>No data found</p></Grid>}
                    </Grid>
                    <Dialog open={open} keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        className='custom_modal'>
                        <DialogTitle>Would you like to delete AboutUs?</DialogTitle>
                        {truebutton === false ?
                        <DialogActions>
                            <Button onClick={handleClose} className='cancel_btn'>No</Button>
                            <Button onClick={handleDeleteService} className='theme-btn'>Yes</Button>
                        </DialogActions>
                        : truebutton === true &&
                        <DialogActions>
                            <Button className='theme-btn'>Please Wait</Button>
                        </DialogActions>
}
                    </Dialog>
                </Box>
                <TablePagination
                    rowsPerPageOptions={[8, 12, 16, 20, 24]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Box>

            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    About Us
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="">
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper "
                                    slidesPerView={1}
                                    pagination={{
                                        clickable: true,
                                    }}
                                >
                                    {alldata && alldata.allimages && alldata.allimages?.length > 0 && alldata.allimages.map((tdata, i) => (
                                        <SwiperSlide> <img width={'100%'} height={300} className='objfit_cover' src={process.env.REACT_APP_BASE_URL + `${tdata}`} alt='' /></SwiperSlide>
                                    )
                                    )}
                                </Swiper>
                            </div>

                        </Grid>

                        <Grid style={{ marginTop: '-200px' }} item xs={12} sm={12} md={6} lg={6}>

                            <Box className=''>
                                <Typography component='span' variant='body1' className='fz-15 semibold'>Service: </Typography>
                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.course_name}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid>
                        <div className="">
                            <img width={'100%'} height={300} className='objfit_cover' src={process.env.REACT_APP_BASE_URL + `${alldata.image}`} alt=''/>
                        </div>
                    </Grid>
                    <Typography component='div' variant='body1' className='fz-14 semibold my-10'>Description: </Typography>
                    {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                        : <Typography className='p18_tag' >There is no description</Typography>}

                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}
