import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField,Typography} from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom';


export default function EditCategory() {
    let location = useLocation()
    const tokendata = {
        'headers' :{
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }

 


    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    var editId = location.state.data._id
    const [category, setCategory] = React.useState({ title: location.state.data.category_title, description: location.state.data.description })
    const [error, setError] = React.useState({ title: "", description: ""})

    let name, value;
    const handleCategory = (e) => {
        name = e.target.name;
        value = e.target.value
        setCategory({ ...category, [name]: value })
    }

    
    
    const Validator = () => {
        if (!category.title && !category.description ) {
            setError({ title: "This field is required.", description: "This field is required." })
            return false
        }
        if (!category.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (!category.description) {
            setError({ description: "This field is required." })
            return false
        }
        return true
    }

    const handleUpdate = async () => {
        var Valid = Validator()
        if (Valid) {
            let body = {
                createdby:location.state.data.createdby,
                category_title: category.title.toString(),
                description: category.description.toString(),
            }
            await axios.put(process.env.REACT_APP_BASE_URL + `Category/EditCategory/${editId}`,body, tokendata )
                .then((res) => {
                    navigate('/category')
                    enqueueSnackbar("Course updated successfully", { variant: 'success' })
                })
                .catch((error) => {
                    enqueueSnackbar("Something went wrong.", { variant: 'error' })
                    console.log(error.response, "error");
                })
        }
    } 

    return (
        <Box className=''>
            <MiniDrawer menuOrder={8} submenuOrder={16} />
            <Box component="section" className="contentWraper">

                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit Category</Typography><br></br>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                               
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>

                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                name="title"
                                                value={category.title}
                                                onChange={(e) => { handleCategory(e); setError({ title: "" }) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description</Typography>
                                            <textarea
                                                className='textarea'
                                                placeholder='Description'
                                                name="description"
                                                value={category.description}
                                                onChange={(e) => handleCategory(e)}
                                                rows={4}
                                                cols={40}
                                                style={{ resize: 'none' }}  />
                                           
                                        </FormControl>
                                    </Grid>

                                </Grid>

 
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleUpdate} variant="contained" className='theme-btn '>Update</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}

