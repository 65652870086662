import Resizer from "react-image-file-resizer";

export const processImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      2000, // Target width
      500,  // Target height
      "JPEG",
      100,  // Quality
      0,    // Rotation
      (resizedUri) => {
        const img = new Image();
        img.src = resizedUri;
        img.onload = () => {
          const finalImage = createBlurredCanvas(img, img.width, img.height);
          resolve(finalImage);
        };
      },
      "base64"
    );
  });

  const createBlurredCanvas = (image, width, height) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
  
    // Set canvas size
    canvas.width = 2000;
    canvas.height = 500;
  
    // Draw blurred background (covering the entire canvas)
    ctx.drawImage(image, 0, 0, width, height);
  
    ctx.filter = "blur(30px)"; // Apply blur
    ctx.drawImage(canvas, 0, 0, 2000, 500); // Redraw blurred background
  
    ctx.filter = "none"; // Remove blur for main image
  
    // Maintain aspect ratio for the main image
    const aspectRatio = width / height;
    let newWidth = 2000;
    let newHeight = 500;
  
    if (aspectRatio > 2000 / 500) {
      newHeight = 500;
      newWidth = Math.floor(newHeight * aspectRatio);
    } else {
      newWidth = 2000;
      newHeight = Math.floor(newWidth / aspectRatio);
    }
  
    // Center the main image on top of the blurred background
    const x = (2000 - newWidth) / 2;
    const y = (500 - newHeight) / 2;
    ctx.drawImage(image, x, y, newWidth, newHeight);
  
    return canvas.toDataURL("image/jpeg");
  };
  
