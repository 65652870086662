import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Button, CircularProgress, Card} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//     '& .MuiDialogContent-root': {
//         padding: theme.spacing(2),
//     },
//     '& .MuiDialogActions-root': {
//         padding: '15px',
//     },
// }));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const auth = JSON.parse(localStorage.getItem('userData'))
    const { enqueueSnackbar } = useSnackbar();
    const [newdata, setNewdata] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null); // State to store the ID of image to delete
    const [rowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page] = React.useState(0); 
    const [deleteImagePath, setDeleteImagePath] = React.useState(null);
    const [isDeleting,setIsDeleting] = React.useState(false);
    const[Tableloading,setTableloading]=React.useState(true)
    const role = JSON.parse(localStorage.getItem("roledata"))

    React.useEffect(() => {
        GetImages();
    }, [rowsPerPage, page]);

    const GetImages = async () => {

        setTableloading(true)
        function filterObjectsWithAdditionalImages(imagedata) {
            return imagedata.filter(item => item?.additionalimages && item?.additionalimages.length > 0);
          }
        try {
            const url = auth && auth.role_id === 1 ? `image/GetImages?size=${rowsPerPage}&page=${page}` : `image/GetImagescreatedby/${auth._id}`
            const response = await axios.get(process.env.REACT_APP_BASE_URL + url, { headers: headerlocal });
 
        
           
      const filteredData = filterObjectsWithAdditionalImages(response.data.imagedata);
          
            const result = filteredData.flatMap(item =>
                item.additionalimages.map(image => ({
                  _id: item._id,
                  additionalimages: image
                }))
              );
              setNewdata(result);
            setTotal(response.data.totalcount);
        } catch (error) {
            console.error("Error fetching images:", error,total);
            enqueueSnackbar("Error fetching images", { variant: 'error' });
        }
        finally{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        }
    }

    const handleDeleteImage = async (imagePath) => {
        const body = {
            imgpath: imagePath
        };
      
        try {
            setIsDeleting(true);
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}image/deleteImage/${deleteId}`,
                body,
                { headers: headerlocal }
            );
            enqueueSnackbar("Image deleted successfully", { variant: 'success' });
            GetImages();
            handleClose();
        } catch (error) {
            console.error("Error deleting image:", error);
            enqueueSnackbar("Error deleting image", { variant: 'error' });
        }finally{
            setIsDeleting(false);
        }
    };




  





    const handleClose = () => {
        setOpen(false);
    };





    const handleDeleteDialogOpen = (id, imagePath) => {
        setDeleteId(id); // Set the _id of the image to delete
        setDeleteImagePath(imagePath); // Set the image path
        setOpen(true); // Open the delete dialog
    };

    const handleDeleteDialogClose = () => {
        setDeleteId(null);
        setOpen(false);
    };

    return (
        <>
            <Box className='whiteContainer p-20'>
                {/* <Box className='flex-center flex-wrap mb-20 '> */}
                    {/* <Box component='div' className='DTsearch mr-10'>
                        <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            value={search}
                            className='textfield search '
                            onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                            placeholder='Search Here...'
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                            }}
                        />
                    </Box> */}
                {/* </Box> */}
                <Box>

                    {/* temp file newData..// */}

                <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                className="servicesList otherList"
              >
                {Tableloading?
                            (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item>
                                            <CircularProgress  sx={{ color: '#F3714D' }} />
                                        </Grid>
                                    </Grid>
                            )
                            :

                (newdata && newdata.length > 0
                  ? newdata.map((data, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                        sx={{ marginTop: 2 }}
                      >
                        <Card className="cardGrid">
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="carosuel" >
                              
                             
                                  <div key={index} className="imageContainer" style={{margin:"10px"}} >
                                    <img
                                      width={"100%"}
                                      height={250}
                                      src={
                                        process.env.REACT_APP_BASE_URL +
                                        `${data.additionalimages}`
                                      }
                                      style={{ marginRight: "5px" }}
                                      alt={`Pic ${index}`}
                                    />
                                    {
                                        role.UserRoles.Images.deleteImages &&
                                        <IconButton
                                        onClick={() =>
                                          handleDeleteDialogOpen(data._id, data.additionalimages)
                                        }
                                        className="deleteIcon"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                   
                                  </div>
                                
                            </div>
                          </Grid>
                        </Card>
                      </Grid>
                    ))
                  : <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="body1" className='text-center semibold'>No data found</Typography>
                    </Grid>)}
              </Grid>

                    <Dialog
                        open={open}
                        keepMounted
                        onClose={handleDeleteDialogClose}
                        aria-describedby="alert-dialog-slide-description"
                        className='custom_modal'
                    >
                        <DialogTitle>Would you like to delete this image?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogClose} className='cancel_btn'>No</Button>
                            {
                                isDeleting? 
                            <Button className='theme-btn' disabled>Deleting...</Button>:
                            <Button onClick={() => handleDeleteImage(deleteImagePath)} className='theme-btn'>Yes</Button>

                            }
                            

                        </DialogActions>
                    </Dialog>

                </Box>
                {/* <TablePagination
                    rowsPerPageOptions={[8, 12, 16, 20, 24]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                /> */}
            </Box >
        </>
    );
}
