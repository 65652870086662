import * as React from 'react';
import Typography from '@mui/material/Typography';
import {  Box,  Button,  Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import axios from 'axios';
import {  Dialog, DialogActions,  DialogTitle } from '@mui/material';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";


export default function Drip_schedule() {

	const [selectedFromDateTime,] = React.useState(dayjs(new Date()));
	// const [selectedToDateTime, setSelectedToDateTime] = React.useState(dayjs(new Date()));

  

    const [chapters,setChapters] = React.useState([])
    const [deleteopen,setdeleteopen]= React.useState('')

       // Initialize state for lessons' date and time
       const [lessonTimes, setLessonTimes] = React.useState({});

    const {id} = useParams()

    React.useEffect(() => {
        getChapters();
        // eslint-disable-next-line 
    }, []);

    const getChapters = async () => {
        try {
        const response = await axios.get(
           process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`
        );

        setChapters(response.data.course.chapters);

       

    
        const initialTimes = {};
            response.data.course.chapters.forEach(chapter => {
               
                   
                    
                    
                    chapter.lessons.forEach((lesson,i) => {
                  
                    if (lesson.DripSchedule?.length > 0) {
                        const dateStr = lesson.DripSchedule[0]?.releaseDate; // Get the first release date
                        if (dateStr) {
                            initialTimes[lesson._id] = {
                                releaseDate: dayjs(dateStr),
                            };
                        }
                    } else {
                        initialTimes[lesson._id] = {
                            releaseDate: dayjs(new Date()), // Set to current date if empty
                        };
                    }
                    });
                    setLessonTimes(initialTimes);
           
                
            });
            



            
        setOpen(new Array(chapters.length).fill(false));
        } catch (error) {
        console.error(error);
        }
    };




    // handleUpdate

   



  const [open, setOpen] = React.useState(chapters.map(() => true));

  const handleDateTimeChange = (id) => (newValue) => {
    setLessonTimes(prevTimes => ({
        ...prevTimes,
        [id]: {
            ...prevTimes[id],
            releaseDate: newValue
        }
    }));
};
	

    const [activeItemId] = React.useState(null);


    const handleSubmit = async (id,chapterId,lessonId) => {
        
        const { releaseDate } = lessonTimes[lessonId];

        // Get the current date using Day.js
    const currentDate = dayjs();

    // Validate that releaseDate is not in the past
    if (releaseDate.isBefore(currentDate)) {
        enqueueSnackbar('Release date cannot be in the past.', { variant: 'error' });
        return; // Exit the function if the validation fails
    }
       
        
            let dripSchedule = {
                courseId:id,
                 chapterId, 
                 lessonId, 
                 releaseDate
            }
            
         
            
            await axios.post(process.env.REACT_APP_BASE_URL + `Course1/dripSchedule`, dripSchedule)
                .then((res) => {
               
                    enqueueSnackbar('Lesson Scheduled Successfully', { variant: 'success' });
              
                getChapters()
                })
                .catch((error) => {
                    console.log(error, "error in Lesson Scheduled")
                })
           
        
    };

    const handleDelete = ( )=>{
       
        setdeleteopen(true); 

       
        

   
    }

    const handleDeleteClose=()=>{

        setdeleteopen(false)
        getChapters()
        
    }


    const DeleteChapterInfo= async(id,chapid,lessonId,drpObject)=>{

        await axios.put(process.env.REACT_APP_BASE_URL + `Course1/dripscheduleDelete/${id}/${chapid}/${lessonId}/${drpObject?._id}
            `)
                .then((res) => {
                   
                    enqueueSnackbar('Scheduled Time is deleted Successfully', { variant: 'success' });
                    setdeleteopen(false)
                getChapters()
                })
                .catch((error) => {
                    console.log(error, "error in Lesson Scheduled")
                })

    }

 



    


    return (
        <div className='drip_schedule'>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={3} >
                    {/* <Box className="chapterlist whiteContainer p-20">
                        {chapters.map((item, index) => (
                            <Typography className='fz-16 text_black semibold mb-20' key={index}>
                                {index > 0 ? 'Chapter ' + index + ': ' + item.title : item.title}
                            </Typography>
                        ))}

                    </Box> */}
                    <Box className="chapterlist">
            {chapters.length > 0 &&
              chapters.map((item, chapterIndex) => (
                <Accordion
                  key={chapterIndex}
                  className="mb-20"
                  expanded={open[chapterIndex]}
                //   onChange={handleChange(chapterIndex)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item._id}-content`}
                    id={`panel${item._id}-header`}
                  >
                    <Typography className="fz-16 text_black semibold">
                      {chapterIndex >= 0
                        ? `Chapter  +  ${chapterIndex+1} + : ` + item.title
                        : item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>

                    <List className="mb-10">
                      {item.lessons.map((cardItem,lessonIndex) => (
                        <ListItem
                          key={cardItem._id}
                          // className="eachcard mb-10 flex-col align-items-start"
                          // onClick={handleLessonClick(chapterIndex, lessonIndex)}
                          className='eachcard mb-10 flex-col align-items-start '
                        // onClick={handleLessonClick(chapterIndex, lessonIndex)}
                        style={{
                          backgroundColor: '#fdf5e6', // Change this to your desired color
                        }}
                        >
                          <ListItemText primary={cardItem.title} />
                          <div style={{
                            display:"flex"
                          }}>
                          {/* <PictureAsPdfIcon className="text-grey"/> */}
                          {/* <AudiotrackIcon className="text-grey"/> */}
                          {/* <Videocam className="text-grey" /> */}
                          </div>
                          
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={9} >
                    <Box>
                        <Box className="chapterlist ">
                            {chapters.map((item, index) => (
                                <Box className='whiteContainer p-20 mb-10' key={index} >
                                    <Typography className='fz-18 text_primary fw-bold' key={index}>
                                        
                                        {index >= 0 ? 'Chapter ' + (index+1) + ': ' + item.title : item.title}
                                    </Typography>

                                    <List className='mb-10 p-0'>
                                        {item.lessons.map((cardItem,Index) => (
                                            <ListItem key={cardItem._id} className={`flex-col d-flex align-items-start mb-10 ${activeItemId === cardItem.id ? 'active' : ''}`}
                                            >
                                                <ListItemText >
                                                    <Typography className='fz-16 text_black mb-10 semibold'>
                                                        {cardItem.title}
                                                    </Typography>
                                                </ListItemText>

                                                <Box className="flex-center">
                                                    <Typography 
                                                    component='span' 
                                                    className='fz-14 text_black mr-10 '
                                                    sx={{
                                                        width:"300px"
                                                    }}
                                                    >
                                                        will be released
                                                    </Typography>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} className="">
                                                        {/* <DemoContainer components={['DatePicker', 'DatePicker']}> */}
                                                        <DateTimePicker
                                                            size="small"
                                                            onChange={handleDateTimeChange(cardItem._id)}
                                                            value={
                                                                 
                                                                lessonTimes[cardItem._id]?.releaseDate || null
                                                            }
                                                            format="DD-MM-YYYY hh:mm A"
                                                            minDate={dayjs(selectedFromDateTime)}
                                                            ampm={true}
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}
                                                            className="textfield mr-10 datetimepicker"
                                                        />
                                                        {/* </DemoContainer> */}
                                                    </LocalizationProvider>
                                                    
                                                </Box>

                             
                                            
                                                <Box className="flex-center mt-10"
                                                sx={{
                                                    position:"absolute",
                                                    right:'10px',
                                                    bottom:"10px"
                                                }}>
                                                    {cardItem.DripSchedule.length > 0 ? (

                                                        <div> 
                                                           {!deleteopen?(
                                                        <Button 
                                                        variant="contained" 
                                                        className="theme-btn" 
                                                        onClick={handleDelete}
                                                        >
                                                        
                                                            Delete
                                                        </Button>

                                                    ):(
                                                        <Dialog
                                                        open={deleteopen}
                                                        keepMounted
                                                        onClose={handleDeleteClose}
                                                        aria-describedby="alert-dialog-slide-description"
                                                        className='custom_modal'
                                                    >
                                                        <DialogTitle>{"Would you like to delete this Released Data and Time?"}</DialogTitle>
                                                        <DialogActions>
                                                            <Button onClick={handleDeleteClose} className='cancel_btn'>No</Button>
                                                            <Button onClick={() => DeleteChapterInfo( id,
                                                                                                        item._id,
                                                                                                        cardItem._id,
                                                                                                        cardItem.DripSchedule[Index])} className='theme-btn'>Yes</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                    )} 
                                                    </div>
                                                             ) : (
                                                        <Button 
                                                        variant="contained" 
                                                        className="theme-btn" 
                                                        onClick={()=>handleSubmit(
                                                            id,
                                                            item._id,
                                                            cardItem._id,
                                                            )}
                                                        >
                                                            Submit
                                                        </Button>
                                                    )}
                                                
                                               
                                         
                                                </Box>


                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                          
                            ))}
                        </Box>
                    </Box>

                </Grid>
            </Grid>

        </div>
    );
}
