import * as React from 'react';

import { Typography, IconButton, TextField, InputAdornment, Menu, MenuItem, Box, Button, FormControl, CircularProgress, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import { useSnackbar } from 'notistack'
import { Verified } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import {  SearchOutlined, CloseOutlined } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import playBtn from '../../../assets/images/play.png'
import Switch, {  } from '@mui/material/Switch';


// const IOSSwitch = styled((props) => (
//     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//     width: 40,
//     height: 20,
//     padding: 0,
//     '& .MuiSwitch-switchBase': {
//         padding: 0,
//         margin: 0,
//         transitionDuration: '300ms',
//         '&.Mui-checked': {
//             transform: 'translateX(20px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//                 opacity: 1,
//                 border: 0,
//             },
//             '&.Mui-disabled + .MuiSwitch-track': {
//                 opacity: 0.5,
//             },
//         },
//         '&.Mui-focusVisible .MuiSwitch-thumb': {
//             color: '#33cf4d',
//             border: '6px solid #fff',
//         },
//         '&.Mui-disabled .MuiSwitch-thumb': {
//             color:
//                 theme.palette.mode === 'light'
//                     ? theme.palette.grey[100]
//                     : theme.palette.grey[600],
//         },
//         '&.Mui-disabled + .MuiSwitch-track': {
//             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxSizing: 'border-box',
//         width: 18.5,
//         height: 18.5,
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 18.5 / 2,
//         backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
//         opacity: 1,
//         transition: theme.transitions.create(['background-color'], {
//             duration: 500,
//         }),
//     },
// }));
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: theme.palette.common.black,
//         color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,
//     },
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//     '& .MuiDialogContent-root': {
//         padding: theme.spacing(2),
//     },
//     '& .MuiDialogActions-root': {
//         padding: '15px',
//     },
// }));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >

                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function Podcast_cardview() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [podcastDetails, setPodcastDetails] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [alldata, setAlldata] = React.useState('');
    const [isDeleting,setIsDeleting] = React.useState(false)
    const role = JSON.parse(localStorage.getItem("roledata"))
    const[Tableloading,setTableloading]=React.useState(true)
    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    React.useEffect(() => {
        getPodcast()
    }, [page, rowsPerPage, searchTerm])

    const handleClose_menu = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleRemove = () => {
        setSearchTerm('')
        setPage(0)
    }


    const getPodcast = () => {
        setTableloading(true)
        axios.get(process.env.REACT_APP_BASE_URL + `podcast/GetPodcast?createdby=${auth && auth._id}&size=${rowsPerPage}&page=${page}&search=${searchTerm}`, tokendata).then((res) => {
         
            setPodcastDetails(res.data.podcast)
            setCount(res.data.totalcount || 0);
        }).catch((err) => {
            console.log(err);
        })
        .finally(()=>{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        })
        
    }



    const handleDelete = async () => {
        setIsDeleting(true)
        await axios.put(process.env.REACT_APP_BASE_URL + `podcast/deletepodcast/${deleteid}`, {}, tokendata,).then((res) => {
            handleClose()
            getPodcast()
            enqueueSnackbar("Podcast deleted successfully", { variant: 'success' })

        }).catch((err) => {
            console.log(err);
        }).finally(()=>{
            setIsDeleting(false)
        })
    }

    const EditData = (row) => {
        let obj = {
            data: alldata
        }
       ;
        navigate("/podcast/editpodcast", { state: obj })
    }



    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }


    const handleChangeStatus = async (e, item, type) => {

        // let veried = item.createdby.SetVerifyTeacher

       
        if (type === 'Public') {
            const body = {
                public: e.target.checked,
            };
          
            try {
                 await axios.put(process.env.REACT_APP_BASE_URL + `podcast/updatePodcast/${item._id}`, body, tokendata);
                getPodcast();
            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating public", error);
            }
        }
        if (type === 'Primary') {
            const body = {
                primary: e.target.checked,
            };
            try {
                 await axios.put(process.env.REACT_APP_BASE_URL + `podcast/updatePodcast/${item._id}`, body, tokendata);
                getPodcast();
            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating primary event", error);
            }
        }

        if (type === 'Approve') {
        
            const body = {
                approve_podcast: e.target.checked,
            };
            
            try {
                await axios.put(process.env.REACT_APP_BASE_URL + `podcast/Updateapprove_podcast/${item._id}`, body, tokendata);
                getPodcast();
            } catch (error) {
                // enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                // console.error("Error while updating primary event", error);
            }
        // }
    }
    };

    const handleView = (e) => {
        let podcastDetails = {
            podcastData: alldata
        }
        navigate(`/podcast/Podcast-details/${alldata.name}`, { state: podcastDetails });
    }

    const handleNavigate = (e, data) => {
        let podcastDetails = {
            podcastData: data
        }
        navigate(`/podcast/Podcast-details/${data.name}`, { state: podcastDetails });
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box  className='whiteContainer '>
                <Toolbar className='toolbar comptoolbar'>

                   
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <Grid container columnSpacing={4} rowSpacing={4} className='p-20'>
                    {

Tableloading?
(
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
        >
            <Grid item>
                <CircularProgress sx={{ color: '#F3714D' }} />
            </Grid>
        </Grid>
):
                    (podcastDetails && podcastDetails.length > 0 ? podcastDetails.map((item, i) => {
                        return (
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className='d-flex'>
                                <Box className='poditem'>
                                    <Box className='podImg_box'>
                                        <IconButton aria-label="more" id="long-button"
                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                            aria-expanded={menuopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, item)}
                                            className='vertical_menu'>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={handleClose_menu}
                                            BoxProps={{
                                                style: {
                                                    width: '15ch',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem className='menuitems' onClick={(e) => handleView(e, item)} ><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>
                    {role.UserRoles.Podcast.editPodcast &&<MenuItem className='menuitems' onClick={() => EditData()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>}
                      {role.UserRoles.Podcast.deletePodcast &&<MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, item._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>}
                                        </Menu>
                                        <img src={process.env.REACT_APP_BASE_URL + item.thumbnail} alt='' />
                                        <Button className='playbtn' onClick={(e) => handleNavigate(e, item)} ><img src={playBtn} alt='' /></Button>
                                    </Box>


                                    <Box className='d-flex flex-wrap flex-between mb-10'>
                                        <Box className='flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 semibold mr-10'>Public:   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch size='small' onChange={(e) => handleChangeStatus(e, item, 'Public')} checked={item.public} />} />
                                        </Box>
                                        <Box className=' flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 semibold mr-10'>Primary:   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch size='small' onChange={(e) => handleChangeStatus(e, item, 'Primary')} checked={item.primary} />} />
                                        </Box>
                                        {item.createdby._id === auth._id ? '' :
                                        <Box className='flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 mr-5 semibold '>Approved:</Typography>
                                            <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<Switch size="small" onChange={(e) => handleChangeStatus(e, item, 'Approve')} checked={item.approve_podcast} />} />
                                        </Box>}
                                    </Box>

                                    <Box className='d-flex mb-5'>
                                        <Box>
                                            <Typography component='div' variant='body1' className='text_black fz-14 semibold letter_sp'>{item.name}</Typography>
                                            <Typography component='div' variant='body1' className='text_black fz-14 semibold letter_sp'>
                                                {item.audio_size && `${item.audio_size}mb`}
                                            </Typography>
                                        </Box>
                                        {
                                            item.primary && <Typography component='div' variant='body1' className='fz-14 ml-auto' ><Verified className='fz-18' style={{ color: '#1492fd' }} /></Typography>
                                        }
                                    </Box>
                                    <Typography component='div' variant='body1' className='text_black fz-14 ellipse2 fw-500 letter_sp mb-5'>{item.description}</Typography>
                                </Box>
                            </Grid>

                        );
                    }) :  <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p className='text-center semibold'>No data found</p></Grid>)}
                </Grid>




                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />

            </Box>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete Podcast?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    {
                        isDeleting ? 
                    <Button className='theme-btn' disabled> Deleting... </Button>:

                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                    }
                </DialogActions>
            </Dialog>
        </Box>
    );
}

