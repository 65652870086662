import { Autocomplete, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, RadioGroup, InputLabel, ListItemText, MenuItem, Radio, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

const AddMultipleTeacherBundle = ({
    teacher,
    communities,
    course,
    yogaFans,
    selectedTeacher,
    selectedCommunity,
    selectedCourses,
    selectedYogaFans,
    handleTeacherChange,
    handleCommunityChange,
    handleCourseChange,
    handleYogaFansChange,
    handlePaymentPlansChange,
    errors,
    errorCommunityindex,
    errorFanpageindex,
    errorCourseindex,
    setSelectedItemsPaymentComm,
    setSelectedItemsPaymentForFansPage,
    setSelectedPaymentForCourse,
    selectedItemsPaymentComm,
    selectedItemsPaymentForFansPage,
    selectedPaymentForCourse,
}) => {

    

    const [filteredCommunities, setFilteredCommunities] = useState(communities);
  const [filteredYogaFans, setFilteredYogaFans] = useState(yogaFans);
  const [filteredCourses, setFilteredCourses] = useState(course);


  const [communityObject, setCommunityObject] = useState(selectedCommunity);
  const [courseObject, setCourseObject] = useState(selectedCommunity);
  const [yogaFansObject, setYogaFansObject] = useState(selectedCommunity);


    // const [selectedItemsPaymentComm, setSelectedItemsPaymentComm] = useState([]); // State to store payment plans for each fan item
    // const [selectedItemsPaymentForCourse, setSelectedItemsPaymentForCourse] = useState([]);
    // const [selectedItemsPaymentForFansPage, setSelectedItemsPaymentForFansPage] = useState([]);
    const [selectedTeacherU, setSelectedTeacher] = useState([]);


 
   useEffect(() => {
    if (selectedTeacher.length > 0) {
        // Filter Communities based on selected teachers' IDs
        setFilteredCommunities(communities.filter(community => 
          selectedTeacher.some(teacherId => 
            community.createdby.some(teacher => teacher._id === teacherId) // Check if teacher._id is in the createdBy array
          )
        ));
    
        // Filter YogaFans Pages based on selected teachers' IDs
        setFilteredYogaFans(yogaFans.filter(yogaFan => 
          selectedTeacher.some(teacherId => 
            yogaFan.createdby === teacherId // Check if teacher._id is in the createdBy array
          )
        ));
    
          // Filter Courses based on selected teachers' IDs
        setFilteredCourses(course.filter(c => 
          c.teacher.some(teacherId => 
              selectedTeacher.includes(teacherId) // Check if any teacher ID in c.teacher is in selectedTeacher
          )
        ));
      } else {
        // If no teacher is selected, show all options
        setFilteredCommunities(communities);
        setFilteredYogaFans(yogaFans);
        setFilteredCourses(course);
      }
  }, [selectedTeacher, communities, yogaFans, course]);

  const handlePaymentChangeForCommunity = (event, id) => {
    const selectedPaymentPlan = event.target.value;
    const selectedCommunity = communityObject.find(item => item._id === id);

    // Find if the selected community already has a plan selected
    const currentSelectedPlan = selectedItemsPaymentComm.find(item => item._id === id);

    // If the user clicks the currently selected plan, unselect it
    if (currentSelectedPlan && currentSelectedPlan.paymentPlan === selectedPaymentPlan) {
        // Deselect by removing the current selection for that community
        setSelectedItemsPaymentComm(prevState => {
            const updatedState = prevState.filter(item => item._id !== id);

            // Send all updated data to the parent (communities, courses, fans, teachers)
            handlePaymentPlansChange({
              communities: updatedState,
              courses:selectedPaymentForCourse ,  // Update with the current state of selected courses
              yogaFans: selectedItemsPaymentForFansPage,  // Update with the current state of selected yoga fans
              teachers: selectedTeacherU,  // Pass only the IDs to the parent
            });

            return updatedState;
        });
    } else {
        // If a new plan is selected, update the state
        const updatedPaymentPlan = {
            _id: selectedCommunity._id,
            name: selectedCommunity.coummunity_name,
            amount: selectedPaymentPlan === "month"
            ? selectedCommunity.amountInMonth  // If monthly, use the monthly amount
            : selectedPaymentPlan === "year"
            ? selectedCommunity.amountInYear  // If yearly, use the yearly amount
            : null,  // If one-time payment, use the one-time amount,
            billingCycle: selectedPaymentPlan,
            paymentPlan: selectedPaymentPlan,
            amountInYear: selectedCommunity.amountInYear ,
            amountInMonth: selectedCommunity.amountInMonth ,
            image:selectedCommunity.image
        };

        setSelectedItemsPaymentComm(prevState => {
            const updatedState = [...prevState];
            const existingIndex = updatedState.findIndex(item => item._id === id);

            if (existingIndex !== -1) {
                // Update the existing plan
                updatedState[existingIndex] = updatedPaymentPlan;
            } else {
                // Add the new selection
                updatedState.push(updatedPaymentPlan);
            }

            // Send all updated data to the parent (communities, courses, fans, teachers)
            handlePaymentPlansChange({
              communities: updatedState,
              courses: selectedPaymentForCourse,  // Update with the current state of selected courses
              yogaFans: selectedItemsPaymentForFansPage,  // Update with the current state of selected yoga fans
              teachers: selectedTeacherU,  // Pass only the IDs to the parent

            });

            return updatedState;
        });
    }

    

};



const handlePaymentChangeForCourse = (event, id) => {
  const selectedPaymentPlan = event.target.value;

  // Find the selected course from the filteredCourses
  const selectedCourse = filteredCourses.find(item => item._id === id);

  if (!selectedCourse) return;  // If the course isn't found, return early

  // Create the updated payment plan object for this course
  const updatedPaymentPlan = {
    _id: selectedCourse._id,
    name: selectedCourse.course_title,
    image:selectedCourse.image,
    amountInWeek: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInWeek : null,
    amountInBiWeek: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInByWeekly : null,
    amountInMonth: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInMonth : null,
    amountInOneTime: selectedCourse.course_paymenttype === "onetimepayment" ? selectedCourse.amountOneTime : null,
    paymentPlan: selectedPaymentPlan,
    interval: selectedCourse.course_paymenttype === "recurring" ? "recurring" : "onetimepayment",
  };

  // Update the selected payment plans for courses in the local state
  setSelectedPaymentForCourse(prevState => {
    const updatedState = [...prevState];
    const existingIndex = updatedState.findIndex(item => item._id === id);

    if (existingIndex !== -1) {
      // Update the payment plan for the existing course item
      updatedState[existingIndex] = updatedPaymentPlan;
    } else {
      // Add the new selected payment plan for the course
      updatedState.push(updatedPaymentPlan);
    }

    // Send all updated data to the parent (communities, courses, fans, teachers)
    handlePaymentPlansChange({
      communities: selectedItemsPaymentComm,  // Use the current state of selected community plans
      courses: updatedState,                 // Pass the updated course plans
      yogaFans: selectedItemsPaymentForFansPage,            // Include the selected yoga fans if needed
      teachers: selectedTeacherU,  // Pass only the IDs to the parent

      
    });

    return updatedState;
  });

 
};


const handlePaymentChangeForFansPage = (event, id) => {
  const selectedPaymentPlan = event.target.value;

  // Find the selected fan page from the selectedItemsObjectFans
  const selectedFanPage = filteredYogaFans.find(item => item._id === id);

  if (!selectedFanPage) return;  // If the fan page isn't found, return early

  // Create the updated payment plan object for this fan page
  const updatedPaymentPlan = {
    _id: selectedFanPage._id,
    name: selectedFanPage.name,
    image:selectedFanPage.image,
    amountInYear: selectedFanPage.amountInYear,  // Yearly payment
    amountInMonth: selectedFanPage.amountInMonth,  // Monthly payment
    paymentPlan: selectedPaymentPlan, // The selected plan (monthly, yearly, or onetimepayment)
    amount: selectedPaymentPlan === "month"
      ? selectedFanPage.amountInMonth  // If monthly, use the monthly amount
      : selectedPaymentPlan === "year"
      ? selectedFanPage.amountInYear  // If yearly, use the yearly amount
      : selectedFanPage.amountOneTime  // If one-time payment, use the one-time amount
  };

  // Update the selected payment plans for fan pages in the local state
  setSelectedItemsPaymentForFansPage(prevState => {
    const updatedState = [...prevState];
    const existingIndex = updatedState.findIndex(item => item._id === id);

    if (existingIndex !== -1) {
      // Update the payment plan for the existing fan page item
      updatedState[existingIndex] = updatedPaymentPlan;
    } else {
      // Add the new selected payment plan for the fan page
      updatedState.push(updatedPaymentPlan);
    }

    // Send all updated data to the parent (communities, courses, fans, teachers)
    handlePaymentPlansChange({
      communities: selectedItemsPaymentComm,  // Use the current state of selected community plans
      courses: selectedPaymentForCourse, // Include the current course plans
      yogaFans: updatedState,  // Pass the updated fan page payment plans
      teachers: selectedTeacherU,  // Pass only the IDs to the parent
      
    });

    return updatedState;
  });

};

const handleLocalTeacherChange = (event, newValue) => {
  // 'newValue' will be an array of selected teacher objects


  // Update local state with selected teacher objects
  setSelectedTeacher(newValue);

  // Get just the _id values from the selected teacher objects
  const selectedTeacherIds = newValue.map(teacher => teacher._id);

  // Notify the parent component by calling the parent handler with the updated teacher IDs
  handlePaymentPlansChange({
    communities: selectedItemsPaymentComm,  // You can pass your other selected payment plans
    courses: selectedPaymentForCourse, 
    yogaFans: selectedItemsPaymentForFansPage,
    teachers: selectedTeacherIds,  // Pass only the selected teacher IDs
  });

  // Notify parent of selected teacher IDs (optional, depending on how your parent handles it)
  handleTeacherChange(selectedTeacherIds);  // Send only the teacher IDs to the parent
};

  
  const handleLocalChangecom = (event) => {
    // Get the selected values (IDs of selected communities)
    const selectedValues = event.target.value;

  
    // Filter the communities based on the selected values (_id)
    const selectedObjects = communities.filter(option => selectedValues.includes(option._id));
  
    // Get just the _id values for the parent component
    const selectedCommunityIds = selectedObjects.map(option => option._id);

    // Step 1: Update the community object with selected communities
    setCommunityObject(selectedObjects);  // Save the full community objects locally
  
    // Step 2: Update the selectedItemsPaymentComm by adding or removing entries
    setSelectedItemsPaymentComm(prevState => {
      const updatedState = prevState.filter(item => selectedValues.includes(item._id)); // Remove unselected items
  
      // Add new selected communities if they are not already in the updatedState
      selectedObjects.forEach(selectedCommunity => {
        const existingItem = updatedState.find(item => item._id === selectedCommunity._id);
        if (!existingItem) {
          updatedState.push({
            _id: selectedCommunity._id,
            image: selectedCommunity.image,
            name: selectedCommunity.coummunity_name,
            amount: selectedCommunity.amount,
            billingCycle: selectedCommunity.interval,
            paymentPlan: '', // You can set a default payment plan if needed
          });
        }
      });
  
      return updatedState;
    });
  
    // Step 3: Notify the parent component with the selected community IDs
    handleCommunityChange(selectedCommunityIds);  // Send only the IDs to the parent
  };



  const handleLocalChangeCourse = (event) => {
    // Get the selected values (IDs of selected courses)
    const selectedValues = event.target.value;

  
    // Filter the courses based on the selected values (_id)
    const selectedObjects = course.filter(option => selectedValues.includes(option._id));
  
    // Get just the _id values for the parent component
    const selectedCourseIds = selectedObjects.map(option => option._id);
  
    // Step 1: Update the selected courses object with selected courses
    setCourseObject(selectedObjects);  // Save the full course objects locally
  
    // Step 2: Update the selectedItemsPaymentForCourse by adding or removing entries
    setSelectedPaymentForCourse(prevState => {
      const updatedState = prevState.filter(item => selectedValues.includes(item._id)); // Remove unselected items
  
      // Add new selected courses if they are not already in the updatedState
      selectedObjects.forEach(selectedCourse => {
        const existingItem = updatedState.find(item => item._id === selectedCourse._id);
        if (!existingItem) {
          updatedState.push({
            _id: selectedCourse._id,
            name: selectedCourse.course_title,
            image:selectedCourse.image,
            amountInWeek: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInWeek : null,
            amountInBiWeek: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInByWeekly : null,
            amountInMonth: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInMonth : null,
            amountInOneTime: selectedCourse.course_paymenttype === "onetimepayment" ? selectedCourse.amountOneTime : null,
            paymentPlan: '', // You can set a default payment plan if needed
            interval: selectedCourse.course_paymenttype === "recurring" ? "recurring" : "onetimepayment"
          });
        }
      });
  
      return updatedState;
    });
  
  
    handleCourseChange(selectedCourseIds);  // Send only the IDs to the parent
  };




  const handleLocalChangeYogaFans = (event) => {
    // Get the selected values (IDs of selected yoga fans)
    const selectedValues = event.target.value;

  
    // Filter the yoga fans based on the selected values (_id)
    const selectedObjects = yogaFans.filter(option => selectedValues.includes(option._id));
  
    // Get just the _id values for the parent component
    const selectedYogaFanIds = selectedObjects.map(option => option._id);
  
    // Step 1: Update the selected yoga fans object with selected yoga fans
    setYogaFansObject(selectedObjects);  // Save the full yoga fan objects locally
  
    // Step 2: Update the selectedItemsPaymentForFansPage by adding or removing entries
    setSelectedItemsPaymentForFansPage(prevState => {
      const updatedState = prevState.filter(item => selectedValues.includes(item._id)); // Remove unselected items
  
      // Add new selected yoga fans if they are not already in the updatedState
      selectedObjects.forEach(selectedFan => {
        const existingItem = updatedState.find(item => item._id === selectedFan._id);
        if (!existingItem) {
          // Create the updated payment plan object for this specific fan page
          const updatedPaymentPlan = {
            _id: selectedFan._id,
            name: selectedFan.name,
            image:selectedFan.image,
            amountInYear: selectedFan.amountInYear,  // Yearly amount
            amountInMonth: selectedFan.amountInMonth,  // Monthly amount
            paymentPlan: '',  // Default or selected plan
            amount: selectedFan.paymentPlan === "month"
              ? selectedFan.amountInMonth 
              : selectedFan.paymentPlan === "year"
              ? selectedFan.amountInYear
              : null
          };
  
          updatedState.push(updatedPaymentPlan);
        }
      });
  
      return updatedState;
    });
  
    // Step 3: Notify the parent component with the selected yoga fan IDs
    handleYogaFansChange(selectedYogaFanIds);  // Send only the IDs to the parent
  };
  



  const renderValue = (selected, options, placeholder) => {
   
    return selected.length === 0 ? placeholder : selected.map(item => {
      const selectedOption = options.find(option => option._id === item);
      return selectedOption ? selectedOption.coummunity_name ||selectedOption.name ||selectedOption.course_title : item;
    }).join(', ');
  };

    return (
        <>
        <div>
          {/* -------------------------- Assign Teacher -------------------------- */}
          <Typography component="div" className="label">
            Select Teachers <Typography component="span" className="star">*</Typography>
          </Typography>
          <FormControl variant="outlined" className='mb-20 mt-5'>
            <Autocomplete
              multiple
              id="teacher-autocomplete"
              options={teacher}
              getOptionLabel={(option) => option.username || ""}
              value={teacher.length > 0 ? teacher.filter(master => selectedTeacher.includes(master._id)) : []}
              onChange={handleLocalTeacherChange} // Update selected teacher in parent
              sx={{ minWidth: "500px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Type to search..."
                />
              )}
              renderTags={(selected, getTagProps) => selected.map((option, index) => (
                <Chip
                  key={option._id}
                  label={option.username}
                  {...getTagProps({ index })}
                />
              ))}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option.username}
                </li>
              )}
              noOptionsText="No Record's found"
            />

{errors && errors.teacher && (
            <div style={{ color: 'red' }}>
              <p>{errors.teacher}</p>
            </div>
          )}
          </FormControl>
          

         {selectedTeacherU.length>0?
         
        
        
       ( <> 
          <Typography component="div" className='label'>
            Assign Communities
          </Typography>
          <FormControl className='mb-20 mt-5'>
            <InputLabel id="community-select-label">Select Communities</InputLabel>
            <Select
              labelId="community-select-label"
              id="community-select"
              multiple
              value={selectedCommunity}
              onChange={handleLocalChangecom} // Handle change locally in the child 
              renderValue={() => renderValue(selectedCommunity, communities, 'Select Yoga Communities')}
              sx={{ minWidth: "500px" }}
            >
              {filteredCommunities.length>0?
              (filteredCommunities.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  <Checkbox checked={selectedCommunity.indexOf(option._id) > -1} />
                  <ListItemText primary={option.coummunity_name} />
                </MenuItem>
              ))):
                (
                  <MenuItem disabled>No Communities found</MenuItem>
                )
                                        }
            </Select>

            {errors && errors.paymentPlan && (
            <div style={{ color: 'red' }}>
              <p>{errors.paymentPlan}</p>
            </div>
          )}
          </FormControl>

          {communityObject?.map((plan, index) => {
            // Find the selected payment plan for this community
            const selectedPaymentPlan = selectedItemsPaymentComm.find(
              (item) => item._id === plan._id
            )?.paymentPlan;

            return (
              <div key={index}>
                <Typography component="div" className="label">
                  <span>{plan.coummunity_name}</span>
                  <Typography component="span" className="star">*</Typography>
                </Typography>

                <FormControl className="mb-20 mt-5">
                  <FormLabel component="legend" className="label">
                    Choose Payment Plan for {plan.coummunity_name}
                  </FormLabel>

                  <RadioGroup
                    value={selectedPaymentPlan || ''} // Ensure value is correctly controlled by state
                    onChange={(event) => handlePaymentChangeForCommunity(event, plan._id)}
                  >
                    {/* Render radio button for Yearly Payment Plan */}
                    {plan.amountInYear ? (
                          <FormControlLabel
                            value="year"
                            control={<Radio />}
                            label={`Amount In Year: ${plan.amountInYear} USD`}
                          />
                        ) : (
                          <Typography component="div" className="label">
                            <span>Amount In Year: No Amount Available</span>
                          </Typography>
                        )}

                        {/* Render radio button for Monthly Payment Plan */}
                        {plan.amountInMonth ? (
                          <FormControlLabel
                            value="month"
                            control={<Radio />}
                            label={`Amount In Month: ${plan.amountInMonth} USD`}
                          />
                        ) : (
                          <Typography component="div" className="label">
                            <span>Amount In Month: No Amount Available</span>
                          </Typography>
                        )}
                  </RadioGroup>
                  {errors.Communitypay && errorCommunityindex===index&&(
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.Communitypay}
                                          </p>
                                        )}

                </FormControl>
              </div>
            );
          })}

          {/* -------------------------- Assign Courses -------------------------- */}
          <Typography component="div" className='label'>
            Assign Courses
          </Typography>
          <FormControl className='mb-20 mt-5'>
            <InputLabel id="courses-select-label">Select Courses</InputLabel>
            <Select
              labelId="courses-select-label"
              id="courses-select"
              multiple
              value={selectedCourses}
              onChange={handleLocalChangeCourse} // Update selected courses in parent
              renderValue={() => renderValue(selectedCourses, course, 'Select Courses')}
              sx={{ minWidth: "500px" }}
            >
              {filteredCourses.length>0?
              (filteredCourses.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  <Checkbox checked={selectedCourses.indexOf(option._id) > -1} />
                  <ListItemText primary={option.course_title} />
                </MenuItem>
              )))
              :
              (
                <MenuItem disabled>No Course found</MenuItem>
              )}
            </Select>
            {errors && errors.paymentPlan && (
            <div style={{ color: 'red' }}>
              <p>{errors.paymentPlan}</p>
            </div>
          )}
          </FormControl>

          {
            courseObject.length > 0 && (
              <>
                <Typography component="div" className="label">
                  <span></span>
                  <Typography component="span" className="star"></Typography>
                </Typography>
                {/* Loop through each item in the selectedItemObject array */}
                {courseObject?.map((plan, index) => {
                  // Find the selected payment plan for this course
                  const selectedPaymentPlan = selectedPaymentForCourse.find(
                    (item) => item._id === plan._id
                  )?.paymentPlan;

                  return (
                    <div key={plan._id}> {/* Use plan._id as the key for better performance */}
                      <Typography component="div" className="label">
                        <span>{plan.course_title}</span>
                        <Typography component="span" className="star">*</Typography>
                      </Typography>

                      <FormControl className="mb-20 mt-5">
                        <FormLabel component="legend" className="label">
                          Choose Payment Plan for {plan.course_title}
                        </FormLabel>

                        <RadioGroup
                          value={selectedPaymentPlan || ''} // Ensure value is controlled by state
                          onChange={(event) => handlePaymentChangeForCourse(event, plan._id)} // Handle payment change
                        >
                          {/* Render radio button for weekly payment option, if valid */}
                          {plan.course_paymenttype === 'recurring' && plan.amountInWeek && (
                            <FormControlLabel
                              value="week"
                              control={<Radio />}
                              label={`Amount In Week: ${plan.amountInWeek} ${plan.currency}`}
                            />
                          )}

                          {/* Render radio button for bi-weekly payment option, if valid */}
                          {plan.course_paymenttype === 'recurring' && plan.amountInByWeekly && (
                            <FormControlLabel
                              value="biWeek"
                              control={<Radio />}
                              label={`Amount In BiWeek: ${plan.amountInByWeekly} ${plan.currency}`}
                            />
                          )}

                          {/* Render radio button for monthly payment option, if valid */}
                          {plan.course_paymenttype === 'recurring' && plan.amountInMonth && (
                            <FormControlLabel
                              value="month"
                              control={<Radio />}
                              label={`Amount In Month: ${plan.amountInMonth} ${plan.currency}`}
                            />
                          )}

                          {/* Render radio button for one-time payment option, if valid */}
                          {plan.course_paymenttype === 'onetimepayment' && plan.amountOneTime && (
                            <FormControlLabel
                              value="OneTime"
                              control={<Radio />}
                              label={`One Time Amount: ${plan.amountOneTime} ${plan.currency}`}
                            />
                          )}
                        </RadioGroup>
                        {errors.CoursePay && errorCourseindex===index&&(
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.CoursePay}
                                          </p>
                                        )}
                      </FormControl>
                    </div>
                  );
                })}

              </>
            )
          }

          {/* -------------------------- Assign Yoga-Fans Pages -------------------------- */}
          <Typography component="div" className='label'>
            Assign Yoga-Fan Pages
          </Typography>
          <FormControl className='mb-20 mt-5'>
            <InputLabel id="yoga-fans-select-label">Select Yoga-Fans Pages</InputLabel>
            <Select
              labelId="yoga-fans-select-label"
              id="yoga-fans-select"
              multiple
              value={selectedYogaFans}
              onChange={handleLocalChangeYogaFans} // Update selected yoga fans in parent
              renderValue={() => renderValue(selectedYogaFans, yogaFans, 'Select Yoga-Fans Pages')}
              sx={{ minWidth: "500px" }}
            >
              {filteredYogaFans.length>0?
              (filteredYogaFans.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  <Checkbox checked={selectedYogaFans.indexOf(option._id) > -1} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              )))
              :
              (
                <MenuItem disabled>No Yoga-Fans Pages found</MenuItem>
              )}
            </Select>
            {errors && errors.paymentPlan && (
            <div style={{ color: 'red' }}>
              <p>{errors.paymentPlan}</p>
            </div>
          )}
          </FormControl>

          {yogaFansObject.length > 0 && (
            <>
              <Typography component="div" className="label">
                Choose Payment Plan for Yoga Fan Pages
              </Typography>
              {yogaFansObject?.map((plan,index) => {
                // Find the selected payment plan for this fan page
                const selectedPaymentPlan = selectedItemsPaymentForFansPage.find(
                  (item) => item._id === plan._id
                )?.paymentPlan;

                return (
                  <div key={plan._id}> {/* Use plan._id as the key for better performance */}
                    <Typography component="div" className="label">
                      <span>{plan.name}</span>
                      <Typography component="span" className="star">*</Typography>
                    </Typography>

                    <FormControl className="mb-20 mt-5">
                      <FormLabel component="legend" className="label">
                        Choose Payment Plan for {plan.name}
                      </FormLabel>

                      <RadioGroup
                        value={selectedPaymentPlan || ''} // Ensure value is controlled by state
                        onChange={(event) => handlePaymentChangeForFansPage(event, plan._id)} // Handle payment change
                      >
                        {/* Render radio button for Yearly Payment Plan */}
                        {plan.amountInYear ? (
                          <FormControlLabel
                            value="year"
                            control={<Radio />}
                            label={`Amount In Year: ${plan.amountInYear} ${plan.currency}`}
                          />
                        ) : (
                          <Typography component="div" className="label">
                            <span>Amount In Year: No Amount Available</span>
                          </Typography>
                        )}

                        {/* Render radio button for Monthly Payment Plan */}
                        {plan.amountInMonth ? (
                          <FormControlLabel
                            value="month"
                            control={<Radio />}
                            label={`Amount In Month: ${plan.amountInMonth} ${plan.currency}`}
                          />
                        ) : (
                          <Typography component="div" className="label">
                            <span>Amount In Month: No Amount Available</span>
                          </Typography>
                        )}
                      </RadioGroup>
                      {errors.FansPagepay && errorFanpageindex===index&&(
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.CoursePay}
                                          </p>
                                        )}
                    </FormControl>
                  </div>
                );
              })}
            </>
          )}

          {/* -------------------------- Display Errors -------------------------- */}
          </>)
          :
          ""}
        </div>
        </>
    )
}

export default AddMultipleTeacherBundle