import {  Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
// import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
import axios from 'axios'
import React, { useState } from 'react'
import {  useNavigate, useParams } from 'react-router-dom'
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import MiniDrawer from '../../../components/drawer'
import { enqueueSnackbar } from 'notistack';
import AddMultipleTeacherBundle from './AddMultipleTeacherBundle';
import { ceil } from 'lodash';



const CouponandOffersettings = () => {
  const headerlocal = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("token")
}

const headerformdata = {
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem("token")
}
  const params = useParams()
  const navigate = useNavigate()
  const [selectedOffer, setSelectedOffer] = useState("sel")
  const [selectedDiscount, setSelectedDiscount] = useState("sel")
  const [discountValue, setDiscountValue] = useState('')
  const [minimumAmount, setMinimumAmount] = useState('')
  const [selectedCommunity, setSelectedCommunity] = useState([])
  const [selectedYogaFans, setSelectedYogaFans] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [couponCode, setCouponCode] = useState('')
  const [selectedItemObject, setSelectedItemObject] = useState(null);

  const [selectedItemsObjectFans, setSelectedItemsObjectFans] = useState([]);
  const [selectedItemsObjectComm, setSelectedItemsObjectComm] = useState([]);
  const [selectedItemsObjectCourse, setSelectedItemsObjectCourse] = useState([]);

	const [isPrivate, setIsPrivate] = React.useState(false)
  const [isMerged, setIsMerged] = React.useState(false)

  const [truebutton,setTruebutton]=React.useState(false)

  const [notes, setNotes] = useState('')

  const [communities, setCommunities] = useState([])
  const [course, setCourses] = useState([])
  const [yogaFans, setYogaFans] = useState([])

  const [expiresAt] = useState(null);
  const [activateNow] = useState(null);
  // Inside your component:
  const [amountInYearAmount, setAmountInYearAmount] = useState("");  // default to checked
  const [amountInMonthAmount, setAmountInMonthAmount] = useState("");
  const [selectedPayment, setSelectedPayment] = useState(''); // Initialize as empty string or default to the first option



  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedItem, setSelectedItem] = useState('');


const [teacher,setTeacher] = useState('')
const [selectedTeacher,setSelectedTeacher] = useState('')


const [comm,setcom] = useState([])
const [cors,setcors] = useState([])
const [fans,setfans] = useState([])
const [tea,setTea] = useState([])

const [errorCommunityindex,seterrorCommunityindex]=useState(0)
const [errorFanpageindex,seterrorFanpageindex]=useState(0)
const [errorCourseindex,seterrorCourseindex]=useState(0)


  React.useEffect(() => {
    getTeacherDetails()
}, [])

  const getTeacherDetails = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}Admin/getTeachers`, { headers: headerformdata })
      .then((res) => {
        const filteredTeachers = res.data.teachers.filter(
          teacher => teacher.SetVerifyTeacher === true && teacher.bundleApproved === true
        );
        // Set the filtered data
        setTeacher(filteredTeachers);
      })
      .catch((err) => {
        console.log(err);
      });

  };



  React.useEffect(() => {
    getCommunity();
  }, []);

  const getCommunity = async () => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
        `Community/getCommunity`, { headers: headerlocal }
      )
      .then((res) => {


     
        

        const discountOptions = res.data.community.filter(community => 
          community.community_type !== 'public' && community.festiveOffer !== false
      );
        setCommunities(discountOptions)

        localStorage.setItem('communityData', JSON.stringify(res.data.communities));
      })
      .catch((error) => {
        console.log(error, "error in community get");
      });
  };

  React.useEffect(() => {
    CourseTable()
  }, [])


  const CourseTable = () => {
    axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourseallpublic`)
      .then((res) => {
        // setTotal(res.data.totalcount)
        // const paidcourses = res.data.course.filter(course => course.course_paymenttype !== 'free'  && course.festiveOffer !== false);
        const paidcourses = res.data.course.filter(course => course.course_paymenttype !== 'free' );
        setCourses(paidcourses)
       
        
      })
      .catch((err) => {
        console.log(err, "error in CourseTable");
      })
  }

  

  React.useEffect(() => {
    getFans()
  }, [])

  const getFans = async () => {
    await axios.get(process.env.REACT_APP_BASE_URL + `FanPage/GettingallfansPages`).then((res) => {
        
        const paidFanspage = res.data.fansPages.filter(fan => 
          fan.fanspage_paymentype !== 'free' && (fan.festiveOffer !== false && fan.festiveOffer !== undefined )
          );
      setYogaFans(paidFanspage)
    }).catch((error) => {
      console.log(error, "error in service get");
    })
  }





  const handleChangeCouponType = (e) => {
    setSelectedOffer(e.target.value)
    if (e.target.value !== "sel") {
      setErrors(prevErrors => ({ ...prevErrors, selectedOffer: "" }));
    }
    setErrors({
      couponCode: "",
      selectedDiscount: "",
      discountValue: "",
      selectedCommunity: "",
      selectedYogaFans: "",
      selectedCourses: "",
      minimumAmount: "",
      selectedCategory: "",
      selectedItem: "",
      expiresAt: "",
      teacher:"",
      activateNow: "",
      paymentPlan: "",
      notes: ""
    });
  }

  const handleChangeDiscountType = (e) => {
    setSelectedDiscount(e.target.value)
    if (e.target.value !== "sel") {
      setErrors(prevErrors => ({ ...prevErrors, selectedDiscount: "" }));
    }
  }
  
  const handleChangeDiscountValue = (event) => {
    setDiscountValue(event.target.value);
    // Clear the error when a valid input is provided
    if (event.target.value) {
      setErrors(prevErrors => ({ ...prevErrors, discountValue: "" }));
    }
  };

  const handleChangeMinimumAmount = (event) => {
    setMinimumAmount(event.target.value);
    // Clear the error when a valid input is provided
    if (event.target.value) {
      setErrors(prevErrors => ({ ...prevErrors, minimumAmount: "" }));
    }
  };


  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value); // Update selected payment option  
    setAmountInYearAmount(selectedItemObject.amountInYear)
    setAmountInMonthAmount(selectedItemObject.amountInMonth)
    setErrors(prevErrors => ({ ...prevErrors, paymentPlan: "" }));
  };


  const handleTogglePrivate = () => {
		setIsPrivate(!isPrivate)
	}


  const handleIsmerged = ()=>{
    setIsMerged(!isMerged)
  }



  const handleChangeCommunity = (event) => {
    const {
      target: { value },
    } = event;

    const id = event.target.value

    setSelectedCommunity(typeof value === 'string' ? value.split(',') : value);
    const selectedObjects = communities.filter(option => id.includes(option._id));
    setSelectedItemsObjectComm(selectedObjects);

    setErrors({})
  };

  const [selectedItemsPaymentComm, setSelectedItemsPaymentComm] = useState([]); // State to store payment plans for each fan item

  
  const handlePaymentChangeForCommunity = (event,id) => {
    const selectedPaymentPlan = event.target.value;

    
    

  // Get the selected community's details using the id
  const selectedCommunity = selectedItemsObjectComm.find(item => item._id === id);

 


  // Create the updated payment plan object for this specific community
  const updatedPaymentPlan = {
    _id: selectedCommunity._id,
    name: selectedCommunity.coummunity_name,
    amountInYear: selectedCommunity.amountInYear ,
    amountInMonth: selectedCommunity.amountInMonth ,
    amount:selectedPaymentPlan === "month"? selectedCommunity.amountInMonth : selectedPaymentPlan === "year"? selectedCommunity.amountInYear :null,
    image:selectedCommunity.image,
    billingCycle: selectedPaymentPlan, // You might want to update the interval here if needed
    paymentPlan: selectedPaymentPlan, // The selected plan (week/month/year)

  };


  // setAmountInMonthAmount()
  // setAmountInYearAmount()


   // Now check if this community already has a payment plan in the selectedItemsPaymentComm
  // If yes, update the payment plan; if no, add the initial plan to the array
  setSelectedItemsPaymentComm(prevState => {
    const updatedState = [...prevState];

    const existingIndex = updatedState.findIndex(item => item._id === id);

    if (existingIndex !== -1) {
      // Update the payment plan for the existing community item
      updatedState[existingIndex] = updatedPaymentPlan;
    } else {
      // If the payment plan for this community does not exist, add it to the state
      updatedState.push(updatedPaymentPlan);
    }
    return updatedState;
  });
   

  // Reset error message for payment plan
  setErrors(prevErrors => ({ ...prevErrors, Communitypay: '' }));
  seterrorCommunityindex(-1)
  };

 

  const handleChangeYogaFans = (event) => {
    const {
      target: { value },
    } = event;
    const id = event.target.value    
    setSelectedYogaFans(typeof value === 'string' ? value.split(',') : value);
    const selectedObjects = yogaFans.filter(option => id.includes(option._id));
    setSelectedItemsObjectFans(selectedObjects);
  };
  
  const [selectedItemsPaymentForFansPage, setSelectedItemsPaymentForFansPage] = useState([]); // State to store payment plans for each fan item



  const handlePaymentChangeForFansPage = (event, id) => {
    const selectedPaymentPlan = event.target.value;
    
    // Get the selected fan page's details using plan._id
    const selectedFanPage = selectedItemsObjectFans.find(item => item._id === id);
  

  

    // Check if the fan page was found
    if (!selectedFanPage) return;

  
    // Create the updated payment plan object for this specific fan page
    const updatedPaymentPlan = {
      _id: selectedFanPage._id,
      name: selectedFanPage.name,
      amountInYear: selectedFanPage.amountInYear ,
      image:selectedFanPage.image,
      // amountInMonth: selectedFanPage.amountInMonth * 12,
      amountInMonth: selectedFanPage.amountInMonth ,
      paymentPlan: selectedPaymentPlan, // The selected plan (yearly or monthly)
      amount: selectedPaymentPlan === "month"? selectedFanPage.amountInMonth : selectedPaymentPlan === "year"? selectedFanPage.amountInYear :null,
    };
  
    // Update the state to reflect the selected payment plan for this fan
    setSelectedItemsPaymentForFansPage(prevState => {
      const updatedState = [...prevState];
  
      const existingIndex = updatedState.findIndex(item => item._id === id);
  
      if (existingIndex !== -1) {
        // Update the payment plan for the existing community item
        updatedState[existingIndex] = updatedPaymentPlan;
      } else {
        // If the payment plan for this community does not exist, add it to the state
        updatedState.push(updatedPaymentPlan);
      }
      return updatedState;
    });
  
    // Reset error message for payment plan
    setErrors(prevErrors => ({ ...prevErrors, FansPagepay: '' }));
  };

 


  const handleChangeCourses = (event) => {
    const {
      target: { value },
    } = event;
    const id = event.target.value
  

    setSelectedCourses(typeof value === 'string' ? value.split(',') : value);
    const selectedObjects = course.filter(option => id.includes(option._id));
    setSelectedItemsObjectCourse(selectedObjects);
  };


  const [selectedPaymentForCourse, setSelectedPaymentForCourse] = useState([]);

  const handlePaymentChangeForCourse = (event, id) => {
    const selectedPaymentPlan = event.target.value;

 
    
    // Get the selected course's details using id
    const selectedCourse = selectedItemsObjectCourse.find(item => item._id === id);
   
    // Check if the course was found
    if (!selectedCourse) return;
  
    // Create the updated payment plan object for this specific course
    const updatedPaymentPlan = {
      _id: selectedCourse._id,
      name: selectedCourse.course_title,
      image:selectedCourse.image,
      amountInWeek: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInWeek : null,
      amountInBiWeek: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInByWeekly : null,
      amountInMonth: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInMonth : null,
      amountInOneTime: selectedCourse.course_paymenttype === "onetimepayment" ? selectedCourse.amountOneTime : null,
      paymentPlan: selectedPaymentPlan,
      interval: selectedCourse.course_paymenttype === "recurring" ? "recurring" : "onetimepayment"
      
    };
  
    // Update the state for selected payments for courses
    // Update the state to reflect the selected payment plan for this fan
    setSelectedPaymentForCourse(prevState => {
      const updatedState = [...prevState];
  
      const existingIndex = updatedState.findIndex(item => item._id === id);
  
      if (existingIndex !== -1) {
        // Update the payment plan for the existing community item
        updatedState[existingIndex] = updatedPaymentPlan;
      } else {
        // If the payment plan for this community does not exist, add it to the state
        updatedState.push(updatedPaymentPlan);
      }
      return updatedState;
    });
  
    // Reset error message for payment plan
    setErrors(prevErrors => ({ ...prevErrors, CoursePay: '' }));
  };
  
 
  

  const renderValue = (selected, options, placeholder) => {
    if (selected.length === 0) return placeholder;
    const selectedLabels = options
      .filter(option => selected.includes(option._id))
      .map(option => option.coummunity_name);
    return selectedLabels.join(', ');
  };

  const renderValueCourse = (selected, options, placeholder) => {
 
    if (selected.length === 0) return placeholder;
    const selectedLabels = options
      .filter(option => selected.includes(option._id))
      .map(option => option.course_title);
    return selectedLabels.join(', ');
  };

  // yogaFans

  const renderValueFansPage = (selected, options, placeholder) => {
    if (selected.length === 0) return placeholder;
    const selectedLabels = options
      .filter(option => selected.includes(option._id))
      .map(option => option.name);
    return selectedLabels.join(', ');
  };



// ------------------------------------------------------------IMP Functions Start------------------------------------------------------------

//   const handleActivateNowChange = (newValue) => {
//     setActivateNow(newValue);

//     if (!newValue) {
//         setErrors(prev => ({ ...prev, activateNow: "Activation time is required." }));
//         return; // Exit early if no value
//     }

//     if (newValue.isBefore(dayjs())) {
//         setErrors(prev => ({ ...prev, activateNow: "Activation time must be in the future." }));
//     } else if (expiresAt && newValue.isAfter(expiresAt)) {
//         setErrors(prev => ({ ...prev, activateNow: "Activation time must be before the expiration date." }));
//     } else {
//         setErrors(prev => ({ ...prev, activateNow: '' }));
//     }
// };

// const handleExpiresAtChange = (newValue) => {
//     setExpiresAt(newValue);

//     if (!newValue) {
//         setErrors(prev => ({ ...prev, expiresAt: "Expiration date is required." }));
//         return; // Exit early if no value
//     }

//     if (newValue.isBefore(dayjs())) {
//         setErrors(prev => ({ ...prev, expiresAt: "Expiration time must be greater than the current time." }));
//     } else if (activateNow && newValue.isBefore(activateNow)) {
//         setErrors(prev => ({ ...prev, expiresAt: "Expiration time must be greater than the activation time." }));
//     } else {
//         setErrors(prev => ({ ...prev, expiresAt: '' }));
//     }
// };

// ------------------------------------------------------------IMP Functions End------------------------------------------------------------
  
 
const handleItemChange = (event) => {
  setSelectedItem(event.target.value);
  let value = event.target.value;
 

  if (selectedCategory === "Yoga-Fans") {
    const selectedObject = yogaFans.find(option => option._id === value);
    setSelectedItemObject(selectedObject);
    return;
  }
  if (selectedCategory === "Communities") {
    const selectedObject = communities.find(option => option._id === value);
    setSelectedItemObject(selectedObject);
  }
  if (selectedCategory === "Courses") {
    const selectedObject = course.find(option => option._id === value);
  
    
    setSelectedItemObject(selectedObject);
    return
  }

setErrors({selectedItem:''})
// Find the selected object from the yogaFans array

};



const handleCategoryChange = (event) => {
  setSelectedCategory(event.target.value);
  setSelectedItem(''); // Reset selected item when category changes
  setSelectedItemObject(null)
  setErrors({selectedCategory:''})
};  

const renderOptions = () => {
  let options = [];
  if (selectedCategory === 'Communities') {
    options = communities;
  } else if (selectedCategory === 'Yoga-Fans') {
    options = yogaFans;
  } else if (selectedCategory === 'Courses') {
    options = course;
  } 



 if(options.length>0)
 {
  return options.map((option) => (
    <MenuItem key={option._id} value={option._id}>
      <Checkbox checked={selectedItem === option._id} />
      <ListItemText primary={option.coummunity_title || option.name || option.course_title} />
    </MenuItem>
  ));

 }
 else{
  return  <MenuItem disabled>No {selectedCategory} found</MenuItem> 
 }
  
 
};
const getSelectedItemName = () => {
  const selectedOption = (selectedCategory === 'Communities' ? communities :
    selectedCategory === 'Yoga-Fans' ? yogaFans : course
  ).find(option => option._id === selectedItem);

  return selectedOption ? selectedOption.coummunity_title || selectedOption.name || selectedOption.course_title : '';
};


  const [errors, setErrors] = useState({
    couponCode: "",
    selectedDiscount: "",
    discountValue: "",
    selectedCommunity: "",
    selectedYogaFans: "",
    selectedCourses: "",
    minimumAmount:'',
    selectedCategory:"",
    selectedItem:"",
    expiresAt:"",
    activateNow: '',
    teacher:"",
    Communitypay:"",
    FansPagepay:'',
    CoursePay:'',
    notes:''
  });

  const modifyString = str => str.split(" ").map((word, index) => index === 0 ? word.slice(0, -1) : word).join(" ");

  const validateFields = () => {
    const newErrors = { ...errors }; // Start with current errors


    // Reset errors
    Object.keys(newErrors).forEach(key => {
      newErrors[key] = "";
    });

    // Check required fields
    if (!selectedOffer || selectedOffer === "sel") {
      newErrors.selectedOffer = "Offer type is required.";
      
    }

    if (selectedOffer === "item_Level") {
      if (!couponCode) {
        newErrors.couponCode = "Coupon code is required.";
      }

      if (!selectedDiscount || selectedDiscount === "sel") {
        newErrors.selectedDiscount = "Discount type is required.";
      }
   
    

      

      if (!discountValue) {
        newErrors.discountValue = "Discount value is required.";
      }
    

      if(discountValue<=0 && selectedDiscount==="flatDiscount")
        {
      
          newErrors.discountValue = "Discount value must be greater than zero.";
        }
  if(discountValue<=0  && (selectedDiscount==="Percentage"|| (discountValue>=100 && selectedDiscount==="Percentage")))
          {
          
            
            newErrors.discountValue = "Discount value must be greater than 0 and less than or equal to 100.";
          }

      if (!notes) {
        newErrors.notes = "Description is required.";
      }

      if(!selectedCategory){
        newErrors.selectedCategory = "Category is required.";
      }

      if(selectedCategory){
        if(!selectedItem){
          newErrors.selectedItem = `${modifyString(selectedCategory)} is required`;
        }
      }

      if(!selectedPayment&&selectedCategory!=="Courses")
      {
        newErrors.paymentPlan = `Payment Plan is required`;
      }

    


      // if (selectedCommunity.length === 0) {
      //   newErrors.selectedCommunity = "At least one community must be selected.";
      // }

      // if (selectedYogaFans.length === 0) {
      //   newErrors.selectedYogaFans = "At least one Yoga-Fan page must be selected.";
      // }

      // if (selectedCourses.length === 0) {
      //   newErrors.selectedCourses = "At least one course must be selected.";
      // }

      // Validate "Expires At"
      // if (!expiresAt) {
      //   newErrors.expiresAt = "Expiration date is required.";
      // } 
      // Validate "Activate Now"
      // if (!activateNow) {
      //   newErrors.activateNow = "Activation time is required.";
      // }


    }else if(selectedOffer === "cart_Level"){
      if (!couponCode) {
        newErrors.couponCode = "Coupon code is required.";
      }

      if (!selectedDiscount || selectedDiscount === "sel") {
        newErrors.selectedDiscount = "Discount type is required.";
      }

      if (!discountValue) {
        newErrors.discountValue = "Discount value is required.";
      }


      if(discountValue<=0 && selectedDiscount==="flatDiscount")
        {
          newErrors.discountValue = "Discount value must be greater than zero.";
        }
        
        if(discountValue<=0&& (selectedDiscount==="Percentage" ||( discountValue>=100 && selectedDiscount==="Percentage")))
          {
            newErrors.discountValue = "Discount value must be greater than 0 and less than or equal to 100.";
          }
      if (!minimumAmount) {
        newErrors.minimumAmount = "Minimum Amount is Required.";
      }
     


     

      if (!notes) {
        newErrors.notes = "Description is required.";
      }
      //  // Validate "Expires At"
      //  if (!expiresAt) {
      //   newErrors.expiresAt = "Expiration date is required.";
      // } 
      // // Validate "Activate Now"
      // if (!activateNow) {
      //   newErrors.activateNow = "Activation time is required.";
      // }
    }else if(selectedOffer === "bundle_Level"){
      if (!couponCode) {
        newErrors.couponCode = "Coupon code is required.";
      }

      if (!selectedDiscount || selectedDiscount === "sel") {
        newErrors.selectedDiscount = "Discount type is required.";
      }

      if (!discountValue) {
        newErrors.discountValue = "Discount value is required.";
      }
      if (!notes) {
        newErrors.notes = "Description is required.";
      }

      if(discountValue<=0 && selectedDiscount==="flatDiscount")
        {
          newErrors.discountValue = "Discount value must be greater than zero.";
        }
        
        if(discountValue<=0 && (selectedDiscount==="Percentage"|| (discountValue>=100 && selectedDiscount==="Percentage")))
          {
            newErrors.discountValue = "Discount value must be greater than 0 and less than or equal to 100.";
          }
      
    
        
          
     
        if((selectedCommunity.length>0 &&!selectedItemsPaymentComm.length) ||(selectedCommunity.length!==selectedItemsPaymentComm.length && 
          (selectedCommunity.length>selectedItemsPaymentComm.length)) ){

          newErrors.Communitypay = `This Field is required`;
          seterrorCommunityindex(selectedCommunity.length-1)
      
        }
        if((selectedYogaFans.length>0 &&!selectedItemsPaymentForFansPage.length )
          ||(selectedYogaFans.length!==selectedItemsPaymentForFansPage.length&& selectedYogaFans.length>selectedItemsPaymentForFansPage.length  )){
          newErrors.FansPagepay = `This Field is required`;
          seterrorFanpageindex(selectedYogaFans.length-1)
        
        }
        if( (selectedCourses.length>0 &&!selectedPaymentForCourse.length)||(selectedCourses.length!==selectedPaymentForCourse.length&&selectedCourses.length>selectedPaymentForCourse.length )){
        
          
          newErrors.CoursePay = `This Field is required`;
          seterrorCourseindex(selectedCourses.length-1)
          
        }

     
      
       
        if(!(selectedPaymentForCourse.length>=2 &&
          selectedCourses.length>=selectedPaymentForCourse.length) 
          ||(selectedItemsPaymentForFansPage.length>=2&&
          selectedYogaFans.length>=selectedItemsPaymentForFansPage.length )||
            (selectedItemsPaymentComm.length>=2&& 
            selectedCommunity.length>=selectedItemsPaymentComm.length) ) 
        {

          
          if(!(selectedYogaFans.length>0&&selectedCommunity.length>0) )

            {

              if(!(selectedCourses.length>0&&selectedYogaFans.length>0))
              {

                if(!(selectedCourses.length>0&&selectedCommunity.length>0))
                {
                  const hasNoValues = Object.values(newErrors).every(value => !value);
                
                  if (hasNoValues) {
                    newErrors.CoursePay = `This Field is required`;
                    enqueueSnackbar(" Please add atleast 2 products to create a bundle offer.",{variant:"error"})

                  }                                 
                }
              }
            
            }
         

        }
      
      if (!notes) {
        newErrors.notes = "Description is required.";
      }
    }
    else if (selectedOffer === "MultiTeacherbundle_Level") {
      if (!couponCode) {
        newErrors.couponCode = "Coupon code is required.";
      }

      if (!selectedDiscount || selectedDiscount === "sel") {
        newErrors.selectedDiscount = "Discount type is required.";
      }

      if (!discountValue) {
        newErrors.discountValue = "Discount value is required.";
      }


      if (discountValue <= 0 && selectedDiscount==="flatDiscount") {
        newErrors.discountValue = "Discount value must be greater than zero.";
      }

      if (discountValue <= 0 && discountValue >= 100 && selectedDiscount==="Percentage") {
        newErrors.discountValue = "Discount value must be greater than 0 and less than or equal to 100.";
      }

      if (!selectedTeacher.length) {
        newErrors.teacher = 'Teacher is required.'
      }

      selectedItemsPaymentComm.forEach((data, index) => {


        if (data.amount===undefined) {
          newErrors.Communitypay = `This Field is required`;
          seterrorCommunityindex(index)
        }

      })





      selectedItemsPaymentForFansPage.forEach((data, index) => {


        if (data.amount==="") {
          newErrors.FansPagepay = `This Field is required`;
          seterrorFanpageindex(index)
        }

      })
      selectedPaymentForCourse.forEach((data, index) => {


        if (data.paymentPlan===undefined) {
          newErrors.FansPagepay = `This Field is required`;
          seterrorCourseindex(index)
        }

      })







      if (!(selectedYogaFans.length > 0 && selectedCommunity.length > 0)) {

        if (!(selectedCourses.length > 0 && selectedYogaFans.length > 0)) {

          if (!(selectedCourses.length > 0 && selectedCommunity.length > 0)) {
            const hasNoValues = Object.values(newErrors).every(value => !value);

            if (hasNoValues) {
              newErrors.CoursePay = `This Field is required`;
              enqueueSnackbar(" Please add atleast 2 products to create a bundle offer.", { variant: "error" })

            }
          }
        }

      }
      if (!notes) {
        newErrors.notes = "Description is required.";
      }


      
     
    }



    setErrors(newErrors);
    return newErrors;
  };





  // const [priceDetails, setPriceDetails] = useState(null);

  // function calculateTotalPrice(communities,courses,fanspages,discountType,discountAmount) {
  //   // Initialize sums for each category
  //   let communitySum = 0;
  //   let courseSum = 0;
  //   let fanspageSum = 0;

  //   // 1. Calculate the sum for communities
  //   communities.forEach(community => {
     
  //     if (community.paymentPlan === 'week') communitySum += community.amount * 52;
  //     if (community.paymentPlan === 'month') communitySum += community.amount * 12;
  //     if (community.paymentPlan === 'year') communitySum += community.amount;
  //   });


  //   // 2. Calculate the sum for courses
  //   courses.forEach(course => {
  //     if (course.amountInMonth) courseSum += course.amountInMonth * 12;
  //     if (course.amountInWeek) courseSum += course.amountInWeek * 52;
  //     if (course.amountInBiWeek) courseSum += course.amountInBiWeek * 26;
  //     if (course.amountInOneTime) courseSum += course.amountInOneTime;
  //   });



  //   // 3. Calculate the sum for fan pages
  //   fanspages.forEach(fanspage => {

  //     if(fanspage.paymentPlan === 'year') fanspageSum += fanspage.amountInYear;
       
  //     if(fanspage.paymentPlan === 'month') fanspageSum += fanspage.amountInMonth * 12;
  //   });



  
  //   // 4. Calculate the total sum
  //   let totalSum = communitySum + courseSum + fanspageSum;
  
  //   // 5. Apply discount based on discount type
  //   let finalDiscountedPrice = totalSum;
  //   if (discountType === "flatDiscount") {
  //     finalDiscountedPrice = totalSum - (parseFloat(discountAmount) || 0);
  //   } else if (discountType === "Percentage") {
  //     finalDiscountedPrice = Math.floor(totalSum - (totalSum * (parseFloat(discountAmount) / 100)));
  //   }
  
  //   // 6. Return the result with the total and discounted price
  //   return [
  //     {
  //       originalSum:totalSum,
  //       discountedPrice:finalDiscountedPrice,
  //       discountType:selectedDiscount,
  //       discountAmount:discountValue,
  //     }
  //   ];
  // }


  function calculateTotalPrice(communities, courses, fanspages, discountType, discountAmount) {


  


    // Initialize sums for each category
    let communitySum = 0;
    let courseSum = 0;
    let fanspageSum = 0;

    // 1. Calculate the sum for communities
    communities.forEach(community => {
        if (community.paymentPlan === 'week') communitySum += community.amount * 52;
        if (community.paymentPlan === 'month') communitySum += community.amount * 12;
        if (community.paymentPlan === 'year') communitySum += community.amount;
    });

    // 2. Calculate the sum for courses
    courses.forEach(course => {
        if (course.amountInMonth) courseSum += course.amountInMonth * 12;
        if (course.amountInWeek) courseSum += course.amountInWeek * 52;
        if (course.amountInBiWeek) courseSum += course.amountInBiWeek * 26;
        if (course.amountInOneTime) courseSum += course.amountInOneTime;
    });

    // 3. Calculate the sum for fan pages
    fanspages.forEach(fanspage => {
        if (fanspage.paymentPlan === 'year') fanspageSum += fanspage.amountInYear;
        if (fanspage.paymentPlan === 'month') fanspageSum += fanspage.amountInMonth * 12;
    });


    // Calculate total sum across all categories
    let totalSum = communitySum + courseSum + fanspageSum;
    
    // Calculate the total number of items across all arrays
    let totalItems = communities.length + courses.length + fanspages.length;

    // 4. Apply discount based on discount type
    let discountPerItem = 0;
    let finalDiscountedPrice = 0;

    if (discountType === "flatDiscount") {
        // Calculate the flat discount per item
        discountPerItem = (parseFloat(discountAmount) || 0) / totalItems;

        // Apply the discount to each item in the arrays
        communities.forEach(community => {
          if (community.paymentPlan === 'week') community.AfterDiscount =  community.amount * 52 - discountPerItem;
          if (community.paymentPlan === 'month') community.AfterDiscount =  community.amount * 12 - discountPerItem;
          if (community.paymentPlan === 'year') community.AfterDiscount =  community.amount - discountPerItem;
        });
        courses.forEach(course => {
            if (course.amountInMonth) course.AfterDiscount = course.amountInMonth * 12 - discountPerItem;
            if (course.amountInWeek) course.AfterDiscount = course.amountInWeek * 52 - discountPerItem;
            if (course.amountInBiWeek) course.AfterDiscount = course.amountInBiWeek * 26 - discountPerItem;
            if (course.amountInOneTime) course.AfterDiscount = course.amountInOneTime - discountPerItem;
        });
        fanspages.forEach(fanspage => {
            if (fanspage.paymentPlan === 'year') fanspage.AfterDiscount = fanspage.amountInYear - discountPerItem;
            if (fanspage.paymentPlan === 'month') fanspage.AfterDiscount = fanspage.amountInMonth * 12 - discountPerItem;
        });

        // 5. Calculate the final discounted price (after applying discount)
    finalDiscountedPrice = totalSum - discountPerItem * totalItems;
    } else if (discountType === "Percentage") {
        // Apply percentage discount to each item in the arrays
        communities.forEach(community => {

          if (community.paymentPlan === 'week') community.AfterDiscount = community.amount * 52 - (community.amount * 52 * (parseFloat(discountAmount) / 100))
          if (community.paymentPlan === 'month') community.AfterDiscount = community.amount * 12 - (community.amount * 12 * (parseFloat(discountAmount) / 100));
          if (community.paymentPlan === 'year') community.AfterDiscount = community.amount - (community.amount *  (parseFloat(discountAmount) / 100))
            
        });
        courses.forEach(course => {
            if (course.amountInMonth) course.AfterDiscount = course.amountInMonth * 12 - (course.amountInMonth * 12 * (parseFloat(discountAmount) / 100));
            if (course.amountInWeek) course.AfterDiscount = course.amountInWeek * 52 - (course.amountInWeek * 52 * (parseFloat(discountAmount) / 100));
            if (course.amountInBiWeek) course.AfterDiscount = course.amountInBiWeek * 26 - (course.amountInBiWeek * 26 * (parseFloat(discountAmount) / 100));
            if (course.amountInOneTime) course.AfterDiscount = course.amountInOneTime - (course.amountInOneTime * (parseFloat(discountAmount) / 100));
        });
        fanspages.forEach(fanspage => {
            if (fanspage.paymentPlan === 'year') fanspage.AfterDiscount = fanspage.amountInYear - (fanspage.amountInYear * (parseFloat(discountAmount) / 100));
            if (fanspage.paymentPlan === 'month') fanspage.AfterDiscount = fanspage.amountInMonth * 12 - (fanspage.amountInMonth * 12 * (parseFloat(discountAmount) / 100));
        });
        // 5. Calculate the final discounted price (after applying percentage discount)
        finalDiscountedPrice = totalSum - (totalSum * (ceil(discountAmount) / 100));
    }



    // The arrays have been directly updated with the price field


    return [
          {
            originalSum:totalSum,
            discountedPrice:finalDiscountedPrice,
            discountType:selectedDiscount,
            discountAmount:discountValue,
          }
        ];
}


  const handleTeacherChange = (newSelectedTeachers) => {
    setErrors((prev)=>({...prev,teacher: ""}));
    setSelectedTeacher(newSelectedTeachers);
  };

  const handleCommunityChange = (newSelectedCommunity) => {
    setErrors((prev)=>({...prev,paymentPlan: ""}));

    setSelectedCommunity(newSelectedCommunity);


  };

  const handleCourseChange = (newSelectedCourses) => {
    setErrors((prev)=>({...prev,paymentPlan: ""}));

   
    setSelectedCourses(newSelectedCourses);
  };

  const handleYogaFansChange = (newSelectedYogaFans) => {
    
    setErrors((prev)=>({...prev,paymentPlan: ""}));
    
    setSelectedYogaFans(newSelectedYogaFans);
  };



  const handlePaymentPlansChange = (data) => {
  
    setErrors((prev)=>({...prev,paymentPlan: ""}));
    // You can now handle each type of data independently, like:
    // const { communities, courses, yogaFans, teachers } = data;
  
    // Update your state accordingly or handle each one based on your need
    // For example:
    setcom(data.communities);

    setcors(data.courses);
    setfans(data.yogaFans);
    setTea(data.teachers)
    // setSelectedTeachers(teachers);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateFields();

    if (Object.keys(validationErrors).some(key => validationErrors[key])) {
      return; // Exit if there are validation errors
    }

    if (selectedCategory === 'Communities' && (selectedOffer !== "item_Level")) {
      setSelectedCommunity(selectedItem)
    }
     else if (selectedCategory === 'Yoga-Fans' && (selectedOffer !== "item_Level")) {
     
      setSelectedYogaFans((prevState) => [...prevState, selectedItem])
    } 
    else if (selectedCategory === 'Courses' &&  (selectedOffer !== "item_Level")) {
      setSelectedCourses(selectedItem)
    }

   

    let data
    if(selectedOffer === "bundle_Level"){
      const result = calculateTotalPrice(selectedItemsPaymentComm,selectedPaymentForCourse,selectedItemsPaymentForFansPage,selectedDiscount,discountValue);
   
 
      // setPriceDetails(result[0]);
      data = {
        aprrove:true,
         offerType:selectedOffer,
        coupon_code:couponCode,
        discountType:selectedDiscount,
        discountAmount:discountValue,
        // communities:selectedCommunity,
        // fanspages:selectedYogaFans,
        // courses:selectedCourses,
        communities: selectedItemsPaymentComm,
        fanspages:selectedItemsPaymentForFansPage,
        courses: selectedPaymentForCourse,
        expiresAt:expiresAt,
        activate:activateNow,
        notes:notes,
        paymentType:selectedPayment ? selectedPayment : "none",
        amountInYear:amountInYearAmount,
        amountInMonth:amountInMonthAmount,
        minimum_amount:minimumAmount,
        bundle_price:result,
        createdby:params.id
      }
    }else if(selectedOffer === "MultiTeacherbundle_Level"){
      const result = calculateTotalPrice(comm,cors,fans,selectedDiscount,discountValue);
   
      // setPriceDetails(result[0]);
      data = {
        aprrove:true,
         offerType:selectedOffer,
        coupon_code:couponCode,
        discountType:selectedDiscount,
        discountAmount:discountValue,
        // communities:selectedCommunity,
        // fanspages:selectedYogaFans,
        // courses:selectedCourses,
        communities: comm,
        fanspages:fans,
        courses: cors,
        expiresAt:expiresAt,
        activate:activateNow,
        notes:notes,
        teacher:tea,
        paymentType:selectedPayment ? selectedPayment : "none",
        amountInYear:amountInYearAmount,
        amountInMonth:amountInMonthAmount,
        minimum_amount:minimumAmount,
        bundle_price:result,
        createdby:params.id
    }}
    else{


      if(selectedCategory === 'Courses')
      {
        data = {
          aprrove:true,
           offerType:selectedOffer,
          coupon_code:couponCode,
          discountType:selectedDiscount,
          discountAmount:discountValue,
          courses:selectedCategory === 'Courses'? [{_id:selectedItem}] : selectedCourses ,
          expiresAt:expiresAt,
          activate:activateNow,
          notes:notes,
          isPrivate:isPrivate,
          isMerged:isMerged,
          paymentType:selectedItemObject.course_paymenttype,
          amountInYear:amountInYearAmount,
          amountInMonth:selectedItemObject.amountInMonth?selectedItemObject.amountInMonth:"",
          amountOneTime: selectedItemObject.amountOneTime?selectedItemObject.amountOneTime:'' ,
          amountInWeek: selectedItemObject.amountInWeek?selectedItemObject.amountInWeek:"",
          amountInByWeekly: selectedItemObject.amountInByWeekly?selectedItemObject.amountInByWeekly:"",
          minimum_amount:minimumAmount,
          createdby:params.id
        };




      }
      else{
        data = {
          aprrove:true,
           offerType:selectedOffer,
          coupon_code:couponCode,
          discountType:selectedDiscount,
          discountAmount:discountValue,
          // communities:selectedCommunity,
          // fanspages:selectedYogaFans,
          // courses:selectedCourses,
          communities: selectedCategory === 'Communities' ? [{_id:selectedItem}] : selectedCommunity ,
          fanspages:selectedCategory === 'Yoga-Fans'? [{_id:selectedItem}] : selectedYogaFans ,
          courses:selectedCategory === 'Courses'? [{_id:selectedItem}] : selectedCourses ,
          expiresAt:expiresAt,
          activate:activateNow,
          notes:notes,
          isPrivate:isPrivate,
          isMerged:isMerged,
          paymentType:selectedPayment ? selectedPayment : "none",
          amountInYear:amountInYearAmount,
          amountInMonth:amountInMonthAmount,
          minimum_amount:minimumAmount,
          createdby:params.id
        };
      }
      
    }

   


    // // Prepare data for submission
    // const data = {
    //    offerType:selectedOffer,
    //   coupon_code:couponCode,
    //   discountType:selectedDiscount,
    //   discountAmount:discountValue,
    //   // communities:selectedCommunity,
    //   // fanspages:selectedYogaFans,
    //   // courses:selectedCourses,
    //   communities: selectedCategory === 'Communities' ? selectedItem : selectedCommunity ,
    //   fanspages:selectedCategory === 'Yoga-Fans'? selectedItem : selectedYogaFans ,
    //   courses:selectedCategory === 'Courses'? selectedItem : selectedCourses ,
    //   expiresAt:expiresAt,
    //   activate:activateNow,
    //   notes:notes,
    //   paymentType:selectedPayment ? selectedPayment : "none",
    //   amountInYear:amountInYearAmount,
    //   amountInMonth:amountInMonthAmount,
    //   minimum_amount:minimumAmount,
    //   createdby:params.id
    // };

    setTruebutton(true)

    try {
    await axios.post(process.env.REACT_APP_BASE_URL + 'Coupon/addCoupon', data); // Replace with your actual API endpoint
      navigate(`/settings/coupons/${params.id}`)
      enqueueSnackbar("Coupon Added Successfully", { variant: 'success' });
    

      // Handle success (e.g., show a success message, clear the form, etc.)

    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(`${error.response.data.error}`, { variant: 'error' });
      // Handle error (e.g., show an error message)
    }
    finally{
      setTruebutton(false)
    }
  };





  return (
    <>
    <Box className='companies'>
            <MiniDrawer menuOrder={23} submenuOrder={26}/>
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Settings</Typography>                    
                </Box>
                <Box className='whiteContainer' sx={{minHeight:"80vh"}}>
                <Grid item xs={12} sm={6} md={6} lg={12}>
        <Box className="">
          <Box className="mb-10  p-10">
            <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Add Coupons and Offers</Typography>
          </Box>
          <Box className="mb-20 p-10">
            <Box className="mb-10 p-20 whiteContainer ">
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Box className='mb-20'>
                  <FormControl  >
                    <Typography component='div' className='label'>Select The Type of Offer<Typography component='span' className='star'>*</Typography></Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"

                      value={selectedOffer ? selectedOffer : "sel"}
                      placeholder="Select The Type of Offer"
                      className="select"
                      sx={{
                        minWidth: "500px"
                      }}
                      onChange={handleChangeCouponType}
                    >
                      <MenuItem disabled value={'sel'}>Select Offer Type</MenuItem>
                      <MenuItem value={"item_Level"}>Item Level Offer</MenuItem>
                      <MenuItem value={"cart_Level"}>Cart Level Offer</MenuItem>
                      <MenuItem value={"bundle_Level"}>Bundle Level Offer</MenuItem>
                      <MenuItem value={"MultiTeacherbundle_Level"}>Multiple Teacher Bundle Level Offer</MenuItem>
                    </Select>
                      {errors.selectedOffer && (
                        <p style={{ color: 'red' }} className="fz-13 m-0">
                          {errors.selectedOffer}
                        </p>
                      )}
                  </FormControl>
                  </Box>
                  <Box className="mb-20 ">

                  <Typography component='div' className='label'>
                                  <span>Enter {selectedOffer === "bundle_Level" ? "Bundle name" : selectedOffer === "MultiTeacherbundle_Level" ? "Multi Teacher Bundle Name" : "Coupon Code"}</span>
                        <Typography component='span' className='star'>*</Typography>
                        <Typography component='span' style={{ fontSize: 'smaller' }}>
                          {/* This name will be used to create a unique URL for your community */}
                        </Typography>
                      </Typography>
                      <FormControl variant="standard" className='mb-10'>
                        <TextField
                          id="filled-size-small"
                          variant="outlined"
                          size="small"
                          name='name'
                          className='textfield'
                          style={{ width: '500px' }}
                        value={couponCode}
                        onChange={(e) => {
                          setCouponCode(e.target.value)   ;
                          setErrors(prev => ({ ...prev, couponCode: "" }));
                        }}
                        />
                        {errors.couponCode && <p style={{ color: 'red' }} className="fz-13 m-0">{errors.couponCode}</p>}
                      </FormControl>


                      
                      <Typography component='div' className='label'>
                        Choose Discount Type<Typography component='span' className='star'>*</Typography>
                      </Typography>
                      <FormControl className='mb-20' >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"

                          value={selectedDiscount ? selectedDiscount : "sel"}
                          placeholder="Select Discount Type"
                          className="select"
                          sx={{
                            minWidth: "500px"
                          }}
                          onChange={handleChangeDiscountType}
                        >
                          <MenuItem disabled value={'sel'}>Select Discount Type</MenuItem>

                          <MenuItem value={"flatDiscount"}>Flat Discount</MenuItem>
                          <MenuItem value={"Percentage"}>Percentage</MenuItem>
                        </Select>
                        {errors.selectedDiscount && (
                          <p style={{ color: 'red' }} className="fz-13 m-0">
                            {errors.selectedDiscount}
                          </p>
                        )}
                      </FormControl>

                      <Typography component='div' className='label'>
                        <span>Discount Value</span>
                        <Typography component='span' className='star'>*</Typography>
                        <Typography component='span' style={{ fontSize: 'smaller' }}>
                          {/* This name will be used to create a unique URL for your community */}
                        </Typography>
                      </Typography>
                      <FormControl variant="standard" className='mb-10'>
                        <TextField
                          id="filled-size-small"
                          variant="outlined"
                          size="small"
                          name='name'
                          className='textfield'
                          style={{ width: '500px' }}
                          value={discountValue}
                          onChange={handleChangeDiscountValue}
                          InputProps={{
                            startAdornment: selectedDiscount ==="Percentage" ? (
                              <InputAdornment position="start">%</InputAdornment>
                            ) : <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                        {errors.discountValue && (
                          <p style={{ color: 'red' }} className="fz-13 m-0">
                            {errors.discountValue}
                          </p>
                        )}
                      </FormControl>
                  </Box>
                  
                  {/* Conditional Rendering Based on Selected Value */}

{/* ------------------------------------->>************item_Level Start**************<<-------------------------------------------------------- */}


                  {selectedOffer === "item_Level" && (

                    <Box className="mb-20 ">
{/* ------------------------------------Date-Time Picker start-------------------------------------------------------------------- */}
                      

                      {/* <Box> */}
                        {/* Activate Now Field */}
                        {/* <Box className="flex-col mb-20">
                          <Typography
                            component='div' className='label'
                          >
                            Activate from <Typography component='span' className='star'>*</Typography>
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                            sx={{
                              width:"500px"
                            }}
                              size="small"
                              onChange={handleActivateNowChange}
                              value={activateNow}
                              format="DD-MM-YYYY hh:mm A"
                              minDate={dayjs()} // Ensures it's not less than current time
                              ampm={true}
                              renderInput={(params) => (
                                <TextField {...params} className="textfield mr-10 datetimepicker" />
                              )}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              className="textfield mr-10 datetimepicker"
                            />
                            Error message for Activate Now
                            {errors.activateNow && (
                              <Typography variant="body2" color="error">
                                {errors.activateNow}
                              </Typography>
                            )}
                          </LocalizationProvider>
                        </Box> */}



                        {/* Expires At Field */}
                        {/* <Box className="flex-col mb-20">
                          <Typography
                            component='div' className='label'
                          >
                            Expires At <Typography component='span' className='star'>*</Typography>
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                            sx={{
                              width:"500px"
                            }}
                              size="small"
                              onChange={handleExpiresAtChange}
                              value={expiresAt}
                              format="DD-MM-YYYY hh:mm A"
                              minDate={dayjs()} // Ensures it's not less than current time
                              ampm={true}
                              renderInput={(params) => (
                                <TextField {...params} className="textfield mr-10 datetimepicker" />
                              )}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              className="textfield mr-10 datetimepicker"
                            />
                            Error message for Expires At
                            {errors.expiresAt && (
                              <Typography variant="body2" color="error">
                                {errors.expiresAt}
                              </Typography>
                            )}
                          </LocalizationProvider>
                        </Box> */}
                      {/* </Box> */}

{/* ------------------------------------Date-Time Picker End-------------------------------------------------------------------- */}

                      <Typography component='div' className='label'>
                        <span>Select Category</span>
                        <Typography component='span' className='star'>*</Typography>
                        <Typography component='span' style={{ fontSize: 'smaller' }}>
                          {/* This name will be used to create a unique URL for your community */}
                        </Typography>
                      </Typography>
                            <FormControl className='mb-20 mt-5'>
                              <InputLabel id="category-select-label">Select Category</InputLabel>
                              <Select
                                labelId="category-select-label"
                                id="category-select"
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                sx={{ minWidth: "500px" }}
                              >
                                <MenuItem value="Communities">Communities</MenuItem>
                                <MenuItem value="Yoga-Fans">Yoga-Fans Pages</MenuItem>
                                <MenuItem value="Courses">Courses</MenuItem>
                              </Select>
                              {errors.selectedCategory && (
                          <p style={{ color: 'red' }} className="fz-13 m-0">
                            {errors.selectedCategory}
                          </p>
                        )}
                            </FormControl>


                            {selectedCategory && (
                              <>
                                <Typography component='div' className='label'>
                                  <span>{`Select ${selectedCategory}`}</span>
                                  <Typography component='span' className='star'>*</Typography>
                                  <Typography component='span' style={{ fontSize: 'smaller' }}>
                                    {/* This name will be used to create a unique URL for your community */}
                                  </Typography>
                                </Typography>
                                <FormControl className='mb-20 mt-5'>
                                  <InputLabel id="item-select-label">Select {selectedCategory}</InputLabel>
                                  <Select
                                    labelId="item-select-label"
                                    id="item-select"
                                    value={selectedItem}
                                    onChange={handleItemChange}
                                    renderValue={getSelectedItemName} // Display only the selected item's name
                                    sx={{ minWidth: "500px" }}
                                  >
                                    {renderOptions()}
                                  </Select>
                                  {errors.selectedItem && (
                                    <p style={{ color: 'red' }} className="fz-13 m-0">
                                      {errors.selectedItem}
                                    </p>
                                  )}
                                </FormControl>
                              </>
                            )}


                            {selectedItemObject  && (selectedCategory === 'Communities' || selectedCategory === 'Yoga-Fans') && (
                              <>

                                <Typography component="div" className="label">
                                  <span>Select Payment Amount 
                                
                                  </span>
                                  <Typography component='span' className='star'>*</Typography>
                                </Typography>

                                <FormControl className="mb-20 mt-5">
                                  <FormLabel component="legend" className="label">
                                    Choose Payment Plan:
                                  </FormLabel>

                                  {/* Radio group for Payment Options */}
                                  <RadioGroup
                                    value={selectedPayment} // The state variable to track selected payment plan
                                    onChange={handlePaymentChange} // Function to handle radio button selection change
                                  >
                                    {/* Render radio button for amountInYear, if available */}
                                    {selectedItemObject && selectedItemObject.amountInYear ? (
                                      <FormControlLabel
                                        value="year"
                                        control={<Radio />}
                                        label={`Amount Per Year: ${selectedItemObject.amountInYear} ${"$"}`}
                                      />
                                    ) : (
                                      <Typography component="div" className="label">
                                        <span>Amount Per Year: No Amount Available</span>
                                      </Typography>
                                    )}

                                    {/* Render radio button for amountInMonth, if available */}
                                    {selectedItemObject && selectedItemObject.amountInMonth ? (
                                      <FormControlLabel
                                        value="month"
                                        control={<Radio />}
                                        label={`Amount Per Month: ${selectedItemObject.amountInMonth} ${"$"}`}
                                      />
                                    ) : (
                                      <Typography component="div" className="label">
                                        <span>Amount Per Month: No Amount Available</span>
                                      </Typography>
                                    )}

                                  </RadioGroup>

                                  {errors.paymentPlan && (
                                    <p style={{ color: 'red' }} className="fz-13 m-0">
                                      {errors.paymentPlan}
                                    </p>
                                  )}
                                </FormControl>
                              </>
                            )}


                            {selectedItemObject && selectedCategory === 'Courses' &&(

                              <>
                               <Typography component="div" className="label">
                                        <span>Course Payment type:{selectedItemObject.course_paymenttype==='recurring'?" Recurring":" One Time"}</span>
                                      </Typography>
                                    {selectedItemObject.course_paymenttype==='recurring'?(
                                          <Typography component="div" className="label">
                                            <span>{selectedItemObject.amountInByWeekly?`Amount by Weekly:      ${selectedItemObject.amountInByWeekly}`
                                    :selectedItemObject.amountInMonth?`Amount In Month:      ${selectedItemObject.amountInMonth}`
                                   :selectedItemObject.amountInWeek?`Amount In Week:      ${selectedItemObject.amountInWeek}`:"No Amount Available"}</span>
                                </Typography>

                                    ):(


                                      <Typography component="div" className="label">
                                        <span> {`Amount One Time:      ${selectedItemObject.amountOneTime}`}</span>
                                      </Typography>
                                      

                                    )}
                                     
                              
                              </>
                            )
                              
                            }

                            <Box className="mb-20">
                              <FormControlLabel
                                className="checkbox_list"
                                control={
                                  <Checkbox
                                    checked={isPrivate}
                                    onChange={handleTogglePrivate}
                                    name="checkbox-value"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography component="div" className="label mb-0">
                                    Do you want to make this  as Private Coupon ? {" "}
                                  </Typography>
                                }
                              />
                            </Box>


                            <Box className="mb-20">
                              <FormControlLabel
                                className="checkbox_list"
                                control={
                                  <Checkbox
                                    checked={isMerged}
                                    onChange={handleIsmerged}
                                    name="checkbox-value"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography component="div" className="label mb-0">
                                    Do you want to merge this Coupon with Other ? {" "}
                                  </Typography>
                                }
                              />
                            </Box>


                            

                    </Box>
                  )}
{/* ------------------------------------->>************item_Level End**************<<-------------------------------------------------------- */}






{/* ------------------------------------->>************cart_Level Start**************<<-------------------------------------------------------- */}

                  {selectedOffer === "cart_Level" && (
                    <Box className="mb-20 ">

                      <Typography component='div' className='label'>
                        <span>Minimum Amount</span>
                        <Typography component='span' className='star'>*</Typography>
                        <Typography component='span' style={{ fontSize: 'smaller' }}>
                          {/* This name will be used to create a unique URL for your community */}
                        </Typography>
                      </Typography>
                      <FormControl variant="standard" className='mb-10'>
                        <TextField
                          id="filled-size-small"
                          variant="outlined"
                          size="small"
                          name='name'
                          className='textfield'
                          style={{ width: '500px' }}
                          value={minimumAmount}
                          onChange={handleChangeMinimumAmount}
                          InputProps={{
                            startAdornment:<InputAdornment position="start">$</InputAdornment>
                          }}
                        />
                        {errors.minimumAmount && (
                          <p style={{ color: 'red' }} className="fz-13 m-0">
                            {errors.minimumAmount}
                          </p>
                        )}
                      </FormControl>


                      <Box className="mb-20">
                  <FormControlLabel
                    className="checkbox_list"
                    control={
                      <Checkbox
                        checked={isPrivate}
                        onChange={handleTogglePrivate}
                        name="checkbox-value"
                        color="primary"
                      />
                    }
                    label={
                      <Typography component="div" className="label mb-0">
                        Do you want to make this  as Private Coupon ? {" "}
                      </Typography>
                    }
                  />
                  </Box>
                    </Box>
                  )}

{/* ------------------------------------->>************cart_Level End**************<<-------------------------------------------------------- */}





{/* ------------------------------------->>************bundle_Level Start**************<<------------------------------------------------------ */}
                    {selectedOffer === "bundle_Level" && (

                    <Box className="mb-20 ">

                      {/* ----------------------------------------------Communities Start--------------------------------------- */}

                    <Typography component='div' className='label'>
                        Assign Communities
                        {/* <Typography component='span' className='star'>*</Typography> */}
                      </Typography>
                      <FormControl className='mb-20 mt-5'>
                        <InputLabel id="demo-simple-select-label">Select Communities</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          multiple
                          value={selectedCommunity}
                          onChange={handleChangeCommunity}
                          renderValue={() => renderValue(selectedCommunity, communities, 'Select Yoga Communities')}
                          sx={{ minWidth: "500px" }}
                        >
                          {communities.length>0?
                          (communities.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              <Checkbox checked={selectedCommunity.indexOf(option._id) > -1} />
                              <ListItemText primary={option.coummunity_name} />
                            </MenuItem>
                          ))):
                          (
                            <MenuItem disabled>No Communities found</MenuItem> 
                          )}
                        </Select>
                        {/* Display error message if any */}
                    
                      </FormControl>

                      

                      {
                              selectedItemsObjectComm.length > 0 && (
                                <>
                                  <Typography component="div" className="label">
                                    <span></span>
                                    <Typography component="span" className="star"></Typography>
                                  </Typography>
                                  {/* Loop through each item in the selectedItemObject array */}
                                  
                                  {selectedItemsObjectComm?.map((plan, index) => 
                                  
                                  
                                 { 
                                 
                                  
                                  
                                  return (
                                    <>
                                      <Typography component="div" className="label">
                                        <span>{plan.coummunity_name}</span>
                                        <Typography component="span" className="star">*</Typography>
                                      </Typography>
                                      <FormControl className="mb-20 mt-5" key={index}>

                                        <FormLabel component="legend" className="label">
                                          Choose Payment Plan for {plan.coummunity_name}: {/* Display the plan index */}
                                        </FormLabel>

                                        {/* Radio group for Payment Options */}
                                        <RadioGroup
                                          value={selectedItemsPaymentComm.find(item => item._id === plan._id)?.paymentPlan || ''} // The state variable to track selected payment plan
                                          onChange={(event)=>handlePaymentChangeForCommunity(event,plan._id)} // Function to handle radio button selection change
                                        >



                                          {/* Render radio button for amountInYear, if valid */}
                                          {plan.amountInYear ? (
                                            <FormControlLabel
                                              value={`year`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`Amount Per Year: ${plan.amountInYear} USD`}
                                            />
                                          ) : (
                                            <Typography component="div" className="label">
                                              <span>Amount Per Year: No Amount Available</span>
                                            </Typography>
                                          )}

                                          {/* Render radio button for amountInMonth, if valid */}
                                          {plan.amountInMonth ? (
                                            <FormControlLabel
                                              value={`month`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`Amount Per Month: ${plan.amountInMonth} USD`}
                                            />
                                          ) : (
                                            <Typography component="div" className="label">
                                              <span>Amount Per Month: No Amount Available</span>
                                            </Typography>
                                          )}
                                        </RadioGroup>
                                        {errors.Communitypay && errorCommunityindex===index && (
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.Communitypay}
                                          </p>
                                        )}
                                      
                                      </FormControl>
                                     
                                    </>
                                  )})}

                                </>
                              )
                            }

                      {/* ----------------------------------------------Communities End--------------------------------------- */}


                      {/* ----------------------------------------------Yoga-Fans Start--------------------------------------- */}

                      <Typography component='div' className='label'>
                        Assign Yoga-Fan Pages
                      </Typography>
                      <FormControl className='mb-20 mt-5'>
                        <InputLabel id="yoga-fans-select-label">Select Yoga-Fans Pages</InputLabel>
                        <Select
                          labelId="yoga-fans-select-label"
                          id="yoga-fans-select"
                          multiple
                          value={selectedYogaFans}
                          onChange={handleChangeYogaFans}
                          renderValue={() => renderValueFansPage(selectedYogaFans, yogaFans, 'Select Yoga-Fans Pages')}
                          sx={{ minWidth: "500px" }}
                        >
                          {yogaFans.length>0?
                          (yogaFans.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              <Checkbox checked={selectedYogaFans.indexOf(option._id) > -1} />
                              <ListItemText primary={option.name} />
                            </MenuItem>
                          ))):
                          (
                            <MenuItem disabled>No Yoga-Fan Pages found</MenuItem> 
                          )}
                        </Select>
                           {/* Display error message if any */}
                           
                      </FormControl>       
                          



                            {
                              selectedItemsObjectFans.length > 0 && (
                                <>
                                  <Typography component="div" className="label">
                                    <span></span>
                                    <Typography component="span" className="star"></Typography>
                                  </Typography>
                                  {/* Loop through each item in the selectedItemObject array */}
                                  {selectedItemsObjectFans?.map((plan, index) => (
                                    <>
                                      <Typography component="div" className="label">
                                        <span>{plan.name}</span>
                                        <Typography component="span" className="star">*</Typography>
                                      </Typography>
                                      <FormControl className="mb-20 mt-5" key={index}>

                                        <FormLabel component="legend" className="label">
                                          Choose Payment Plan for {plan.name}: {/* Display the plan index */}
                                        </FormLabel>

                                        {/* Radio group for Payment Options */}
                                        <RadioGroup
                                          value={selectedItemsPaymentForFansPage.find(item => item._id === plan._id)?.paymentPlan || ''} // The state variable to track selected payment plan
                                          onChange={(event)=>handlePaymentChangeForFansPage(event,plan._id)} // Function to handle radio button selection change
                                        >
                                          {/* Render radio button for amountInYear, if valid */}
                                          {plan.amountInYear ? (
                                            <FormControlLabel
                                              value={`year`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`Amount Per Year: ${plan.amountInYear} ${plan.currency}`}
                                            />
                                          ) : (
                                            <Typography component="div" className="label">
                                              <span>Amount Per Year: No Amount Available</span>
                                            </Typography>
                                          )}

                                          {/* Render radio button for amountInMonth, if valid */}
                                          {plan.amountInMonth ? (
                                            <FormControlLabel
                                              value={`month`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`Amount Per Month: ${plan.amountInMonth} ${plan.currency}`}
                                            />
                                          ) : (
                                            <Typography component="div" className="label">
                                              <span>Amount Per Month: No Amount Available</span>
                                            </Typography>
                                          )}
                                        </RadioGroup>
                                        {errors.FansPagepay && errorFanpageindex===index &&(
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.FansPagepay}
                                          </p>
                                        )}
                                        
                                      </FormControl>
                                    </>
                                  ))}

                                </>
                              )
                            }

                      
                      {/* --------------------------------------Yoga-Fans End---------------------------------------- */}


                      <Typography component='div' className='label'>
                        Assign Courses
                      </Typography>
                      <FormControl className='mb-20 mt-5'>
                        <InputLabel id="courses-select-label">Select Courses</InputLabel>
                        <Select
                          labelId="courses-select-label"
                          id="courses-select"
                          multiple
                          value={selectedCourses}
                          onChange={handleChangeCourses}
                          renderValue={() => renderValueCourse(selectedCourses, course, 'Select Courses')}
                          sx={{ minWidth: "500px" }}
                        >
                          {course.length>0?
                          
                          (course.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              <Checkbox checked={selectedCourses.indexOf(option._id) > -1} />
                              <ListItemText primary={option.course_title} />
                            </MenuItem>
                          ))):
                          (
                            <MenuItem disabled>No Courses found</MenuItem> 
                          )}
                        </Select>
                         {/* Display error message if any */}
                      
                      </FormControl>
                            {
                              selectedItemsObjectCourse.length > 0 && (
                                <>
                                  <Typography component="div" className="label">
                                    <span></span>
                                    <Typography component="span" className="star"></Typography>
                                  </Typography>
                                  {/* Loop through each item in the selectedItemObject array */}
                                  {selectedItemsObjectCourse?.map((plan, index) => (
                                    <>
                                      <Typography component="div" className="label">
                                        <span>{plan.course_title}</span>
                                        <Typography component="span" className="star">*</Typography>
                                      </Typography>
                                      <FormControl className="mb-20 mt-5" key={index}>

                                        <FormLabel component="legend" className="label">
                                          Choose Payment Plan for {plan.course_title}: {/* Display the plan index */}
                                        </FormLabel>

                                        {/* Radio group for Payment Options */}
                                        <RadioGroup
                                          value={selectedPaymentForCourse.find(item => item._id === plan._id)?.paymentPlan || ''} // The state variable to track selected payment plan
                                          onChange={(event) => handlePaymentChangeForCourse(event, plan._id)} // Function to handle radio button selection change
                                        >
                                          {/* Render radio button for week, if valid */}
                                          {plan.course_paymenttype === "recurring" ? (
                                            <>
                                              {plan.amountInWeek &&
                                                <FormControlLabel
                                                  value={`week`} // Unique value for each plan
                                                  control={<Radio />}
                                                  label={`Amount Per Week: ${plan.amountInWeek} ${plan.currency}`}
                                                />}
                                              {
                                                plan.amountInByWeekly &&
                                                <FormControlLabel
                                                  value={`biWeek`} // Unique value for each plan
                                                  control={<Radio />}
                                                  label={`Amount Per BiWeek: ${plan.amountInByWeekly} ${plan.currency}`}
                                                />
                                              }

                                              {
                                                plan.amountInMonth &&
                                                <FormControlLabel
                                                  value={`month`} // Unique value for each plan
                                                  control={<Radio />}
                                                  label={`Amount Per Month: ${plan.amountInMonth} ${plan.currency}`}
                                                />
                                              }
                                            </>
                                          ) : ''}

                                          {/* Render radio button for onltime, if valid */}
                                          {plan.course_paymenttype === "onetimepayment" ? (
                                            <FormControlLabel
                                              value={`OneTime`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`One Time Amount: ${plan.amountOneTime} ${plan.currency}`}
                                            />
                                          ) : ''}

                                        </RadioGroup>
                                        {errors.CoursePay && errorCourseindex===index&&(
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.CoursePay}
                                          </p>
                                        )}

                                      </FormControl>
                                    </>
                                  ))}

                                </>
                              )
                            }
                    </Box>
                    )}

 {/* --------------------------------------Multiple Teacher Bundle---------------------------------------- */}



                        {
                          selectedOffer === "MultiTeacherbundle_Level" && (

                            <Box className="mb-20 ">

                              <AddMultipleTeacherBundle
                                teacher={teacher}  // Replace with your actual data
                                communities={communities}  // Replace with your actual data
                                course={course}  // Replace with your actual data
                                yogaFans={yogaFans}  // Replace with your actual data
                                selectedTeacher={selectedTeacher}
                                selectedCommunity={selectedCommunity}
                                selectedCourses={selectedCourses}
                                selectedYogaFans={selectedYogaFans}
                                handleTeacherChange={handleTeacherChange}
                                handleCommunityChange={handleCommunityChange}
                                handleCourseChange={handleCourseChange}
                                handleYogaFansChange={handleYogaFansChange}
                                setSelectedItemsPaymentComm={setSelectedItemsPaymentComm}
                                setSelectedItemsPaymentForFansPage={setSelectedItemsPaymentForFansPage}
                                setSelectedPaymentForCourse={setSelectedPaymentForCourse}
                                selectedItemsPaymentComm={selectedItemsPaymentComm}
                                selectedItemsPaymentForFansPage={selectedItemsPaymentForFansPage}
                                selectedPaymentForCourse={selectedPaymentForCourse}
                                handlePaymentPlansChange={handlePaymentPlansChange}
                                errors={errors}
                                errorCommunityindex={errorCommunityindex}
                                errorFanpageindex={errorFanpageindex}
                                errorCourseindex={errorCourseindex}
                              />

                            </Box>

                          )
                        }

 {/* --------------------------------------Multiple Teacher Bundle End---------------------------------------- */}



                        <Box className="mb-20">
                        <Typography component='div' className='label'>
                              <span>Description</span>
                              <Typography component='span' className='star'>*</Typography>
                              <Typography component='span' style={{ fontSize: 'smaller' }}>
                                {/* This name will be used to create a unique URL for your community */}
                              </Typography>
                            </Typography>
                            <FormControl variant="standard" className='mb-10'>
                              <TextField
                                id="filled-size-small-textarea"
                                variant="outlined"
                                size="small"
                                name='notes'
                                className='textfield'
                                style={{ width: '500px' }}
                                multiline
                                rows={3} // Adjust the number of rows as needed
                                value={notes}
                                onChange={(e) => {
                                  setNotes(e.target.value);
                                  setErrors(prev => ({ ...prev, notes: "" }));
                                }}
                              />
                              {errors.notes && <p style={{ color: 'red' }} className="fz-13 m-0">{errors.notes}</p>}
                            </FormControl>
                        </Box>

                </FormGroup>


{/* ------------------------------------->>************bundle_Level End**************<<-------------------------------------------------------- */}
{
  truebutton?
  <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 4,
                    pt: 3
                  }}
                >
                  <Button  variant="contained" className='addbtn mr-10'>
                    Please wait...
                  </Button>
                </Box>
                :
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 4,
                    pt: 3
                  }}
                >
                  <Button type="submit" variant="contained" className='addbtn mr-10'>
                    Submit
                  </Button>
                </Box>

}
                
              </form>
            </Box>


          </Box>
        </Box>
      </Grid>
                </Box>
            </Box>
        </Box>
      

    </>
  )
}

export default CouponandOffersettings