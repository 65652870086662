import React, { useState } from "react";


import Sidebar from '../Sidebar'
import MiniDrawer from '../../../../components/drawer'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'

import { Box, Button,  Grid, Typography } from '@mui/material'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Add_Video from './EditVideo'



// const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
//     [`&.${stepConnectorClasses.alternativeLabel}`]: {
//         top: 22,
//     },
//     [`&.${stepConnectorClasses.active}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             backgroundColor:
//                 '#E80A89',
//         },
//     },
//     [`&.${stepConnectorClasses.completed}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             backgroundColor:
//                 '#E80A89',
//         },
//     },
//     [`& .${stepConnectorClasses.line}`]: {
//         height: 3,
//         border: 0,
//         backgroundColor:
//             theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//         borderRadius: 1,
//     },
// }));

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

function Service_Steppyform() {
    // const headerlocal = {
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*",
    //     Authorization: localStorage.getItem("token")
    // }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [videos, setVideos] = useState([])
    // const [community, setCommunity] = React.useState([])
    // const [user, setUser] = React.useState([])
    const params = useParams()
    const [addVideoFrom] = useState('Community')
    
    const [imgraw,setImgraw]=useState()
    const[vidoeraw,setvideoRaw]=useState()

    const [error, setError]=useState({img:'',video:''})
const [truebutton,settruebutton]=useState(false)

    const validatenew = () => {
        
     
        

        let validatenew= true

       
            if(!imgraw)
            {
                setError({img:"This field is required."})
                
            validatenew=false;
            return
            }
            if(!vidoeraw)
                {
                    setError({ video: "This field is required." })
                
                validatenew=false;
                return;
                }
       
        

     
        return validatenew;
    }

    const handleAddVideo = () => {
        const imgrawArray = videos.map(video => video.imgraw);
        const vdorawArray = videos.map(video => video.vdoraw);
        var valid = validatenew();

        if (valid === true) {
            settruebutton(true)
            const formData = new FormData();
            formData.append('createdby[]', params && params.id);
            formData.append('addVideoFrom',addVideoFrom )
            formData.append('community_id[]', params && params.id)
          // eslint-disable-next-line 
            { imgrawArray ? imgrawArray.forEach((item) => { formData.append("thumbnail", item) }) : formData.append("thumbnail", '') }
            // eslint-disable-next-line 
            { vdorawArray ? vdorawArray.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') }
        
            axios.post(process.env.REACT_APP_BASE_URL + `video/addVideo`, formData, { headers: headerformdata })
                .then((res) => {
                    settruebutton(false)
                    navigate(`/community/community-details/videotable/${params.id}`);
                    enqueueSnackbar("Video added successfully", { variant: 'success' })
                   
                })
                .catch((err) => {
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(err, "error");
                   settruebutton(false)
                })
        }
    }

    const handleparent = (value) => {
        const newData = value.map(obj => ({
            "imgraw": obj.imgraw,
            "vdoraw": obj.vdoraw
        }));
        value.forEach(obj=>{
            setImgraw(obj.imgraw)
            
            
            setvideoRaw(obj.vdoraw)
          
        })
        
        
        setVideos(newData);

    }

    return (
        <Box className='withSubmenu'>
            <MiniDrawer menuOrder={7} />
            <Sidebar subtype={4} />
            <Box component="section" className="contentWraper">
                <Box className=''>
                    <Typography component='h6' className='formTitle'>Add Video</Typography>

                    <Box className="whiteContainer mt-20 mb-10 p-20">
                        <Box className=" mb-10">
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <label className='form-labels ' for="my-input" >Videos <Typography component='span' className='star'> * </Typography></label>
                                <Box className="mt-20">
                                
                                    <Add_Video parentFunc={handleparent} error={error} setErro={setError}/>
                                  
                                </Box>
                            </Grid>
                        </Box>


                        {truebutton===false?(
                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>


                        <Button onClick={handleAddVideo} variant="contained" className='theme-btn'>Submit</Button>
                            </Box>
                        ):(
                  <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>


               <Button disabled variant="contained" className='theme-btn'>Please wait...</Button>
              </Box>
                        )}
                       
                    </Box>

                  
                </Box>
            </Box >
        </Box >
    )
}

export default Service_Steppyform;
