
import { IoIosAdd } from "react-icons/io";
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable'

export default function Tag() {
    let navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem("roledata"))

    //To Navigate:
    const addTag = () => {
        navigate('/addyogaquote');
    }


    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={1} submenuOrder={2} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Yoga Quotes</Typography>
                    {role.UserRoles == null ? <Button
                        variant="contained"
                        className='addbtn'
                        onClick={addTag}
                        style={{ paddingLeft: '8px', paddingRight: '8px' }}
                    >
                        <IoIosAdd style={{ fontSize: '23px' }} className='mr-2' />
                        Add Yoga Quotes
                    </Button> :
                        role.UserRoles != null && role.UserRoles.quotes.AddAquotes &&
                        <Button
                            variant="contained"
                            className='addbtn'
                            onClick={addTag}
                            style={{ paddingLeft: '8px', paddingRight: '8px' }}
                        >
                            <IoIosAdd style={{ fontSize: '23px' }} className='mr-2' />
                            Add Yoga Quotes
                        </Button>}
                </Box>
                <Box className='whiteContainer'>
                    <DataTable />
                </Box>
            </Box>
        </Box>
    )
}