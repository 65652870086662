import React, { useEffect, useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Cell
} from 'recharts';
import { FormControl, Select, MenuItem, Box, Typography, Autocomplete, TextField } from '@mui/material';
import axios from 'axios';

const ElearningChart = () => {
    const [selectedPeriod, setSelectedPeriod] = useState(3); // Default to Last 3 months
    const [selectedSegment, setSelectedSegment] = useState(null); // Default value null (no segment selected initially)
    const [teachers, setTeachers] = useState([]);
    const [teachermerit, setteachermerit] = useState([]);
    const [Legend, setLegend] = useState([]);
    const [LinechartData, setLineChartData] = useState([]);
    const [fullData, setFullData] = useState([]);

    const [showdefaultvalue, setshowdefaultvalue] = useState(true)

    // Fetch teacher data and set default value
    useEffect(() => {
        GetDropdownnames();
        if (showdefaultvalue) {
            defaultvalue();
        }
    }, [selectedPeriod]);

    const GetDropdownnames = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}Admin/allteachers`)
            .then((res) => {


                setTeachers(res.data.teachers[0].content);
                setteachermerit(res.data.teachers[1].content);
                setLegend(res.data.teachers[2].content);
            })
            .catch(() => {
                console.log('Error fetching teacher data');
            });
    };

    const handleSelectChange = (event) => {


        if (showdefaultvalue) {
            setSelectedPeriod(event.target.value);
            defaultvalue(event.target.value)

        }
        else {

            setSelectedPeriod(event.target.value);
            setLineChartData(fullData.slice(0, event.target.value));
        }

    };


    const handleSegmentChange = (event, newValue) => {




        if (newValue === null) {
            setSelectedSegment(null);
            defaultvalue();
        } else {
            setSelectedSegment(newValue);
            GetCourse(newValue._id);

        }
    };

    const defaultvalue = (selectedPeriod) => {


        axios.get(`${process.env.REACT_APP_BASE_URL}Course1/TeacherCharts?months=${selectedPeriod}`)
            .then((res) => {


                setFullData(res.data);
                setLineChartData(res.data.slice(0, selectedPeriod)); // Set data with default period
            })
            .catch((err) => {
                console.log('Error fetching default data', err);
            });
    };

    const GetCourse = (id) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}Course1/CourseCharts1/${id}`)
            .then((res) => {




                setFullData(res.data);
                setLineChartData(res.data.slice(0, selectedPeriod));
                setshowdefaultvalue(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Box

        >
            <Box className="flex-center flex-between flex-wrap mb-20">
                <Typography
                    variant="h6"
                    component="h6"
                    className="fz-18 fw-600 "
                >
                    E-learning
                </Typography>
                <Box className="flex-center flex-wrap flex-sm-nowrap">
                    <FormControl fullWidth className="ml-10 mr-10 minw200">
                        <Autocomplete
                            value={selectedSegment}
                            onChange={handleSegmentChange}
                            options={[...teachers, ...Legend, ...teachermerit]} // Include teachers and legends
                            // disableClearable
                            freeSolo
                            groupBy={(option) => {
                                if (teachers && teachers.length > 0 && teachers.includes(option)) return 'Teachers';
                                if (Legend && Legend.length > 0 && Legend.includes(option)) return 'Legends';
                                if (teachermerit && teachermerit.length > 0 && teachermerit.includes(option)) return 'Teacher Of Great Merit';
                                return '';
                            }}
                            getOptionLabel={(option) => option?.username || option || ''}
                            isOptionEqualToValue={(option, value) =>
                                option.username === value.username || option === value
                            }
                            filterOptions={(options, state) => {
                                return options.filter((option) =>
                                    (option?.username?.toLowerCase() || option.username?.toLowerCase())?.includes(state.inputValue.toLowerCase())
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className='textfield xs-textfield'
                                    placeholder='Search...'
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth className='minw200'>
                        <Select
                            value={selectedPeriod}
                            onChange={handleSelectChange}
                            className="select"
                        >
                            <MenuItem value={3}>Last 3 Months</MenuItem>
                            <MenuItem value={6}>Last 6 Months</MenuItem>
                            <MenuItem value={9}>Last 9 Months</MenuItem>
                            <MenuItem value={12}>Last 12 Months</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>


            <Box className="position_rel flex-center justify-content-center mt-20 p-20 border borderR_4" >
                <BarChart
                    width={600}
                    height={300}
                    data={LinechartData}
                    layout="vertical"
                >
                    {/* Y-axis for course names */}
                    <YAxis
                        dataKey="courseName"
                        type="category"
                        tickLine={false}
                        width={120}
                    />

                    {/* X-axis for active member count */}
                    <XAxis
                        dataKey="Count"
                        type="number"
                        tickLine={false}
                    />

                    <CartesianGrid
                        strokeDasharray="3"
                        vertical={false}
                    />

                    <Tooltip />

                    {/* Bar Component with dynamic colors */}
                    <Bar
                        dataKey="Count"
                        barSize={20}
                        label={false}
                    >
                        {LinechartData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={entry.fill || '#000000'}
                            />
                        ))}
                    </Bar>
                </BarChart>

                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        position: "absolute",
                        left: "20px",
                        top: "20%",
                        transform: "translateY(100%) rotate(180deg)", // Added rotation here
                        writingMode: "vertical-rl",
                        color: "#000",
                        zIndex: 8,
                        letterSpacing: "0.5px",
                        fontWeight: "bold"
                    }}
                    className="fz-14 fw-600"
                >
                    Courses
                </Typography>
                <Typography
                    className="fz-14 fw-600"
                    variant="h6"
                    component="h6"
                    sx={{
                        position: "absolute",

                        left: "50%",

                        color: "#000",
                        zIndex: 8,
                        letterSpacing: "0.5px",
                        bottom: "10px",

                    }}
                >
                    Course Count
                </Typography>
            </Box>
        </Box>
    );
};

export default ElearningChart;