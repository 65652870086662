
import React, {  useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../components/drawer';
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, TextField, Radio, Typography, RadioGroup, Input, Checkbox, FormControlLabel } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import 'swiper/css/navigation';



const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

const steps = ['Basic Information', 'News Information'];

export default function Service_Steppyform() {
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let location = useLocation()
    const [activeStep, setActiveStep] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar()
    const [news, setNews] = React.useState({ title: location.state.data.news_title, journalist: location.state.data.journalist })
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({ title: "" })
    const [categoryId, setCategoryId] = React.useState(location.state.data.category_id)
    console.log("location.state.datalocation.state.data",location.state.data);
        const [videoAddError, setVideoAddError] = React.useState('')
    const [subcategoryId, setSubCategoryId] = React.useState(location.state.data.Subcategory_id ? location.state.data.Subcategory_id : "")
    const [catError, setCatError] = React.useState('')
    const [subcatError, setSubCatError] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [state1, setState1] = React.useState(location.state.data.additionalimages)
    const [state2, setState2] = React.useState(location.state.data.additionalimages)
    const [existingimages] = React.useState(location.state.data.additionalimages)
    const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
    const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
    const [description, setDescription] = React.useState(location.state.data.description)
    const [imageAddError, setImageAddError] = React.useState('')
    const [selectedVideo, setSelectedVideo] = useState([...[], location.state.data.Video]);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState([...[], location.state.data.Video]);
    const [isPrimary, setPrimary] = React.useState(location.state.data.Primary)
    const [selectGallert, setSelectGallery] = useState(location.state.data.gallery)
    const [subcat, setSubcat] = React.useState([])
    const [category, setCategory] = React.useState([])
    const [isToggled, setToggled] = React.useState(location.state.data.verifyNews)
    const [isPublic, setPublic] = React.useState(location.state.data.mega_news)


    const handleToggle = () => {
        setToggled(!isToggled)
    }

    const handleRemoveRestart = () => {
        setSubCategoryId('')
    }

    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }

    const handleTogglePublic = () => {
        setPublic(!isPublic)
    }

    const handleEditGallery = (e) => {
        setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')
    }



    const handleVideoChange = (e, index) => {
        // setImageError('')
        setError({ video: '' });
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files);
        for (let i = 0; i < fileObj[0].length; i++) {
            if (fileObj[0][i].size > 25 * 1024 * 1024) { // Corrected comparison
                setError({ video: 'Video should be less than 25 MB' });
                return;
            }
            fileArray.push(URL.createObjectURL(fileObj[0][i]));
            filepath.push(fileObj[0][i]);
        }

        setVideoPreviewUrl(fileArray);
        setSelectedVideo(filepath);
    }

    const clearVideo = (e, index) => {
        setSelectedVideo([]);
        setVideoPreviewUrl([]);
    };

    const handleUpdateNews = async () => {
        var multipleImage = state2.filter(x => x != null);
        var valid = validate()
        var valid11 = validatenew()
        if (valid===true && valid11===true) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("news_title", news.title)
            formData.append("journalist", news.journalist)
            formData.append("category_id", categoryId)
            formData.append("Subcategory_id", subcategoryId)
            formData.append("description", description)
            formData.append("verifyNews", isToggled)
            formData.append("Primary", isPrimary)
            formData.append("mega_news", isPublic)
            formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')

            { statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
            {
                selectGallert === 'Image' ? <>
                    {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
                    {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
                    {existingimages ? existingimages.forEach((item) => { formData.append("existingimages", item) }) : formData.append("existingimages", '')}
                </>
                    :
                    <>
                        {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
                        {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}
                        {existingimages ? existingimages.forEach((item) => { formData.append("existingimages", '') }) : formData.append("existingimages", '')}
                    </>
            }
            
            await axios.put(process.env.REACT_APP_BASE_URL + `News/updateNews/${location.state.data._id}`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    navigate('/news');
                    setNews({ title: "", description: "", journalist: "" })
                    setCategoryId('');
                    setSubCategoryId('');
                    setState1([])
                    setState2([])
                    enqueueSnackbar("News updated successfully", { variant: 'success' })
            
                }).catch((err) => {
                    setTruebutton(false)
                    enqueueSnackbar(err.response.data.error, { variant: 'error' })
                    // enqueueSnackbar(err.response && err.response.data.message ? err.response.data.message : "Something went wrong.", { variant: 'error' })
                    console.log(err.response.data.error, "error");
                })
        }
    }


    const validate = () => {
        if (!news.title && statesingle2?.length===0) {
            setError({ title: "This field is required." })
            setImageError("This field is required.")
            return false
        }
        if (!news.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (!categoryId) {
            setCatError("This field is required.")
        }
        if (statesingle2?.length===0) {
            setImageError("This field is required.")
            return false
        }
        return true
    }

    const validatenew = () => {
      
        if (state2.length===0 && selectedVideo.length===0) {
            setImageAddError("This field is required.")
            setVideoAddError("Video field is required.")
    
            
            return false
        }
       
        
        return true
    }

    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setNews({ ...news, [name]: value })
    }

    React.useEffect(() => {
        getCategory()
    }, [])

    React.useEffect(() => {
        getSubCategory()
    }, [categoryId])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
            setCategory(res.data.category)
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }

    const getSubCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
            .then((res) => {
                setSubcat(res.data.subcategory)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const maxFileSizeMB = 1; // Maximum file size allowed in MB

    const validateFileSize = (file) => {
        const fileSizeMB = file.size / (1024 * 1024); // Calculate file size in MB
        return fileSizeMB <= maxFileSizeMB;
    };

    const multipleImgChangeMultiple = (e, index) => {
        setImageAddError('');
        var fileObj = e.target.files;
        var fileArray = [];
        var filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            if (validateFileSize(fileObj[i])) {
                fileArray.push(URL.createObjectURL(fileObj[i]));
                filepath.push(fileObj[i]);
            } else {
                setImageAddError('Image size should be less than 1 MB.');
            }
        }

        // Continue with state updates only for valid files
        if (fileArray.length > 0) {
            var data = state1.filter(x => x != null);
            var data1 = state2.filter(x => x != null);
            setState1(data);
            setState2(data1);
            setState1(prevValue => prevValue.concat(fileArray));
            setState2(prevValue => prevValue.concat(filepath));
        }
    };

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const handleNext = () => {
        var valid = validate()
        if (valid === true) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const CategoryData = (e) => {
        setSubCategoryId('')
        setCategoryId(e.target.value);
        setCatError('');
        setError({ title: '' });
        setCatError('');
        setSubCatError('')
    }


    const SingleImage = (e, index) => {
        setImageError('');
        const fileObj = e.target.files;

        const fileArray = [];
        const filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            let file = fileObj[i];
            // Check if file size is less than 1MB (1000000 bytes)
            if (file.size > 1000000) {
                setImageError("Image should be less than 1 MB");
                continue; // Skip adding this file to state
            }

            fileArray.push(URL.createObjectURL(file));
            filepath.push(file);
        }

        // Updating states based on your logic


        setStatesingle(prevValue => [...prevValue, ...fileArray]);
        setStatesingle2(prevValue => [...prevValue, ...filepath]);
    };

    return (
        <Box className=''>
            <MiniDrawer menuOrder={11} submenuOrder={19}/>
            <Box component="section" className="contentWraper">
                <Box className='whiteContainer p-20'>
                    <Typography component='h6' className='formTitle'>Edit News</Typography>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 &&
                        <Box className='steppy_content'>
                            <Box className="maxw_700">
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            name='title'
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={news.title}
                                            onChange={(e) => {
                                                handlechange(e); setError(prevError => ({ ...prevError, title: '' }))
                                            }}
                                        />
                                        {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                    </FormControl>
                                </Box>

                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box className='mb-10 d-flex align-items-start justify-content-between' >
                                        <Box className=' text-right' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography component='div' variant='body1' className='fz-14 '>News Verified?  </Typography>
                                            <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<IOSSwitch onClick={handleToggle} checked={isToggled} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Box>
                                </Grid> */}

                                <Box className='mb-20'>
                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isToggled}
                                                onChange={handleToggle}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to verify this News? </Typography>}
                                    />

                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isPrimary}
                                                onChange={handleTogglePrimary}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to make this as Primary? </Typography>}
                                    />

                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isPublic}
                                                onChange={handleTogglePublic}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to make this as Mega News? </Typography>}
                                    />
                                </Box>

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Journalist Name</Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            placeholder='Name'
                                            size="small"
                                            name='journalist'
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={news.journalist}
                                            onChange={(e) => {
                                                handlechange(e);
                                            }}

                                        />
                                        {/* {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>} */}
                                    </FormControl>
                                </Box>

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl fullWidth >
                                        <Select
                                            className="select"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={categoryId ? categoryId : 'sel'}
                                            onChange={(e) => { CategoryData(e); }}>
                                            <MenuItem disabled value={'sel'}>Select category</MenuItem>
                                            {category && category?.length > 0 ? category.map((data) => (
                                                <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                            )) :
                                                <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                        </Select>
                                        {catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20">
                                    <Typography component='div' className='label' >Select Sub Category</Typography>
                                    <Box className="mb-10" style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl fullWidth style={{ flex: 1 }}>
                                            <Select
                                                className="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                disabled={categoryId !== '' ? false : true}
                                                value={subcategoryId ? subcategoryId : 'sel18'}
                                                onChange={(e) => { setSubCategoryId(e.target.value); setSubCatError('') }}>
                                                <MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
                                                {subcat && subcat?.length > 0 ? (
                                                    subcat.map((data5) => (
                                                        <MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
                                                    ))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
                                            </Select>
                                            {subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
                                        </FormControl>
                                        {subcategoryId !== '' && (
                                            <RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
                                        )}
                                    </Box>
                                </Box>

                                <Box className="mb-20">
                                    <Typography component='div' className='label' >Cover Image <Typography component='span' className='star'> * </Typography></Typography>
                                    <Box className="d-flex justify-content-between flex-wrap align-items-center">
                                        {statesingle.length < 1 &&
                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                                {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                                <Grid item xs={12} sm={12} md={12}  >
                                    {statesingle.length > 0 &&
                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                            {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <Box className='uploaded_img' >
                                                        <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                        <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                    </Box>
                                                </Grid>
                                            )) : ""}

                                        </Grid>
                                    }
                                </Grid>

                                <Box className="mb-20">
                                    <Typography component='div' className='label' >Description </Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <textarea
                                            placeholder='Short Description'
                                            className='textarea'
                                            defaultValue={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={4}
                                            cols={40} />
                                    </FormControl>
                                </Box>

                                <Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
                                </Box>

                            </Box>
                        </Box >
                    }
                    {
                        activeStep === 1 &&
                        <Box className="steppy_content mb-10">
                            <Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">

                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Typography component='div' className='label'>Images/Video </Typography>

                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                    <Box className=" ">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-customized-radios"
                                            name="payment_type"
                                            onChange={handleEditGallery}
                                            value={selectGallert}
                                        >
                                            <FormControlLabel value="Image" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Additional images</Typography>} />
                                            <FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
                                        </RadioGroup>
                                    </Box>
                                </Grid>
                                {selectGallert === 'Image' ? <>
                                    <Grid item xs={12} sm={12} md={12}  >
                                        <Box className=" ">
                                            <Typography component='div' className='label'>Additional Images<Typography component='span' className='star'> * </Typography></Typography>

                                            {state1.length < 1 &&
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChangeMultiple} hidden multiple type="file" />
                                                </Button>
                                            }
                                        </Box>
                                        {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}  >
                                        {state1.length > 0 &&
                                            <Grid container columnSpacing={1} rowSpacing={2} className="multiple_upload_container">
                                                {state1.length > 0 ? state1.map((x, i) => (
                                                    <Grid item xs={6} sm={4} md={3} lg={3} >
                                                        <Box className='uploaded_img' >
                                                            <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                            <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                        </Box>
                                                    </Grid>
                                                )) : ""}
                                                <Grid item xs={6} sm={4} md={3} lg={3} >
                                                    <label htmlFor="contained-button-file1" className="w-full">
                                                        <Box className='multiple_upload_btn' >
                                                            <Input
                                                                inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                onChange={multipleImgChangeMultiple}
                                                                style={{ display: "none", }}
                                                                id="contained-button-file1"
                                                                type="file"
                                                            />
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                        </Box>
                                                    </label>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                     

                                    </Grid>
                                </> : <>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <label className='form-labels' htmlFor="video-upload">Video<Typography component='span' className='star'> * </Typography></label>
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            {videoPreviewUrl && videoPreviewUrl?.length === 0 &&
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                    </Box>
                                                    <input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
                                                </Button>
                                            }
                                            {videoPreviewUrl && videoPreviewUrl?.length > 0 &&
                                                <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                    {videoPreviewUrl.length > 0 ? videoPreviewUrl.map((x, i) => (
                                                        <Grid item xs={6} sm={4} md={4} lg={4} >
                                                            <Box  >
                                                                <Button className='close_icon' onClick={(e) => clearVideo(e, i)}><Close /></Button>
                                                                <video className='uploaded_vdo' controls>
                                                                    <source src={x && x.slice && x.slice(0, 4) === "blob" ? x : (process.env.REACT_APP_BASE_URL + `${x}`)} height={155} width={195} />
                                                                </video>
                                                            </Box>
                                                          
                                                        </Grid>
                                                    )) : ""}
                                                     
                                                </Grid>
                                            }
                                             
                                        </Box>
                                        {videoAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{videoAddError}</p>}
                                    </Grid>


                                </>}
                            </Grid>
                        </Box>
                    }

                    <>
                        {activeStep === 1 && truebutton === false ?
                            <>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
                                    <Button onClick={handleUpdateNews} variant="contained" className='theme-btn'>Update</Button>
                                </Box>
                            </>
                            :
                            activeStep === 1 && truebutton === true &&
                            <>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
                                    <Button variant="contained" className='theme-btn  mr-10'>Please wait...</Button>
                                </Box>

                            </>
                        }
                    </>
                </Box>
            </Box>
        </Box>
    )
}