import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, IconButton, TextField, InputAdornment,  Box, Button, FormControl } from '@mui/material';
import TableCell, {  } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import PersonIcon from '@mui/icons-material/Person';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack'
import Switch, {  } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SearchOutlined, CloseOutlined, DeleteOutline } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';







const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};




BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DataTable() {
    const headerformdata = {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("token")
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [studios, setStudios] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const role = JSON.parse(localStorage.getItem("roledata"))
  

    React.useEffect(() => {
        getStudios()
    }, [page, rowsPerPage, searchTerm])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getStudios = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaStudio?size=${rowsPerPage}&page=${page}&search=${searchTerm}`, { headers: headerformdata }).then((res) => {
       
            setStudios(res.data.yogaStudio)
            setCount(res.data.totalcount)
        }).catch((err) => {
            console.log(err);
        })
    }


    const handleDelete = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `Admin/deleteYogaStudio/${deleteid}`, {}, { headers: headerformdata },).then((res) => {
            handleClose()
            getStudios()
            enqueueSnackbar("Yoga Studio deleted successfully", { variant: 'success' })

        }).catch((err) => {
            console.log(err);
        })
    }

    const handleChangeStatus = async (e, data) => {
        // Check if the subscription is true before toggling approval status
        if (!data.isSubscribed) {
            enqueueSnackbar('Please check the email for the payment link and complete the process.', { variant: 'error' });
            // Prevent toggling if not subscribed
            e.target.checked = !e.target.checked;
            return;
        }

        const body = {
            SetVerifyTeacher: e.target.checked,
        };

        try {
            await axios.put(
                process.env.REACT_APP_BASE_URL + `Admin/UpdateSetVerifyTeacher/${data._id}`,
                body,
                { headers: headerformdata }
            );
            getStudios();
            if (! e.target.checked) {
                enqueueSnackbar('Yoga Studio approved successfully.', { variant: 'success' }); // Toast message on success
            }  
            else if (e.target.checked) {
                enqueueSnackbar('Yoga Studio approve removed successfully.', { variant: 'success' });
            }
        } catch (err) {
            console.error("Error while toggling teacher:", err);
        }
    };

    const EditData = (row) => {
       
        let obj = {
            data: row
        }
        navigate("/edityogastudios", { state: obj })
    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleRemove = (e) => {
        setSearchTerm('');
        setPage(0)
    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>

                    
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <TableContainer id='table'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className='tble'>
                        <TableHead className='DT-head'>
                            <TableRow className=''>
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Profile Image</strong></TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Name</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Studio Name</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Email</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'> <strong>Approved</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Payment Status</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Specialization</strong> </TableCell>
                                <TableCell style={{ minWidth: 180 }} align="left" className='tbl_head'> <strong>Address</strong>  </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Phone</strong>  </TableCell>
                                {
                                    role.UserRoles.studio.deletestudio ||role.UserRoles.studio.editstudio?
                                    <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'> <strong>Action</strong>  </TableCell>
                                    :''
                                }
                              

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {studios && studios.length > 0 ? studios.map((row, i) => {
                                return (
                                    <TableRow className='tbody-row' key={row._id}>
                                        <TableCell align="center" className='DT-row'>
                                            {row.image
                                                ? <img className="cat_image" src={process.env.REACT_APP_BASE_URL + row.image} alt='' height={50} width={50} />
                                                : <PersonIcon style={{ height: 50, width: 50 }} /> // Replace PersonIcon with your actual icon component or element
                                            }
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="none" className='DT-row'  >{row.name ? row.name : '-'}</TableCell>
                                        <TableCell component="th" scope="row" padding="none" className='DT-row'  >{row.username ? row.username : '-'}</TableCell>
                                        <TableCell align="left" className='DT-row'  >{row.email ? row.email : '-'}</TableCell>
                                        <TableCell align="center" className='DT-row'  ><FormControlLabel className='m-0 justify-content-center' control={<Switch size='small' onChange={(e) => handleChangeStatus(e, row)} checked={row.SetVerifyTeacher} sx={{ m: 1 }} />} /></TableCell>
                                    
                                        <TableCell align="center" className='DT-row'>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '50%',
                                                height: '90%',
                                                borderRadius: '4px',
                                                padding: '8px',
                                                fontWeight: 'bold',
                                                fontSize: '12px',
                                                textTransform: 'uppercase',
                                                color: row.isSubscribed ? 'green' : 'red',
                                                backgroundColor: row.isSubscribed ? '#d3f5d3' : '#f5d3d3',
                                            }}>
                                                {row.isSubscribed ? 'Completed' : 'Pending'}
                                            </div>
                                        </TableCell>
                                       
                                        <TableCell align="left" className='DT-row'>
                                            {Array.isArray(row.specialization) && row.specialization.length > 0
                                                ? row.specialization.join(', ')
                                                : '-'}
                                        </TableCell>
                                        <TableCell align="left" className='DT-row'  >{row.address ? row.address : '-'}</TableCell>
                                        <TableCell align="left" className='DT-row' >{row.contact_number ? row.contact_number : '-'}</TableCell>
                                     
                                        <TableCell align="center" className='DT-row' style={{ cursor: 'pointer' }} >
                                            <Box className="flex-center justify-content-center actionbtns">
                                                {
                                                    role.UserRoles.studio.editstudio?
                                                    <Tooltip title="Edit"><Box className='a_tag' onClick={() => EditData(row)}><EditOutlinedIcon className='icons text-grey' /></Box></Tooltip>
                                                    :''
                                                }
                                               {role.UserRoles.studio.deletestudio?
                                                <Tooltip title="Delete"><Box ><DeleteOutline className='icons text-grey' onClick={() => handleOpen(row._id)} /></Box></Tooltip>
                                              :''
                                            }
                                               
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableCell colSpan={10} className='text-center semibold' align='center' > No data found </TableCell>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete Yoga Studio?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
