import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable_HomeTab'

export default function Hometab() {
    let navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem("roledata"))

    const AddHomeTab = () => {
        navigate('/addhometab');
    }
    return (
      <Box className="companies">
        <MiniDrawer menuOrder={1} submenuOrder={4} />
        <Box component="section" className="contentWraper">
          <Box className="headingBox">
            <Typography
              component="h4"
              variant="h4"
              className="Submenu-page-heading formTitle"
            >
              Home Tab
            </Typography>
            {role.UserRoles == null ? (
              <Button
                variant="contained"
                className="addbtn"
                onClick={() => AddHomeTab()}
              >
                {" "}
                <PlaylistAddRounded className="fz-18 mr-5" /> Add Home Tab{" "}
              </Button>
            ) : (
              role.UserRoles != null &&
              role.UserRoles.settings.Addsettings && (
                <Button
                  variant="contained"
                  className="addbtn"
                  onClick={() => AddHomeTab()}
                >
                  {" "}
                  <PlaylistAddRounded className="fz-18 mr-5" /> Add Home Tab{" "}
                </Button>
              )
            )}{" "}
          </Box>
          <DataTable />
        </Box>
      </Box>
    );
}