
import { Box,  Typography } from '@mui/material'
import React from 'react'

import MiniDrawer from '../../../components/drawer'
import DataTable from './Table_Newsletter'

export default function Newsletter() {
    

    // const AddCategory = () => {
    //     navigate('/addsubcategory');
    // }
    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={17}  />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Newsletter Subscription</Typography>
                    {/* <Button variant="contained" className='addbtn' onClick={() => AddCategory()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Sub Category</Button> */}
                </Box>
                <DataTable />
            </Box>
        </Box>
    )
}
