import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogTitle, FormControlLabel, Switch, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import axios from 'axios';

import { enqueueSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

import CircularProgress from '@mui/material/CircularProgress';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';






const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const QnATable = () => {

  const navigate = useNavigate()


  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [total, setTotal] = useState(0); // Total number of items
  const [dense] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [deleteid, setDeleteId] = React.useState("");

  const [question, setQuestion] = React.useState([]);





  // Fetch data from API
  const getQuestion = () => {
    setLoading(true); // Start loading
    axios
      .get(process.env.REACT_APP_BASE_URL + `Questions/GetquestionsToadmin`, {
        params: {
          page: page + 1,
          limit: rowsPerPage
        }
      })
      .then((res) => {
        setQuestion(res.data.questions);

        setLoading(false); // Set loading to false once data is fetched
        setTotal(res.data.totalQuestions); // Assuming the API returns total count of questions
      })
      .catch((err) => {
        console.error(err, "Error in fetching questions");
        setLoading(false); // Stop loading even in case of error
      });
  };

  // Fetch questions on mount and when page or rowsPerPage changes
  useEffect(() => {
    getQuestion();
  }, [page, rowsPerPage]); // Re-fetch on page or rowsPerPage change

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };






  function capitalizeFirstLetter(string) {

    if (!string) return string; // Check for empty string
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  const handleChangeViewmode = async (e, row) => {
    const newStatus = e.target.checked;


    try {
      // API endpoint to approve/reject the course /Questions/updateQuestionVisible/questionid
      const res = await axios.put(process.env.REACT_APP_BASE_URL + `Questions/updateQuestionVisible/${row._id}`,
        {
          isQuestionVisible: newStatus, // This should be the new status (true/false)
        });

      if (!res.data.UpdateVisible.isQuestionVisible) {
        enqueueSnackbar("Question is now visible", { variant: 'success' });
      }
      else {
        enqueueSnackbar("Question is now hidden", { variant: 'success' });

      }
      getQuestion()

    } catch (error) {
      console.error('API error:', error);
      // Optionally, revert the switch if the API call fails
    }
  };



  const navigateToViewPage = (row) => {

    navigate(`/QuestionandAnswerView/${row._id}`, { state: row })
  }

  const handleOpen = (id) => {
    setDeleteId(id)
    setOpen(true)
  }


  const handleDelete = () => {



    axios.put(process.env.REACT_APP_BASE_URL + `Questions/deletequestions/${deleteid}`)
      .then((res) => {
        getQuestion()
        enqueueSnackbar('Question Deleted Successfully', { variant: 'success' })
        setOpen(false);

      })
      .catch((err) => {
        console.log(err, "error in DeleteCourseInfo");
      })
  }

  const handleClose = () => {
    setDeleteId('')
    setOpen(false);
  };




  return (
    <>
      <Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead className="DT-head">
              <TableRow className="">
                <TableCell
                  align="left"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Question
                </TableCell>
                {/* <TableCell align="left" className='DT-checkbox TRow semibold' style={{ minWidth: 200, padding: '6px 16px' }}>Mega Event</TableCell> */}
                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Asked By
                </TableCell>
                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Answers Count
                </TableCell>
                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Visibility
                </TableCell>

                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Display loader while data is being fetched */}
            {loading ? (
              <TableBody className="characters">
                <TableRow>
                  <TableCell colSpan={12} align="center" className="DT-row">
                    <CircularProgress sx={{ color: '#f4733e' }} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="characters">
                {question && question.length > 0 ? (
                  question.map((row, i) => (
                    <TableRow key={i}>
                      {row && row.title ? (
                        <TableCell className="DT-row" align="left" style={{ minWidth: 200 }}>
                          {row.title.length > 150 ? row.title.slice(0, 150) + "..." : row.title}
                        </TableCell>
                      ) : (
                        <TableCell className="DT-row" style={{ minWidth: 200 }}>
                          -
                        </TableCell>
                      )}

                      {row && row.AskedBy && (
                        <StyledTableCell align="center" className="DT-row">
                          <Typography component="div">
                            {capitalizeFirstLetter(row.AskedBy.username)}
                          </Typography>
                        </StyledTableCell>
                      )}

                      <StyledTableCell align="center" className="DT-row">
                        {row.answersCount}
                      </StyledTableCell>

                      <StyledTableCell align="center" className="DT-row">
                        <FormControlLabel
                          className="m-0"
                          control={
                            <Switch
                              size="small"
                              onChange={(e) => handleChangeViewmode(e, row)}
                              checked={row.isQuestionVisible}
                            />
                          }
                        />
                      </StyledTableCell>

                      <StyledTableCell align="center" className="DT-row">
                        <Tooltip title="Submit Your Answer" arrow>
                          <ChatBubbleIcon onClick={() => navigateToViewPage(row)} className="fz-18 mr-5 icons text-grey" />
                        </Tooltip>

                        <DeleteIcon onClick={() => handleOpen(row._id)} className="fz-18 mr-5 icons text-grey" />
                      </StyledTableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="DT-row" align="center" colSpan={5}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}


          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="DT-pagination"
        />
      </Box>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="custom_modal"
      >
        <DialogTitle>Would you like to delete Question?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} className="cancel_btn">
            No
          </Button>
          <Button onClick={handleDelete} className="theme-btn">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QnATable