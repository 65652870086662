
import { Box} from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../../components/drawer'
import Description from './Desc'
import Sidebar from '../Sidebar'
import { useParams } from 'react-router-dom'


export default function DataTable() {
    const params = useParams()
    return (
        <Box className='settings withSubmenu '>         
            <MiniDrawer menuOrder={13} submenuOrder={23}/>
            <Sidebar subtype={1}/>
            <Box component="section" className="contentWraper">
                <Description params={params}/>
            </Box>
        </Box>
    );
}
