import { Box, Typography } from '@mui/material'
import React from 'react'
import {  useParams } from 'react-router-dom'
import MiniDrawer from '../../../../components/drawer'
import CourseTabs from './tabs'
import axios from 'axios'
import courselogo from '../../../../assets/images/courseimage.png'


export default function CourseDetail() {
    //UseState:
    const { id } = useParams()
    // const auth = JSON.parse(localStorage.getItem("userData"))
    const [title, setTitle] = React.useState('');
    const [courseprofile, setCourseProfile] = React.useState('')
  

    //hooks state:
    React.useEffect(() => {
        getChapters()
        // eslint-disable-next-line 
    }, [id])

    const getChapters = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`);
            setTitle(response.data && response.data?.course?.course_title);
            setCourseProfile(response.data && response.data.course?.image)
        } catch (error) {
            console.error(error);
        }
    }



    return (

        <Box className="">
             <MiniDrawer menuOrder={4} submenuOrder={13} />
            <Box component="section" className="contentWraper courseDetail_page">
                <Box className='headingBox justify-content-start'>

                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>{title}</Typography>
            
                    <img src={courseprofile?.length>0 ? process.env.REACT_APP_BASE_URL + courseprofile : courselogo} width={30} height={30} style={{marginLeft:10}} alt='' />
                    
                </Box>
                
                <Box className=''>
             <CourseTabs/>
                </Box>
            </Box>
        </Box>
    )
}


