import React, { useState } from "react"
import {  Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography,  IconButton } from '@mui/material'
import axios from 'axios'
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack'
import contactusLogo from './../../assets/images/contactusLogo.png';

import { getMessaging, getToken } from 'firebase/messaging';

import { useCookies } from 'react-cookie';
import 'firebase/compat/database';
import { messaging } from "../../firebase";

export default function Adimlogimn() {

    const [fcmkey, setFcmKey] = React.useState('')
    
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    const { enqueueSnackbar } = useSnackbar()
    let navigate = useNavigate();
    const [userdata, setUserData] = React.useState({ username: "", password: "" })
    const [error, setError] = useState({ username: "", password: "" })

    const [values, setValues] = React.useState({
        showPassword: false,
        showRePassword: false
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleClickShowRePassword = () => {
        setValues({
            ...values,
            showRePassword: !values.showRePassword,
        });
    };

    // React.useEffect(() =>  {
    //     getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo" })
    //     .then((currentToken) => {
    //         if (currentToken) {
    //           setFcmKey(currentToken)
    //         } else {
    //           console.log('No FCM token available.');
    //         }
    //       }).catch((error) => {
    //         console.error(error, 'Error getting FCM token:');
    //       });
    //   }, [])
    React.useEffect(() => {
        const fetchData = async () => {
          try {
            const currentToken = await getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo" });
            if (currentToken) {
              setFcmKey(currentToken);
            } else {
              console.log('No FCM token available.');
            }
          } catch (error) {
            console.error('Error getting FCM token:', error);
          }
        };
      
        fetchData(); // Call the async function
      
        // Note: Dependency array is empty, meaning this effect runs only once after mount
      
      }, []);

    let uname, uvalue
    const handleChange = (e) => {
        setUserData({ ...userdata, [e.target.name]: e.target.value })
        setError({ ...error, [e.target.name]: "" })
    }
    const validator = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        if (!userdata.username && !userdata.password) {
            setError({ username: "Email cannot be empty", password: "Password cannot be empty" })
            return false
        }
        else if (!userdata.username) {
            setError({ username: "Email cannot be empty" });
            return false
        }
        if (!userdata.username.match(emailRegex)) {
            setError({ username: "Email is invalid" });
            return false
        }
        else if (!userdata.password) {
            setError({ password: "Password cannot be empty" });
            return false
        }
       
        return true
    }

    const navigateuser=(roledata)=>{


        if (roledata.UserRoles.quotes && Object.values(roledata.UserRoles.quotes).includes(true)) {
            navigate("/yogaquote")
        }
       else if (roledata.UserRoles.tags && Object.values(roledata.UserRoles.tags).includes(true)) {
            navigate("/tag")
        }
        else if (roledata.UserRoles.settings && Object.values(roledata.UserRoles.settings).includes(true)) {
            navigate("/hometab")
        }
        else if (roledata.UserRoles.Teachers && Object.values(roledata.UserRoles.Teachers).includes(true)) {
            navigate("/teachers")
        }
        else if (roledata.UserRoles.legends && Object.values(roledata.UserRoles.legends).includes(true)) {
            navigate("/yogalegend")
        }
        else if (roledata.UserRoles.merit && Object.values(roledata.UserRoles.merit).includes(true)) {
            navigate("/teachermerit")
        }
        else if (roledata.UserRoles.school && Object.values(roledata.UserRoles.school).includes(true)) {
            navigate("/yogaschool")
        }

        else if (roledata.UserRoles.studio && Object.values(roledata.UserRoles.studio).includes(true)) {
            navigate("/yogastudios")
        }
        else if (roledata.UserRoles.Event&& Object.values(roledata.UserRoles.Event).includes(true)) {
            navigate("/events")
        }
        else if (roledata.UserRoles.Course&& Object.values(roledata.UserRoles.Course).includes(true)) {
            navigate("/course")
        }
        else if (roledata.UserRoles.Blogs&& Object.values(roledata.UserRoles.Blogs).includes(true)) {
            navigate("/blogs")
        }
        else if (roledata.UserRoles.Images&& Object.values(roledata.UserRoles.Images).includes(true)) {
            navigate("/image")
        }
        else if (roledata.UserRoles.Videos&& Object.values(roledata.UserRoles.Videos).includes(true)) {
            navigate("/video")
        }
        else if (roledata.UserRoles.Category&& Object.values(roledata.UserRoles.Category).includes(true)) {
            navigate("/category")
        }
        else if (roledata.UserRoles.SubCategory&& Object.values(roledata.UserRoles.SubCategory).includes(true)) {
            navigate("/subcategory")
        }
        else if (roledata.UserRoles.About&& Object.values(roledata.UserRoles.About).includes(true)) {
            navigate("/about")
        }
        else if (roledata.UserRoles.subscription&& Object.values(roledata.UserRoles.subscription).includes(true)) {
            navigate("/subscription")
        }
        else if (roledata.UserRoles.News&& Object.values(roledata.UserRoles.News).includes(true)) {
            navigate("/news")
        }
        else if (roledata.UserRoles.Jobs&& Object.values(roledata.UserRoles.Jobs).includes(true)) {
            navigate("/career")
        }
        else if (roledata.UserRoles.Community&& Object.values(roledata.UserRoles.Community).includes(true)) {
            navigate("/community")
        }
        else if (roledata.UserRoles.BookReview&& Object.values(roledata.UserRoles.BookReview).includes(true)) {
            navigate("/bookreviews")
        }
        else if (roledata.UserRoles.Podcast&& Object.values(roledata.UserRoles.Podcast).includes(true)) {
            navigate("/podcast")
        }
        else if (roledata.UserRoles.AddManagements&& Object.values(roledata.UserRoles.AddManagements).includes(true)) {
            navigate("/offers")
        }
        else if (roledata.UserRoles.newsletter&& Object.values(roledata.UserRoles.newsletter).includes(true)) {
            navigate("/newsletter")
        }
        else if (roledata.UserRoles.users&& Object.values(roledata.UserRoles.users).includes(true)) {
            navigate("/users")
        }
        else{
            return;
        }




    }


//Login functionality:
    const handleLogin =  (e) => {
        e.preventDefault();
        var validate = validator();

        if (validate) {
            var body = {
                email: userdata.username,
                password: userdata.password,
                FCM_token: fcmkey != '' ? fcmkey : undefined
            }
         
            axios.post(process.env.REACT_APP_BASE_URL + "Admin/AdminLogin", body)
                .then((res) => {
                    localStorage.setItem("userData", JSON.stringify(res.data.admin))
                    localStorage.setItem("roledata", JSON.stringify(res.data.mergedUser))
                
                    
                    localStorage.setItem("token", res.data.token)
                    localStorage.setItem("fcmkey", fcmkey)
                    console.log(res.data.mergedUser)
                    console.log(res.data.admin)

                    setCookie('value', res.data.token);
                    
                    // firebase.database().ref(`Users/${res.data.admin._id}`).update({
                    //     fcm: fcmkey,
                    //     isOnline: true
                    // })
                    const roledata=res.data.mergedUser;


                 
                    if(roledata.usertype==="User")
                    {
                        
                       navigateuser(roledata)
                   

                    }
                    else{
                        enqueueSnackbar("Admin Login Successfully", { variant: 'success' });
                        navigate("/admindashboard")
                    }
                  
                        
                    
                
                   
                })
                // .catch((err) => {
                //     if (err.response) {
                //         console.log(err.response.data);
                //         enqueueSnackbar(err.response.data.error, { variant: 'error' });
                //     } else if (err.request) {
                //         console.log(err.request);
                //         setError({ password: "Something went wrong" });
                //         enqueueSnackbar("Something went wrong", { variant: 'error' });
                //     } else {
                //         console.log('Error', err.message);
                //         setError({ password: "Something went wrong" });
                //         enqueueSnackbar("Something went wrong", { variant: 'error' });
                //     }
                // });
                .catch((err) => {
                    console.log(err,"error123");
                    setError({ username: "", password: "" });
                    if (err.response) {
                        if (err.response.data.error.includes("email")) {
                            setError({ ...error, username: err.response.data.error });
                        } else if (err.response.data.error.includes("Password")) {
                            setError({ ...error, password: err.response.data.error });
                        } else if(err.response.data.error == "data and hash arguments required"){
                            setError({...error,password:"Kindly Check Your Register email and set Password"})
                            enqueueSnackbar("Kindly Check Your Register email and set Password", { variant: 'error' });

                        }
                        
                        else {
                            setError({ ...error, password: err.response.data.error });
                        }
                        // enqueueSnackbar(err.response.data.error, { variant: 'error' });
                    } else {
                        setError({ ...error, password: "Something went wrong" });
                        enqueueSnackbar("Something went wrong", { variant: 'error' });
                    }
                })
        }
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin(e)
        }
    }

    return (
        <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                        <Box className='logintext_box'>
                            <h1 className='heading semibold'>Yoga</h1>
                            <Typography component='p' variant='body1' className='subheading'>Yoga takes you into present moment. The only place where life exists. </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='Login_content'>
                        <Box className="header_main_left">
                            <img src={contactusLogo} height={100} />
                        </Box>
                        <Typography component='h4' variant='h4'>Admin Login</Typography>
                        <Box className='form'>

                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Email</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Enter your Email'
                                    name='username'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Person className='start_icon' /></InputAdornment>,
                                    }}
                                />
                              {error.username && <p style={{color:'red'}} className='error-field'>{error.username}</p> }
                            </FormControl>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Password</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    placeholder='Enter your Password'
                                    type={values.showRePassword ? 'text' : 'password'}
                                    className='textfield'
                                    name='password'
                                    onKeyDown={_handleKeyDown}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowRePassword}
                                                    edge="end">
                                                    {values.showRePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                />
                             {error.password && <p style={{color:'red'}} className='error-field'>{error.password}</p>}
                            </FormControl>
                        </Box>
                        <Button className='loginbtn mt-0' onClick={handleLogin}>LOGIN</Button>
                        <Box className="logintype">
                            <Box className='a-link'>
                                {/* <Typography component='a' href='/'>Login as Teacher </Typography> */}
                            </Box>
                            <Box className='a-link'>
                                {/* <Typography component='a' href='/superdminforgotpassword'>Forgot your password?</Typography> */}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}