import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable_Legend'

export default function TeachersMerit() {
  let navigate = useNavigate()
  const role = JSON.parse(localStorage.getItem("roledata"))

  const addYogaLegend = () => {
    navigate('/addyogalegend');
  }
  return (
    <Box className="companies">
      <MiniDrawer menuOrder={2} submenuOrder={6} />
      <Box component="section" className="contentWraper">
        <Box className="headingBox">

          <Typography
            component="h4"
            variant="h4"
            className="Submenu-page-heading formTitle"
          >
            Yoga Legend
          </Typography>

          {role.UserRoles == null ? (

            <Button
              variant="contained"
              className="addbtn"
              onClick={() => addYogaLegend()}
            >
              {" "}
              <PlaylistAddRounded className="fz-18 mr-5" /> Add Yoga Legend
            </Button>

          ) : (role.UserRoles.legends.Addlegends && (
            <Button
              variant="contained"
              className="addbtn"
              onClick={() => addYogaLegend()}
            >
              {" "}
              <PlaylistAddRounded className="fz-18 mr-5" /> Add Yoga Legend
            </Button>
          )
          )}{" "}
        </Box>
        <Box className="whiteContainer">
          <DataTable />
        </Box>
      </Box>
    </Box>
  );
}